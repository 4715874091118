
export class ReferenceField {
    name: string;
    value: string;
    fieldName: string;
    fieldValue: string;
    dataType: string;
    minLength: number;
    maxLength: number;
    placeholder: string;
  
    constructor(name: string, value: string, fieldName: string, fieldValue: string, dataType: string, minLength: number, maxLength: number, placeholder: string) {
      this.name = name;
      this.value = value;
      this.fieldName = fieldName;
      this.fieldValue = fieldValue;
      this.dataType = dataType;
      this.minLength = minLength;
      this.maxLength = maxLength;
      this.placeholder = placeholder;
    }
  
  }