import { Directive, Input } from '@angular/core';
import { AbstractControl, FormControl, Validator, ValidatorFn } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appDateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true
    }]
})
export class DateValidatorDirective implements Validator {

  validator: ValidatorFn;

  constructor() {
    this.validator = this.dateValidator();
  }

  validate(control: FormControl) {
    return this.validator(control);
  }

  dateValidator(): ValidatorFn {
    return (control: FormControl) => {

      if (control.value && typeof (control.value) !== typeof (String) &&
        control.value.year && control.value.month && control.value.day) {
        return null;
      } else if (control.value && (typeof control.value === 'string' || control.value instanceof String)) {
        const isDate = /\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*/.test(control.value.toString());
        if (isDate) {
          return null;
        } else {
          return {
            datevalidator: {
              valid: false
            }
          };
        }
      } else {
        return {
          emailvalidator: {
            valid: false
          }
        };
      }
    };
  }
}
