import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../shared/app-constant';
import { map } from 'rxjs/operators';
import { ResultModel } from './models/result.model';
import { BulkRefundFilter } from './models/bulk-refund-filter.model';
import { BulkRefundFilterResponse } from './models/bulk-refund-filter-response.model';
import { BulkRefundDownloadTemplateResponse } from './models/bulk-refund-download-template-response.model';
import { BulkRefundUploadFileRequest } from './models/bulk-refund-upload-file-request.model';
import { GenerateBulkRefundIdResponse } from './models/generate-bulk-refund-id-response.model';
import { AuthenticationService } from './auth/authentication.service';
import { BulkRefundDownloadFileResponse } from './models/bulk-refund-download-file-response.model';

@Injectable({
  providedIn: 'root'
})
export class BulkRefundService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public search(request: BulkRefundFilter): Observable<BulkRefundFilterResponse> {
    return this.http.post(environment.bulkRefundSearch, request).pipe(map((response: BulkRefundFilterResponse) => {
      return response
    }));
  }

  public downloadFileTemplate(): Observable<BulkRefundDownloadTemplateResponse> {
    return this.http.get(environment.downloadBulkRefundTemplate)
      .pipe(map((response: BulkRefundDownloadTemplateResponse) => {
        return response;
      }))
  }

  public generateBulkRefundId(): Observable<GenerateBulkRefundIdResponse> {
    return this.http.get(environment.generateBulkRefundId)
      .pipe(map((response: GenerateBulkRefundIdResponse) => {
        return response;
      }))
  }

  public uploadFile(bulkRefundUploadFileRequest: BulkRefundUploadFileRequest): Observable<BulkRefundDownloadTemplateResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    const params = new FormData();
    params.append('file', new Blob([bulkRefundUploadFileRequest.file], { type: bulkRefundUploadFileRequest.fileType }), bulkRefundUploadFileRequest.file.name);
    params.append('batchId', bulkRefundUploadFileRequest.bulkRefundId);
    params.append('userId', currentUser.userId.toString());
    return this.http.post(environment.bulkRefundUploadFile, params)
      .pipe(map((response: BulkRefundDownloadTemplateResponse) => {
        return response
      }));
  }


  public deleteFile(bulkRefundId: string): Observable<ResultModel> {
    const url = environment.deleteBulkRefundFile
      .replace("{bulkRefundId}", bulkRefundId);
    return this.http.post(url, {})
      .pipe(map((response: BulkRefundDownloadTemplateResponse) => {
        return response
      }));
  }

  public cancelFile(bulkRefundId: string): Observable<ResultModel> {
    const url = environment.cancelBulkRefundFile
      .replace("{bulkRefundId}", bulkRefundId);
    return this.http.post(url, {})
      .pipe(map((response: BulkRefundDownloadTemplateResponse) => {
        return response
      }));
  }

  public downloadFile(bulkRefundId: string): Observable<BulkRefundDownloadFileResponse> {
    const url = environment.downloadBulkRefundFile
      .replace("{bulkRefundId}", bulkRefundId);
    return this.http.post(url, {})
      .pipe(map((response: BulkRefundDownloadFileResponse) => {
        return response;
      }))
  }
}
