import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentLink } from '../../services/models/payment-link';
import { PaymentLinkService } from '../../services/payment-link.service';
import { STATUS, PAYMENT_LINK_STATUS, CACHE_OBSERVABLE_SERVICE, PAYMENT_LINK_CLONE_MESSAGE, PAYMENT_LINK_DISABLE_MESSAGE, API_ERROR } from '../../shared/app-constant';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { CacheObservableService } from '../../services/cache-observable.service';
import { Authority, AUTHORITY } from '../../shared/authority.util';
import { Subject } from 'rxjs';
import { PaymentLinkCloneResponse } from '../../services/models/payment-link-clone.model';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.css']
})
export class PaymentLinkComponent implements OnInit {

  public link: PaymentLink;
  isAllowClone: boolean;
  isAllowDisable: boolean;

  private isClone: boolean;

  constructor(
    private route: ActivatedRoute,
    private service: PaymentLinkService,
    private router: Router,
    private santitizer: DomSanitizer,
    private commonService: CommonService,
    private location: Location,
    private cacheObservableService: CacheObservableService
  ) { }

  ngOnInit() {
    this.isClone = false;
    this.link = new PaymentLink();
    this.getLink();
    this.setAllowClone();
    this.isAllowDisable = Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_EDIT]) && Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_VIEW]);
  }

  setAllowClone() {
    this.isAllowClone = false;

    this.service.getMasterMerchantIndicator().subscribe((res: any) => {
      if (res.status_code == 'internal_permission_denied') {
        this.router.navigate(['/nopermission']);
        this.commonService.isLoading(false);
        return;
      }

      this.isAllowClone = Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_EDIT]) && Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_VIEW])
                          && !res.masterMerchantIndicator;
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  getLink() {
    this.commonService.isLoading(true);
    this.service.get(this.route.snapshot.params['linkRef']).subscribe((res: any) => {
      this.commonService.isLoading(false);
      if (res.status_code == STATUS.SUCCESS) {
        this.link = res.paymentLink;
      } else if (res.status_code == 'internal_permission_denied') {
        this.router.navigate(['/nopermission']);
      }
      else {
        this.router.navigate(['/404']);
      }
    })
  }

  getType(paymentLink: PaymentLink) {
    return paymentLink.type == 'MULTIPLE' ? 'Multiple times' : 'One time'
  }

  getQR() {
    return this.santitizer.bypassSecurityTrustUrl(this.link.qrCode);
  }

  disableLink() {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.commonService.isLoading(true);
        this.service.disable(this.link).subscribe((res: any) => {
          if (res.status_code == 'internal_permission_denied') {
            this.router.navigate(['/nopermission']);
          } else {
          this.getLink();
          }
          this.commonService.isLoading(false);
        });
      }
    });
    this.commonService.alertConfirm(PAYMENT_LINK_DISABLE_MESSAGE.TITLE, PAYMENT_LINK_DISABLE_MESSAGE.MESSAGE, callback);
  }

  isDisplayDisable() {
    return this.link.status != PAYMENT_LINK_STATUS.DISABLED.toUpperCase()
    && this.link.status != PAYMENT_LINK_STATUS.PAID.toUpperCase()
    && this.isAllowDisable;
  }

  goBack() {
    //this.location.back();
    this.router.navigate(['/payment-link']);
  }

  gotoTransaction() {
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT, this.link.ref);
    this.router.navigate(['/transaction']);
  }

  clone() {
    this.commonService.isLoading(true);
    this.service.clone(this.link.ref).subscribe((response: PaymentLinkCloneResponse) => {
      if (this.commonService.isSuccess(response)) {
        const callback = new Subject<boolean>();
        callback.asObservable().subscribe(result => {
          if (result === true) {
            this.isClone = true;
            this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_CLONE, response.paymentLink);
            this.router.navigate(["/payment-link/create"]);
          }
        });
        if (response.paymentLink.isOriginal) {
          this.commonService.alertConfirm(PAYMENT_LINK_CLONE_MESSAGE.TITLE, PAYMENT_LINK_CLONE_MESSAGE.MESSAGE, callback);
        } else {
          this.commonService.alertConfirm(PAYMENT_LINK_CLONE_MESSAGE.TITLE_CLONE_INVALID, PAYMENT_LINK_CLONE_MESSAGE.MESSAGE_CLONE_INVALID, callback);
        }
      } else if (response.status_code == API_ERROR.INTERNAL_PERMISSION_ERR) {
        this.router.navigate(['/nopermission']);
      }
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
    
  }

}
