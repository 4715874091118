import { Component, OnInit, Input } from '@angular/core';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { CommonService } from '../../services/common.service';
import { SFTPUserInfoModel, SFTPAccountModel } from '../../services/models/sftp-account.model';
import { ResultModel } from '../../services/models/result.model';

@Component({
  selector: 'app-merchant-setting-sftp-acc-info',
  templateUrl: './merchant-setting-sftp-acc-info.component.html',
  styleUrls: ['./merchant-setting-sftp-acc-info.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantSettingSftpAccInfoComponent implements OnInit {

  @Input() companyId: string;
  listSFTPAccount: SFTPUserInfoModel[];
  viewDetail: boolean = false;
  isFirstTime: boolean = false;
  
  constructor(private merchantSettingService: MerchantSettingService, private commonService: CommonService) { }

  ngOnInit() {
    this.listSFTPAccount = [];
    this.isFirstTime = true;
  }

  onClickViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (this.viewDetail && this.isFirstTime) {
      this.getSFTPAccount();
    }
    this.isFirstTime = false;
  }

  getSFTPAccount() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getSFTPAccount(parseInt(this.companyId, 10)).subscribe((sftpAccountResponse: SFTPAccountModel) => {
      if (this.commonService.isSuccess(sftpAccountResponse)) {
        this.listSFTPAccount = sftpAccountResponse.sftpUserInfos;
        this.listSFTPAccount.map((sfptInfo) => sfptInfo.isDisplayPassphrase = true);
      } else {
        this.commonService.alertError('Fail', sftpAccountResponse.status_message, null);
      }
      this.commonService.isLoading(false);
    }, error => {
      this.commonService.isLoading(false);
    });
  }

  viewPassphrase(sftpInfo: SFTPUserInfoModel) {
    this.commonService.isLoading(true);
    this.merchantSettingService.getPassphrase(sftpInfo.sftpUserId).subscribe((passphraseResponse: ResultModel) => {
      if (this.commonService.isSuccess(passphraseResponse)) {
        let temSftpInfo = this.listSFTPAccount.find((sftpAccount) => sftpAccount.sftpUserId === sftpInfo.sftpUserId);
        temSftpInfo.isDisplayPassphrase = false;
        temSftpInfo.passphrase = passphraseResponse.properties.decryptKeyPassphrase;
        this.listSFTPAccount.splice(this.listSFTPAccount.indexOf(sftpInfo), 1, temSftpInfo);
      } else {
        this.commonService.alertError('Fail', passphraseResponse.status_message, null);
      }
      this.commonService.isLoading(false);
    }, error => {
      this.commonService.isLoading(false);
    });
  }

}
