import { Component, OnInit } from '@angular/core';
import { PaymentLink } from '../../services/models/payment-link';
import { PaymentLinkService } from '../../services/payment-link.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { Authority, AUTHORITY } from '../../shared/authority.util';
import { PaymentLinkSearchRequest } from '../../services/models/payment-link-search-request.model';
import { CACHE_OBSERVABLE_SERVICE } from '../../shared/app-constant';

const PAYMENT_LINK_TYPE = [
  { key: '', value: "All" },
  { key: 'ONE_TIME', value: "One-time" },
  { key: 'MULTIPLE', value: "Multiple" }
];
const PAYMENT_LINK_STATUS = [
  { key: '', value: "All" },
  { key: 'SCHEDULED', value: "Scheduled" },
  { key: 'ACTIVE', value: "Active" },
  { key: 'PAID', value: "Paid" },
  { key: 'DISABLED', value: "Disabled" },
  { key: 'EXPIRED', value: "Expired" },
];

@Component({
  selector: 'app-payment-links',
  templateUrl: './payment-links.component.html',
  styleUrls: ['./payment-links.component.css']
})
export class PaymentLinksComponent implements OnInit {

  linkTypes: any[];
  linkStatuses: any[];
  links: PaymentLink[];
  request: PaymentLinkSearchRequest;
  collectionSize: number;
  isAllowCreated: boolean;
  isShowSearch: boolean;

  public paymentLinkStatus: any;
  public linkType: string;
  // Payment Link Status
  public currentStatus: string;
  public selectedList: any[];

  constructor(
    private paymentLinkService: PaymentLinkService,
    private router: Router,
    private commonService: CommonService,
    private cacheObservableService: CacheObservableService
  ) { }

  ngOnInit() {
    this.commonService.isLoading(true);
    this.isShowSearch = false;
    
    this.selectedList = [];
    this.request = new PaymentLinkSearchRequest();
    this.request.type = [];
    this.request.status = PAYMENT_LINK_STATUS[0].key;
    this.linkStatuses = PAYMENT_LINK_STATUS;
    this.linkTypes = PAYMENT_LINK_TYPE;
    this.linkType = PAYMENT_LINK_TYPE[0].key;

    this.setAllowCreated();
    this.paymentLinkStatus = PAYMENT_LINK_STATUS;
    this.currentStatus = '';
    if (this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SEARCH)) {
      this.request = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SEARCH);
      this.selectedList = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SELECTED_LIST);
      this.currentStatus = this.request.status;
      this.search(this.request);
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SEARCH);
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SELECTED_LIST);
    } else {
      this.getLinks();
    }
  }

  setAllowCreated() {
    this.isAllowCreated = false;

    this.paymentLinkService.getMasterMerchantIndicator().subscribe((res: any) => {
      if (res.status_code == 'internal_permission_denied') {
        this.router.navigate(['/nopermission']);
        this.commonService.isLoading(false);
        return;
      }

      this.isAllowCreated = Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_EDIT]) && Authority.hasAuthorities([AUTHORITY.PAYMENT_LINK_VIEW])
                          && !res.masterMerchantIndicator;
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  getLinks(index?: number) {
    this.commonService.isLoading(true);
    if (index) {
      this.request.pageIndex = index
    }
    this.request.type = [];
    this.linkTypes.forEach(linkType => {
      if (this.linkType && linkType.key == this.linkType) {
        this.request.type.push(linkType.key);
      }
    });

    this.search(this.request);
  }

  goToDetail(link: PaymentLink) {
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SEARCH, this.request);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINKS_SELECTED_LIST, this.selectedList);
    this.router.navigate(['payment-link/' + link]);
  }

  goToCreate() {
    this.router.navigate(['payment-link/create']);
  }

  loadPage(index: number) {
    this.request.pageIndex = index;
    this.getLinks();
  }

  searchAdvance(event: any) {
    this.isShowSearch = false;
    const status = this.request.status;
    this.request = event.request;
    this.selectedList = event.selectedSearchs;
    this.linkType = this.request.type && this.request.type.length == 1 ? this.request.type[0] : '';
    this.request.status = status;
    this.search(this.request);
  }

  search(request) {
    this.links = [];
    this.paymentLinkService.getPaymentLinks(request).subscribe((res: any) => {
      if (res.status_code == 'internal_permission_denied') {
        this.router.navigate(['/nopermission']);
        this.commonService.isLoading(false);
        return
      }
      if(res.total_elements === 0) {
       this.links = []; 
      } else this.links = res.paymentLinks;
      this.collectionSize = res.total_elements;
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  closeSearch() {
    this.isShowSearch = false;
  }

  filterPaymentLinkStatus(status: string) {
    if (this.currentStatus !== status) {
      this.currentStatus = status;
      this.request.status = status;
      this.getLinks(1);
  }
  }

  isCurrentPaymentLinkStatus(status: string) {
    return status === this.currentStatus;
  }

}
