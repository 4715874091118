
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

import { environment } from '../shared/app-constant';
import { SummaryRequest } from './models/summary-request';
import { SummaryResponse } from './models/summary-response';
import { SummaryMonthlyResponse } from './models/summary-monthly-response';
import { CommonService } from './common.service';
import { SummaryMonthlyItem } from './models/summary-monthly-item';

@Injectable()
export class SummaryService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private commonService: CommonService
    ) {
  }

  getSummaries(request: SummaryRequest): Observable<SummaryResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(
      environment.summaryUrl.replace('{userId}', currentUser.userId.toString()),
      JSON.stringify(request)).pipe(
      map((response: SummaryResponse) => {
        // console.log(response);
        return response;
      }));
  }

  summaryMonthly(request: SummaryRequest): Observable<SummaryMonthlyResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(
      environment.summaryMonthlyUrl.replace('{userId}', currentUser.userId.toString()),
      JSON.stringify(request)).pipe(
      map((response: SummaryMonthlyResponse) => {
        if (this.commonService.isSuccess(response)) {
          return response;
        }
      }));
  }

}
