import { Component, OnInit, OnDestroy, ViewChild, QueryList, ElementRef, AfterViewInit, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { TransactionService } from '../../services/transaction.service';
import { TransactionInfoModel } from '../../services/models/transaction-info.model';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CommonService } from '../../services/common.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { CacheObservableService } from '../../services/cache-observable.service';
import { Router } from '@angular/router';

import { TransactionStatus } from '../../shared/transaction-status.enum';
import { TransactionStatusState } from '../../shared/transaction-status-state.enum';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

import { faCreditCard, faQrcode, faLock, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faWeixin, faAlipay, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { TRANSACTION_STATUS, ELEMENT, CACHE_OBSERVABLE_SERVICE, SEARCH_ADVANCE, SUB_FILTER, ERROR_CODE, message, ERROR_MESSAGES } from '../../shared/app-constant';
import { TransactionUtil } from '../../shared/transaction.util';
import { AdvanceSearchFormInterface } from '../advancesearch/advance-search-form-interface';
import { AdvancesearchComponent } from '../advancesearch/advancesearch.component';
import { cloneObject } from '../../shared/common.util';
import { faWeChatpay } from '../../shared/fortawesome-custom-icon';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class TransactionComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(AdvancesearchComponent) private advSearchComponent: AdvancesearchComponent
  @ViewChildren('listFilter') listFilter: QueryList<ElementRef>;
  @ViewChild('subRefundedFilterEle') subRefundedFilterEle: ElementRef;

  faCreditCard = faCreditCard;
  faQrcode = faQrcode;
  faLock = faLock;
  faWeixin = faWeixin;
  faAlipay = faAlipay;
  faPaypal = faPaypal;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faWeChatpay = faWeChatpay;

  isMobile = false;
  currentMenuInfo: any = {};
  groupList: any = [];
  selectedList = [];
  selectedListValue = {};
  selectedExportCriteria = [];
  selectedExportCriteriaValue = {};
  selectListConfig = [
    'cardNo',
    'productDescription',
    'sourceType',
    'sourceOfFund',
    'paymentType',
    'cardType',
    'bankName',
    'refNumber',
    'objectId',
    'secureTransaction',
    'refTrxId',
    'currencyCode',
    'amount',
    'transactionDate',
    'company',
    'mobileNo',
    'merchantId',
    'refundId',
    'ref1',
    'ref2',
    'ref3',
    'transactionStatus',
    'paymentLinkRef',
    'supplierNo',
    'channel',
    'bulkRefundId'
  ];

  request: TransactionRequestModel;
  transactionModel: any = {};
  companyList: any = {};

  isShowLinkTransactionDetail = false;
  isClickViewTransactiondetail = false;
  isClickAdvanceSearch: boolean;
  isCancelSearch: boolean;

  // Pagination
  collectionSize: number;

  // Transaction Status
  currentStatus: string;
  subStatusRefunded: string;

  //filter list
  filterList = [
    TRANSACTION_STATUS.ALL,
    TRANSACTION_STATUS.INITIALIZED,
    TRANSACTION_STATUS.AUTHORIZED,
    TRANSACTION_STATUS.DECLINED,
    TRANSACTION_STATUS.VOIDED,
    TRANSACTION_STATUS.SETTLED,
    TRANSACTION_STATUS.REFUNDED
  ]

  //limit display od records
  limit: number;
  totalDays: number;
  public subRefundedFilters = [SUB_FILTER.ALL, SUB_FILTER.PARTIAL_REFUND, SUB_FILTER.REFUNDED, SUB_FILTER.REFUND_FAILED];

  public refunded = TRANSACTION_STATUS.REFUNDED;
  public isExceedLimit: boolean;

  constructor(
    private commonService: CommonService,
    private auth: AuthenticationService,
    private transactionservice: TransactionService,
    private cacheObservableService: CacheObservableService,
    private router: Router,
    private changeDefectRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.commonService.scrollToTopPage(ELEMENT.MAIN_CONTENT_ID);
    this.isCancelSearch = false;
    console.log('Transaction : (ngOnInit)... ');
    this.currentMenuInfo = this.auth.getCurrentUserMenuInfo().find(x => x.url === 'transaction');
    this.isShowLinkTransactionDetail = this.commonService.isVisibleLinkTransactionDetail();
    if (this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK)) {
      this.request = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK);
      this.isClickAdvanceSearch = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.SEARH_ADVANCE);
      this.selectedExportCriteria = cloneObject(this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA));
      this.selectedExportCriteriaValue = cloneObject(this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA_VALUE));
      this.selectedList = cloneObject(this.request.selectedSearchList);
      this.selectedListValue = cloneObject(this.request.selectedListValue);
      console.log('selectedSearchList : ');
      console.log(this.request);
      this.setFilterTransactionStatusFromCache();
    } else if (this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT)) {
      this.request = this.setRequestTransaction();
      this.isClickAdvanceSearch = false;
      this.request.paymentLinkRef = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT);
      console.log('selectedSearchList : ');
      console.log(this.request);
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT);
    } else {
      this.request = this.setRequestTransaction();
      this.isClickAdvanceSearch = false;
    }
    this.getTransaction();
    this.setCompanyNameDisplay();
  }

  ngAfterViewInit(): void {
    let marginLeftRefunded = 0;
    // list filter button
    const filters = this.listFilter.toArray();
    // get position Partial Refund filter
    const indexOfRefunded = filters.indexOf(filters.find((value) => value.nativeElement.textContent == TRANSACTION_STATUS.REFUNDED.toLocaleUpperCase()));
    // count width all button left side of Partial Refund filter
    for (let i = 0; i < indexOfRefunded; i++) {
      marginLeftRefunded += filters[i].nativeElement.offsetWidth;
    }
    // minus some pixel to sub filter set right position
    marginLeftRefunded -= indexOfRefunded;
    if (this.subRefundedFilterEle) {
      this.subRefundedFilterEle.nativeElement.style.marginLeft = marginLeftRefunded + 'px';
    }
    this.changeDefectRef.detectChanges();
  }

  ngOnDestroy() {
    console.log('TransactionComponent ngOnDestroy');
    if (!this.isClickViewTransactiondetail && this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK)) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK);
    }
    if (!this.isClickViewTransactiondetail && this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT)) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_REDIRECT);
    }
  }

  viewTransactiondetail(ObjectId: string) {
    console.log('viewTransactiondetail ' + ObjectId);
    this.isClickViewTransactiondetail = true;
    console.log('viewTransactiondetail : ');
    console.log(this.selectedList);
    if (!this.isCancelSearch) {
      this.setCache();
    }
    this.router.navigate(['/transactiondetail/' + ObjectId]);
  }

  onApplyFilter(event: { request: TransactionRequestModel, selectedCriteria: Array<any>, selectedCriteriaValue: AdvanceSearchFormInterface, isCancel?: boolean }) {
    if (event.isCancel) {
      this.isCancelSearch = event.isCancel;
      return;
    }
    this.isCancelSearch = false;
    this.isClickAdvanceSearch = true;
    this.request = event.request;
    this.request.pageIndex = 1;
    this.selectedList = cloneObject(event.selectedCriteria);
    this.selectedListValue = cloneObject(event.selectedCriteriaValue);
    for (const status of this.advSearchComponent.exportTransactionStatus) {
      status.value = event.request.status ? event.request.status.includes(status.name) : false;
    }
    this.selectedExportCriteria = JSON.parse(JSON.stringify(event.selectedCriteria));
    this.selectedExportCriteriaValue = JSON.parse(JSON.stringify(event.selectedCriteriaValue));
    this.subStatusRefunded = TRANSACTION_STATUS.ALL;
    this.setCache();
    this.getTransaction();
  }

  loadPage(pageIndex: number) {
    this.request.pageIndex = pageIndex;
    this.getTransaction();
  }

  private setCache() {
    this.request.selectedSearchList = this.selectedList;
    this.request.selectedListValue = this.selectedListValue;
    const trxStatusState = this.request.trxStatusState;
    const clone = JSON.parse(JSON.stringify(this.request));
    clone.trxStatusState = trxStatusState;
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK, clone);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.FILTER_TRANSACTION_STATUS, this.currentStatus);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SUB_FILTER_REFUNDED_STATUS, this.subStatusRefunded);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SEARH_ADVANCE, this.isClickAdvanceSearch);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA, this.selectedExportCriteria);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA_VALUE, this.selectedExportCriteriaValue);
  }

  private setRequestTransaction(): TransactionRequestModel {
    const request = new TransactionRequestModel();
    const dateRef1 = new Date();
    const dateRef2 = new Date();
    dateRef1.setHours(0,0,0,0);
    dateRef2.setHours(23,59,59,0);
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });

    request.trxState = [];
    request.pageIndex = 1;
    request.pageSize = 15;
    request.searchDate = [] 
    request.searchDate.push({
      searchTypeDate : 'created',
      dateRef1 : dateRef1,
      dateRef2 : dateRef2
    })

    this.currentStatus = TRANSACTION_STATUS.ALL;
    this.subStatusRefunded = TRANSACTION_STATUS.ALL;
    request.trxStatusState = [];

    return request;
  }

  private setCompanyNameDisplay() {
    this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return this.companyList[element.companyId] = { name: element.companyIdName };
    });
  }

  private getTransaction() {
    this.commonService.isLoading(true);
    this.transactionservice.getTransaction(this.request).subscribe(
      result => {
        this.clearTransactionList();
        this.currentStatus = TransactionUtil.getStatusCurrent(this.request.trxStatusState);
        this.isExceedLimit = false; 
        if (this.commonService.isSuccess(result)) {
          this.transactionModel = result;
          this.groupingTransaction();
        } else {
          if (result.status_code == ERROR_CODE.TRX_EXCEED_LIMIT) {
            this.isExceedLimit = true;
            this.commonService.alertError(message.title.error, result.status_message, null);
          }
        }
        this.limit = result && result.total_limit ? result.total_limit : 0;
        this.totalDays = result && result.total_days ? result.total_days : 0;
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  groupingTransaction() {
    this.collectionSize = +this.transactionModel.total_elements;
    for (const group of this.transactionModel.trx_info_summaries) {
      console.log('trx group: ' + group);
      const groupName = group.groupDate.split(' ').shift();
      if (this.groupList.some(x => x.groupName === groupName)) {
        const modelGroup = this.groupList.find(x => x.groupName === groupName);
      } else {
        const transactionList = this.transactionModel.trx_infos.filter(m =>
          m.CreateDate.split(' ').shift() === groupName);

        const modelGroup = {
          groupDate: group.groupDate,
          groupName: groupName,
          transactionList: transactionList
        };
        this.groupList.push(modelGroup);
      }
    }
  }

  clearTransactionList() {
    this.collectionSize = 0;
    this.groupList = [];
  }

  getStatusColorClass(transaction: TransactionInfoModel) {
    const StatusIdNumber = toInteger(transaction.StatusId);
    const StatusStateIdNumber = toInteger(transaction.StatusStateId);
    let colorClass = {};
    if ((StatusIdNumber === TransactionStatus.Settled || StatusIdNumber === TransactionStatus.Qr_Settled)
      && StatusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-green': true };
    } else if ((StatusIdNumber === TransactionStatus.Refunded || StatusIdNumber === TransactionStatus.Qr_Refunded)
      && StatusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-red': true };
    } else if ((StatusIdNumber === TransactionStatus.Declined
      || StatusIdNumber === TransactionStatus.Voided
      || StatusIdNumber === TransactionStatus.Reversed
      || StatusIdNumber === TransactionStatus.Qr_Voided)
      && StatusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-darklight': true };
    } else if ((StatusIdNumber === TransactionStatus.Authorized)
      && (StatusStateIdNumber === TransactionStatusState.Wait_For_Authentication
        || StatusStateIdNumber === TransactionStatusState.Wait_For_Confirm
        || StatusStateIdNumber === TransactionStatusState.Fail)) {
      colorClass = { 'text-darklight': true };
    } // otherwise: black font color.
    // return colorClass;
    return colorClass;
  }

  setFilterTransactionStatusFromCache() {
    this.currentStatus = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.FILTER_TRANSACTION_STATUS);
    this.subStatusRefunded = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.SUB_FILTER_REFUNDED_STATUS);
    this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.FILTER_TRANSACTION_STATUS);
    this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.SUB_FILTER_REFUNDED_STATUS);
  }

  filterTransactionStatus(status: string) {
    if (this.currentStatus !== status) {
      this.currentStatus = status;
      this.request.trxStatusState = TransactionUtil.setTrxStatusState(this.currentStatus, this.subStatusRefunded).value;
      this.request.refundStatus = status == TRANSACTION_STATUS.REFUNDED ? this.subStatusRefunded : null;
      this.request.pageIndex = 1;
      this.request.status = [status];
      for (const criteria of this.selectedExportCriteria) {
        if (criteria.name == SEARCH_ADVANCE.TRANSACTION_STATUS) {
          for (const trxStatus of criteria.transactionStatus) {
            trxStatus.value = trxStatus.name == status
          }
        }
      }
      for (const criteria of this.selectedList) {
        if (criteria.name == SEARCH_ADVANCE.TRANSACTION_STATUS) {
          for (const trxStatus of criteria.transactionStatus) {
            trxStatus.value = trxStatus.name == status
          }
        }
      }
      for (const status of this.advSearchComponent.exportTransactionStatus) {
        status.value = this.request.status ? this.request.status.includes(status.name) : false;
      }
      this.getTransaction();
    }
  }

  isCurrentTransactionStatus(status: string) {
    return status === this.currentStatus;
  }

  showAdvanceSearch(option?: any) {
    this.commonService.visibleSearch(true, option);
  }

  selectSubFilters(status, filter) {
    switch(filter){
      case TRANSACTION_STATUS.REFUNDED:
        if (this.subStatusRefunded == status) {
          return
        }
        this.subStatusRefunded = status;
        this.request.trxStatusState = TransactionUtil.setTrxStatusState(this.currentStatus, this.subStatusRefunded).value;
        this.request.refundStatus = status;
        this.request.pageIndex = 1;
        this.getTransaction();
        break;
    }
  }

}
