import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveGuard implements CanActivate {

  constructor(
    private _routes: Router,
    private _auth: AuthenticationService,
    private _commonService: CommonService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    // const url: string = next.url[0].path;
    return this.disableRegister(url);
  }

  disableRegister(url: string): boolean {
    console.log('checkActive');
    if (url === '/register' && this._commonService.isEnvironmentLive()) {
      this._routes.navigate(['/404']);
      return false;
    }

    return true;
  }
}
