import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { MerchantSettingQRResponse } from '../../services/models/merchant-setting-qr.model';

@Component({
  selector: 'app-merchant-configuration-qr',
  templateUrl: './merchant-configuration-qr.component.html',
  styleUrls: ['./merchant-configuration-qr.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationQrComponent implements OnInit {

  @Input() companyId: string;

  isOpenQRSof: boolean = false;
  merchantSettingQRResponse: MerchantSettingQRResponse;
  loadFlag: boolean = false;

  constructor(
    private merchantSetting: MerchantSettingService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  onClickMoreDetail() {
    this.isOpenQRSof = !this.isOpenQRSof;
    if(this.loadFlag){
      return;
    }
    this.getMerchantQr();
    this.loadFlag = true;
  }

  getMerchantQr(){
    this.commonService.isLoading(true);
    this.merchantSetting.getMerchantSettingQR(this.companyId).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.merchantSettingQRResponse = result;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

}
