import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { CompanyConfigRequestModel } from '../../services/models/company-config-request.model';
import { DigiPayInfo } from '../../services/models/digipay-info.model';
import { InstallmentCrossBankResponse } from '../../services/models/installment-cross-bank-response.model';
import { Request } from '../../services/models/request';

@Component({
  selector: 'app-merchant-setting-company-installment-cross-bank',
  templateUrl: './merchant-setting-company-installment-cross-bank.component.html',
  styleUrls: ['./merchant-setting-company-installment-cross-bank.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantSettingCompanyInstallmentCrossBankComponent implements OnInit {

  @Input() companyId: string;
  
  public viewDetail: boolean = false;
  public digiPayInfos: DigiPayInfo[];
  public pageIndex: number;
  public pageSize: number;
  public totalElements: number = 0;
  public isFirstTime: boolean = false;
  
  constructor(private commonService: CommonService, private merchantSettingService: MerchantSettingService) { }

  ngOnInit() {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.isFirstTime = true;
  }

  clickViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (this.viewDetail && this.isFirstTime) {
      this.getDigipayInfo()
    }
    this.isFirstTime = false;
  }

  loadPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.getDigipayInfo();
  }

  private getDigipayInfo() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getDigipayInfo(this.companyId, this.pageIndex).subscribe((response: InstallmentCrossBankResponse) => {
      if (this.commonService.isSuccess(response)) {
        if (response.digipayInfo) {
          this.digiPayInfos = response.digipayInfo;
          this.totalElements = response.totalElement;
          this.pageSize = response.pageSize
        }
      }
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }
}
