import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { SmartpayConfigRequestModel } from '../../services/models/smartpay-config-request.model';
import { MerchantSettingCompanyInfoResultModel } from '../../services/models/merchant-setting-company-info-result.model';
import { TransactionLimit } from '../../services/models/transaction-limit.model';
import { CompanyConfigRequestModel } from '../../services/models/company-config-request.model';
import { DigiPayInfo } from '../../services/models/digipay-info.model';

@Component({
  selector: 'app-merchant-setting-company-info-selector',
  templateUrl: './merchant-setting-company-info-selector.component.html',
  styleUrls: ['./merchant-setting-company-info-selector.component.css']
})
export class MerchantSettingCompanyInfoSelectorComponent implements OnInit {
  companyId;
  entryEnabled = false;
  configEnabled = false;
  isShowConfig: boolean;
  isShowSFTP: boolean;
  companyInfoModel: MerchantSettingCompanyInfoResultModel;
  editingFlag = false;
  transactionLimit: TransactionLimit;
  public companyConfigRequest: CompanyConfigRequestModel;
  public digiPayInfos: DigiPayInfo[];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonService,
    private merchantSettingService: MerchantSettingService
  ) { }

  ngOnInit() {
    this.isShowSFTP = false;
    this.isShowConfig = false;
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    const request = new SmartpayConfigRequestModel();
    request.companyId = this.companyId;
    this.merchantSettingService.getSFTPAccount(request.companyId).subscribe(result => {
      if (this.commonService.isSuccess(result) && result.sftpUserInfos.length > 0) {
        this.isShowSFTP = true;
      }
    });
    this.merchantSettingService.getTransactionLimit(this.companyId).subscribe((transactionLimit) => {
      this.transactionLimit = transactionLimit;
    });
    this.doEntry();
    this.companyConfigRequest = new CompanyConfigRequestModel();
  }

  doEntry() {
    this.entryEnabled = true;
    this.configEnabled = false;
  }

  doConfig() {
    if (this.isShowConfig) {
      this.entryEnabled = false;
      this.configEnabled = true;
    }
  }

  goBack() {
    this.location.back();
  }

  onCompanyFetched(cInfo) {
    this.companyInfoModel = cInfo;
  }

}
