export class BulkRefundFilter {
    from?: Date;
    to?: Date;
    status?: string;
    bulkRefundId?: string;
    pageIndex?: number;

    constructor(from?: Date, to?: Date, status?: string, bulkRefundId?: string, pageIndex?: number) {
        this.from = from;
        this.to = to;
        this.status = status;
        this.bulkRefundId = bulkRefundId;
        this.pageIndex = pageIndex;
    }
}