import { Component, OnInit, Input } from '@angular/core';
import { CampaignInfoModel, CampaignInfoResponseModel } from '../../services/models/campaign-info.model';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { ToasterService } from '../../services/toaster.service';
import { Request } from '../../services/models/request';

@Component({
  selector: 'app-merchant-setting-campaign-info',
  templateUrl: './merchant-setting-campaign-info.component.html',
  styleUrls: ['./merchant-setting-campaign-info.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantSettingCampaignInfoComponent implements OnInit {

  @Input() companyId: string;
  campaignInfoList: CampaignInfoModel[];
  viewDetail: boolean = false;
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  isFirstTime: boolean = false;

  constructor(
    private commonService: CommonService,
    private toasterService: ToasterService,
    private merchantSettingService: MerchantSettingService
  ) { }

  ngOnInit() {
    this.campaignInfoList = [];
    this.pageIndex = 1;
    this.pageSize = 10;
    this.isFirstTime = true;
  }

  onClickViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (this.viewDetail && this.isFirstTime) {
      this.getListCampaignInfo();
    }
    this.isFirstTime = false;
  }

  getListCampaignInfo() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getCampaignInfo(this.companyId, this.pageIndex).subscribe(
      (response: CampaignInfoResponseModel) => {
        if (this.commonService.isSuccess(response)) {
          this.campaignInfoList = response.campaignInfos;
          this.totalElements = response.totalElement;
          this.pageSize = response.pageSize;
        }
        this.commonService.isLoading(false);
      }
    ), err => {
      this.commonService.isLoading(false);
      this.toasterService.error('Something went wrong!');
    }
  }

  loadPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.getListCampaignInfo();
  }

}
