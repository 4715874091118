import { UserInfoModel } from '../models/userinfo.model';
import { MenuInfoModel } from '../models/menuinfo.model';
import { CompanyInfoModel } from '../models/companyinfo.model';
import { ResultModel } from './result.model';

export class UserModel extends ResultModel {
    // status_code: string;
    // status_message: string;

    userInfo: UserInfoModel;
    menuInfo: MenuInfoModel[];
    companyInfos: CompanyInfoModel[];
    switchCompanyInfos: CompanyInfoModel[];
    passwordWillExpire: number;
    activeCompany: number;
    token: string;

    constructor(
        userInfo: UserInfoModel, menuInfo: MenuInfoModel[], companyInfos: CompanyInfoModel[],
        switchCompanyInfos?: CompanyInfoModel[], passwordWillExpire?: number,
        activeCompany?: number, token?: string
    ) {
        super();
        this.userInfo = userInfo;
        this.menuInfo = menuInfo;
        this.companyInfos = companyInfos;
        this.switchCompanyInfos = switchCompanyInfos;
        this.passwordWillExpire = passwordWillExpire ? passwordWillExpire : null;
        this.activeCompany = activeCompany;
        this.token = token;
    }
}
