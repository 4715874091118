
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

import { environment } from '../shared/app-constant';
import { MultipleRefundRequestModel, MultipleRefundResponse } from './models/multiple-refund-response.model';


@Injectable()
export class MultipleRefundService {

    constructor(
        private http: HttpClient,
        private auth: AuthenticationService
    ) { }
    getMultipleRefundSelect(request: MultipleRefundRequestModel): Observable<MultipleRefundResponse> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.getMultipleRefundSelect.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request)).pipe(
                map((response: MultipleRefundResponse) => {
                    return response;
                }));
    }
}
