import { TransactionRequestDateModel } from './transaction.request.model.date';

export class CustomerRequestModel {
    companyId: number[];

    customerEmail: string;
    customerName: string;
    customerObjectId: string;
    searchDate: TransactionRequestDateModel[];

    pageIndex: number;
    pageSize: number;
}
