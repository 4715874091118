import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardNumber'
})
export class CreditCardNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return value.replace(/[^\d*]/g, '').replace(/(.{4})/g, '$1 ').trim();
    } else {
      return null;
    }

  }

}
