import { Component, AfterViewInit, ViewContainerRef, OnInit } from '@angular/core';
import { trigger, animate, style, group, animateChild, query, stagger, transition, state } from '@angular/animations';

import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/auth/authentication.service';
import { fadeAnimation } from './animations';
import { ELEMENT } from './shared/app-constant';

@Component({
  selector: 'app-root',
  animations: [fadeAnimation],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'app';

  isLoggedIn = false;
  isSelectCompany = false;
  idMainContent = ELEMENT.MAIN_CONTENT_ID;

  constructor(
    private translate: TranslateService,
    private auth: AuthenticationService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    translate.addLangs(['en', 'th']);
  }

  ngOnInit() {
    this.subscribeAuthenticated();
    this.subscribeSelectedCompany();
  }

  getState(outletRef) {
    const r = outletRef.isActivated ? outletRef.activatedRoute : '';
    // console.log('test route:' + r);
    return r;
  }
  private subscribeAuthenticated() {
    this.auth.isAuthenticated$.subscribe(data => {
      this.isLoggedIn = data;
    });
  }
  private subscribeSelectedCompany() {
    this.auth.isSelectedComapany$.subscribe(data => {
      this.isSelectCompany = data;
    });
  }

  private isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  ngAfterViewInit(): void {
    const appHeader = document.querySelector('app-header') as HTMLElement;
    const bodySection = document.querySelector('.body-section') as HTMLElement;

    const contentHeight = 'calc(100% - ' + appHeader.offsetHeight + 'px)';
    bodySection.style.height = contentHeight;
    if(this.isMobile()){
      const contentHeightForMobile = 'calc(86vh - 14em)';
      bodySection.style.maxHeight = contentHeightForMobile;
    }
  }
}
