import { CardSmartpay } from "./card-smartpay.model";
import { Payplus } from "./payplus.model";

export class PaymentLink {
    public serviceName: string;
    public description: string;
    public currency: string;
    public amount: number;
    public type: string;
    public maxPaymentTime?: string;
    public activeTime: any;
    public expireTime: any;
    public ref: string;
    public createDate: Date;
    public status: string;
    public url: string;
    public qrCode: string;
    public paidTime: number;
    public orderReference: string;
    public merchantId: string;
    public refMax1: number;
    public refMax2: number;
    public refMax3: number;
    public refMin1: number;
    public refMin2: number;
    public refMin3: number;
    public refName1: string;
    public refName2: string;
    public refName3: string;
    public refType1: string;
    public refType2: string;
    public refType3: string;
    public ref1: string;
    public ref2: string;
    public ref3: string;
    public info1: string;
    public info2: string;
    public info3: string;
    public merchantName: string;
    public merchantLocation: string;
    public cardSmartPay: CardSmartpay;
    public payPlusKPlus: Payplus;
    public payPlusPromptpay: Payplus;
    public payPlusAppswitch: Payplus;
    public sourceOfFund: string[];
}

export class PaymentLinkMerchant {
    public merchantId: string;
    public allowVCNBin: boolean;
}
