import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _auth: AuthenticationService, private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = next.url[0].path;
    return this.checkRole(url);
  }

  checkRole(url: string): boolean {
    console.log('checkRole');
    const userMenu = this._auth.getCurrentUserMenuInfo();
    const isSelectedCompany = this._auth.isSelectedCompany();
    if (!userMenu) {
      // Navigate to the no permission page with extras
      this._router.navigate(['/nopermission']);
      return false;
    }
    if (userMenu.some(x => x.url === url)) {
      if(!isSelectedCompany){
        this._router.navigate(['/book-mark']);
        return;
      }
      return true;
    } else if (!isSelectedCompany) {
      return true;
    } else if (url === 'book-mark' && isSelectedCompany) {
      // Navigate to the defalut page with extras
      const defaultMenu = userMenu.find(m => m.defaultScreen);
      if (defaultMenu) {
        this._router.navigateByUrl(defaultMenu.url);
        return;
      }
      // Navigate to the dashboard with extras
      if (userMenu.some(x => x.url === 'dashboard')) {
        this._router.navigate(['/dashboard']);
      }
    }
  }
}
