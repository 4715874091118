import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../shared/app-constant";
import { VerifyPaymentLinkModel } from "./models/verify-payment-link.model";
import { VerifyPaymentLinkResultModel } from "./models/verify-payment-link-result.model";
import { map } from "rxjs/operators";
import { ResultModel } from "./models/result.model";
import { Observable } from "rxjs";
// import { PaymentLinkSearchRequest } from "./models/payment-link-search-request.model";

@Injectable()
export class VerifyPaymentLinkService {

    constructor(
        private http: HttpClient
    ) { }

    getCaptchaSiteKey(): string {
        return environment.captchaSiteKey;
    }

    verifyPaymentLink(request: VerifyPaymentLinkModel): Observable<VerifyPaymentLinkResultModel> {
        console.log('## invoke service verifyPaymentLink...');
        return this.http.post<VerifyPaymentLinkResultModel>(
            environment.verifyPaymentLink,
            JSON.stringify(request));
    }
}
