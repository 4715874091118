import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ReportConfigModel } from '../../services/models/report-config.model';
import { CompanyInfoModel } from '../../services/models/companyinfo.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { TransactionService } from '../../services/transaction.service';
import { ReportConfigService } from '../../services/report-config.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../services/toaster.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { DateUtil } from '../../shared/date.util';
import { message, FORMAT, HTTP_ERROR_MESSAGE } from '../../shared/app-constant';
import { CommonService } from '../../services/common.service';
import { FormDateModel } from '../../services/models/form-date.model';
import { TransactionUtil } from '../../shared/transaction.util';
import { CountReportRequest } from '../../services/models/count-report-request.model';
import { ExportReportRequest } from '../../services/models/export-report-request.model';
import { MultiselectDropdownConfig } from '../../services/models/multiselect-dropdown-config.model';
import { PromotionInfo } from '../../services/models/promotion-info.model';
import { PromotionRequest } from '../../services/models/promotion-request.model';
import { SupplierNumberListResponse } from '../../services/models/supplier-number-list-response.model';

const isEquals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const isBefore = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const isAfter = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const MINIMUM_EXPORT_DATE = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const MINIMUM_EXPORT_DATE_ALLOW_TWO_YEARS = new Date(new Date().setFullYear(new Date().getFullYear() - 2));

const REPORT_TYPE = {
  INSTALLMENT_PLAN: "Installment_Plan",
  SETTLEMENT: "Settlement",

}

@Component({
  selector: 'app-transaction-create-report',
  templateUrl: './transaction-create-report.component.html',
  styleUrls: ['./transaction-create-report.component.css']
})

export class TransactionCreateReportComponent implements OnInit, OnDestroy {

  reportConfigId: number;
  reportConfigType: string;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  model: FormDateModel = {};
  reportConfigModels: ReportConfigModel[];
  reportConfigModel: ReportConfigModel;
  totalRecords = 0;

  // Pagination
  collectionSize: number;

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDateMobile: Date;
  // set max date at input date on mobile
  maxDateMobile: Date;
  // not exceed XX days
  notExceedDays: number;

  listCompanyInfo: CompanyInfoModel[] = [];
  selectedCompanies: CompanyInfoModel[] = [];
  companyDropdownSettings: Object;
  invalidToDate: string;
  invalidFromDate: string;
  invalidExceedDays: string;

  public companyIds: number[];
  public configSupplierNumberDropdown: MultiselectDropdownConfig;
  public configProductNameDropdown: MultiselectDropdownConfig;
  public configModelNameDropdown: MultiselectDropdownConfig;
  public isSelectedSupplierNumber: boolean; // show/hide Product Type and Model Number
  // list promotion when init data
  public promotions: PromotionInfo[];

  public selectedSupplierNumber: PromotionInfo[]; // data for Supplier Number dropdown
  public selectedProductName: PromotionInfo[]; // data for Product Type dropdown
  public selectedModelName: PromotionInfo[]; // data for Model Number dropdown

  public isLoadingModelNumber: boolean;
  public supplierPlanReportType = REPORT_TYPE.INSTALLMENT_PLAN;
  public settlementReportType = REPORT_TYPE.SETTLEMENT;
  public selectOlder180Days: boolean;
  public allowTransactionForTwoYears: boolean;
  public minus180Days: Date;

  // private request: TransactionRequestModel;
  private isBackToReport: boolean = false;
  private selectedCompaniesOld: CompanyInfoModel[] = [];
  // list selected supplier number
  private supplierNumbers: string[];
  // list selected model no
  private modelNoList: string[];
  // list selected product type
  private productTypeList: number[];
  private minDays: number;
  private maxDays: number;
  public digipayReportId: string;

  isHovered = inputDate => this.fromDate && !this.toDate && this.hoveredDate && isAfter(inputDate, this.fromDate) && isBefore(inputDate, this.hoveredDate);
  isInside = inputDate => isAfter(inputDate, this.fromDate) && isBefore(inputDate, this.toDate);
  isFrom = inputDate => isEquals(inputDate, this.fromDate);
  isTo = inputDate => isEquals(inputDate, this.toDate);
  isDisabled = (date: NgbDate) => {
    return date.after(NgbDate.from(this.maxDate));
  }


  constructor(
    private _transactionService: TransactionService,
    private _reportConfigService: ReportConfigService,
    private _router: Router,
    private _toasterService: ToasterService,
    private _cacheObservableService: CacheObservableService,
    private _commonService: CommonService,
    private changeDefectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.minDate = DateUtil.dateToNgbDateStruct(MINIMUM_EXPORT_DATE);
    const yesterday = new Date(new Date().getTime() - (86400 * 1000));
    this.fromDate = DateUtil.formatToNgbDate(yesterday);
    this.toDate = DateUtil.formatToNgbDate(yesterday);
    this.initData();
  }

  initData() {
    this.companyDropdownSettings = {
      singleSelection: false,
      idField: 'companyId',
      textField: 'companyNameEn',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    this.listCompanyInfo = JSON.parse(sessionStorage.getItem('currentUser')).companyInfos;
    this.selectedCompanies = this.listCompanyInfo.map((info) => {
      const company = new CompanyInfoModel();
      company.companyId = info.companyId;
      company.companyNameEn = info.companyNameEn.charAt(0).toUpperCase() + info.companyNameEn.slice(1).toLowerCase();
      return company;
    });
    this.listCompanyInfo = [...this.selectedCompanies];
    this.setDefaultDateRange();
    // set default date on Mobile
    this.model.from = DateUtil.ngbDateStructToDate(this.fromDate);
    this.model.to = DateUtil.ngbDateStructToDate(this.toDate);

    // set min/max date for from-date input on Mobile
    this.minDateMobile = DateUtil.ngbDateStructToDate(this.minDate);
    this.maxDateMobile = DateUtil.ngbDateStructToDate(this.maxDate);

    this._reportConfigService.getReportConfig()
      .subscribe(response => {
        if (response.reportConfig) {
          this.reportConfigModels = response.reportConfig;
          for (let i = 0; i < this.reportConfigModels.length; i++) {
            this.reportConfigModel = this.reportConfigModels[i];
            if(!response.installmentCrossBank && this._transactionService.isInstallmentCrossBank(this.reportConfigModels[i].reportConfigName) ) {
              this.reportConfigModels.splice(i, 1);
              i--;
            }
          }
        }
        this.minDays = response.minDays;
        this.maxDays = response.maxDays;
        this.notExceedDays = response.notExceedDays;
        this.allowTransactionForTwoYears = response.allowTransactionForTwoYears;
        this.isSelectedSupplierNumber = false;
        this._commonService.isLoading(false);
      }, (err) => {
        this._commonService.isLoading(false);
        console.error('_reportConfigService error=')
      });
    this.selectedSupplierNumber = [];
    this.selectedProductName = [];
    this.selectedModelName = [];
    this.isLoadingModelNumber = false;
    this.selectOlder180Days = false;
  }

  onDateSelection(inputDate: NgbDateStruct) {
    this.invalidFromDate = null;
    this.invalidToDate = null;
    this.invalidExceedDays = null;
    this.totalRecords = 0;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = inputDate;
      this.model.from = DateUtil.ngbDateStructToDate(inputDate);
      // update max-date limit 60 days from formDate or 180 date with installment plan report
      // Settlement and not check into checkbox -> maxDate is current day
      // Settlement and check into checkbox -> maxDate is current - 181 days
      this.maxDate = DateUtil.dateToNgbDateStruct(this.setMaxDateOnCalendar());
      this.changeDefectorRef.detectChanges();
    } else if (this.fromDate && !this.toDate && (isAfter(inputDate, this.fromDate) || isEquals(inputDate, this.fromDate))) {
      this.toDate = inputDate;
      this.model.to = DateUtil.ngbDateStructToDate(inputDate);
      this.setDefaultDateRange();
    } else {
      this.fromDate = inputDate;
      this.toDate = null;
      this.model.from = DateUtil.ngbDateStructToDate(inputDate);
      this.model.to = null;
      this.maxDate = DateUtil.dateToNgbDateStruct(this.setMaxDateOnCalendar());
      this.maxDateMobile = this.setMaxDateOnCalendar();
      this.changeDefectorRef.detectChanges();
    }

    if (this.model.from && this.model.to) {
      this.setDefaultDateRange();
      this.refreshTotalRecords(this.model.from, this.model.to);
    }

  }

  fromDateChange(event: any) {
    this.invalidFromDate = null;
    this.invalidToDate = null;
    this.invalidExceedDays = null;
    this.totalRecords = 0;
    this.model.from = event.target.valueAsDate;
    this.fromDate = DateUtil.dateToNgbDateStruct(event.target.valueAsDate);
    if (this.checkDateRange('from')) {
      this.refreshTotalRecords(this.model.from, this.model.to);
    }
  }

  toDateChange(event: any) {
    this.invalidFromDate = null;
    this.invalidToDate = null;
    this.invalidExceedDays = null;
    this.totalRecords = 0;
    this.model.to = event.target.valueAsDate;
    this.toDate = DateUtil.dateToNgbDateStruct(this.model.to);
    if (this.checkDateRange('to')) {
      this.refreshTotalRecords(this.model.from, this.model.to);
    }
  }


  /**
   * Count total transaction record follow condition from, to, reportConfigId
   * @param from
   * @param to
   */
  refreshTotalRecords(from: Date, to: Date) {
    this.totalRecords = 0;
    if (!from || this.reportConfigId === undefined || !this.checkDateRange()
      || (TransactionUtil.isEmpty(this.selectedCompanies) && this.reportConfigType != REPORT_TYPE.INSTALLMENT_PLAN)
      || (this.reportConfigType == REPORT_TYPE.INSTALLMENT_PLAN &&
        ((!this.supplierNumbers || this.supplierNumbers.length == 0)))
    ) {
      return;
    }

    if (!this.validateNotExceedDays()) {
      return;
    }

    this._commonService.isLoading(true);
    this.companyIds = this.selectedCompanies.map((info) => info.companyId);
    const request = new CountReportRequest(from, to, this.reportConfigId, this.companyIds, this.supplierNumbers, this.modelNoList, this.productTypeList);
    this._transactionService.getTotalRecords(request).subscribe(
      result => {
        this.selectedCompaniesOld = this.selectedCompanies;
        if (this._commonService.isSuccess(result)) {
          if (!this.invalidToDate && !this.invalidFromDate) {
            this.totalRecords = result.properties.count;
            this.digipayReportId = result.properties.digipayReportId;
          }
        }
        this._commonService.isLoading(false);
      }, err => {
        this._commonService.isLoading(false);
        // console.log('err: ' + err);
        this._commonService.alertError('Information',
          'ขออภัย ไม่สามารถเรียกดูรายงานได้ กรุณาลดช่วงเวลาในการดึงข้อมูล และทำรายการใหม่อีกครั้ง \n' +
          'Sorry, couldn\'t load the data. Please decrease the date range and try again later.', null);
      }
    );
  }

  exportReport() {
    if (this.reportConfigId === undefined) {
      return
    }
    this._commonService.isLoading(true);
    const createReportRequest = new ExportReportRequest(
      this.model.from, this.model.to, this.reportConfigId, this.companyIds, this.totalRecords, this.supplierNumbers, this.modelNoList, this.productTypeList, this.digipayReportId);
    this._transactionService.exportTransactionReportFile(createReportRequest).subscribe(
      result => {
        if (this._commonService.isSuccess(result)) {
          if (this._cacheObservableService.has('transaction_report_goback')) {
            this._cacheObservableService.remove('transaction_report_goback');
          }
          this._toasterService.success(message.content.processing);
          this.backToReport();
        } else {
          this._toasterService.error(message.content.create_fail);
        }
      },
      error => {
        if (error.status != '401') {
          this._toasterService.error(HTTP_ERROR_MESSAGE[error.status] || message.content.common_error);
        }
      }, () => {
        this._commonService.isLoading(false);
      }
    );
  }

  changeReportType(): void {
    this.totalRecords = 0;
    if (!this.reportConfigId) {
      this.reportConfigType = "";
      this.isSelectedSupplierNumber = false;
      this.minDateMobile = MINIMUM_EXPORT_DATE;
      this.maxDateMobile = new Date();
      this.minDate = DateUtil.dateToNgbDateStruct(this.minDateMobile);
      this.maxDate = DateUtil.dateToNgbDateStruct(this.maxDateMobile);
      return
    }
    this.selectedCompaniesOld = [];
    this.selectedCompanies.map(company => this.selectedCompaniesOld.push(company));
    this.reportConfigModel = this.reportConfigModels.find((config) => config.reportConfigId == this.reportConfigId);
    this.reportConfigType = this.reportConfigModel.reportConfigType;
    this.setDefaultDateRange();
    // setup data for supplier plan report
    if (this.reportConfigType == REPORT_TYPE.INSTALLMENT_PLAN) {
      this._commonService.isLoading(true);
      this.minDate = null;
      this.selectedSupplierNumber = [];
      this.supplierNumbers = [];
      this.productTypeList = [];
      this.modelNoList = [];
      // set data to shown on Supplier Number field
      if (!this.promotions || this.promotions.length == 0) {
        this._reportConfigService.getSupplierNumberList().subscribe((response: SupplierNumberListResponse) => {
          if (this._commonService.isSuccess(response)) {
            this.promotions = response.supplierNumberList;
            this.promotions.forEach((promotion) => {
              const tmpPromotions = promotion;
              tmpPromotions['displayName'] = this.setNameForDropdown(promotion.supplyNo, promotion.supplyName);
              this.selectedSupplierNumber.push(tmpPromotions);
              // config dropdown Supplier Number
              this.configSupplierNumberDropdown = new MultiselectDropdownConfig(
                'supplyNo', 'displayName', 'Select All', 'UnSelect All', 3, true, this.selectedSupplierNumber, 'supplyNo', false, true);
            });
          }
          this._commonService.isLoading(false);
        }, err => {
          this._commonService.isLoading(false);
        });
      } else {
        this._commonService.isLoading(false);
      }
      if (this.configSupplierNumberDropdown && this.configSupplierNumberDropdown.selectAll) {
        this.selectedSupplierNumber.forEach((promotion => {
          this.supplierNumbers.push(promotion.supplyNo);
        }));
        let promotionRequest = new PromotionRequest();
        promotionRequest.supplierNumber = this.supplierNumbers;
        // from supplier number get data for Product Type and Model Number dropdown
        this.getProductTypeAndModelNo(promotionRequest);
      }
      if (!this.checkDateRange()) {
        this.selectYesterdayOnCalendar(MINIMUM_EXPORT_DATE);

      }
    } else if (this.reportConfigType == REPORT_TYPE.SETTLEMENT) {
      if (this.allowTransactionForTwoYears) {
        this.minDate = DateUtil.dateToNgbDateStruct(MINIMUM_EXPORT_DATE_ALLOW_TWO_YEARS);
        this.maxDate = DateUtil.dateToNgbDateStruct(new Date());
        this.minDateMobile = DateUtil.ngbDateStructToDate(this.minDate);
        this.maxDateMobile = DateUtil.ngbDateStructToDate(this.maxDate);
      } else {
        this.maxDateMobile = this.selectOlder180Days ? DateUtil.subtractDay(new Date(), (this.maxDays + 1)) : new Date();
        this.minDateMobile = this.selectOlder180Days ? DateUtil.subtractDay(new Date(), this.minDays) : DateUtil.subtractDay(new Date(), this.maxDays);
        this.minDate = DateUtil.dateToNgbDateStruct(this.minDateMobile);
        this.maxDate = DateUtil.dateToNgbDateStruct(this.maxDateMobile);
        this.minus180Days = DateUtil.subtractDay(new Date(), this.maxDays);
      }
      this.changeDefectorRef.detectChanges();
      if (!this.checkDateRange()) {
        this.selectOlder180Days = false;
        this.maxDateMobile = new Date();
        this.maxDate = DateUtil.dateToNgbDateStruct(this.maxDateMobile);
        this.changeDefectorRef.detectChanges();
        this.selectYesterdayOnCalendar(DateUtil.subtractDay(new Date(), this.maxDays));
      }
      this.refreshTotalRecords(this.model.from, this.model.to);
    } else {
      this.maxDateMobile = new Date();
      this.maxDate = DateUtil.dateToNgbDateStruct(this.maxDateMobile);
      if (this.allowTransactionForTwoYears) {
        this.minDateMobile = MINIMUM_EXPORT_DATE_ALLOW_TWO_YEARS;
        this.minDate = DateUtil.dateToNgbDateStruct(MINIMUM_EXPORT_DATE_ALLOW_TWO_YEARS);
      } else {
        this.minDateMobile = MINIMUM_EXPORT_DATE;
        this.minDate = DateUtil.dateToNgbDateStruct(MINIMUM_EXPORT_DATE);
      }
      this.changeDefectorRef.detectChanges();
      if (!this.checkDateRange()) {
        this.selectYesterdayOnCalendar(MINIMUM_EXPORT_DATE);
      }
      this.refreshTotalRecords(this.model.from, this.model.to);
    }
    if (this.reportConfigType != REPORT_TYPE.INSTALLMENT_PLAN) {
      this.isSelectedSupplierNumber = false;
    }
    if (this.reportConfigType != REPORT_TYPE.SETTLEMENT) {
      this.selectOlder180Days = false
    }
  }

  limitSettlementDateRange() {
    if (this.selectOlder180Days) {
      this.minDateMobile = DateUtil.subtractDay(new Date(), this.minDays);
      this.maxDateMobile = DateUtil.subtractDay(new Date(), this.maxDays + 1);
      this.minDate = DateUtil.dateToNgbDateStruct(this.minDateMobile);
      this.maxDate = DateUtil.dateToNgbDateStruct(this.maxDateMobile);
      this.changeDefectorRef.detectChanges();
      // select from date on calendar
      this.onDateSelection(this.maxDate);
      // select to date on calendar
      this.onDateSelection(this.maxDate);
      this.model.from = DateUtil.ngbDateStructToDate(this.fromDate);
      this.model.to = DateUtil.ngbDateStructToDate(this.toDate);
    } else {
      this.maxDateMobile = new Date();
      this.maxDate = DateUtil.dateToNgbDateStruct(new Date());
      this.changeDefectorRef.detectChanges();
      const yesterday = new Date(new Date().getTime() - (86400 * 1000));
      this.minDate = DateUtil.dateToNgbDateStruct(yesterday);
      // select from date on calendar
      this.onDateSelection(this.minDate);
      // select to date on calendar
      this.onDateSelection(this.minDate);
      this.model.from = DateUtil.ngbDateStructToDate(this.fromDate);
      this.model.to = DateUtil.ngbDateStructToDate(this.toDate);
      // set min date to
      setTimeout(() => {
        this.minDateMobile = DateUtil.subtractDay(new Date(), this.maxDays);
        this.minDate = DateUtil.dateToNgbDateStruct(this.minDateMobile);
      });
    }
  }

  onDropDownClose() {
    if (TransactionUtil.isEmpty(this.selectedCompanies)) {
      this.selectedCompaniesOld = this.selectedCompanies;
      this.totalRecords = 0;
      return;
    }
    const isIncludeAll = this.selectedCompanies.every((info) => {
      const index = this.selectedCompaniesOld.findIndex((i) => i.companyId == info.companyId);
      return index > -1;
    });
    if (this.selectedCompanies.length === this.selectedCompaniesOld.length && isIncludeAll) {
      return;
    }
    // reset when change company list
    this.totalRecords = 0;
    if (this.reportConfigId) {
      this.refreshTotalRecords(this.model.from, this.model.to);
    }
  }

  closeSupplierNumberDropdown(selectedSupplierNumber) {
    // check change between selectedSupplierNumber and supplierNumbers
    const array = selectedSupplierNumber.filter((i) => this.supplierNumbers.includes(i.supplyNo));
    if (array.length == this.supplierNumbers.length && array.length == selectedSupplierNumber.length) {
      return
    }
    this.supplierNumbers = [];
    if (selectedSupplierNumber.length > 0) {
      this._commonService.isLoading(true);
      this.productTypeList = [];
      this.modelNoList = [];
      selectedSupplierNumber.forEach(promotion => {
        this.supplierNumbers.push(promotion.supplyNo);
      });
      this.refreshTotalRecords(this.model.from, this.model.to);
      let promotionRequest = new PromotionRequest();
      promotionRequest.supplierNumber = this.supplierNumbers;
      this.getProductTypeAndModelNo(promotionRequest);
    } else {
      this.isSelectedSupplierNumber = false;
      this.refreshTotalRecords(this.model.from, this.model.to);
    }
  }

  closeProductNameDropdown(selectedProductType) {
    // check change between selectedProductType and productTypeList
    const array = selectedProductType.filter((i) => this.productTypeList.includes(i.productType));
    if (selectedProductType.length == 0 && this.productTypeList.length == 0 || (array.length == this.productTypeList.length && array.length == selectedProductType.length)) {
      return
    }
    this._commonService.isLoading(true);
    this.isLoadingModelNumber = true;
    this.totalRecords = 0;
    this.productTypeList = [];
    this.modelNoList = [];
    selectedProductType.forEach(promotion => {
      this.productTypeList.push(promotion.productType);
    });
    let promotionRequest = new PromotionRequest();
    promotionRequest.supplierNumber = this.supplierNumbers;
    promotionRequest.productType = this.productTypeList;
    this._reportConfigService.getModelNoList(promotionRequest).subscribe((response) => {
      this.selectedModelName = [];
      if (this._commonService.isSuccess(response)) {
        setTimeout(() => {
          this.selectedModelName = response.modelNoList;
          this.selectedModelName.map((promotion) => {
            promotion['displayName'] = this.setNameForDropdown(promotion.modelNo, promotion.modelName);
          });
          this.configModelNameDropdown = new MultiselectDropdownConfig(
            ['supplyNo', 'productType', 'modelNo'], 'displayName', 'Select All', 'UnSelect All', 3, true, this.selectedModelName, 'modelNo', false, true);
          this.isLoadingModelNumber = false;
          this.refreshTotalRecords(this.model.from, this.model.to);
          this._commonService.isLoading(false);
        }, 500);
      } else {
        this.isLoadingModelNumber = false;
        this.refreshTotalRecords(this.model.from, this.model.to);
      }
    }, err => {
      this.isLoadingModelNumber = false;
      this._commonService.isLoading(false);
    });
  }

  closeModelNameDropdown(selectedModelNumber) {
    // check change between selectedModelNumber and modelNoList
    const array = selectedModelNumber.filter((i) => this.modelNoList.includes(i.supplyNo));
    if (array.length == this.modelNoList.length && array.length == selectedModelNumber.length) {
      return
    }
    this.modelNoList = [];
    selectedModelNumber.forEach(promotion => {
      this.modelNoList.push(promotion.modelNo);
    });
    this.refreshTotalRecords(this.model.from, this.model.to);
  }

  backToReport() {
    this.isBackToReport = true;
    this._router.navigate(['transaction-report']);
  }

  isInstalllmentCrossBank() {
    return this.reportConfigModel && this._transactionService.isInstallmentCrossBank(this.reportConfigModel.reportConfigName);
  }

  private selectYesterdayOnCalendar(minDate: Date) {
    // reset fromDate when fromDate is selected before
    this.fromDate = null;
    const yesterday = new Date(new Date().getTime() - (86400 * 1000));
    this.minDate = DateUtil.dateToNgbDateStruct(yesterday);
    this.onDateSelection(this.minDate);
    this.onDateSelection(this.minDate);
    this.model.from = DateUtil.ngbDateStructToDate(this.fromDate);
    this.model.to = DateUtil.ngbDateStructToDate(this.toDate);
    // set min date to
    setTimeout(() => {
      this.minDate = DateUtil.dateToNgbDateStruct(minDate);
    });
  }

  private checkDateRange(check?: string): boolean {
    let result = true;
    if (!this.model.from || !this.model.to) {
      return false;
    }
    if (this.reportConfigType == REPORT_TYPE.INSTALLMENT_PLAN) {
      return result
    }
    if (!DateUtil.isBetween(this.model.from, this.minDateMobile, this.maxDateMobile)) {
      // show message from date must between today - 1 year:today
      this.invalidFromDate = message.content.error_date_range + DateUtil.formatDate(this.minDateMobile, '/', FORMAT.MMDDYYYY_FORMAT)
        + ' and ' + DateUtil.formatDate(this.maxDateMobile, '/', FORMAT.MMDDYYYY_FORMAT);
      return false;
    }

    if (!DateUtil.isBetween(this.model.to, this.minDateMobile, this.maxDateMobile)
    ) {
      // show message from date must between today - 1 year:today
      this.invalidToDate = message.content.error_date_range + DateUtil.formatDate(this.minDateMobile, '/', FORMAT.MMDDYYYY_FORMAT)
        + ' and ' + DateUtil.formatDate(this.maxDateMobile, '/', FORMAT.MMDDYYYY_FORMAT);
      return false;
    }

    if (this.model.from != null
        && !DateUtil.isBetween(this.model.to, this.model.from, DateUtil.subtractDay(this.model.from, -(this.notExceedDays - 1)))
        && (check === 'to' || check == null)) {
      // show message from date: Date range must not exceed XX days
      this.invalidExceedDays = message.content.error_date_range_exceed.replace(/\{0\}/g, '' + this.notExceedDays);
      result = false;
    }

    if (this.model.to != null
        && !DateUtil.isBetween(this.model.from, DateUtil.subtractDay(this.model.to, (this.notExceedDays - 1)), this.model.to)
        && (check === 'from' || check == null)) {
      // show message to date: Date range must not exceed XX days
      this.invalidExceedDays = message.content.error_date_range_exceed.replace(/\{0\}/g, '' + this.notExceedDays);
      result = false;
    }

    // if (this.model.from != null
    //   && !DateUtil.isBetween(this.model.to, this.model.from, DateUtil.subtractDay(this.model.from, -91))
    //   && (check === 'to' || check == null)) {
    //   let day = DateUtil.subtractDay(this.model.from, -91);
    //   day = isAfter(DateUtil.dateToNgbDateStructCustom(day), this.maxDate) ? DateUtil.ngbDateStructToDate(this.maxDate) : day;
    //   // show message from date must between from:from + 92
    //   this.invalidToDate = message.content.error_date_range + DateUtil.formatDate(this.model.from, '/', FORMAT.MMDDYYYY_FORMAT)
    //     + ' and ' + DateUtil.formatDate(day, '/', FORMAT.MMDDYYYY_FORMAT);
    //   result = false;
    // }

    // if (this.model.to != null
    //   && !DateUtil.isBetween(this.model.from, DateUtil.subtractDay(this.model.to, 91), this.model.to)
    //   && (check === 'from' || check == null)) {
    //   let day = DateUtil.subtractDay(this.model.to, 91);
    //   day = isBefore(DateUtil.formatToNgbDate(day), this.minDate) ? DateUtil.ngbDateStructToDate(this.minDate) : day;
    //   // show message from date must between to - 92:to
    //   this.invalidFromDate = message.content.error_date_range + DateUtil.formatDate(day, '/', FORMAT.MMDDYYYY_FORMAT)
    //     + ' and ' + DateUtil.formatDate(this.model.to, '/', FORMAT.MMDDYYYY_FORMAT);
    //   result = false;
    // }

    if (this.model.to > new Date() || this.model.from > new Date()) {
      result = false;
    }
    return result;
  }

  /**
   * set min/max date (datepicker on laptop)
   */
  private setDefaultDateRange() {
    let maxDate;
    switch (this.reportConfigType) {
      case REPORT_TYPE.INSTALLMENT_PLAN:
        maxDate = DateUtil.subtractDay(new Date(), -180);
        break;
      case REPORT_TYPE.SETTLEMENT:
        maxDate = this.selectOlder180Days ? DateUtil.subtractDay(new Date(), this.maxDays + 1) : new Date();
        break;
      default:
        maxDate = new Date();
        break;
    }
    this.maxDate = DateUtil.dateToNgbDateStruct(maxDate);
    this.maxDateMobile = maxDate;
    this.changeDefectorRef.detectChanges();
  }

  private validateNotExceedDays(): boolean {
    if (this.reportConfigType != REPORT_TYPE.INSTALLMENT_PLAN) {
      if (this.model.from && this.model.to
          && (!DateUtil.isBetween(this.model.to, this.model.from, DateUtil.subtractDay(this.model.from, -(this.notExceedDays - 1)))
            || !DateUtil.isBetween(this.model.from, DateUtil.subtractDay(this.model.to, (this.notExceedDays - 1)), this.model.to))) {
        this.invalidExceedDays = message.content.error_date_range_exceed.replace(/\{0\}/g, '' + this.notExceedDays);
        return false;
      }
    }
    return true;
  }

  /**
  * Get max date: 92 days previous (count from date selected)
  */
  private limit92Days(from?: NgbDateStruct): Date {
    let d = from ? DateUtil.ngbDateStructToDate(from) : DateUtil.ngbDateStructToDate(this.fromDate);
    d = new Date(d.getTime() + 91 * 24 * 60 * 60 * 1000);
    return d > new Date() ? new Date() : d;
  }

  private setNameForDropdown(no, name) {
    return no + (name && name.trim() ? ': ' + name : '');
  }

  private getProductTypeAndModelNo(promotionRequest: PromotionRequest) {
    this._reportConfigService.getProductTypeList(promotionRequest).subscribe((response) => {
      this.isSelectedSupplierNumber = false;
      if (this._commonService.isSuccess(response)) {
        this.selectedProductName = response.productTypeList;
        this.selectedProductName.map((promotion) => {
          promotion['displayName'] = this.setNameForDropdown(promotion.productType, promotion.productName);
          this.configProductNameDropdown = new MultiselectDropdownConfig(
            'productType', 'displayName', 'Select All', 'UnSelect All', 3, true, this.selectedProductName, 'productType', false, true);
        });
      }
      this._reportConfigService.getModelNoList(promotionRequest).subscribe((response) => {
        if (this._commonService.isSuccess(response)) {
          this.selectedModelName = response.modelNoList;
          this.selectedModelName.map((promotion) => {
            promotion['displayName'] = this.setNameForDropdown(promotion.modelNo, promotion.modelName);
            this.configModelNameDropdown = new MultiselectDropdownConfig(
              'modelNo', 'displayName', 'Select All', 'UnSelect All', 3, true, this.selectedModelName, 'modelNo', false, true);
          });
          setTimeout(() => {
            this.isSelectedSupplierNumber = true;
            this._commonService.isLoading(false);
          }, 0);
        }
      });
    }, err => {
      this._commonService.isLoading(false);
    });
  }

  private setMaxDateOnCalendar(): Date {
    let maxDate: Date;
    switch (this.reportConfigType) {
      case REPORT_TYPE.INSTALLMENT_PLAN:
        maxDate = DateUtil.subtractDay(new Date(), -180);
        break;
      case REPORT_TYPE.SETTLEMENT:
        if (this.selectOlder180Days) {
          maxDate = this.limit92Days() > DateUtil.subtractDay(new Date(), this.maxDays + 1) ? DateUtil.subtractDay(new Date(), this.maxDays + 1) : this.limit92Days();
        } else {
          maxDate = this.limit92Days();
        }
        break;
      default:
        maxDate = this.limit92Days();
        break;
    }
    return maxDate;
  }

  ngOnDestroy(): void {
    if (!this.isBackToReport && this._cacheObservableService.has('transaction_report_goback')) {
      this._cacheObservableService.remove('transaction_report_goback');
    }
  }

}
