import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheObservableService } from '../../services/cache-observable.service';
import { CommonService } from '../../services/common.service';
import { RefundResultRequest, RefundResultResponse } from '../../services/models/multiple-refund-result.model';
import { MultipleRefundResultService } from '../../services/multiple-refund-result.service';
import { ToasterService } from '../../services/toaster.service';
import { CACHE_OBSERVABLE_SERVICE, ERROR_CODE, message } from '../../shared/app-constant';

@Component({
  selector: 'app-multiple-refund-result',
  templateUrl: './multiple-refund-result.component.html',
  styleUrls: ['./multiple-refund-result.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MultipleRefundResultComponent implements OnInit {

  groupList: any = [];
  request: RefundResultRequest;
  response: RefundResultResponse;
  refundedTransaction: string;
  isShowLinkTransactionDetail = false;
  isClickViewTransactiondetail = false;
  historyId: string;
  private isBack: boolean = false;

  // Pagination
  collectionSize: number;

  constructor(
    private commonService: CommonService,
    private cache : CacheObservableService,
    private router : Router,
    private refundResultService: MultipleRefundResultService,
    private route: ActivatedRoute,
    
    private toasterService : ToasterService
  ) { }

  ngOnInit() {
    this.request = new RefundResultRequest();
    this.isShowLinkTransactionDetail = this.commonService.isVisibleLinkTransactionDetail();
    if(this.cache.has(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK)){
      this.request = this.cache.getAnyObject(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK);
    } else {
      this.historyId = this.route.snapshot.paramMap.get('historyId');
      this.request.historyId = Number(this.historyId);
      this.request.pageIndex = 1;
    }
    this.getRefundResult();
  }

  getRefundResult(){
    this.commonService.isLoading(true);
    this.refundResultService.getRefundResult(this.request).subscribe(
      result =>{
        this.clearTransactionList();
        if(this.commonService.isSuccess(result)){
          this.response = result;
          this.refundedTransaction = result.refundedTransaction;
          this.groupingTransaction();
        } else {
          if (result.status_code == message.title.fail) {
            this.commonService.alertError(message.title.fail, result.status_message, null);
          }
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  groupingTransaction(){
    this.collectionSize = +this.response.totalElements;
    for (const group of this.response.trxInfoSummaries) {
      console.log('trx group: ' + group);
      const groupName = group.groupDate.split(' ').shift();
      if (!this.groupList.some(x => x.groupName === groupName)) {
        const transactionList = this.response.multipleRefundResultTrxInfos.filter(m =>
          m.createDateTime.split(' ').shift() === groupName);
        const modelGroup = {
          groupDate: group.groupDate,
          groupName: groupName,
          transactionList: transactionList
        };
        this.groupList.push(modelGroup);
      }
    }
  }

  loadPage(pageIndex: number) {
    this.request.pageIndex = pageIndex;
    this.getRefundResult();
  }

  viewTransactiondetail(objectId: string) {
    console.log('viewTransactiondetail ' + objectId);
    this.isClickViewTransactiondetail = true;
    console.log('viewTransactiondetail : ');
    this.setCache();
    this.router.navigate(['/transactiondetail/' + objectId]);
  }

  clearTransactionList() {
    this.collectionSize = 0;
    this.groupList = [];
  }

  goBack(){
    this.isBack = true;
    this.router.navigateByUrl('/multiple-selection');
  }

  ngOnDestroy() {
    console.log('TransactionComponent ngOnDestroy');
    if (!this.isClickViewTransactiondetail && !this.isBack) {
      this.cache.clear();
    }
    if(this.isBack){
      this.cache.remove(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK);
    }
  }

  private setCache() {
    this.cache.set(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK, this.request);
  }

}
