import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAvailableSearchList',
  pure: false
})

export class FilterAvailableSearchListPipe implements PipeTransform {

  transform(value: any[], currentSelect: number, selected: any[], isPaymentSearch?: boolean): any {
    return currentSelect ? value.reduce((prev, next) => {
      if (isPaymentSearch && (next.id === currentSelect || !selected.some(x => x.id === next.id))) {
          prev.push(next);
      } else {
      if (next.id === currentSelect) {
        prev.push(next);
      } else if (next.id > 2 && !selected.some(x => x.id === next.id)) {
        prev.push(next);
      } else if (next.id <= 2 && (!selected.some(x => x.id <= 2) || currentSelect <= 2)) {
        prev.push(next);
      }
      }

      return prev;
    }, []) : value;

  }

}
