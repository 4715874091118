import { TransactionRequestStatusStateModel } from "../services/models/transaction.request.status-state.model";
import { TRANSACTION_STATUS, SUB_FILTER } from "./app-constant";

export const TRANSACTION_DATA: TransactionDataModel[] = [
    {
        name: TRANSACTION_STATUS.INITIALIZED,
        value: [
            TransactionRequestStatusStateModel.Authorized_Wait_For_Authentication,
            TransactionRequestStatusStateModel.Authorized_Wait_For_Confirm
        ]
    },
    {
        name: TRANSACTION_STATUS.DECLINED,
        value: [
            TransactionRequestStatusStateModel.Declined_Complete,
            TransactionRequestStatusStateModel.Authorized_Fail,
            TransactionRequestStatusStateModel.BillPayment_Declined
        ]
    },
    {
        name: TRANSACTION_STATUS.AUTHORIZED,
        value: [
            TransactionRequestStatusStateModel.Authorized_Process,
            TransactionRequestStatusStateModel.Authorized_Complete,
            TransactionRequestStatusStateModel.Qr_Authorized_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.VOIDED,
        value: [
            TransactionRequestStatusStateModel.Voided_Complete,
            TransactionRequestStatusStateModel.Qr_Voided_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.SETTLED,
        value: [
            TransactionRequestStatusStateModel.Settled_Process,
            TransactionRequestStatusStateModel.Qr_Settled_Process,
            TransactionRequestStatusStateModel.Settled_Complete,
            TransactionRequestStatusStateModel.Qr_Settled_Complete
        ]
    },
    // {
    //     name: TRANSACTION_STATUS.REFUNDED,
    //     value: [
    //         TransactionRequestStatusStateModel.Refunded_Process,
    //         TransactionRequestStatusStateModel.Refunded_Complete,
    //         TransactionRequestStatusStateModel.Qr_Refunded_Process,
    //         TransactionRequestStatusStateModel.Qr_Refunded_Complete
    //     ]
    // },
    {
        name: TRANSACTION_STATUS.REFUNDED,
        subName: SUB_FILTER.ALL,
        value: [
            TransactionRequestStatusStateModel.Refunded_Fail,
            TransactionRequestStatusStateModel.Refunded_Process,
            TransactionRequestStatusStateModel.Qr_Refunded_Process,
            TransactionRequestStatusStateModel.Refunded_Complete,
            TransactionRequestStatusStateModel.Qr_Refunded_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.REFUNDED,
        subName: SUB_FILTER.PARTIAL_REFUND,
        value: [
            TransactionRequestStatusStateModel.Refunded_Process,
            TransactionRequestStatusStateModel.Qr_Refunded_Process,
            TransactionRequestStatusStateModel.Refunded_Complete,
            TransactionRequestStatusStateModel.Qr_Refunded_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.REFUNDED,
        subName: SUB_FILTER.REFUNDED,
        value: [
            TransactionRequestStatusStateModel.Refunded_Process,
            TransactionRequestStatusStateModel.Qr_Refunded_Process,
            TransactionRequestStatusStateModel.Refunded_Complete,
            TransactionRequestStatusStateModel.Qr_Refunded_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.REFUNDED,
        subName: SUB_FILTER.REFUND_FAILED,
        value: [
            TransactionRequestStatusStateModel.Refunded_Fail,
            TransactionRequestStatusStateModel.Refunded_Complete,
            TransactionRequestStatusStateModel.Qr_Refunded_Complete
        ]
    },
    {
        name: TRANSACTION_STATUS.ALL,
        value: []
    }
]

export class TransactionUtil {

    static setTrxStatusState(status: string, subRefundFilter?: string): TransactionDataModel {
        const isSearchFollowSubName = status == TRANSACTION_STATUS.REFUNDED;
        return TRANSACTION_DATA.find(tran => tran.name === status && ((isSearchFollowSubName && tran.subName == subRefundFilter) || !isSearchFollowSubName));
    }

    static getStatusCurrent(transactionStatus: TransactionRequestStatusStateModel[]): string {
        let result = this.isContain(TRANSACTION_DATA, transactionStatus);
        const isSubFilterRefundedStatus =  result.filter((value) => value.name == TRANSACTION_STATUS.REFUNDED).length == result.length;
        return result.length > 1 ? isSubFilterRefundedStatus ? result[0].name : TRANSACTION_STATUS.ALL : result[0].name;
    }

    static isContain(template: TransactionDataModel[], searchingStatusState: TransactionRequestStatusStateModel[]): TransactionDataModel[] {
        let result = []
        if (!searchingStatusState || searchingStatusState.length === 0) {
            return TRANSACTION_DATA;
        }
        for (let i = 0; i < template.length; i++) {
            // when status is REFUNDED and sub filter is ALL or REFUND FAILED,
            // the trxStatusState can contain SETTLED complete
            // so must check all value in template
            const templateInsideSearching = template[i].value.filter((value) => searchingStatusState.includes(value)).length == template[i].value.length;
            // if template in list searchingStatusState -> add template into result
            if (template[i].value.length > 0 && templateInsideSearching) {
                result.push(template[i]);
            }
        }
        return result;
    }

    static isEmpty(array: Array<any>) {
        return !array || array.length == 0;
    }
}

class TransactionDataModel {
    name: string;
    value: TransactionRequestStatusStateModel[];
    subName?: string;
}

