import { Directive, Input, HostListener, ElementRef, QueryList, OnInit } from '@angular/core';

@Directive({
  selector: '[appAdjustUi]'
})
export class AdjustUiDirective implements OnInit {

  @Input('alignElement') alignElement: string;
  @Input('baseOnElement') baseOnElement: QueryList<ElementRef>;
  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.setMarginLeft();
  }

  @HostListener('window: resize', ['$event.target'])
  onResize() {
    this.setMarginLeft();
  }

  private setMarginLeft() {
    if (!this.baseOnElement) {
      return;
    }
    // get array button
    const filters = this.baseOnElement.toArray();
    // count element need margin left
    const indexOfElement = filters.indexOf(filters.find((value) => value.nativeElement.textContent.toLocaleLowerCase().trim() == this.alignElement.toLocaleLowerCase()));
    let marginLeftElement = 0;
    // count width button
    for (let i = 0; i < indexOfElement; i++) {
      marginLeftElement += filters[i].nativeElement.offsetWidth;
    }
    // minus some pixels
    marginLeftElement -= indexOfElement;
    this.element.nativeElement.style.marginLeft = marginLeftElement + 'px';
  }
}
