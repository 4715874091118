import { ResultModel } from "./result.model";
import { TransactionRequestModel } from "./transaction.request.model";

export class MultipleRefundResponse extends ResultModel {
  totalElements: number;
  totalLimit: number;
  maxMultipleRefund: number;
  totalDays: number;
  trxInfoSummaries: GroupDate[];
  multipleRefundTrxInfos: MultipleTrxInfos[];
  validSelectedTrxIds?: string[];
}

export class GroupDate {
  groupDate: string;
}

export class MultipleTrxInfos {
  trxId: string;
  createDate: string;
  refNumber: string;
  productDescription: string;
  trxState: string;
  paymentTypeDesc: string;
  currencyCode: string;
  settledAmount: string;
  isSelected?: boolean;
  isFullRefund?: boolean;
  lastRefundDatetime?: string;
  remainingAmount?: string;
  refundAmount?: string;
}

export class GroupList {
  groupDate: string;
  groupName: string;
  trxInfos: MultipleTrxInfos[];
}

export class PageInfo {
  pageNumber: number;
  selectAll: boolean;
}

export class MultipleRefundRequestModel {
  selectedTrxIds: string[];
  transactionReq: TransactionRequestModel;
}