import { Directive, HostListener, ElementRef } from '@angular/core';
import { PATTERN } from '../shared/app-constant';

@Directive({
  selector: '[appInputTime]'
})
export class InputTimeDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'Del', 'ArrowLeft', 'ArrowRight', 'Left', 'Right'];
  private functionKeys: Array<number> = [65, 67, 86, 88]; // 65 -> a; 76 -> c; 86 -> v; 88 -> x

  constructor(private element: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    console.log(event);
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if ((event.ctrlKey || event.metaKey) && (this.functionKeys.indexOf(event.keyCode) !== -1)){
      return;
    }
    const current: string = this.element.nativeElement.value;
    const next: string = current.concat(event.key);
    const regex = new RegExp(PATTERN.NUMBER_ONLY);
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
//TODO: prevent invalid time
  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    console.log(event);
    const value = event.target.value;
    if (event.target.value) {

    }
  }

}
