import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { DateUtil } from '../../shared/date.util';
import { NgbDateStruct, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { CommonService } from '../../services/common.service';
import { message, FILE_TYPES } from '../../shared/app-constant';
import { Subject } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { BulkPaymentFilter } from '../../services/models/bulk-payment-filter.model';
import { HistoryInfo } from '../../services/models/bulk-payment-info.model';
import { BulkPaymentService } from '../../services/bulk-payment.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const STATUS = [
  { value: 'pending', name: 'Pending' },
  { value: 'processing', name: 'Processing' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'ready_to_download', name: 'Ready To Download' }
];
export const VALUE_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  CANCELLED: 'cancelled',
  READY_TO_DOWNLOAD: 'ready_to_download'
}

export const pendingMessage = 'Your transactions will be processed at {time}. Meanwhile, the request can be cancelled.';
export const processingMessage = 'Processing your batch file. You can download the batch result after the batch status is “Ready to download”.';
export const BULK_PAYMENT_SCREEN = {
  SCREEN_CODE: 'M037',
  ROLE_ACTION_CODE: 'ROLE_B_5101'
}

export const LAST_BATCH_RUN_HOUR = 18;
export const RESUME_BATCH_RUN_HOUR = 22;
export const CUTOFF_HOUR_FOR_AM_PM = 12;

@Component({
  selector: 'app-bulk-payment',
  templateUrl: './bulk-payment.component.html',
  styleUrls: ['./bulk-payment.component.css']
})
export class BulkPaymentComponent implements OnInit {
  @ViewChild('rangInputDate') rangInputDate: ElementRef;
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;
  @ViewChild('dateTemplate') dateTemplate: ElementRef;

  public fileTypes: any;
  public statusList: any;
  public fileType: string;
  public status: string;
  public maxDate: any;

  public hoveredDate: NgbDateStruct;
  public fromDate: any;
  public toDate: any;

  public reportConfigModel: string;
  public parsed: string;
  public modelDatePiker: string;
  public pendingMessage: string;
  public isEditRole: boolean;
  public bulkPaymentFilter: BulkPaymentFilter;
  public historyInfos: HistoryInfo[];
  public collectionSize: number;
  public valueStatus: any;
  public pageSize: number;

  private timeBatchRun: number;
  private serverDateTime: any;

  faDownload = faDownload;

  isHovered = inputDate => this.fromDate && !this.toDate && this.hoveredDate && DateUtil.isAfter(inputDate, this.fromDate) && DateUtil.isBefore(inputDate, this.hoveredDate);
  isInside = inputDate => DateUtil.isAfter(inputDate, this.fromDate) && DateUtil.isBefore(inputDate, this.toDate);
  isFrom = inputDate => DateUtil.isEqualsNgbDateStruct(inputDate, this.fromDate);
  isTo = inputDate => DateUtil.isEqualsNgbDateStruct(inputDate, this.toDate);
  isDisabled = (date: NgbDate, current: { year: number }) => date.day <= this.maxDate;

  constructor(
    private commonService: CommonService,
    private renderer: Renderer2,
    private parserFormatter: NgbDateParserFormatter,
    private bulkPaymentService: BulkPaymentService,
    private toasterService: ToasterService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.fileTypes = FILE_TYPES;
    this.statusList = STATUS;
    this.valueStatus = VALUE_STATUS;
    this.maxDate = DateUtil.dateToNgbDateStructCustom(new Date());
    this.parsed = "All";
    this.pendingMessage = pendingMessage;
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    this.isEditRole = menuInfos.some(info => info.screenCode == BULK_PAYMENT_SCREEN.SCREEN_CODE && info.componentsInfo.some(com => com.roleActionCode == BULK_PAYMENT_SCREEN.ROLE_ACTION_CODE));
    this.bulkPaymentFilter = new BulkPaymentFilter();
    this.bulkPaymentFilter.status = null;
    this.bulkPaymentFilter.from = null;
    this.bulkPaymentFilter.to = null;
    this.bulkPaymentFilter.bulkPaymentId = null;
    this.bulkPaymentFilter.pageIndex = 1;
    this.pageSize = 20;
    this.historyInfos = [];
    this.collectionSize = 0;
    this.search();
  }

  public refresh() {
    this.search();
  }

  public changeFilterCondition() {
    this.bulkPaymentFilter.pageIndex = 1;
    this.search()
  }

  public closeActiveDatePicker(event) {
    if (event) {
      if (!this.toDate && this.fromDate) {
        this.toDate = this.fromDate
        this.parsed += ' - ' + this.parserFormatter.format(this.fromDate);
        this.writtenValue(this.parsed);
        this.bulkPaymentFilter.from = DateUtil.parseToDate(this.fromDate);
        this.bulkPaymentFilter.to = DateUtil.parseToDate(this.toDate);
        this.search();
      }
      this.datePicker.close();
    }
  }

  /**
   * deleteRecord
   */
  // confirm show popup when delete
  public deleteFile(fileId) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doDelete(fileId);
      }
    });
    this.commonService.alertDelete(message.title.confirm_delete, message.content.confirm_delete, callback);
  }

  /**
   * downloadFile
   */
  public downloadFile(idFile: string) {
    this.commonService.isLoading(true);
    this.bulkPaymentService.downloadFile(idFile).subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        const anchor = document.createElement('a');
        anchor.href = response.preSignUrl;
        anchor.setAttribute('download', '');
        anchor.click();
        this.toasterService.success(message.content.download_success);
      } else {
        this.toasterService.error(message.content.download_fail)
      }
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  /**
   * cancelFile
   */
  public cancelFile(fileId) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doCancel(fileId);
      }
    });
    this.commonService.alertDelete(message.title.cancel_file, message.content.confirm_cancel, callback);
  }

  public onDateSelection(inputDate: NgbDateStruct) {
    this.parsed = ''; // initializing with empty string
    if (!this.fromDate && !this.toDate) {
      this.fromDate = inputDate;
    } else if (this.fromDate && !this.toDate && (DateUtil.isAfter(inputDate, this.fromDate) || DateUtil.isEqualsNgbDateStruct(inputDate, this.fromDate))) {
      this.toDate = inputDate
    } else {
      this.toDate = null;
      this.fromDate = inputDate;
    }
    if (this.fromDate) {
      // if fromDate is set: add the first date
      this.parsed += this.parserFormatter.format(this.fromDate);
      this.renderer.setProperty(this.rangInputDate
        .nativeElement, 'value', this.parsed);
    }
    if (this.toDate) {
      // if toDate is set: add the second date with separator
      this.parsed += ' - ' + DateUtil.formatDate(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day));
    }
    if (this.fromDate && this.toDate) {
      // here we update the input value with the new this.parsed value
      this.writtenValue(this.parsed);
      this.bulkPaymentFilter.from = DateUtil.parseToDate(this.fromDate);
      this.bulkPaymentFilter.to = DateUtil.parseToDate(this.toDate);
      this.search();
    }
  }

  public getMessageTooltip(status) {
    const timeAfterPlus = new Date(this.serverDateTime);
    if (timeAfterPlus.getMinutes() < this.timeBatchRun) timeAfterPlus.setMinutes(this.timeBatchRun);
    else timeAfterPlus.setMinutes(this.timeBatchRun + 60);
    let hours = timeAfterPlus.getHours();
    if (LAST_BATCH_RUN_HOUR < hours && hours < RESUME_BATCH_RUN_HOUR) hours = RESUME_BATCH_RUN_HOUR;
    let time = hours + ':' + String(timeAfterPlus.getMinutes()).padStart(2, '0') + ' ' + (hours >= CUTOFF_HOUR_FOR_AM_PM ? 'pm' : 'am');
    this.pendingMessage = pendingMessage.replace('{time}', time);
    return status == this.valueStatus.PENDING ? this.pendingMessage : processingMessage
  }

  public loadPage(page: number) {
    this.bulkPaymentFilter.pageIndex = page;
    this.search();
  }

  public changeBulkPaymentId() {
    this.bulkPaymentFilter.pageIndex = 1;
    if (!this.bulkPaymentFilter.bulkPaymentId) {
      this.bulkPaymentFilter.bulkPaymentId = null;
    }
    this.search();
  }

  public onPasteBuildPaymentId(event: ClipboardEvent){
    const bulkPaymentIdPasteData = event.clipboardData.getData("text").trim();
    console.log(bulkPaymentIdPasteData);
    this.bulkPaymentFilter.bulkPaymentId = bulkPaymentIdPasteData;
  }

  public parseType(type: string) {
    return this.fileTypes.find((item) => item.value == type).name
  }

  public parseStatus(status: string) {
    return this.statusList.find((item) => item.value == status.toLocaleLowerCase()).name;
  }

  /**
     * update the input value for datepicker
     * @param value
     */
  private writtenValue(value) {
    this.renderer.setProperty(this.rangInputDate
      .nativeElement, 'value', value);
    this.datePicker.close();
  }

  private doDelete(fileId) {
    this.bulkPaymentService.deleteFile(fileId).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.delete_success);
        this.bulkPaymentFilter.pageIndex = 1;
        this.search();
      } else {
        this.toasterService.error(message.content.recurring_delete_fail);
      }
    })
  }

  private doCancel(fileId) {
    this.bulkPaymentService.cancelFile(fileId).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.cancel_success);
        this.bulkPaymentFilter.pageIndex = 1;
        this.search();
      } else {
        this.toasterService.error(message.content.cancel_fail);
      }
    })
  }

  private search() {
    this.commonService.isLoading(true);
    this.historyInfos = [];
    this.bulkPaymentService.search(this.bulkPaymentFilter).subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.serverDateTime = new Date(response.serverDateTime);
        this.historyInfos = response.historyInfos;
        this.collectionSize = response.totalElement;
        this.timeBatchRun = response.timeRunBatch;
        this.pageSize = response.pageSize;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    })
  }

}
