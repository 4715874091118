import { FORMAT, DATE_PATTERN, ERROR_MESSAGES, DateRegExp } from "./app-constant";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AbstractControl } from "@angular/forms";
import { Observable, of } from "rxjs";
import { distinctUntilChanged, debounceTime, switchMap, } from "rxjs/operators";

export class DateUtil {
  /**
  * format date to dd/mm/yyyy or follow format variable
  * @param date 
  * @param syntax 
  * @param format 
  */
  public static formatDate(date: Date, syntax?: string, format?: string) {
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();
    let syntaxFormat = syntax ? syntax : '/';

    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;

    if (format === FORMAT.YYYYMMDD_FORMAT) {
      return [year, month, day].join(syntaxFormat);
    } else if (format === FORMAT.MMDDYYYY_FORMAT) {
      return [month, day, year].join(syntaxFormat);
    }

    return [day, month, year].join(syntaxFormat);
  }

  /**
 * formatToNgbDate
 * @param date 
 */
  public static formatToNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  public static dateToNgbDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }
  
  public static dateToNgbDateStructCustom(date: Date): any {
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return {
      year: date.getFullYear(),
      month: month,
      day: day
    };
  }

  public static dateToNgbDateStructCustomForThaiQR(date: Date): any {
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return {
      year: date.getFullYear(),
      month: Number(month),
      day: Number(day)
    };
  }

  public static ngbDateStructToDate(dateStruct: NgbDateStruct): Date {
    return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
  }

  public static isBetween(date: Date, from: Date, to: Date): boolean {
    const dateStruct = this.dateToNgbDateStructCustom(date);
    const fromStruct = this.dateToNgbDateStructCustom(from);
    const toStruct = this.dateToNgbDateStructCustom(to);
    return (this.isAfter(dateStruct, fromStruct) && this.isBefore(dateStruct, toStruct))
      || this.isEqualsNgbDateStruct(dateStruct, fromStruct) || this.isEqualsNgbDateStruct(dateStruct, toStruct);
  }

  public static subtractDay(date: Date, numberOfDay: number) {
    return new Date(date.getTime() - numberOfDay * 24 * 60 * 60 * 1000);
  }

  public static isEqualsNgbDateStruct = (one: NgbDateStruct, two: NgbDateStruct) =>
    one && two && two.year === one.year && two.month === one.month && two.day === one.day;

  public static isBefore = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day < two.day : one.month < two.month : one.year < two.year;

  public static isAfter = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day > two.day : one.month > two.month : one.year > two.year;

  // check Date
  public static isEqualsDate = (one: Date, two: Date) =>
    one && two && two.getFullYear() === one.getFullYear() && two.getMonth() === one.getMonth() && two.getDate() === one.getDate();

  public static isAfterToday(date: Date) {
    const today = new Date();
    return date.getFullYear() === today.getFullYear() ? date.getMonth() === today.getMonth() ? date.getDate() === today.getDate()
      ? false : date.getDate() > today.getDate() : date.getMonth() > today.getMonth() : date.getFullYear() > today.getFullYear();
  }
  public static isBeforeDate = (one: Date, two: Date) =>
    !one || !two ? false : one.getFullYear() === two.getFullYear() ? one.getMonth() === two.getMonth() ? one.getDate() === two.getDate()
      ? false : one.getDate() < two.getDate() : one.getMonth() < two.getMonth() : one.getFullYear() < two.getFullYear();

  public static isAfterDate = (one: Date, two: Date) =>
    !one || !two ? false : one.getFullYear() === two.getFullYear() ? one.getMonth() === two.getMonth() ? one.getDate() === two.getDate()
      ? false : one.getDate() > two.getDate() : one.getMonth() > two.getMonth() : one.getFullYear() > two.getFullYear();

  // check Time type Date
  public static isTimeBefore = (one: Date, two: Date) =>
    !one || !two ? false : one.getHours() === two.getHours() ? one.getMinutes() === two.getMinutes() ? one.getMilliseconds() === two.getMilliseconds()
      ? false : one.getMilliseconds() < two.getMilliseconds() : one.getMinutes() < two.getMinutes() : one.getHours() < two.getHours();

  public static isTimeAfter = (one: Date, two: Date) =>
    !one || !two ? false : one.getHours() === two.getHours() ? one.getMinutes() === two.getMinutes() ? one.getMilliseconds() === two.getMilliseconds()
      ? false : one.getMilliseconds() > two.getMilliseconds() : one.getMinutes() > two.getMinutes() : one.getHours() > two.getHours();

  public static isTimeEquals = (one: Date, two: Date) =>
    one && two && two.getTime() === one.getTime() && two.getMinutes() === one.getMinutes() && two.getMilliseconds() === one.getMilliseconds();

  public static isTimeBetween(date: Date, from: Date, to: Date): boolean {
    return (this.isTimeAfter(date, from) && this.isTimeBefore(date, to))
      || this.isTimeEquals(date, from) || this.isTimeEquals(date, to);
  }

  public static createDate(str: string, regex: RegExp, dayMonthYear?) {
    return regex.test(str) && str.length <= 10 ? new Date(dayMonthYear ? str.replace(regex, '$2/$1/$3') : str) : DateRegExp.TIME_ZONE.test(str) ? new Date(str) : null;
  }

  public static subtractMonth(date: Date, months: number) {
    const dateSubMonth = new Date(date);
    dateSubMonth.setMonth(date.getMonth() - months);
    return dateSubMonth;
  }

  public static conv(date: Date) {
    const isoStrArr = date.toISOString().split('T');
    return isoStrArr[0].slice(8) + '/' + isoStrArr[0].slice(5, 7) + '/' + isoStrArr[0].slice(0, 4) + ' ' + isoStrArr[1].slice(0, 8);
  }

  // Date pattern dd/mm/yyyy to mm/dd/yyyy
  public static convDateToMMDDYYY(date: string) {
    const dateArr = date.split('/');
    return dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
  }
  public static parseToDate(value): Date {
    if (!value) {
      return null;
    }
    if (value instanceof Object) {
      return DateUtil.ngbDateStructToDate(value);
    } else {
      return new Date(DateUtil.convDateToMMDDYYY(value));
    }
  }

  public static validatorDate(fc: AbstractControl) {
    var value = fc.value;
    var day, month, year;
    if (!value) {
      return {
        'invalid': true,
        'message': 'Date is required.'
      }
    }
    if (typeof value == 'string' && (!DATE_PATTERN.test(value) || (DATE_PATTERN.test(value) && value.split('/')[2].length > 4))) {
      return {
        'invalid': true,
        'message': ERROR_MESSAGES.DATE_INVALID
      }
    }

    if (value instanceof Object) {
      value = DateUtil.ngbDateStructToDate(value);
      day = value.getDate();
      month = value.getMonth() + 1;
      year = value.getFullYear();
    }

    if (typeof value == 'string') {
      value = DateUtil.convDateToMMDDYYY(value);
      day = value.split('/')[1];
      month = value.split('/')[0];
      year = value.split('/')[2];
    }
    const date = new Date(value);
    //if date valid, check date is the same as the date entered
    const isValid = !isNaN(date.valueOf()) ? date.getDate() != +day || (date.getMonth() + 1) != +month || date.getFullYear() != +year ? false : true : false;
    if (!isValid) {
      return {
        'invalid': true,
        'message': ERROR_MESSAGES.DATE_INVALID
      }
    }
    return null
  }
  public static validatorDateAsync(fc: AbstractControl) {
    return new Promise((resolve) => {
      new Observable((observable) => observable.next(fc)).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: AbstractControl) => {
          return of(DateUtil.validatorDate(value))
        })
      ).subscribe((res) => {
        console.log(res);
        resolve(res);
      })

    });
  }
}
