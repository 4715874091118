import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../shared/app-constant';

import { MerchantSettingUserResultModel } from './models/merchant-setting-user-result.model';
import { MerchantSettingUserSaveModel } from './models/merchant-setting-user-save.model';
import { MerchantSettingUserSaveResultModel } from './models/merchant-setting-user-save-result.model';
import { MerchantSettingUserChangePaswordResultModel } from './models/merchant-setting-user-change-password-result.model';
import { MerchantSettingUserChangePasswordModel } from './models/merchant-setting-user-change-password.model';

import { MerchantSettingCompanyInfoResultModel } from './models/merchant-setting-company-info-result.model';
import { MerchantSettingCompanyInfoSaveModel } from './models/merchant-setting-company-info-save.model';
import { MerchantSettingCompanyInfoSaveResultModel } from './models/merchant-setting-company-info-save-result.model';
import { PaypalReferralsResponse } from './models/paypal-referrals-response.model';
import { SFTPAccountModel } from './models/sftp-account.model';
import { ResultModel } from './models/result.model';
import { CampaignInfoResponseModel } from './models/campaign-info.model';
import { CommonService } from './common.service';
import { ToasterService } from './toaster.service';
import { TransactionLimit, TransactionLimitResponse } from './models/transaction-limit.model';
import { OneTimeTokenResponse } from './models/one-time-token-response';
import { InstallmentCrossBankResponse } from './models/installment-cross-bank-response.model';
import { EwaletResponse } from './models/ewalet-response.model';
import { MerchantSettingTokenizationResponse } from './models/merchant-setting-tokenization.model';
import { MerchantConfigurationResponse } from './models/merchant-configuration.model';
import { MerchantSettingDCCResponse } from './models/merchant-setting-dcc.model';
import { MerchantSettingMCCResponse } from './models/merchant-setting-mcc.model';
import { MerchantSettingPayPlusResponse } from './models/merchant-setting-payplus.model';
import { MerchantSettingQRResponse } from './models/merchant-setting-qr.model';
import { InstallmentInfoDetail, InstallmentInfoDetailRequest, MerchantSettingInstallmentInfo } from './models/merchant-setting-installment-info.model';

@Injectable()
export class MerchantSettingService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private toasterService: ToasterService
  ) { }

  getUser(): Observable<MerchantSettingUserResultModel> {
    console.log('## invoke service getUser...');
    return this.http.get<MerchantSettingUserResultModel>(
      environment.merchantSettingUserGetApiUrl);
  }

  saveUser(request: MerchantSettingUserSaveModel): Observable<MerchantSettingUserSaveResultModel> {
    console.log('## invoke service saveUser...');
    return this.http.post<MerchantSettingUserSaveResultModel>(
      environment.merchantSettingUserSaveApiUrl,
      JSON.stringify(request));
  }

  saveUserChangePassword(request: MerchantSettingUserChangePasswordModel): Observable<MerchantSettingUserChangePaswordResultModel> {
    console.log('## invoke service saveUserChangePassword...');
    return this.http.post<MerchantSettingUserChangePaswordResultModel>(
      environment.merchantSettingUserChangePasswordApiUrl,
      JSON.stringify(request));
  }

  getCompanyInfo(companyId: string): Observable<MerchantSettingCompanyInfoResultModel> {
    console.log('## invoke service getCompanyInfo...');
    let params = new HttpParams();
    if (companyId) {
      params = params.append('companyId', companyId);
    }
    return this.http.get<MerchantSettingCompanyInfoResultModel>(
      environment.merchantSettingCompanyInfoGetApiUrl, { params: params });
  }

  saveCompanyInfo(request: MerchantSettingCompanyInfoSaveModel): Observable<MerchantSettingCompanyInfoSaveResultModel> {
    console.log('## invoke service saveCompanyInfo...');
    return this.http.post<MerchantSettingCompanyInfoSaveResultModel>(
      environment.merchantSettingCompanyInfoSaveApiUrl,
      JSON.stringify(request));
  }

  getDigipayInfo(companyId: string, pageIndex: number): Observable<InstallmentCrossBankResponse> {
    const url = environment.getDigiPayInfo + `?companyId=${companyId}&pageIndex=${pageIndex}`;
    return this.http.get(url).pipe(
        map((response: InstallmentCrossBankResponse) => {
          return response;
        }));
  }

  paypalReferals(): Observable<PaypalReferralsResponse> {
    return this.http.get<PaypalReferralsResponse>(
      environment.paypalPartnerReferrals);
  }

  checkActivateEmail(): Observable<MerchantSettingCompanyInfoResultModel> {
    return this.http.get<MerchantSettingCompanyInfoResultModel>(
      environment.checkActivateEmail);
  }

  getSFTPAccount(companyId: number): Observable<SFTPAccountModel> {
    let params = new HttpParams();
    if (companyId) {
      params = params.append('companyId', companyId + '');
    }
    return this.http.get<SFTPAccountModel>(
      environment.merchantSettingSFTPAccountAPIUrl, { params: params });
  }

  getPassphrase(sftpUserId: number): Observable<ResultModel> {
    return this.http.get<ResultModel>(
      environment.getDecryptKeyPassphraseAPIUrl + '/' + sftpUserId);
  }

  getCampaignInfo(companyId: string, pageIndex: number): Observable<CampaignInfoResponseModel> {
    const url = environment.getCampaignInfoAPIUrl + `?companyId=${companyId}&pageIndex=${pageIndex}`
    return this.http.get<CampaignInfoResponseModel>(url).pipe(
        map((response: CampaignInfoResponseModel) => {
          if (this.commonService.isSuccess(response)) {
            return response;
          } else {
            this.toasterService.error(response.status_message);
          }
        })
      );
  }

  getTransactionLimit(companyId: string): Observable<TransactionLimit> {
    let params = new HttpParams();
    if (companyId) {
      params = params.append('companyId', companyId);
    }
    return this.http.get<TransactionLimit>(
      environment.getTransactionLimitApiUrl, { params: params }).pipe(
        map((response: TransactionLimitResponse) => {
          if (this.commonService.isSuccess(response)) {
            return response.transactionLimit;
          } else {
            this.toasterService.error(response.status_message);
          }
        })
      );
  }

  getOneTimeToken(): Observable<OneTimeTokenResponse> {
    return this.http.post<OneTimeTokenResponse>(environment.getOneTimeToken, {}).pipe(map((response: OneTimeTokenResponse) => {
      return response;
    }));
  }

  getWechatPay(companyId: string, pageIndex: number): Observable<EwaletResponse> {
    const url = environment.getMerchantSettingWechat + `?companyId=${companyId}&pageIndex=${pageIndex}`;
    return this.http.get<EwaletResponse>(url).pipe(map((response: EwaletResponse) => {
      return response;
    }));
  }

  getAliPay(companyId: string, pageIndex: number): Observable<EwaletResponse> {
    const url = environment.getMerchantSettingAlipay + `?companyId=${companyId}&pageIndex=${pageIndex}`;
    return this.http.get<EwaletResponse>(url).pipe(map((response: EwaletResponse) => {
      return response;
    }));
  }

  getMerchantConfiguration(companyId: string): Observable<MerchantConfigurationResponse> {
    const url = environment.getMerchantConfiguration + `?companyId=${companyId}`;
    return this.http.get(url).pipe(
      map((response: MerchantConfigurationResponse) => {
        return response;
      }));
  }

  getMerchantSettingDCC(companyId: string, pageNumber: string): Observable<MerchantSettingDCCResponse> {
    const url = environment.getMerchantSettingDCC + `?companyId=${companyId}&pageIndex=${pageNumber}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingDCCResponse) => {
        return response;
      }));
  }

  getMerchantSettingMCC(companyId: string, pageNumber: string): Observable<MerchantSettingMCCResponse> {
    const url = environment.getMerchantSettingMCC + `?companyId=${companyId}&pageIndex=${pageNumber}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingMCCResponse) => {
        return response;
      }));

  }

  getMerchantSettingTokenization(companyId: string, pageNumber: string): Observable<MerchantSettingTokenizationResponse> {
    const url = environment.getMerchantSettingTokenization + `?companyId=${companyId}&pageIndex=${pageNumber}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingTokenizationResponse) => {
        return response;
      }));
  }

  getMerchantSettingPayPlus(companyId: string): Observable<MerchantSettingPayPlusResponse> {
    const url = environment.getMerchantSettingPayPlus + `?companyId=${companyId}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingPayPlusResponse) => {
        return response;
      }));
  }

  getMerchantSettingQR(companyId: string): Observable<MerchantSettingQRResponse> {
    const url = environment.getMerchantSettingQR + `?companyId=${companyId}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingQRResponse) => {
        return response;
      }));
  }

  getInstallmentInfo(companyId: string): Observable<MerchantSettingInstallmentInfo>{
    const url = environment.getInstallmentInfo + `?companyId=${companyId}`
    return this.http.get(url).pipe(
      map((response: MerchantSettingInstallmentInfo) => {
        return response;
      }));
  }

  getInstallmentInfoDetail(request: InstallmentInfoDetailRequest): Observable<InstallmentInfoDetail> {
    return this.http.post(
      environment.getInstallmentInfoDetail,
      JSON.stringify(request)).pipe(
        map((response: InstallmentInfoDetail) => {
          return response;
        }));
  }

  getCompanyIsSyncEWallet(companyId: string): Observable<boolean> {
    console.log('## invoke service getCompanyInfo...');
    let params = new HttpParams();
    if (companyId) {
      params = params.append('companyId', companyId);
    }
    return this.http.get<boolean>(
      environment.getCompanyIsSyncEWallet, { params: params });
  }
}
