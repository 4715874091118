import { Component, OnInit, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RegistrationService } from '../../services/registration.service';

// import { RegistrationModel } from '../../services/models/registration.model';
import { SignupModel } from '../../services/models/signup.model';
import { SignupResultModel } from '../../services/models/signup-result.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {
  private formSubmitAttempt: boolean;

  registrationForm: FormGroup;

  constructor(private commonService: CommonService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService) {
  }

  ngOnInit() {
    /* Init form */
    const password = new FormControl(null, [ Validators.required,
                                             Validators.minLength(8), Validators.maxLength(15),
                                             Validators.pattern(CommonService.VALID_PASSWORD_FORMAT) ]);
    const rePassword = new FormControl(null, CustomValidators.equalTo(password));

    this.registrationForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      'password': password,
      'rePassword': rePassword,
      'companyIdName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_CSV_INJECTION_FORMAT)
      ])
    });
  }

  clearInfo() {
    this.registrationForm.reset();
    this.formSubmitAttempt = false;
  }


  onSubmit() {
    this.formSubmitAttempt = true;
    if (this.registrationForm.valid) {
      this.signup();
    }
  }

  signup() {
    this.commonService.isLoading(true);

    const signupModel = new SignupModel();
    signupModel.email = this.registrationForm.get('email').value;
    signupModel.password = this.registrationForm.get('password').value;
    signupModel.rePassword = this.registrationForm.get('rePassword').value;
    signupModel.companyIdName = this.registrationForm.get('companyIdName').value;

    this.registrationService.signup(signupModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Register ' + this.registrationForm.get('email').value + ' success.');
          this.clearInfo();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.' , 'Server API Error');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  isFieldInvalid(field: string) {
    const aField = this.registrationForm.get(field);
    // return (aField.invalid && (aField.touched || aField.dirty))
    //   ||  (aField.untouched && this.formSubmitAttempt);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

}
