import { Component, Input, OnInit } from '@angular/core';
import { TransactionLimit } from '../../services/models/transaction-limit.model';

@Component({
  selector: 'app-merchant-setting-transaction-limit',
  templateUrl: './merchant-setting-transaction-limit.component.html',
  styleUrls: ['./merchant-setting-transaction-limit.component.css']
})
export class MerchantSettingTransactionLimitComponent implements OnInit {
  
  @Input() transactionLimit: TransactionLimit;

  constructor() { }

  ngOnInit() {
  }

}
