export class Authority {

    public static hasAuthorities(listRole: string[]): boolean {
        let hasAccess = 0;
        let menuInfo = JSON.parse(sessionStorage.getItem('currentUser'))['menuInfo'];
        menuInfo.forEach(menu => {
            if (menu.subMenus) {
                menu.subMenus.forEach(sub => {
                    hasAccess = sub.componentsInfo.some(com => listRole.includes(com.roleActionCode)) ? ++hasAccess : hasAccess;
                });
            } else {
                hasAccess = menu.componentsInfo.some(com => listRole.includes(com.roleActionCode)) ? ++hasAccess : hasAccess;
            }
        });
        return hasAccess > 0 ? true : false;
    }
}

export const AUTHORITY = {
    // DASHBOARD
    DASHBOARD: 'ROLE_B_1900', // ค้นหาข้อมูล

    // TRANSACTION
    TRANSACTION_LIST: 'ROLE_B_2500', // ค้นหาข้อมูล
    TRANSACTION_DETAIL: 'ROLE_B_2501', // แสดงข้อมูล Transactions
    TRANSACTION_VOID: 'ROLE_B_2502', // ยกเลิกรายการ ( VOID )
    TRANSACTION_REFUND: 'ROLE_B_2503', // ขอคืนเงิน ( REFUND )
    TRANSACTION_SETTLE: 'ROLE_B_2504', // ดำเนินการโอนเงิน ( SETTLEMENT )

    //CUSTOMER
    CUSTOMER_LIST: 'ROLE_B_3200', //ค้นหาข้อมูล Customer
    CUSTOMER_DETAIL: 'ROLE_B_3301', //แสดงข้อมูล Customer และ Card

    // MULTIPLE SELECTION
    MULTIPLE_SELECTION: 'ROLE_B_4900',
    MULTIPLE_REFUND: 'ROLE_B_4901',

    //REPORT
    REPORT_LIST: 'ROLE_B_3400', // ค้นหาข้อมูล Report
    REPORT_CREATE: 'ROLE_B_3402', // เพิ่มข้อมูล Report
    REPORT_DOWNLOAD: 'ROLE_B_3403', //ดาวน์โหลดข้อมูล Report
    REPORT_DELETE: 'ROLE_B_3404', // ลบข้อมูล Report
    REPORT_TRANSACTION: 'ROLE_B_3401',

    // MERCHANT SETTING > COMPANY MANAGEMENT
    COMPANY_LIST: 'ROLE_B_3600', // ค้นหาข้อมูล Company
    COMPANY_DETAIL: 'ROLE_B_3601', // แสดงข้อมูล Company
    COMPANY_UPDATE: 'ROLE_B_3603', // แก้ไขข้อมูล Company

    // MERCHANT SETTING > USER MANAGEMENT
    USER_LIST: 'ROLE_B_3700', // ค้นหาข้อมูล User
    USER_DETAIL: 'ROLE_B_3701', // แสดงข้อมูล User
    USER_CREATE: 'ROLE_B_3702', // Create User
    USER_UPDATE: 'ROLE_B_3703', // แก้ไขข้อมูล User
    USER_DELETE: 'ROLE_B_3704', // ลบข้อมูล User
    PAYMENT_LINK_VIEW: 'ROLE_B_4500', // ค้นหาข้อมูล Payment Link
    PAYMENT_LINK_EDIT: 'ROLE_B_4501', // แก้ไขข้อมูล Payment Link


    // MERCHANT SETTING > ROLE MANAGEMENT
    ROLE_LIST: 'ROLE_B_3800', // ค้นหาข้อมูล Role
    ROLE_DETAIL: 'ROLE_B_3801', // แสดงข้อมูล Role

}
