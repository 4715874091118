import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../shared/app-constant";
import { AuthenticationService } from "./auth/authentication.service";
import { RefundResultRequest, RefundResultResponse } from "./models/multiple-refund-result.model";

@Injectable({
    providedIn: 'root'
})
export class MultipleRefundResultService {

    constructor(
        private http: HttpClient,
        private auth: AuthenticationService,
    ) { }

    getRefundResult(request: RefundResultRequest): Observable<RefundResultResponse> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(environment.getMultipleRefundResult.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request)).pipe(
                map((response: RefundResultResponse) => {
                        return response;
                    }
                ));
    }
}