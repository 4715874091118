import { ResultModel } from "./result.model";

export class MerchantSettingInstallmentInfo extends ResultModel{
    smartpayConfig: Smartpay[];
    smartpayOldConfig: Smartpay[];
}

export class Smartpay{
    merchantId: string;
    terminalId: string;
    paymentLink: boolean;
    manualData: boolean;
    holdMid: boolean;
}

export class InstallmentInfoDetailRequest{
    companyId: number;
    smartpayOld: boolean;
    pageIndex: number;
    merchantId: string;
    terminalId: string;
    manualData: boolean;
}

export class InstallmentInfoDetail extends ResultModel{
    smartpayConfigDetail: SmartpayConfigDetail[];
    showSmartpayDescription: boolean;
    totalElement: number;
    pageSize: number;
}

export class SmartpayConfigDetail{
    smartpayId: string;
    ipanMode: string;
    supplierNo: string;
    productType: number;
    modelNo: string;
    planId: number;
    paymentTerm: number;
    interestRate: string;
    description: string;
}
