
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../shared/app-constant';
import { AuthenticationService } from './auth/authentication.service';
import { ApiKeyInfoRequestModel } from './models/api-key-info-request.model';
import { ApiKeyInfoResponseModel } from './models/api-key-info-response.model';
import { RollApiKeyRequestModel } from './models/roll-api-key-request.model';
import { RollApiKeyResponseModel } from './models/roll-api-key-response.model';

@Injectable()
export class ApiKeyInfoService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getApiKeyInfo(request: ApiKeyInfoRequestModel): Observable<ApiKeyInfoResponseModel> {
    return this.http.post(
      environment.getApiKeyInfo,
      JSON.stringify(request)).pipe(
      map((response: ApiKeyInfoResponseModel) => {
        return response;
    }));
  }

  rollApiKey(request: RollApiKeyRequestModel): Observable<RollApiKeyResponseModel> {
    return this.http.post(
      environment.rollApiKey,
      JSON.stringify(request)).pipe(
      map((response: RollApiKeyResponseModel) => {
        return response;
    }));
  }

}
