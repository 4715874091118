import { ResultModel } from "./result.model";

export class ResponseUrlModel extends ResultModel {
    cardPaymentCallback: string;
    tokenCallback: string;
    alipayCallback: string;
    paypalCallback: string;
    unionpayTPNCallback: string;
    cardPaymentNotify: string;
    thaiQRNotify: string;
    alipayNotify: string;
    wechatNotify: string;
    unionpayTPNNotify: string;
    payplusNotify: string;
    paymentLinkNotify: string;
    recurringNotify: string;
    bulkRefundNotify: string;
}

export class ResponseUrlSaveDataRequest{
    companyId: number;
    cardPaymentCallback: string;
    tokenCallback: string;
    alipayCallback: string;
    unionpayTPNCallback: string;
}