export class BulkPaymentFilter {
    from?: Date;
    to?: Date;
    status?: string;
    bulkPaymentId?: string;
    pageIndex?: number;

    constructor(from?: Date, to?: Date, status?: string, bulkPaymentId?: string, pageIndex?: number) {
        this.from = from;
        this.to = to;
        this.status = status;
        this.bulkPaymentId = bulkPaymentId;
        this.pageIndex = pageIndex;
    }
}
