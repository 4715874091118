import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-nopermission',
  templateUrl: './nopermission.component.html',
  styleUrls: ['./nopermission.component.css']
})
export class NoPermissionComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.isLoading(false);
  }

}
