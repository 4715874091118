import { FormControl } from "@angular/forms";
import { DATE_PATTERN, ERROR_MESSAGES, TIME_PATTERN } from "./app-constant";
import { DateUtil } from "./date.util";

export function cloneObject(obj: any) {
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}


export const DateValidator = (fc: FormControl) => {
    var value = fc.value;
    if (typeof value == 'string' && !DATE_PATTERN.test(value)) {
      return {
        isValid: {
          valid: false,
          message: ERROR_MESSAGES.DATE_INVALID
        }
      }
    }
    if (value instanceof Object) {
      value = DateUtil.ngbDateStructToDate(value);
    }
  
    if (typeof value == 'string') {
      value = DateUtil.convDateToMMDDYYY(value);
    }
    const date = new Date(value);
    const isValid = !isNaN(date.valueOf());
    if (!isValid) {
      return isValid ? null : {
        isValid: {
          valid: false,
          message: ERROR_MESSAGES.DATE_INVALID
        }
      }
    }
    if (date.getTime() > new Date().getTime()) {
      return {
        isValid: {
          valid: false,
          message: ERROR_MESSAGES.DATE_AFTER_TODAY
        }
      }
    }
    return null
  }
  
  export const TimeValidator = (fc: FormControl) => {
    var value = fc.value;
    const invalid = {
      isValid: {
        valid: false,
        message: ERROR_MESSAGES.TIME_INVALID
      }
    }
    if (!value) {
      return null
    }
    if (!TIME_PATTERN.test(value)) {
      return invalid
    } else {
      const timeArr = value.split(":");
      if (isNaN(timeArr[0]) || isNaN(timeArr[1]) || parseInt(timeArr[0]) > 23 || parseInt(timeArr[1]) > 59) {
        return invalid
      }
    }
    return null;
  }