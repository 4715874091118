import { profile } from '../../environments/environment';

export const environment = {
    production: profile.production,
    envName: profile.envName,
    version: profile.version || '',
    captchaSiteKey: profile.captchaSiteKey,
    tokenApiUrl: profile.url + '/KPGW-Auth-Webapi/api/auth/login',
    renewTokenApiUrl: profile.url + '/KPGW-Auth-Webapi/api/auth/refresh',
    logoutApiUrl: profile.url + '/KPGW-Auth-Webapi/api/auth/logout',
    resetPasswordApiUrl: profile.url + '/KPGW-Auth-Webapi/api/auth/resetPassword',
    userInfoApiUrl: profile.url + '/KPGW-Profile-Webapi/api/getUserInfo',
    userSwitchInfoApiUrl: profile.url + '/KPGW-Profile-Webapi/api/getUserSwitchInfo',
    transactionApiUrl: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transactions',
    transactionReportUrl: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transactions/download',
    listPaymentTypesApiUrl: profile.url + '/KPGW-Payment-Webapi/api/payment/data/listPaymentTypes',
    listSourceOfFundApiUrl: profile.url + '/KPGW-Payment-Webapi/api/source-of-fund/data/listSourceOfFunds',
    listCompanyCurrencyCodes: profile.url + '/KPGW-Profile-Webapi/api/listCompanyCurrencyCodes',
    transactionVoidApiUrl: profile.url + '/KPGW-Payment-Webapi/api/prod/void',
    transactionRefundApiUrl: profile.url + '/KPGW-Payment-Webapi/api/prod/refund',
    transactionSettleApiUrl: profile.url + '/KPGW-Payment-Webapi/api/prod/settlement',
    requestGoliveApiUrl: profile.liveUrl + '/KPGW-Profile-Webapi/api/register/requestGolive',
    signupApiUrl: profile.url + '/KPGW-Profile-Webapi/api/register/signup',
    activateUserApiUrl: profile.url + '/KPGW-Profile-Webapi/api/register/activateUser',
    summaryUrl: profile.url + '/KPGW-Payment-Webapi/api/user/{userId}/summaries/',
    summaryMonthlyUrl: profile.url + '/KPGW-Payment-Webapi/api/user/{userId}/summaries/monthly/',
    dUrl: '',
    requestResetPasswordApiUrl: profile.url + '/KPGW-Profile-Webapi/api/resetPassword/request',
    formResetPasswordApiUrl: profile.url + '/KPGW-Profile-Webapi/api/resetPassword/form',
    processResetPasswordApiUrl: profile.url + '/KPGW-Profile-Webapi/api/resetPassword/process',
    getProvinceInfoApiUrl: profile.url + '/KPGW-Profile-Webapi/api/getProvinceInfo',
    getProvinceInfoRegisterApiUrl: profile.url + '/KPGW-Profile-Webapi/api/register/getProvinceInfo',
    getBusinessTypeDescsRegisterApiUrl: profile.url + '/KPGW-Profile-Webapi/api/register/getBusinessTypeDescs',
    transactionDetailApiUrl: profile.url + '/KPGW-Payment-Webapi/api/prod/userInfo/{userId}/transactions/{chargeId}',
    merchantSettingUserGetApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/getUser',
    merchantSettingUserSaveApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/saveUser',
    merchantSettingUserChangePasswordApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/saveUserChangePassword',
    merchantSettingCompanyInfoGetApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/getCompanyInfo',
    merchantSettingCompanyInfoSaveApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/saveCompanyInfo',
    getApiKeyInfo: profile.url + '/KPGW-Profile-Webapi/api/key/getApiKeyInfo',
    rollApiKey: profile.url + '/KPGW-Profile-Webapi/api/key/rollApiKey',
    getSmartPayConfig: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/getSmartPayConfig',
    customersApiUrl: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/customers',
    cardsApiUrl: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/getCards',
    getCustomerInfoApiUrl: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/getCustomerInfo',
    mainMenuStartWith: 'M',
    apiKeyType: profile.apiKeyType,
    orderTransactionAPIUrl: profile.url + '/KPGW-Payment-Webapi/api/qrsimulator/{qrId}',
    qrSimulatorInvokeAPIUrl: profile.url + '/KPGW-Payment-Webapi/api/qrsimulator/{qrId}/invoke',
    settlementReportCount: profile.url + '/KPGW-Payment-Webapi/api/settlementReport/count',
    settlementReport: profile.url + '/KPGW-Payment-Webapi/api/settlementReport/download',
    refundReportCount: profile.url + '/KPGW-Payment-Webapi/api/refundReport/count',
    refundReport: profile.url + '/KPGW-Payment-Webapi/api/refundReport/download',
    paypalReportCount: profile.url + '/KPGW-Payment-Webapi/api/paypalReport/count',
    paypalReport: profile.url + '/KPGW-Payment-Webapi/api/paypalReport/download',
    paypalPartnerReferrals: profile.url + '/KPGW-Redirect-Webapi/api/paypal/partner-referrals',
    checkActivateEmail: profile.url + '/KPGW-Redirect-Webapi/api/paypal/checkActivateEmail',
    getReportConfig: profile.url + '/KPGW-Payment-Webapi/api/user-info/report-config/find-report-config',
    merchantSettingSFTPAccountAPIUrl: profile.url + '/KPGW-Profile-Webapi/api/sftpUser/',
    getDecryptKeyPassphraseAPIUrl: profile.url + '/KPGW-Profile-Webapi/api/sftpUser/decryptKeyPassphrase',
    getCampaignInfoAPIUrl: profile.url + '/KPGW-Profile-Webapi/api/campaign',
    getMessageAPIUrl: profile.url + '/KPGW-Auth-Webapi/api/messages/list',
    countTransactionReport: profile.url + '/KPGW-Payment-Webapi/api/report-log/count',
    exportTransactionReportFile: profile.url + '/KPGW-Payment-Webapi/api/report-log/create',
    getFileReport: profile.url + '/KPGW-Payment-Webapi/api/report-log/search',
    downloadFile: profile.url + '/KPGW-Payment-Webapi/api/report-log/download',
    regenerateReport: profile.url + '/KPGW-Payment-Webapi/api/report-log/regenerate',
    deleteRecordLog: profile.url + '/KPGW-Payment-Webapi/api/report-log/delete',
    getTransactions: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transaction-export/search',
    deleteTransaction: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transaction-export/delete',
    downloadTransaction: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transaction-export/download',
    regenerateTransaction: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transaction-export/regenerate',
    exportTransaction: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transaction-export/create',
    getTransactionLimitApiUrl: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/get-transaction-limit',
    searchThaiQRInquiry: profile.url + '/KPGW-Payment-Webapi/api/user-info/{userId}/thaiqr-inquiry',
    searchPaymentLink: profile.url + '/KPGW-Payment-Webapi/api/payment-link/search',
    getPaymentLink: profile.url + '/KPGW-Payment-Webapi/api/payment-link/',
    getCurrency: profile.url + '/KPGW-Payment-Webapi/api/payment-link/get-currency/',
    getMasterMerchantIndicator: profile.url + '/KPGW-Payment-Webapi/api/payment-link/master-merchant-indicator',
    createPaymentLink: profile.url + '/KPGW-Payment-Webapi/api/payment-link/create',
    disablePaymentLink: profile.url + '/KPGW-Payment-Webapi/api/payment-link/update',
    checkOrderReference: profile.url + '/KPGW-Payment-Webapi/api/payment-link/check-order-ref/',
    getMerchantId: profile.url + '/KPGW-Payment-Webapi/api/payment-link/get-mid/',
    getMaxDateAndAmount: profile.url + '/KPGW-Payment-Webapi/api/payment-link/get-max-date-and-amount',
    verifyPaymentLink: profile.url + '/KPGW-Payment-Webapi/public/api/payment-link/verify-payment-link',
    getSupplierNumberList: profile.url + '/KPGW-Payment-Webapi/api/user-info/supplier-plan-report/get-supplier-number-list',
    getProductTypeList: profile.url + '/KPGW-Payment-Webapi/api/user-info/supplier-plan-report/get-product-type-list',
    getModelNoList: profile.url + '/KPGW-Payment-Webapi/api/user-info/supplier-plan-report/get-model-no-list',
    downloadTemplate: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/download-template-csv?fileType={templateName}',
    recurringPaymentUploadFile: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/upload',
    generateBatchId: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/get-batch-id',
    deleteFile: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/{idFile}/delete',
    cancelFile: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/{idFile}/update-status',
    recurringPaymentSearch: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/search',
    downloadRecurringFile: profile.url + '/KPGW-Payment-Webapi/internal/api/recurring-payment/{idFile}/download',
    bulkRefundSearch: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/bulk-history',
    cancelBulkRefundFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/cancel/{bulkRefundId}',
    deleteBulkRefundFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/delete/{bulkRefundId}',
    downloadBulkRefundFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/download-result/{bulkRefundId}',
    downloadBulkRefundTemplate: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/download-template',
    bulkRefundUploadFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/upload',
    generateBulkRefundId: profile.url + '/KPGW-Payment-Webapi/api/bulk-refund/get-batch-id',
    bulkPaymentSearch: profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/bulk-history',
    cancelBulkPaymentFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/cancel/{bulkPaymentId}',
    deleteBulkPaymentFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/delete/{bulkPaymentId}',
    downloadBulkPaymentFile: profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/download-result/{bulkPaymentId}',
    getOneTimeToken: profile.url + '/KPGW-Payment-Webapi/api/ksocial-pay/token',
    getChannelConfig: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/transactions/channel',
    getInstallmentType: profile.url + '/KPGW-Payment-Webapi/api/payment-link/get-ipan-mode',
    getSmartpayInfo: profile.url + '/KPGW-Payment-Webapi/api/payment-link/get-smartpay-info',
    clonePaymentLink: profile.url + '/KPGW-Payment-Webapi/api/payment-link/validate-clone/',
    getDigiPayInfo: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/getInstallmentCrossBank',
    getMultipleRefundSelect: profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/multiple/refund-select',
    getMultipleSelection: profile.url + "/KPGW-Payment-Webapi/api/userInfo/{userId}/multiple/refund-history",
    getRefundConfirm : profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/multiple/refund-confirm',
    submitMultipleRefund : profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/multiple/refund-submit',
    getMultipleRefundResult : profile.url + '/KPGW-Payment-Webapi/api/userInfo/{userId}/multiple/refund-result',
    getMerchantConfiguration : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/configuration',
    getMerchantSettingMCC : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/mcc',
    getMerchantSettingQR : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/qr',
    getMerchantSettingDCC : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/dcc',
    getMerchantSettingAlipay : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/alipay',
    getMerchantSettingWechat : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/wechat',
    getMerchantSettingPayPlus : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/payplus',
    getResponseUrl : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/responseUrl',
    getInstallmentInfo : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/installmentInfo',
    getInstallmentInfoDetail : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/installmentInfoDetail',
    getMerchantSettingTokenization : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/tokenizationConfig',
    updateResponseUrl : profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/updateResponseUrl',
    generateBulkPaymentId : profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/get-batch-id',
    bulkPaymentUploadFile : profile.url + '/KPGW-Payment-Webapi/api/bulk-payment/upload',
    getCompanyIsSyncEWallet: profile.url + '/KPGW-Profile-Webapi/api/merchantSetting/getCompanyIsSyncEWallet',
};

export const PAY_BY = {
    KBANK: 'KBANK',
    NON_KBANK: 'NON KBANK'
}

export const PAYMENT_METHOD = {
    THAI_QR: 'ThaiQR',
    ALIPAY: 'Alipay',
    WECHAT: 'WeChat'
}

export const SEARCH_ADVANCE = {
    BILL_PAYMENT: 'BillPayment',
    SOURCE_OF_FUND: 'sourceOfFund',
    PAYMENT_METHOD: 'sourceType',
    CREDIT_CARD: 'Card',
    PAYMENT_TYPE: 'paymentType',
    TRANSACTION_STATUS: 'transactionStatus',
    AMOUNT: 'amount',
    MOBILE_NO: 'mobileNo',
    COMPANY: 'company',
    UNION_PAY: 'UnionPay',
    UNION_PAY_TPN: 'UnionPay/TPN',
    TRANSACTION_DATE: 'transactionDate',
    CHANNEL: 'channel'
}

export const SOURCE_TYPE = {
    BILL_PAYMENT: 'billpayment',
    ALIPAY: 'redirect',
    WECHAT: 'qr'
}

export const SOURCE_OF_FUND = {
    NOTIFICATION: 'Pay PLUS (Notification)',
    APP_SWITCH: 'Pay PLUS (App Switch)',
}

export const SOURCE_OF_FUND_DISPLAY = {
    NOTIFICATION: 'PayPLUS (Notification)',
    APP_SWITCH: 'PayPLUS (App Switch)',
}

export const PATTERN = {
    NUMBER_WITH_DOT_PATTERN: /^[0-9]+(\.[0-9]{1,2}){0,1}$/,
    NUMBER_ONLY: /^[0-9]+$/
}

export const TRANSACTION_STATUS = {
    ALL: 'All',
    INITIALIZED: 'Initialized',
    AUTHORIZED: 'Authorized',
    DECLINED: 'Declined',
    VOIDED: 'Voided',
    SETTLED: 'Settled',
    REFUNDED: 'Refunded'
}

export const SUB_FILTER = {
    ALL: 'All',
    PARTIAL_REFUND: 'Partial Refund',
    REFUNDED: 'Refunded',
    REFUND_FAILED: 'Refund Failed'
  }

export const AMOUNT_OPTION = {
    EQUAL: 'equal',
    BETWEEN: 'between'
}

export const STATUS = {
    SUCCESS: 'success',
    NOT_FOUND: 'internal_data_not_found'
}

export const message = {
    title: {
        success: 'Success',
        confirm_delete: 'Confirm Delete',
        confirm_download: 'Confirm Download File',
        fail: 'Fail',
        error: 'Error',
        confirm_refund: 'Confirm Refund',
        cancel_file: 'Confirm Cancel',
        information: "Information",
        confirmation : 'Confirmation',
        warning: 'Warning'
    },
    content: {
        export_success: 'Export success.',
        delete_success: 'Delete success.',
        regenerate_success: 'Regenerate success.',
        confirm_delete: 'Please Confirm Delete',
        confirm_download: 'Please Confirm Download',
        common_error: 'Something went wrong!',
        failMessage: 'Something went wrong, we temporary cannot generate the report. \nPlease contact Administrator',
        orClickRegenerate: '(or click "Regenerate")',
        error_date_range: 'Date must between ',
        error_date_range_exceed: 'กรุณาเลือกช่วงเวลาในการดึงข้อมูลไม่เกิน {0} วัน \nPlease select the date range between {0} dates and try again later.',
        processing: 'Processing...',
        delete_fail:  'Delete fail.',
        regenegate_fail: 'Regenerate fail.',
        create_fail: 'Create fail.',
        download_success: 'Download success.',
        download_fail: 'Download fail.',
        create_success: 'Create success.',
        confirm_refund: "Please click \"confirm\" to refund",
        upload_success: 'Upload successfully',
        recurring_delete_fail: 'Delete file failed. Please try again.',
        cancel_success: 'Cancel file successfully',
        cancel_fail: 'Cancel file failed. Please try again.',
        confirm_cancel: 'Please Confirm Cancel',
        trax_exceed_limit: "Sorry, the system cannot display data more than 5,000 records. Please select a different time period and try again later. That mean user need to re-select the filter again. ",
        inform_installment_cross_bank: "Please Create Report again if you need a new download.",
        confirm_multiple_refund : 'Are you sure to proceed',
        multiple_refund_fail: 'Multiple refund fail',
        max_multiple_refund: 'The selected transaction cannot exceed more than {value} transactions',
        mid_and_tid_hold: 'MID is unable to create Payment Link.'
    }
}

export const COMMON_TEXT = {
    DOWNLOAD: 'Download'
}

export const FORMAT = {
    YYYYMMDD_FORMAT: 'yyyy-mm-dd', // format to send to server
    DDMMYYYY_FORMAT: 'dd/mm/yyyy', // format to show on UI
    MMDDYYYY_FORMAT: 'mm/dd/yyyy' // date format on Mobile
}

export const ELEMENT = {
    MAIN_CONTENT_ID: 'main-contain'
}

export const HTTP_ERROR_MESSAGE = {
    404: 'Not found!',
    403: 'Forbidden!'
}

export const SCREEN_GROUP = {
    MERTCHANT_SETTING_MENU: 'MERCHANT_SETTING_MENU'
}

export const CACHE_OBSERVABLE_SERVICE = {
    SELECTED_EXPORT_CRITERA: 'selected_export_criteria',
    SELECTED_EXPORT_CRITERA_VALUE: 'selected_export_criteria_value',
    REQUEST_EXPORT_MODEL: 'request_export_model',
    TRANSACTION_DETAIL_GO_BACK: 'transaction_detail_goback',
    FILTER_TRANSACTION_STATUS: 'filterTransactionStatus',
    SUB_FILTER_REFUNDED_STATUS: 'subFilterRefundedStatus',
    SEARH_ADVANCE: 'search_Advance',
    PAYMENT_LINK_CLONE: 'payment_link_clone',
    PAYMENT_LINK_REDIRECT: 'payment_link_redirect',
    THAI_QR_INQUIRY: 'thai_qr_inquiry',
    PAYMENT_LINKS_SEARCH: 'PAYMENT_LINKS_SEARCH',
    PAYMENT_LINKS_SELECTED_LIST: 'PAYMENT_LINKS_SELECTED_LIST',
    MULTIPLE_REFUND_CRITERIA: 'MULTIPLE_REFUND_CRITERIA',
    MULTIPLE_REFUND_SELECTED_RECORDS: 'MULTIPLE_REFUND_SELECTED_RECORDS',
    MULTIPLE_REFUND_PAGE_INFO: 'MULTIPLE_REFUND_PAGE_INFO',
    MULTIPLE_SELECTION_GO_BACK : 'MULTIPLE_REFUND_GO_BACK'
}

export const SOURCE_TYPES = [
    {
        name: 'Credit Card',
        value: 'Card'
    },
    {
        name: 'ThaiQR',
        value: 'ThaiQR'
    },
    {
        name: 'Alipay',
        value: 'Alipay'
    },
    {
        name: 'WeChat',
        value: 'WeChat'
    },
    {
        name: 'PayPal',
        value: 'PayPal'
    },
    {
        name: 'Bill Payment',
        value: 'BillPayment'
    }
];

export const CARD_TYPES = [
    {
        name: 'VISA',
        value: false
    },
    {
        name: 'MASTERCARD',
        value: false
    },
    {
        name: 'JCB',
        value: false
    },
    {
        name: 'UnionPay/TPN',
        value: false
    }
];

// export const UNION_PAYS = [
//     {
//         name: 'UnionPay/TPN',
//         value: false
//     },
//     {
//         name: 'XPC',
//         value: false
//     }
// ];

export const AMOUNTS = [
    {
        name: 'Equal',
        value: 'equal'
    },
    {
        name: 'Between',
        value: 'between'
    },
    {
        name: 'Greater',
        value: 'greater'
    },
    {
        name: 'Less',
        value: 'less'
    }
];

export const DATE_OPTIONS = [
    {
        name: 'Today',
        value: 'today'
    },
    {
        name: 'Yesterday',
        value: 'yesterday'
    },
    {
        name: 'Lastweek',
        value: 'lastweek'
    },
    {
        name: 'Twoweek',
        value: 'twoweek'
    },
    {
        name: 'Month',
        value: 'month'
    },
    {
        name: 'Lastmonth',
        value: 'lastmonth'
    },
    {
        name: 'Custom',
        value: 'custom'
    },
    {
        name: 'Between',
        value: 'between'
    }
];

export const SECURE_TRANSACTIONS = [
    {
        showName: 'Non',
        name: false,
        value: false
    },
    {
        showName: 'Secure',
        name: true,
        value: false
    }
];

export const TRANSACTION_STATUS_DATA = [
    {
        name: TRANSACTION_STATUS.INITIALIZED,
        value: false
    },
    {
        name: TRANSACTION_STATUS.AUTHORIZED,
        value: false
    },
    {
        name: TRANSACTION_STATUS.DECLINED,
        value: false
    },
    {
        name: TRANSACTION_STATUS.VOIDED,
        value: false
    },
    {
        name: TRANSACTION_STATUS.SETTLED,
        value: false
    },
    {
        name: TRANSACTION_STATUS.REFUNDED,
        value: false
    }
]

export const ERROR_CODE = {
    DUPLICATED_PWD: 'internal_new_password_duplicate',
    TRX_EXCEED_LIMIT: 'response_transaction_exceed_limit'
}

export class DateRegExp {
    public static readonly DAY_MONTH_YEAR: RegExp = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
    public static readonly YEAR_MONTH_DAY: RegExp = /(\d{4})-(\d{1,2})-(\d{1,2})/;
    public static readonly TIME_ZONE: RegExp = /(\d{4})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2}).(\d{1,3})Z/;
}

export const DATE_OPTIONS_THAIQR = [
    {
      name: 'All',
      value: 'all'
    },
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Yesterday',
      value: 'yesterday'
    },
    {
      name: 'Custom',
      value: 'custom'
    },
    {
      name: 'Between',
      value: 'between'
    }
  ];

  export const DATE_PATTERN = /(^[0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})/;
  export const TIME_PATTERN = /(^[0-9]{1,2}):([0-9]{1,2})/;

  export const FORM_CONTROL = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    START_TIME: 'startTime',
    END_TIME: 'endTime',
    CUSTOM_DATE: 'customDate',
    REF_NUMBER: 'refNumber',
    AMOUNT: 'amount'
  }

  export const ERROR_MESSAGES = {
    DATE_AFTER_TODAY: 'Date cannot after Today.',
    DATE_INVALID: 'Date is invalid.',
    FROM_BEFORE_TO: 'From date cannot after To date.',
    TO_AFTER_FROM: 'To date cannot before From date.',
    OVER_30_DAYS: 'Order Date cannot over than 30 days.',
    TIME_INVALID: 'Time is invalid',
    START_TIME_AFTER_END_TIME: 'Start Time cannot after End Time.',
    END_TIME_BEFORE_START_TIME: 'End Time cannot before Start Time.',
    TRX_EXCEED_LIMIT: "The system cannot display data more than 50,000 records. Please select a different time period and try again later."
  }

export const PAYMENT_LINK_TYPE = {
    ONE_TIME: 'ONE_TIME',
    MULTIPLE: 'MULTIPLE'
}

export const PAYMENT_LINK_STATUS = {
    ACTIVE: 'Active',
    PAID: 'Paid',
    DISABLED: 'Disabled',
    EXPIRED: 'Expired'
}
export const THAI_QR_STATUS = {
    VOIDED: 'VOIDED',
    PAID: 'PAID',
    REQUESTED: 'REQUESTED',
    REFUNDED: 'REFUNDED'
}

export const PAYMENT_LINK_CLONE_MESSAGE = {
    TITLE: 'Confirm Clone Link',
    MESSAGE: 'Please Confirm Clone Link',
    TITLE_CLONE_INVALID: 'Information',
    MESSAGE_CLONE_INVALID: 'Some parameter of original Payment Link isn\'t valid with your current profile. Please confirm to continue.'
}

export const PAYMENT_LINK_DISABLE_MESSAGE = {
    TITLE: 'Confirm Disable Link',
    MESSAGE: 'Please Confirm Disable Link'
}

export const PAYMENT_LINK_ERROR_MESSAGE = {
    emptyProductNameError: 'Product / Service Name is required.',
    emptyOrderRefError: 'Reference number is required.',
    existedOrderRefError: 'The Reference Number you entered is duplicated. Please try again with a new one.',
    invalidOrderRefError: 'The Thai character, space and special character are not allowed to enter.',
    invalidSmallAmountError: 'This currency does not support decimals.',
    amountGreaterThanOrEqualToOneError: 'The amount must be greater than or equal to one.',
    amountGreaterThanLimitPerTransactionError: 'The amount you entered is exceed the maximum limit per transaction. Please enter a lower amount.',
    emptyAmountdError: 'Amount is required for ONE TIME payment',
    invalidMaxPaymentTimeError: 'Max payment times is a number and greater than 0.',
    invalidDateError: 'Date is invalid.',
    invalidMinDateError: 'Selected date cannot before Today.',
    // minActiveTimeError: 'Active time cannot before current time.',
    minExpireTimeError: 'Expire time cannot before current time.',
    activeDateRangeError: 'Active time must be before expire time.',
    expireDateRangeError: 'Expire time must be after active time.',
    emptyActiveDateError: 'Please select active date.',
    emptyExpireDateError: 'Please select expire date.',
    expireDateThanMoreDateAllow: 'Expire time only allow within ${DAYS} from Active time.',
    activeDateThanMoreDateAllow: 'Active time only allow within ${DAYS} from Today.',
    invalidActiveHour: 'The payment link cannot use activate period in the past.',
    invalidRefNamePattern: "This Thai and special characters are not allowed to enter.",
    invalidNumbericPattern: "This value must be numeric."

}

export const PAYMENT_LINK_SEARCH = [
    {id: 1, name: 'linkRef', showName: 'Link Ref.'},
    {id: 2, name: 'serviceName', showName: 'Product/Service Name'},
    {id: 3, name: 'linkDesc', showName: 'Link Description'},
    {id: 4, name: 'orderRef', showName: 'Reference Number'},
    {id: 5, name: 'amount', showName: 'Amount'},
    {id: 6, name: 'createTime', showName: 'Create Date/Time'},
    {id: 7, name: 'linkType', showName: 'Link Type'},
    {id: 8, name: 'activeTime', showName: 'Active Date/Time'},
    {id: 9, name: 'expiredTime', showName: 'Expired Date/Time'}
  ];
  export const PAYMENT_LINK_TYPES = [
    {
        name: 'ONE-TIME',
        key: 'ONE_TIME',
        value: false
    },
    {
        name: 'MULTIPLE TIMES',
        key: 'MULTIPLE',
        value: false
    }
  ];

  export const PAYMENT_LINK_DATE_OPTIONS = [
    {
        name: 'Today',
        value: 'today'
    },
    {
        name: 'Between',
        value: 'between'
    }
  ];

  export const PAYMENT_LINK_DATE_ERROR_MESSAGES = {
    FROM_AFTER_TO: 'From date cannot after To date',
    TO_BEFORE_FROM: "To date cannot before From date",
    ERROR_DATE_RANGE: '${FIELD} cannot over than 6 months.'
}

export enum DataType {
    Alphanumeric = 'Alphanumeric',
    Numeric = 'Numeric'
}

export const FILE_TYPES = [
    { value: 'TOKEN_PROVISIONING', name: 'Token Provisioning' },
    { value: 'RECURRING_PAYMENT', name: 'Recurring Payment' },
    { value: 'RECURRING_REFUND', name: 'Recurring Refund' }];

export const BY_PASS_CONTENT_TYPE_URL = [
    environment.recurringPaymentUploadFile,
    environment.bulkRefundUploadFile,
    environment.bulkPaymentUploadFile
]
export const MESSAGE_EVENT_LISTENER = {
    SOCIAL_PLUGIN_LOADED: 'SOCIAL_PLUGIN_LOADED',
    ASSIGN_ONE_TIME_TOKEN: 'ASSIGN_ONE_TIME_TOKEN',
    ONE_TIME_TOKEN_RECEIVED: 'ONE_TIME_TOKEN_RECEIVED'
}
export const EXCEED_THAIQR_TOTAL_RECORD = 'exceed_total_record';
export const API_ERROR = {
    REF_MIN_MAX_ERR: 'error_ref_min_max',
    INTERNAL_PERMISSION_ERR: 'internal_permission_denied',
    INVALID_IPAN_MODE: 'invalid_ipan_mode',
    INVALID_TERM: 'invalid_term',
    INVALID_MID: 'invalid_mid'
}

export const DATE_OPTION = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LASTWEEK: 'lastweek',
    TWOWEEK: 'twoweek',
    MONTH: 'month',
    LASTMONTH: 'lastmonth',
    CUSTOM: 'custom',
    BETWEEN: 'between'
};

export const BULK_REFUND_VALUE_STATUS = {
    PENDING: 'pending',
    PROCESSING: 'processing',
    CANCELLED: 'cancelled',
    READY_TO_DOWNLOAD: 'ready_to_download'
}

