import { TransactionRequestDateModel } from './transaction.request.model.date';

export class SearchThaiQRInquiry {
    companyId: number[];
    refNumber: string;
    fromDate: any;
    toDate: any;
    pageIndex: number;
    pageSize: number;
    amount: number;
    statusId: number;
    isSettled: boolean;
    channel: string;
    paymentType: string;
}
