import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { DateUtil } from '../../shared/date.util';
import { NgbDateStruct, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { CommonService } from '../../services/common.service';
import { message, FILE_TYPES } from '../../shared/app-constant';
import { Subject } from 'rxjs';
import { RecurringPaymentService } from '../../services/recurring-payment.service';
import { RecurringDownloadTemplateResponse } from '../../services/models/recurring-download-template-response.model';
import { ToasterService } from '../../services/toaster.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { RecurringPaymentInfo } from '../../services/models/recurring-payment-info.model';
import { RecurringPaymentFilter } from '../../services/models/recurring-payment-filter.model';

export const VALUE_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  CANCELLED: 'CANCELLED',
  READY_TO_DOWNLOAD: 'READY_TO_DOWNLOAD'
}

export const STATUS = [
  {value: VALUE_STATUS.PENDING, name: 'Pending'},
  {value: VALUE_STATUS.PROCESSING, name: 'Processing'},
  {value: VALUE_STATUS.CANCELLED, name: 'Cancelled'},
  {value: VALUE_STATUS.READY_TO_DOWNLOAD, name: 'Ready To Download'}
];
export const pendingMessage = 'Your transactions will be processed at {time}. Meanwhile, the request can be cancelled.';
export const processingMessage = 'Processing your batch file. You can download the batch result after the batch status is “Ready to download”.';
export const RECURRING_SCREEN = {
  SCREEN_CODE: 'M034',
  ROLE_ACTION_CODE: 'ROLE_B_4701'
}

export const LAST_BATCH_RUN_HOUR = 18;
export const RESUME_BATCH_RUN_HOUR = 22;
export const CUTOFF_HOUR_FOR_AM_PM = 12;

@Component({
  selector: 'app-recurring-tokenization',
  templateUrl: './recurring-payment.component.html',
  styleUrls: ['./recurring-payment.component.css']
})

export class RecurringPaymentComponent implements OnInit {

  @ViewChild('rangInputDate') rangInputDate: ElementRef;
  @ViewChild('datePicker') datePicker: NgbInputDatepicker;
  @ViewChild('dateTemplate') dateTemplate: ElementRef;

  public fileTypes: any;
  public statusList: any;
  public fileType: string;
  public status: string;
  public maxDate: any;

  public hoveredDate: NgbDateStruct;
  public fromDate: any;
  public toDate: any;

  public reportConfigModel: string;
  public parsed: string;
  public modelDatePiker: string;
  public pendingMessage: string;
  public isEditRole: boolean;
  public recurringPaymentFilter: RecurringPaymentFilter;
  public recurringPaymentInfos: RecurringPaymentInfo[];
  public collectionSize: number;
  public valueStatus: any;

  private timeBatchRun: number;
  private serverDateTime: any;

  isHovered = inputDate => this.fromDate && !this.toDate && this.hoveredDate && DateUtil.isAfter(inputDate, this.fromDate) && DateUtil.isBefore(inputDate, this.hoveredDate);
  isInside = inputDate => DateUtil.isAfter(inputDate, this.fromDate) && DateUtil.isBefore(inputDate, this.toDate);
  isFrom = inputDate => DateUtil.isEqualsNgbDateStruct(inputDate, this.fromDate);
  isTo = inputDate => DateUtil.isEqualsNgbDateStruct(inputDate, this.toDate);
  isDisabled = (date: NgbDate, current: { year: number }) => date.day <= this.maxDate;

  constructor(
    private commonService: CommonService,
    private renderer: Renderer2,
    private parserFormatter: NgbDateParserFormatter,
    private recurringPaymentService: RecurringPaymentService,
    private toasterService: ToasterService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.fileTypes = FILE_TYPES;
    this.statusList = STATUS;
    this.valueStatus = VALUE_STATUS;
    this.maxDate = DateUtil.dateToNgbDateStructCustom(new Date());
    this.parsed = "All";
    this.pendingMessage = pendingMessage;
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    this.isEditRole = menuInfos.some(info => info.screenCode == RECURRING_SCREEN.SCREEN_CODE && info.componentsInfo.some(com => com.roleActionCode == RECURRING_SCREEN.ROLE_ACTION_CODE));
    this.recurringPaymentFilter = new RecurringPaymentFilter();
    this.recurringPaymentFilter.fileType = null;
    this.recurringPaymentFilter.status = null;
    this.recurringPaymentFilter.from = null;
    this.recurringPaymentFilter.to = null;
    this.recurringPaymentFilter.batchId = null;
    this.recurringPaymentFilter.pageIndex = 1;
    this.recurringPaymentFilter.pageSize = 15;
    this.recurringPaymentInfos = [];
    this.collectionSize = 0;
    this.search();
  }

  public refresh() {
    this.search();
  }

  public changeFilterCondition() {
    console.log(this.recurringPaymentFilter);
    this.recurringPaymentFilter.pageIndex = 1;
    this.search()
  }

  public closeActiveDatePicker(event) {
    if (event) {
      if (!this.toDate && this.fromDate) {
        this.toDate = this.fromDate
        this.parsed += ' - ' + this.parserFormatter.format(this.fromDate);
        this.writtenValue(this.parsed);
        this.recurringPaymentFilter.from = DateUtil.parseToDate(this.fromDate);
        this.recurringPaymentFilter.to = DateUtil.parseToDate(this.toDate);
        this.search();
      }
      this.datePicker.close();
    }
  }

  /**
   * deleteRecord
   */
  // confirm show popup when delete
  public deleteFile(fileId) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doDelete(fileId);
      }
    });
    this.commonService.alertDelete(message.title.confirm_delete, message.content.confirm_delete, callback);
  }

  /**
   * downloadFile
   */
  public downloadFile(idFile: string) {
    this.commonService.isLoading(true);
    this.recurringPaymentService.downloadFile(idFile).subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        const anchor = document.createElement('a');
        anchor.href = response.properties.preSignUrl;
        anchor.setAttribute('download', '');
        anchor.click();
        this.toasterService.success(message.content.download_success);
      } else {
        this.toasterService.error(message.content.download_fail)
      }
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  /**
   * cancelFile
   */
  public cancelFile(fileId) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doCancel(fileId);
      }
    });
    this.commonService.alertDelete(message.title.cancel_file, message.content.confirm_cancel, callback);
  }

  public downloadTemplate(template) {
    this.recurringPaymentService.downloadFileTemplate(template).subscribe((response: RecurringDownloadTemplateResponse) => {
      if (this.commonService.isSuccess(response)) {
        const myBlob = new Blob([response.headerFile], { type: 'text/csv' });
        const blobURL = window.URL.createObjectURL(myBlob);
        const anchor = document.createElement('a');
        anchor.download = response.fileName;
        anchor.href = blobURL;
        anchor.click();
      }
    });
  }

  public onDateSelection(inputDate: NgbDateStruct) {
    this.parsed = ''; // initializing with empty string
    if (!this.fromDate && !this.toDate) {
      this.fromDate = inputDate;
    } else if (this.fromDate && !this.toDate && (DateUtil.isAfter(inputDate, this.fromDate) || DateUtil.isEqualsNgbDateStruct(inputDate, this.fromDate))) {
      this.toDate = inputDate
    } else {
      this.toDate = null;
      this.fromDate = inputDate;
    }
    if (this.fromDate) {
      // if fromDate is set: add the first date
      this.parsed += this.parserFormatter.format(this.fromDate);
      this.renderer.setProperty(this.rangInputDate
        .nativeElement, 'value', this.parsed);
    }
    if (this.toDate) {
      // if toDate is set: add the second date with separator
      this.parsed += ' - ' + DateUtil.formatDate(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day));
    }
    if (this.fromDate && this.toDate) {
      // here we update the input value with the new this.parsed value
      this.writtenValue(this.parsed);
      this.recurringPaymentFilter.from = DateUtil.parseToDate(this.fromDate);
      this.recurringPaymentFilter.to = DateUtil.parseToDate(this.toDate);
      this.search();
    }
  }

  public getMessageTooltip(status) {
    const timeAfterPlus = new Date(this.serverDateTime);
    if(timeAfterPlus.getMinutes() < this.timeBatchRun) timeAfterPlus.setMinutes(this.timeBatchRun);
    else timeAfterPlus.setMinutes(this.timeBatchRun + 60);
    let hours = timeAfterPlus.getHours();
    if (LAST_BATCH_RUN_HOUR < hours && hours < RESUME_BATCH_RUN_HOUR) hours = RESUME_BATCH_RUN_HOUR;
    let time = hours + ':' + String(timeAfterPlus.getMinutes()).padStart(2,'0') + ' ' + (hours >= CUTOFF_HOUR_FOR_AM_PM ? 'pm' : 'am');
    this.pendingMessage = pendingMessage.replace('{time}', time);
    return status == this.valueStatus.PENDING ? this.pendingMessage : processingMessage
  }

  public loadPage(page: number) {
    this.recurringPaymentFilter.pageIndex = page;
    this.search();
  }

  public changeBatchId() {
    this.recurringPaymentFilter.pageIndex = 1;
    if (!this.recurringPaymentFilter.batchId) {
      this.recurringPaymentFilter.batchId = null;
    }
    this.search();
  }

  public parseType(type: string) {
    return this.fileTypes.find((item) => item.value == type).name
  }

  public parseStatus(status: string) {
    return this.statusList.find((item) => item.value == status.toLocaleUpperCase()).name
  }

  /**
     * update the input value for datepicker
     * @param value
     */
  private writtenValue(value) {
    this.renderer.setProperty(this.rangInputDate
      .nativeElement, 'value', value);
    this.datePicker.close();
  }

  private doDelete(fileId) {
    this.recurringPaymentService.deleteFile(fileId).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.delete_success);
        this.recurringPaymentFilter.pageIndex = 1;
        this.search();
      } else {
        this.toasterService.error(message.content.recurring_delete_fail);
      }
    })
  }

  private doCancel(fileId) {
    this.recurringPaymentService.cancelFile(fileId).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.cancel_success);
        this.recurringPaymentFilter.pageIndex = 1;
        this.search();
      } else {
        this.toasterService.error(message.content.cancel_fail);
      }
    })
  }

  private search() {
    this.commonService.isLoading(true);
    this.recurringPaymentInfos = [];
    this.recurringPaymentService.search(this.recurringPaymentFilter).subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.serverDateTime = new Date(response.serverDateTime);
        this.recurringPaymentInfos = response.recurring_file_infos;
        this.recurringPaymentInfos.map((info) => {
          if (info.status === this.valueStatus.PROCESSING) {
            const updateTime = new Date(info.updateDateTime);
            var time = this.serverDateTime.getTime() - updateTime.getTime();
            const days = Math.floor(time / 86400000);
            console.log(days);
            const hours = Math.floor((time % 86400000) / 3600000);
            console.log(hours);
            const minutes = Math.round(((time % 86400000) % 3600000) / 60000)
            console.log(minutes);
            if (days > 0) {
              info.lastCheck = days + ' day(s) ago';
            } else if (hours > 0) {
              info.lastCheck = hours + ' hours ago';
            } else if (minutes > 0) {
              info.lastCheck = minutes + ' minutes ago';
            }
          }
        });
        this.collectionSize = response.total_elements;
        this.timeBatchRun = response.timeRunBatch;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    })
  }
}
