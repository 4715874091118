export class CompanyInfoModel {
    accountNo: string;
    addressLine1: string;
    addressLine2: string;
    businessTypeId: number;
    city: string;
    companyId: number;
    companyName: string;
    companyNameEn: string;
    companyIdName: string;
    email: string;
}
