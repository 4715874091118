import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { SummaryResponse } from '../../services/models/summary-response';
import { SummaryService } from '../../services/summary.service';
import { SummaryRequest } from '../../services/models/summary-request';
import { SummaryResponseItem } from '../../services/models/summary-response-item';
import { CommonService } from '../../services/common.service';
import { SummaryMonthlyItem } from '../../services/models/summary-monthly-item';
import { faLock } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() date: Date;
  @Input() monthly: boolean;
  @Input() companies: number[];

  faLock = faLock;

  summaryResponse: SummaryResponse;

  monthSummary: SummaryMonthlyItem;

  limitReached: boolean;
  nearlyLimit: boolean;

  isParents: boolean;


  defaultSummary: SummaryResponseItem = {
    tx_count: 0,
    currency_code: 'THB',
    authorized_total: 0,
    percentage_authorized: 0
  };

  constructor(private commonService: CommonService, private summaryService: SummaryService) {
  }

  ngOnInit(): void {
    this.monthSummary = new SummaryMonthlyItem();
    this.nearlyLimit = false;
    this.limitReached = false;
    if (this.date) {
      this.searchSummary();
    }
    if (this.monthly) {
      this.summaryMonthly();
    }
  }

  private searchSummary() {
    console.log(' -- searchSummary -- ');
    // console.log(this.companies);
    // console.log(this.date);

    const sr = new SummaryRequest();
    sr.companies = this.companies;
    sr.summaryDate = new Date(this.date);

    this.summaryResponse = {
      status_code: 'ok',
      status_message: 'ok',
      properties: null,
      items: []
    };

    this.summaryService.getSummaries(sr)
      .subscribe(response => {
        this.summaryResponse = response;
        if (this.commonService.isSuccess(response)) {
          this.summaryResponse = response;
        } else {
          this.summaryResponse.items = [];
          this.summaryResponse.items.push(this.defaultSummary);
        }
      },
        err => console.log('getSummaries error=' + JSON.stringify(err)));
  }

  private summaryMonthly() {
    console.log(' -- summaryMonthly -- ');

    const sr = new SummaryRequest();
    sr.companies = this.companies;

    this.summaryResponse = {
      status_code: 'ok',
      status_message: 'ok',
      properties: null,
      items: []
    };

    this.summaryService.summaryMonthly(sr)
      .subscribe(response => {
        this.isParents = response.parents;
        this.monthSummary = response.items && response.items.length > 0 ? new SummaryMonthlyItem(response.items.filter((s) => s.companyId == this.companies[0])[0]) : null;
        this.limitReached = this.monthSummary && this.monthSummary.limitAmountPerMonth > 0 && this.monthSummary.monthAmountQuota >= this.monthSummary.limitAmountPerMonth;
        this.nearlyLimit = this.monthSummary && this.monthSummary.limitAmountPerMonth > 0 && this.monthSummary.monthAmountQuota >= (80 / 100) * this.monthSummary.limitAmountPerMonth && !this.limitReached;
        this.commonService.isLoading(false);
      },
        err => console.log('getSummaries error=' + JSON.stringify(err)));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companies && !changes.companies.isFirstChange()) {
      this.companies = changes.companies.currentValue;
      if (this.date) {
        this.searchSummary();
      }
      if (this.monthly) {
        this.summaryMonthly();
      }
    }
  }
}
