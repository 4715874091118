import { TransactionDetailLogModel } from "./models/transaction-detail-log.model";
import { TransactionDetailMulticlearingModel } from "./models/transaction-detail-multiclearing.model";
import { TransactinDetailNotifyHistory } from "./models/transaction-detail-notify-history.model";
import { TransactionDetailModel } from "./models/transaction-detail.model";

export class OrderInfo extends TransactionDetailModel {
    orderTransactionId: string;
    trxId: string;
    merchantId: string;
    orderId: string;
    terminalId: string;
    chargeId: string;
    providerId: string;
    paymentMethod: string;
    sourceOfFund: string;
    refNumber: string;
    providerRefNumber: string;
    productDescription: string;
    amount: string;
    settledAmount: string;
    createdDate: string;
    updatedDate: string;
    qrStatus: string;
    trxStatus: string;
    currency: string;
    ref1: string;
    ref2: string;
    ref3: string;
    paidBy: string;
    logs: TransactionDetailLogModel[];
    multiClearingItems: TransactionDetailMulticlearingModel[];
    multiClearing: string;
    notifyHistories: TransactinDetailNotifyHistory[];
    paymentType: string;
    metaData: string;
    statusDate: string;
    paymentLinkRef: string;
    channel: string;

    constructor() {
        super()
    }

}