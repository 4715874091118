import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AMOUNTS, PAYMENT_LINK_SEARCH, PAYMENT_LINK_TYPES, PAYMENT_LINK_DATE_OPTIONS, PAYMENT_LINK_DATE_ERROR_MESSAGES, PATTERN } from '../../shared/app-constant';
import { DateUtil } from '../../shared/date.util';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaymentLinkSearchRequest } from '../../services/models/payment-link-search-request.model';

@Component({
  selector: 'app-payment-link-search',
  templateUrl: './payment-link-search.component.html',
  styleUrls: ['./payment-link-search.component.css']
})
export class PaymentLinkSearchComponent implements OnInit {

  @Input() isShow: boolean;
  @Input() selectedList: any[]; // keep data search
  @Input() request: PaymentLinkSearchRequest; // keep data search
  @Output() close = new EventEmitter<any>();
  @Output() onApplyFilter = new EventEmitter<any>();

  public availableSearchList: any[];
  public selectedSearchs: any[];

  public paymentLinkSearch: PaymentLinkSearchRequest;
  public isShowAddButton: boolean;
  public searchFormGroup: FormGroup;
  //Form control
  public startCreateDate: FormControl;
  public endCreateDate: FormControl;
  public startActiveDate: FormControl;
  public endActiveDate: FormControl;
  public startExpiredDate: FormControl;
  public endExpiredDate: FormControl;
  public amountRef1Ctrl: FormControl;
  public amountRef2Ctrl: FormControl;
  //Error date field
  public startCreateDateErrMess: string;
  public endCreateDateErrMess: string;
  public startActiveDateErrMess: string;
  public endActiveDateErrMess: string;
  public startExpiredDateErrMess: string;
  public endExpiredDateErrMess: string;
  //Eror date range
  public createDateErrorRange: string;
  public activeDateErrorRange: string;
  public expiredDateErrorRange: string;

  public dateOptionsList = PAYMENT_LINK_DATE_OPTIONS;
  public amountList = AMOUNTS;

  public idCreateDateTime: number; //default filter
  public isPaymentSearch: boolean; // filter directive

  constructor() { }

  ngOnInit() {
    this.initData();
  }

  modelChanged(searchItemId) {
    for (let i = 0; i < this.selectedSearchs.length; i++) {
      if (this.selectedSearchs[i].id == searchItemId) {
        this.selectedSearchs[i].name = this.availableSearchList.find((searchItem) => searchItem.id === searchItemId).name;
      }
    }
  }

  addMoreFilter() {
    const available = this.availableSearchList.find(x =>
      !this.selectedSearchs.some(m => m.id === x.id)
    );
    this.selectedSearchs.push({
      id: available.id,
      name: available.name,
      showName: available.showName,
      linkTypes: PAYMENT_LINK_TYPES,
      dateOptionsList: PAYMENT_LINK_DATE_OPTIONS
    });
    this.isShowAddButton = !(this.selectedSearchs.length == PAYMENT_LINK_SEARCH.length)
  }

  applyFilter() {
    const request = this.bindNewRequest();
    this.onApplyFilter.emit({ request: request, selectedSearchs: this.selectedSearchs });
    this.setDateFormControl();
    this.isShow = false;
  }

  disableApply() {
    const isSearchAmount = this.selectedSearchs.find((item) => item.name == 'amount');
    return (( this.startCreateDateErrMess || this.endCreateDateErrMess || this.createDateErrorRange) && this.paymentLinkSearch.createDateOpt == 'between')
    || ((this.startActiveDateErrMess || this.endActiveDateErrMess || this.activeDateErrorRange) && this.paymentLinkSearch.activeDateOpt == 'between')
    || ((this.startExpiredDateErrMess || this.endExpiredDateErrMess || this.expiredDateErrorRange) && this.paymentLinkSearch.expireDateOpt == 'between')
    || (isSearchAmount && (this.amountRef1Ctrl.errors || (this.amountRef2Ctrl.errors && this.paymentLinkSearch.amountOpr == 'between')))
  }

  removeFilter(selectedSearchId) {
    if (!this.selectedSearchs) {
      return;
    }
    const index = this.selectedSearchs.findIndex(x => x.id === selectedSearchId);
    this.selectedSearchs.splice(index, 1);

    if (this.selectedSearchs.length !== this.availableSearchList.length) {
      this.isShowAddButton = true;
    }
  }

  hideAdvanceSearchFilter() {
    this.isShow = false;
    this.close.emit();
  }

  closeDatePicker(event, field) {
    if (event) {
      field.close();
    }
  }

  changeDateOption(field: string, option: string) {
    switch(field) {
      case 'createDate':
        if (option == 'today') {
          this.startCreateDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
          this.endCreateDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
        }
        break;
      case 'activeDate':
        if (option == 'today') {
          this.startActiveDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
          this.endActiveDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
        }
        break;
      case 'expiredDate':
        if (option == 'today') {
          this.startExpiredDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
          this.endExpiredDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date));
        }
        break;
    }
  }

  blurField(field: string) {
    if (!this.paymentLinkSearch[field]) {
      return
    }
    this.paymentLinkSearch[field] = this.paymentLinkSearch[field].trim();
  }

  private bindNewRequest() {
    const request = new PaymentLinkSearchRequest();
    if (!this.selectedSearchs) {
      return;
    }
    for(const criteria of this.selectedSearchs ) {
      switch (criteria.name) {
        case 'amount':
          request.amountRef1 = this.amountRef1Ctrl.value;
          if (this.paymentLinkSearch.amountOpr == 'between') {
            request.amountRef2 = this.amountRef2Ctrl.value;
          }
          request.amountOpr = this.paymentLinkSearch.amountOpr;
          break;
        case 'linkType':
          request.type = [];
          criteria.linkTypes.map((type) => {
            if(type.value) {
              request.type.push(type.key);
            }
          })
          break;
        case 'createTime':
          const fromCreate = DateUtil.parseToDate(this.startCreateDate.value);
          const toCreate = DateUtil.parseToDate(this.endCreateDate.value);
          fromCreate.setHours(0, 0);
          toCreate.setHours(23, 59, 59, 999);
          request.createDateFrom = fromCreate;
          request.createDateTo = toCreate;
          request.createDateOpt = this.paymentLinkSearch.createDateOpt;
          break;
        case 'activeTime':
          const fromActive = DateUtil.parseToDate(this.startActiveDate.value);
          const toActive = DateUtil.parseToDate(this.endActiveDate.value);
          fromActive.setHours(0, 0);
          toActive.setHours(23, 59, 59, 999);
          request.activeTimeFrom = fromActive;
          request.activeTimeTo = toActive;
          request.activeDateOpt = this.paymentLinkSearch.activeDateOpt;
          break;
        case 'expiredTime':
          const fromExpired = DateUtil.parseToDate(this.startExpiredDate.value);
          const toExpired = DateUtil.parseToDate(this.endExpiredDate.value);
          fromExpired.setHours(0, 0);
          toExpired.setHours(23, 59, 59, 999);
          request.expireTimeFrom = fromExpired;
          request.expireTimeTo = toExpired;
          request.expireDateOpt = this.paymentLinkSearch.expireDateOpt;
          break;
        default:
          request[criteria.name] = this.paymentLinkSearch[criteria.name];
          break;
      }
    }

    return request;
  }

  private initData() {
    this.startActiveDateErrMess = null;
    this.activeDateErrorRange = null;
    this.isPaymentSearch = true;
    this.isShow = false;
    this.isShowAddButton = true;
    this.availableSearchList = PAYMENT_LINK_SEARCH;
    this.selectedSearchs = [];
    this.paymentLinkSearch = new PaymentLinkSearchRequest();
    this.paymentLinkSearch.createDateOpt ='today';
    this.paymentLinkSearch.activeDateOpt ='today';
    this.paymentLinkSearch.expireDateOpt ='today';
    this.initFormControl();

    // default filter on search
    this.selectedSearchs.push({
      id: 6,
      name: 'createTime',
      showName: 'Create Date/Time',
      linkTypes: PAYMENT_LINK_TYPES,
      dateOptionsList: PAYMENT_LINK_DATE_OPTIONS
    });
    this.idCreateDateTime = this.selectedSearchs[0].id;
    // if there is data in the search list
    if (this.selectedList.length > 0) {
      this.selectedSearchs = this.selectedList;
      this.paymentLinkSearch = this.request;
      this.startCreateDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.createDateFrom)), {emitEvent: false});
      this.endCreateDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.createDateTo)), {emitEvent: false});
      this.startActiveDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.activeTimeFrom)), {emitEvent: false});
      this.endActiveDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.activeTimeTo)), {emitEvent: false});
      this.startExpiredDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.expireTimeFrom)), {emitEvent: false});
      this.endExpiredDate.setValue(DateUtil.dateToNgbDateStructCustom(new Date(this.paymentLinkSearch.expireTimeTo)), {emitEvent: false});
      this.amountRef1Ctrl.setValue(this.paymentLinkSearch.amountRef1);
      this.amountRef2Ctrl.setValue(this.paymentLinkSearch.amountRef2);
    }

  }

  private initFormControl() {

    this.amountRef1Ctrl = new FormControl('', { validators: [Validators.pattern(new RegExp(PATTERN.NUMBER_WITH_DOT_PATTERN)), Validators.required], updateOn: 'blur' });
    this.amountRef2Ctrl = new FormControl('', { validators: [Validators.pattern(new RegExp(PATTERN.NUMBER_WITH_DOT_PATTERN)), Validators.required], updateOn: 'blur' });

    const currentDate = new Date();
    this.startCreateDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);
    this.endCreateDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);
    this.startActiveDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);
    this.endActiveDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);
    this.startExpiredDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);
    this.endExpiredDate = new FormControl(DateUtil.dateToNgbDateStructCustom(currentDate), [DateUtil.validatorDate]);

    this.startCreateDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.startCreateDateErrMess = DateUtil.validatorDate(this.startCreateDate) ? DateUtil.validatorDate(this.startCreateDate).message: null;
        if (this.startCreateDateErrMess) {
          this.createDateErrorRange = null;
          this.endCreateDateErrMess = this.validDateField(this.endCreateDate) ? null : this.endCreateDateErrMess;
          return
        }
        const from = DateUtil.parseToDate(value);
        if (this.validDateField(this.endCreateDate)) {
          const to = DateUtil.parseToDate(this.endCreateDate.value);
          if (from.getTime() > to.getTime()) {
            this.createDateErrorRange = null;
            this.endCreateDateErrMess = null;
            this.startCreateDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.FROM_AFTER_TO;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            this.endCreateDateErrMess = null;
            // when entered value is after 180 days Date From
            this.createDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Create Date/Time');
          } else {
            this.createDateErrorRange = null;
            this.endCreateDateErrMess = null;
          }
        }
    });

    this.endCreateDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.endCreateDateErrMess = DateUtil.validatorDate(this.endCreateDate) ? DateUtil.validatorDate(this.endCreateDate).message: null;
        if (this.endCreateDateErrMess) {
          this.createDateErrorRange = null;
          this.startCreateDateErrMess = this.validDateField(this.startCreateDate) ? null : this.startCreateDateErrMess;
          return
        }
        const to = DateUtil.parseToDate(value);
        if (this.validDateField(this.startCreateDate)) {
          const from = DateUtil.parseToDate(this.startCreateDate.value);
          if (from.getTime() > to.getTime()) {
            this.createDateErrorRange = null;
            this.startCreateDateErrMess = null;
            this.endCreateDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.TO_BEFORE_FROM;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            this.startCreateDateErrMess = null;
            // when entered value is after 180 days Date From
            this.createDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Create Date/Time');
          } else {
            this.createDateErrorRange = null;
            this.startCreateDateErrMess = null;
          }
        }
    });

    this.startActiveDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.startActiveDateErrMess = DateUtil.validatorDate(this.startActiveDate) ? DateUtil.validatorDate(this.startActiveDate).message: null;
        if (this.startActiveDateErrMess) {
          this.activeDateErrorRange = null;
          this.endActiveDateErrMess = this.validDateField(this.endActiveDate) ? null : this.endActiveDateErrMess;
          return
        }
        const from = DateUtil.parseToDate(value);
        if (this.validDateField(this.endActiveDate)) {
          const to = DateUtil.parseToDate(this.endActiveDate.value);
          if (from.getTime() > to.getTime()) {
            this.activeDateErrorRange = null;
            this.endActiveDateErrMess = null;
            this.startActiveDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.FROM_AFTER_TO;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            this.endActiveDateErrMess = null;
            // when entered value is after 180 days Date From
            this.activeDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Active Date/Time');
          } else {
            this.activeDateErrorRange = null;
            this.endActiveDateErrMess = null;
          }
        }
    });

    this.endActiveDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.endActiveDateErrMess = DateUtil.validatorDate(this.endActiveDate) ? DateUtil.validatorDate(this.endActiveDate).message: null;
        if (this.endActiveDateErrMess) {
          this.activeDateErrorRange = null;
          this.startActiveDateErrMess = this.validDateField(this.startActiveDate) ? null: this.startActiveDateErrMess;
          return
        }
        const to = DateUtil.parseToDate(value);
        if (this.validDateField(this.startActiveDate)) {
          const from = DateUtil.parseToDate(this.startActiveDate.value);
          if (from.getTime() > to.getTime()) {
            this.activeDateErrorRange = null;
            this.startActiveDateErrMess = null;
            this.endActiveDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.TO_BEFORE_FROM;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            // when entered value is after 180 days Date From
            this.startActiveDateErrMess = null;
            this.activeDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Active Date/Time');
          } else {
            this.startActiveDateErrMess = null;
            this.activeDateErrorRange = null;
          }
        }
    });

    this.startExpiredDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.startExpiredDateErrMess = DateUtil.validatorDate(this.startExpiredDate) ? DateUtil.validatorDate(this.startExpiredDate).message: null;
        if (this.startExpiredDateErrMess) {
          this.expiredDateErrorRange = null;
          this.endExpiredDateErrMess = this.validDateField(this.endExpiredDate) ? null : this.endExpiredDateErrMess;
          return
        }
        const from = DateUtil.parseToDate(value);
        if (this.validDateField(this.endExpiredDate)) {
          const to = DateUtil.parseToDate(this.endExpiredDate.value);
          if (from.getTime() > to.getTime()) {
            this.expiredDateErrorRange = null;
            this.endExpiredDateErrMess = null;
            this.startExpiredDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.FROM_AFTER_TO;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            this.endExpiredDateErrMess = null;
            // when entered value is after 180 days Date From
            this.expiredDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Expire Date/Time');
          } else{
            this.expiredDateErrorRange = null;
            this.endExpiredDateErrMess = null;
          }
        }
    });

    this.endExpiredDate.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
      ).subscribe((value) => {
        this.endExpiredDateErrMess = DateUtil.validatorDate(this.endExpiredDate) ? DateUtil.validatorDate(this.endExpiredDate).message: null;
        if (this.endExpiredDateErrMess) {
          this.expiredDateErrorRange = null;
          this.startExpiredDateErrMess = this.validDateField(this.startExpiredDate) ? null : this.startExpiredDateErrMess;
          return
        }
        const to = DateUtil.parseToDate(value);
        if (this.validDateField(this.startExpiredDate)) {
          const from = DateUtil.parseToDate(this.startExpiredDate.value);
          if (from.getTime() > to.getTime()) {
            this.expiredDateErrorRange = null;
            this.startExpiredDateErrMess = null;
            this.endExpiredDateErrMess = PAYMENT_LINK_DATE_ERROR_MESSAGES.TO_BEFORE_FROM;
          } else if (DateUtil.subtractDay(to, 179).getTime() > from.getTime()) {
            this.startExpiredDateErrMess = null;
            // when entered value is after 180 days Date From
            this.expiredDateErrorRange = PAYMENT_LINK_DATE_ERROR_MESSAGES.ERROR_DATE_RANGE.replace('${FIELD}', 'Expired Date/Time');
          } else {
            this.expiredDateErrorRange = null;
            this.startExpiredDateErrMess = null;
          }
        }
    });
  }

  private validDateField(field: FormControl) {
    return field && (!field.errors || (field.errors && !field.errors.invalid));
  }

  private setDateFormControl() {
    const startCreateDate = DateUtil.parseToDate(this.startCreateDate.value);
    const endCreateDate = DateUtil.parseToDate(this.endCreateDate.value);
    const startActiveDate = DateUtil.parseToDate(this.startActiveDate.value);
    const endActiveDate = DateUtil.parseToDate(this.endActiveDate.value);
    const startExpiredDate = DateUtil.parseToDate(this.startExpiredDate.value);
    const endExpiredDate = DateUtil.parseToDate(this.endExpiredDate.value);

    this.startCreateDate.setValue(startCreateDate ? DateUtil.dateToNgbDateStructCustom(startCreateDate) : null);
    this.endCreateDate.setValue(endCreateDate ? DateUtil.dateToNgbDateStructCustom(endCreateDate) : null);
    this.startActiveDate.setValue(startActiveDate ? DateUtil.dateToNgbDateStructCustom(startActiveDate) : null);
    this.endActiveDate.setValue(endActiveDate ? DateUtil.dateToNgbDateStructCustom(endActiveDate) : null);
    this.startExpiredDate.setValue(startExpiredDate ? DateUtil.dateToNgbDateStructCustom(startExpiredDate) : null);
    this.endExpiredDate.setValue(endExpiredDate ? DateUtil.dateToNgbDateStructCustom(endExpiredDate) : null);
  }

}
