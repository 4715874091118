import { ResultModel } from "./result.model";

export class TransactionLimit {
	limitAmountPerTransaction: number;
	limitAmountPerDay: number;
	limitAmountPerMonth: number;
	maximumTransactionPerDay: number;

	constructor(
		limitAmountPerTransaction?: number,
		limitAmountPerDay?: number,
		limitAmountPerMonth?: number,
		maximumTransactionPerDay?: number
	) {
		this.limitAmountPerTransaction = limitAmountPerTransaction;
		this.limitAmountPerDay = limitAmountPerDay;
		this.limitAmountPerMonth = limitAmountPerMonth;
		this.maximumTransactionPerDay = maximumTransactionPerDay;
	}

	isSetup() {
		return this.limitAmountPerTransaction > 0 || this.limitAmountPerDay > 0 || this.limitAmountPerMonth > 0 || this.maximumTransactionPerDay > 0;
	}
}

export class TransactionLimitResponse extends ResultModel {
	transactionLimit: TransactionLimit;
}