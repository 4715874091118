import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToasterService } from '../../services/toaster.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { Router } from '../../../../node_modules/@angular/router';
import { MenuInfoModel } from '../../services/models/menuinfo.model';
import { UserModel } from '../../services/models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  isLoggedIn = false;
  model: any = {};
  menuInfo: MenuInfoModel[];
  companyInfos: any = {};
  userInfo: any = {};

  constructor(
    public zone: NgZone,
    public router: Router,
    public auth: AuthenticationService,
    private toasterService: ToasterService,
    private cacheObservableService: CacheObservableService,
    private commonService: CommonService
    ) { }

  ngOnInit() {
  }

  onClickGo() {
    this.commonService.isLoading(true);
    this.login();
  }

  login() {
    if (!this.isLoggedIn) {
      console.log('login user: ' + this.model.email);
      this.auth.getToken(this.model.email, this.model.password).subscribe(
        result => {
          if (this.commonService.isSuccess(result) && result.token) {
            this.auth.getUserInfo(result.token).subscribe(
              profileResponse => {
                console.log('profileResponse.status_code=' + profileResponse.status_code);
                if (this.commonService.isSuccess(profileResponse)) {
                  sessionStorage.setItem(
                    'currentUser',
                    JSON.stringify({
                      token: result.token,
                      userInfo: profileResponse.userInfo,
                      menuInfo: profileResponse.menuInfo,
                      companyInfos: profileResponse.companyInfos
                    })
                    );
                    this.auth.setCurrentUserInfo(new UserModel(profileResponse.userInfo,profileResponse.menuInfo, profileResponse.companyInfos));
                  // clear
                  this.model.email = null;
                  this.model.password = null;
                  this.cacheObservableService.clear();
                } else {
                  this.toasterService.error(result.status_message);
                }
              },
              error => {
                this.commonService.isLoading(false);
                this.toasterService.error(error.error.message);
              },
              () => {
                this.toasterService.success('Login success.');
                this.updateHeader();

                console.log('router navigate to default or dashboard.');
                this.zone.run(() => this.navigateToDefault());
              });

          } else {
            console.log('login false.');
            console.log('message: ' + result.status_message);
            // this.errMessage = 'Email or password is incorrect';
            this.toasterService.error(result.status_message);
            this.commonService.isLoading(false);
          }
        },
        error => {
          console.log('Error occured.');
          this.toasterService.error('Error occured.');
          this.commonService.isLoading(false);
        },
        () => { });


    }

    //   this.toastr.success('success', 'Success!');
    //   this.toastr.error(result.status_message, 'Error!');
  }

  updateHeader() {
    this.auth.isAuthenticated();
    this.userInfo = this.auth.getCurrentUserInfo();
    this.companyInfos = this.auth.getCurrentUserCompanyInfos();
    this.menuInfo = this.auth.getCurrentUserMenuInfo();
    if (this.menuInfo && this.menuInfo.some(m => m.screenCode === 'C007')) {
      this.commonService.setVisibleLinkTransactionDetail(true);
    } else {
      this.commonService.setVisibleLinkTransactionDetail(false);
    }
  }

  navigateToDefault() {
    // use default from userRole, otherwise use dashboard
    // this.router.navigateByUrl('dashboard');
    let defaultUrl = '/';
    const menu = this.auth.getCurrentUserMenuInfo();
    if (menu) {
      const defaultMenu = menu.find(m => m.defaultScreen);
      if (defaultMenu) {
        defaultUrl = defaultMenu.url;
      } else {
        defaultUrl = 'dashboard';
      }
    }
    console.log('defaultUrl: ' + defaultUrl);
    this.router.navigateByUrl(defaultUrl);
  }
}
