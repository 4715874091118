import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPositiveNumber]'
})
export class PositiveNumberDirective {

  constructor(private element: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onkeydown(event: KeyboardEvent) {
    if (event.key == "-") [
      event.preventDefault()
    ]
  }

}
