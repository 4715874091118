import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../shared/app-constant';
import 'rxjs/add/observable/throw';

import { TokenModel } from '../models/token.model';
import { UserModel } from '../models/user.model';
import { UserInfoModel } from '../models/userinfo.model';
import { MenuInfoModel } from '../models/menuinfo.model';
import { CompanyInfoModel } from '../models/companyinfo.model';
import { ResultModel } from '../models/result.model';
import { CommonService } from '../common.service';

import { ResetPasswordModel } from './../models/reset-password.model';
import { ResetPasswordRequestModel } from './../models/reset-password.request.model';
import { Router } from '@angular/router';
import { SwitchProfileRequest } from '../models/switch-profile-request.model';

@Injectable()
export class AuthenticationService {
    // public token: string;

    public redirectUrl: string;

    private isAuthenticatedSource = new Subject<boolean>();
    private isSelectedCompanySource = new Subject<boolean>();
    isAuthenticated$ = this.isAuthenticatedSource.asObservable();
    isSelectedComapany$ = this.isSelectedCompanySource.asObservable();

    private currentUserSource = new BehaviorSubject<UserModel>(null);

    public currentUser = this.currentUserSource.asObservable();

    constructor(private http: HttpClient, private commonService: CommonService, private router: Router) {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        currentUser ? this.setCurrentUserInfo(new UserModel(currentUser.userInfo, currentUser.menuInfo, currentUser.companyInfos)) : false;
    }

    private setAuthentication(isAuthenticated: boolean) {
        this.isAuthenticatedSource.next(isAuthenticated);
    }

    private setSelectedCompany(isSelected: boolean) {
        this.isSelectedCompanySource.next(isSelected);
    }

    public isAuthenticated(): boolean {
        const currentUser = sessionStorage.getItem('currentUser');
        if (currentUser) {
            this.setAuthentication(true);
            return true;
        } else {
            this.setAuthentication(false);
            return false;
        }
    }

    public isSelectedCompany(): boolean {
        const isSelectCompany = JSON.parse(sessionStorage.getItem('currentUser')).isSelectedCompany;
        this.setSelectedCompany(isSelectCompany);
        return isSelectCompany;
    }

    public getCurrentUserInfo(): UserInfoModel {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser) {
            return currentUser && currentUser.userInfo;
        } else {
            return null;
        }
    }

    public getCurrentUserMenuInfo(): MenuInfoModel[] {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser) {
            return currentUser && currentUser.menuInfo;
        } else {
            return null;
        }
    }

    public getCurrentUserSwitchCompanyInfos(): CompanyInfoModel[] {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && currentUser.switchCompanyInfos) {
            return currentUser.switchCompanyInfos;
        } else {
            return null;
        }
    }

    public getCurrentUserActiveCompany(): number {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && currentUser.activeCompany) {
            return currentUser.activeCompany;
        } else {
            return null;
        }
    }

    public getCurrentUserCompanyInfos(): CompanyInfoModel[] {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && currentUser.companyInfos) {
            return currentUser.companyInfos;
        } else {
            return null;
        }
    }

    getToken(username: string, password: string): Observable<TokenModel> {
        console.log(environment.tokenApiUrl);
        return this.http.post(
            environment.tokenApiUrl,
            JSON.stringify({ username: username, password: password })).pipe(
                map((response: TokenModel) => {
                    return response;
                }));
    }

    getUserInfo(token: string): Observable<UserModel> {
        return this.http.get(
            environment.userInfoApiUrl,
            // { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token), }
        ).pipe(
            map((response: UserModel) => {
                return response;
            }));
    }

    getUserSwitchInfo(request: SwitchProfileRequest): Observable<UserModel> {
        return this.http.post(
            environment.userSwitchInfoApiUrl,
            JSON.stringify(request)
        ).pipe(
            map((response: UserModel) => {
                return response;
            }));
    }

    setCurrentUserInfo(userInfo: UserModel) {
        this.currentUserSource.next(userInfo);
    }

    renewCurrentUser(): void {
        const token = JSON.parse(sessionStorage.getItem('currentUser')).token;
        this.getUserInfo(token).subscribe((response) => {
            if (this.commonService.isSuccess(response)) {
                this.setCurrentUserInfo(new UserModel(response.userInfo, response.menuInfo, response.companyInfos))
                sessionStorage.setItem(
                    'currentUser',
                    JSON.stringify({
                      token: token,
                      userInfo: response.userInfo,
                      menuInfo: response.menuInfo,
                      companyInfos: response.companyInfos
                    })
                  );
            }
        });
    }

    /**
     * @see getCurrentMenuFromSession()  method for the reason why to save the current menu into the session.
     * @param currentMenu
     */
    public saveCurrentMenuIntoSession(currentMenu: MenuInfoModel[]) {
        sessionStorage.setItem('currentMenu', JSON.stringify(currentMenu));
    }

    /**
     * Due to the menus of a child menu is re-created
     * when the user press the refresh button of browser.
     * Therefore, have to get the current menu which is kept in the session.
     */
    public getCurrentMenuFromSession() {
        return JSON.parse(sessionStorage.getItem('currentMenu'));
    }

    clearUserInfoInStorage(): void {
        console.log('clearUserInfo.');
        sessionStorage.clear();
        // localStorage.clear(); // Note: 20180313 Not clear BrowserID
        this.isAuthenticated();
    }

    logout(): Observable<ResultModel> {
        console.log('logout.');
        this.clearUserInfoInStorage();
        this.router.navigate(['login']);
        return this.http.get<ResultModel>(
            environment.logoutApiUrl);
    }

    renewToken(): Observable<TokenModel> {
    console.log('call renewToken method');
    return this.http.get<TokenModel>(
        environment.renewTokenApiUrl).pipe(
        map((response: TokenModel) => {
            return response;
        }));
    }

    login(username: string, password: string): Observable<UserModel> {
        return this.getToken(username, password)
            .pipe(switchMap(
                sourceValue => {
                    console.log('source value ' + sourceValue);
                    if (sourceValue.token) {
                        const currentTime = new Date().getTime();
                        sessionStorage.setItem('expiredAt', sourceValue.expiredAt + '');
                        sessionStorage.setItem('lastApiCall', currentTime + '');
                        sessionStorage.setItem('lastTokenCheck', currentTime + '');
                        sessionStorage.setItem('currentUser',JSON.stringify({
                                token: sourceValue.token
                            })
                        );

                        return this.getUserInfo(sourceValue.token);
                    } else {
                        return Observable.throw({
                            error: {
                                code: sourceValue.status_code,
                                message: sourceValue.status_message,
                                properties: sourceValue.properties
                            }
                        });
                    }
                }
            ));
    }

    resetPassword(request: ResetPasswordRequestModel): Observable<ResetPasswordModel> {
      console.log('resetPassword.');
      return this.http.post(environment.resetPasswordApiUrl,
        JSON.stringify(request))
        .pipe(map((response: ResetPasswordModel) => { return response; }))
    }
}
