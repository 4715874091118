export class BulkRefundUploadFileRequest {
    file?: File;
    bulkRefundId?: string;
    fileType?: string;

    constructor(file?: File, bulkRefundId?: string,fileType?: string) {
        this.file = file;
        this.bulkRefundId = bulkRefundId;
        this.fileType = fileType;
    }
}