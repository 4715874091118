import { CountReportRequest } from "./count-report-request.model";

export class ExportReportRequest extends CountReportRequest {
    count: number;
    digipayReportId: string;

    constructor(from: Date, toDate: Date, reportConfigId: number, companyIds: number[], count: number, supplierNo?: string[], modelNo?: string[], productNo?: number[] , digipayReportId?: string) {
        super(from, toDate, reportConfigId, companyIds, supplierNo, modelNo, productNo);
        this.count = count;
        this.digipayReportId = digipayReportId;
    }
}
