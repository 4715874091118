import { TransactionStatus } from '../../shared/transaction-status.enum';
import { TransactionStatusState } from '../../shared/transaction-status-state.enum';

export class TransactionRequestStatusStateModel {
  private constructor(public statusId: TransactionStatus, public statusStateId: TransactionStatusState) { }

  /* Card */
  static readonly Authorized_Process = new TransactionRequestStatusStateModel(TransactionStatus.Authorized, TransactionStatusState.Process);
  static readonly Authorized_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Authorized, TransactionStatusState.Complete);
  static readonly Authorized_Wait_For_Authentication = new TransactionRequestStatusStateModel(TransactionStatus.Authorized, TransactionStatusState.Wait_For_Authentication);
  static readonly Authorized_Wait_For_Confirm = new TransactionRequestStatusStateModel(TransactionStatus.Authorized, TransactionStatusState.Wait_For_Confirm);
  static readonly Authorized_Fail = new TransactionRequestStatusStateModel(TransactionStatus.Authorized, TransactionStatusState.Fail);

  static readonly Settled_Process = new TransactionRequestStatusStateModel(TransactionStatus.Settled, TransactionStatusState.Process);
  static readonly Settled_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Settled, TransactionStatusState.Complete);
  static readonly Settled_Fail = new TransactionRequestStatusStateModel(TransactionStatus.Settled, TransactionStatusState.Fail);

  static readonly Voided_Process = new TransactionRequestStatusStateModel(TransactionStatus.Voided, TransactionStatusState.Process);
  static readonly Voided_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Voided, TransactionStatusState.Complete);
  static readonly Voided_Fail = new TransactionRequestStatusStateModel(TransactionStatus.Voided, TransactionStatusState.Fail);

  static readonly Refunded_Process = new TransactionRequestStatusStateModel(TransactionStatus.Refunded, TransactionStatusState.Process);
  static readonly Refunded_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Refunded, TransactionStatusState.Complete);
  static readonly Refunded_Fail = new TransactionRequestStatusStateModel(TransactionStatus.Refunded, TransactionStatusState.Fail);

  static readonly Reversed_Process = new TransactionRequestStatusStateModel(TransactionStatus.Reversed, TransactionStatusState.Process);
  static readonly Reversed_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Reversed, TransactionStatusState.Complete);
  static readonly Reversed_Fail = new TransactionRequestStatusStateModel(TransactionStatus.Reversed, TransactionStatusState.Fail);

  static readonly Declined_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Declined, TransactionStatusState.Complete);
  static readonly BillPayment_Declined = new TransactionRequestStatusStateModel(TransactionStatus.BillPayment_Declined, TransactionStatusState.Complete);

  /* Qr */
  static readonly Qr_Authorized_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Authorized, TransactionStatusState.Complete);
  static readonly Qr_Settled_Process = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Settled, TransactionStatusState.Process);
  static readonly Qr_Settled_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Settled, TransactionStatusState.Complete);
  static readonly Qr_Voided_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Voided, TransactionStatusState.Complete);
  static readonly Qr_Refunded_Process = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Refunded, TransactionStatusState.Process);
  static readonly Qr_Refunded_Complete = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Refunded, TransactionStatusState.Complete);
  
  /* Captured */
  static readonly Captured_Settled_Process = new TransactionRequestStatusStateModel(TransactionStatus.Settled, TransactionStatusState.Process);
  static readonly Captured_Qr_Settled_Process = new TransactionRequestStatusStateModel(TransactionStatus.Qr_Settled, TransactionStatusState.Process);
}
