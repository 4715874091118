import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, RouterEvent } from '@angular/router';

import { MenuInfoModel } from '../../services/models/menuinfo.model';
import { environment } from '../../shared/app-constant';
import { filter } from 'rxjs/operators';
import { pairwise } from 'rxjs-compat/operator/pairwise';

export const SCREEN = {
  DASHBOARD: 'DASHBOARD',
  TRANSACTIONS: 'TRANSACTIONS',
  QR_SIMULATOR: 'QR SIMULATOR',
  CUSTOMER: 'CUSTOMER',
  MULTIPLE_SELECTION: 'MULTIPLE SELECTION',
  BULK_REFUND: 'BULK REFUND',
  BULK_PAYMENT: 'BULK PAYMENT'
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  isLoggedIn = false;
  isSelectCompany = false;
  menuInfos: any = [];
  private currentUrl: string; // set when invoke this.getMenu()
  private previousUrl: string; // set when invoke this.getMenu()

  iconStacks = {
    TRANSACTIONS: 'list',
    CUSTOMER: 'person',
    DASHBOARD: 'clipboard',
    'REQUEST GOLIVE': 'globe',
    'USER SETTING': 'options',
    'KEY SETTING': 'key',
    'PAYMENT SETTING':'wallet',
    'COMPANY INFO SETTING': 'flask',
    'QR SIMULATOR': 'qr-scanner',
    'REPORT': 'list-box',
    'THAI QR INQUIRY': 'qr-code',
    'PAYMENT LINK': 'link',
    'RECURRING PAYMENT': 'recurring',
    'MULTIPLE SELECTION': 'multiple-selection',
    'BULK REFUND': 'bulk-refund',
    'BULK PAYMENT': 'bulk-payment'
  };

  constructor(public auth: AuthenticationService,
    private router: Router) {
    // this.subscribeAuthenticated();
  }

  ngOnInit() {
    this.router.events
    .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
    });
    console.log('Menu : (ngOnInit)... ');

    this.updateMenuAfterNavigationEnd();

    this.isLoggedIn = this.auth.isAuthenticated();
    if (this.isLoggedIn) {
      this.isSelectCompany = this.auth.isSelectedCompany();
    }

    this.subscribeAuthenticated();
    this.subscribeSelectedCompany();
  }

  private updateMenuAfterNavigationEnd() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((route: ActivatedRoute) => {
      this.currentUrl = route.url.toString();
      this.updateMenuList();
      console.log('Menu : (updateMenuAfterNavigationEnd)... ');
    });
  }

  private subscribeAuthenticated() {
    this.auth.isAuthenticated$.subscribe(data => {
      this.isLoggedIn = data;
    });
  }

  private subscribeSelectedCompany() {
    this.auth.isSelectedComapany$.subscribe(data => {
      this.isSelectCompany = data;
    });
  }

  private updateMenuList() {
    if (this.isLoggedIn && this.isSelectCompany) {
      this.menuInfos = this.getMenu();
      this.menuInfos = this.filterMenu(this.menuInfos);
      // Sort position by priority: Dashboard -> Transaction -> QR Simulator -> Customer -> Multiple Selection -> Bulk Payment -> Bulk Refund
      var multipleSelectionMenu = this.menuInfos.find(menuInfo => menuInfo.screenName === SCREEN.MULTIPLE_SELECTION);
      var bulkPaymentMenu = this.menuInfos.find(menuInfo => menuInfo.screenName === SCREEN.BULK_PAYMENT);
      var bulkRefundMenu = this.menuInfos.find(menuInfo => menuInfo.screenName === SCREEN.BULK_REFUND);
      var count = 0;
      if (multipleSelectionMenu || bulkPaymentMenu || bulkRefundMenu) {
        this.menuInfos.forEach(menuInfo => {
          if (menuInfo.screenName === SCREEN.DASHBOARD || menuInfo.screenName === SCREEN.TRANSACTIONS || menuInfo.screenName === SCREEN.CUSTOMER || menuInfo.screenName === SCREEN.QR_SIMULATOR) {
            count++
          }
        });
        if (multipleSelectionMenu) {
          const indexOfMuntipleMenu = this.menuInfos.indexOf(multipleSelectionMenu);
          this.menuInfos.splice(indexOfMuntipleMenu, 1);
          this.menuInfos.splice(count, 0, multipleSelectionMenu);
          count++
        }
        if (bulkPaymentMenu) {
          const indexOfBulkPayment = this.menuInfos.indexOf(bulkPaymentMenu);
          this.menuInfos.splice(indexOfBulkPayment, 1);
          this.menuInfos.splice(count, 0, bulkPaymentMenu);
          count++;
        }
        if (bulkRefundMenu) {
          const indexOfBulkRefund = this.menuInfos.indexOf(bulkRefundMenu);
          this.menuInfos.splice(indexOfBulkRefund, 1);
          this.menuInfos.splice(count, 0, bulkRefundMenu);
        }
      }
      this.resolveIcon(this.menuInfos);
    }
  }

  private getMenu(): MenuInfoModel[] {

    let menu = this.auth.getCurrentUserMenuInfo();

    if (menu) {

      const currentMenu = menu.find(m => this.currentUrl && this.currentUrl.startsWith('/' + m.url));
      if (currentMenu) {
        if (currentMenu.screenCode.startsWith(environment.mainMenuStartWith)) {
          const currentScreenGroup = currentMenu.screenGroup;
          menu = menu.filter(m => m.screenCode.startsWith(environment.mainMenuStartWith))
            .filter(m => m.screenGroup === currentScreenGroup);
          this.auth.saveCurrentMenuIntoSession(menu);
        } else { // in the case of child menu
          // console.log('Url: ' + this.currentUrl + ' is in the child menu: ' +currentMenu.screenCode);
          menu = this.auth.getCurrentMenuFromSession();
        }
      } else {
        // console.log('Url: ' + this.currentUrl + ' not found in current user menu list.');
        // use the current menu from session instead.
        menu = this.auth.getCurrentMenuFromSession();
      }
    }
    return menu;
  }

  filterMenu(mn: MenuInfoModel[]) {
    if (mn) {
      return mn.filter(menuInfo => !menuInfo.screenName.match(/(VOIDED|SETTLED|REFUNDED)/));
    } else {
      return mn;
    }
  }


  resolveIcon(mn: MenuInfoModel[]) {
    if (mn) {
      mn.forEach(menuInfo => {
        menuInfo.screenName = menuInfo.screenName.toUpperCase();
        const icn = this.iconStacks[menuInfo.screenName];
        if (icn) {
          menuInfo.iconPath = icn;
        } else {
          menuInfo.iconPath = 'arrow-swap';
        }
      });
    }

  }

  isStillInPage(menu: string) {

    if (this.router.url.startsWith('/transactiondetail') && this.previousUrl === '/transaction' && menu === 'transaction') {
      return true;
    }

    if (this.router.url.startsWith('/customer-card') && menu === 'customer') {
      return true;
    }

    if (this.router.url.startsWith('/transactiondetail') && this.previousUrl.includes('/multiple-selection/multiple-refund-result') && menu === 'multiple-selection') {
      return true;
    }

    return false;
  }
}

