import { ResultModel } from "./result.model";

// TODO: remove when revert api done
export class IpanModeInfo {
    ipanMode: string;
    desc: string;
    termList: string[];
    midList: string[];
}

export class TermInfo {
    term: string;
    mid: string[]
}

// TODO: remove when revert api done
export class IpanModeInfoResponse extends ResultModel {
    installmentTypes: IpanModeInfo[]
}

// TODO: remove when revert api done
export class GetIpanModeRequest {
    ipanMode: string;
    term: string;
    mid: string;
}


export class InstallmentType {
    ipanMode: string;
    desc: string;
    termObj: TermInfo[];
    requireTerm: boolean;
}

export class InstallmentTypesResponse extends ResultModel {
    installmentTypes: InstallmentType[]
}
