import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { MenuInfoModel } from '../../services/models/menuinfo.model';
import { CompanyInfoModel } from '../../services/models/companyinfo.model';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { environment, SCREEN_GROUP } from '../../shared/app-constant';


import * as screenfull from 'screenfull';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faDownload, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SwitchProfileRequest } from '../../services/models/switch-profile-request.model';
import { UserModel } from '../../services/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  // imgSetting = require('../../../assets/img/ic_setting.svg');
  // imgLogout = require('../../../assets/img/ic_logout.svg');

  model: any = {};
  profiles: CompanyInfoModel[];
  switchProfileRequest: SwitchProfileRequest;
  errMessage: String = '';

  menuInfo: MenuInfoModel[];
  companyInfos: CompanyInfoModel[];
  userInfo: any = {};

  isLoggedIn = false;
  isSelectCompany = false;

  shopName = 'MYNTRA';
  currentLang: string;
  listAllLang: string[];
  showBtnLang: string;

  isShowRegisterBtn: boolean;
  // isShowBtnAdvanceSearch = false;
  isHome = true;
  envLabel = environment.envName === 'prod' ? 'Live' : 'Sandbox' + ' ' + environment.version;

  faDownload = faDownload;
  faSyncAlt = faSyncAlt;
  isOpenDialog: boolean;

  constructor(
    public auth: AuthenticationService,
    public zone: NgZone,
    public router: Router,
    private commonService: CommonService,
    private toasterService: ToasterService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.auth.isAuthenticated();
    this.profiles = [];

    // this.updateHeader();
    this.currentLang = this.getCurrentLang();
    this.listAllLang = this.commonService.getLangs();
    // this.isShowRegisterBtn = this.commonService.isEnvironmentSandBox();
    this.subscribeAuthenticated();
    this.subscribeSelectedCompany();
    this.subscribeIsShowBtnAdvanceSearch();
    this.commonService.handleExportDialog$.subscribe((data => {
      this.isOpenDialog = data;
    }));

    // reset icon when navigation
    this.router.events.subscribe((event) => {
      const menuInfos = this.auth.getCurrentUserMenuInfo();
      if (event instanceof NavigationStart && menuInfos) {
        for (var i = 0; i < menuInfos.length; i++) {
          if (menuInfos[i].url == event.url.replace("/", "")) {
            this.isHome = !(menuInfos[i].screenGroup === SCREEN_GROUP.MERTCHANT_SETTING_MENU);
          }
        }
      }
    });
    //init switch profile
    this.profiles = this.auth.getCurrentUserSwitchCompanyInfos();
  }

  private navigateAfterChoooseProfile(){
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser.isSelectedCompany = true;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.auth.isSelectedCompany();
    let defaultUrl = '/';
    const menu = this.auth.getCurrentUserMenuInfo();
    if (menu) {
      const defaultMenu = menu.find(m => m.defaultScreen);
      if (defaultMenu) {
        defaultUrl = defaultMenu.url;
      } else {
        defaultUrl = 'dashboard';
      }
    }
    console.log('default url is: ' + defaultUrl);
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([defaultUrl]);
    });
  }

  private subscribeIsShowBtnAdvanceSearch() {
    this.commonService.visibleBtnAdvanceSearch$.subscribe(isVisible => {
      // this.isShowBtnAdvanceSearch = isVisible;
    });
  }

  private subscribeAuthenticated() {
    this.auth.isAuthenticated$.subscribe(data => {
      this.isLoggedIn = data;
      this.updateHeader();
      if (this.isLoggedIn === false) {
        this.isHome = true;
      }
    });
  }

  private subscribeSelectedCompany() {
    this.auth.isSelectedComapany$.subscribe(data => {
      this.isSelectCompany = data;
      this.profiles = this.auth.getCurrentUserSwitchCompanyInfos();
      if (this.isSelectCompany) {
        this.profiles = this.profiles.filter(e => e.companyId !== this.companyInfos[0].companyId)
      }
    });
  }

  onClickLogout() {
    this.commonService.isLoading(true);
    this.auth.logout().subscribe(
      result => {
        this.toasterService.success('Logout success.');
      }, error => {
        this.commonService.isLoading(false);
      }, () => {
        this.commonService.isLoading(false);
      }
    );
    this.model.username = null;
    this.model.password = null;
    // this.navigateToHomePage();
  }

  swapMenu(screenGroup) {
    // this.router.navigateByUrl('merchant-setting-user');
    let firstMenuUrl = 'nopermission';
    const menu = this.auth.getCurrentUserMenuInfo();
    if (menu) {
      // MenuInfoModel
      const firstMenu = menu.find((m => m.screenCode.startsWith(environment.mainMenuStartWith))
        && (m => m.screenGroup === screenGroup));
      if (firstMenu) {
        firstMenuUrl = firstMenu.url;
      }
    }
    this.router.navigateByUrl(firstMenuUrl);
  }

  onClickSettingMenu() {
    this.swapMenu('MERCHANT_SETTING_MENU');
    this.isHome = false;
  }
  toggleScreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
  onClickHomeMenu() {
    this.swapMenu('MERCHANT_MENU');
    this.isHome = true;
  }

  updateHeader() {
    this.userInfo = this.auth.getCurrentUserInfo();
    this.auth.currentUser.subscribe((currentUser) => {
      this.companyInfos = currentUser ? currentUser.companyInfos : null;
    });
    this.menuInfo = this.auth.getCurrentUserMenuInfo();

    if (this.menuInfo && this.menuInfo.some(m => m.screenCode === 'C007')) {
      this.commonService.setVisibleLinkTransactionDetail(true);
    } else {
      this.commonService.setVisibleLinkTransactionDetail(false);
    }
  }

  navigateToHomePage() {
    this.router.navigateByUrl('/login');
  }

  navigateToDefault() {
    // use default from userRole, otherwise use dashboard
    // this.router.navigateByUrl('dashboard');
    let defaultUrl = '/';
    const menu = this.auth.getCurrentUserMenuInfo();
    if (menu) {
      const defaultMenu = menu.find(m => m.defaultScreen);
      if (defaultMenu) {
        defaultUrl = defaultMenu.url;
      } else {
        defaultUrl = 'dashboard';
      }
    }
    console.log('defaultUrl: ' + defaultUrl);
    this.router.navigateByUrl(defaultUrl);
  }

  showSearch() {
    this.commonService.visibleSearch(true);
  }

  onClickSwitchLang() {
    this.currentLang = this.commonService.switchLanguage();
  }

  openExportDialog() {
    // this.commonService.isLoading(true);
    this.isOpenDialog = true;
  }

  public onClickProfile(companyId: number){
    this.commonService.isLoading(true);
    const currentActiveCompany = this.auth.getCurrentUserActiveCompany();
    const user = this.auth.getCurrentUserInfo();
    if(currentActiveCompany === companyId){
      this.navigateAfterChoooseProfile()
    } else {
      this.switchProfileRequest = new SwitchProfileRequest();
      this.switchProfileRequest.companyId = companyId;
      this.switchProfileRequest.userId = user.userId;
      this.auth.getUserSwitchInfo(this.switchProfileRequest).subscribe(
        response => {
          if(this.commonService.isSuccess(response)){
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            currentUser.menuInfo = response.menuInfo;
            currentUser.companyInfos = [];
            currentUser.companyInfos = response.companyInfos;
            currentUser.activeCompany = response.activeCompany;
            currentUser.token = response.token;
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.auth.setCurrentUserInfo(new UserModel(currentUser.userInfo, currentUser.menuInfo, currentUser.companyInfos, currentUser.switchCompanyInfos, currentUser.activeCompany, currentUser.token));
          }
        }, error => {
          this.commonService.isLoading(false);
          this.toasterService.error('Please contact administrator.', 'Server API Error');
        }, () => {
          this.commonService.isLoading(false);
            this.navigateAfterChoooseProfile()
        }
      );
    }
  }


  private getCurrentLang(): string {
    return this.commonService.getCurrentLang();
  }

}
