export class SummaryMonthlyItem {
  companyId: number;
  amountQuota: number;
  monthAmountQuota: number;
  transactionQuota: number;
  monthTransactionQuota: number;
  limitAmountPerTxn?: number;
  limitAmountPerDay?: number;
  limitAmountPerMonth?: number;
  limitTxnPerday?: number;

  constructor(item?: any) {
    if (item) {
      this.companyId = item.companyId;
      this.amountQuota = item.amountQuota;
      this.monthAmountQuota = item.monthAmountQuota;
      this.transactionQuota = item.transactionQuota;
      this.monthTransactionQuota = item.monthTransactionQuota;
      this.limitAmountPerTxn = item.limitAmountPerTxn;
      this.limitAmountPerDay = item.limitAmountPerDay;
      this.limitAmountPerMonth = item.limitAmountPerMonth;
      this.limitTxnPerday = item.limitTxnPerday;
    }
  }

  isSetup() {
    return this.limitAmountPerTxn > 0 || this.limitAmountPerDay > 0 || this.limitAmountPerMonth > 0 || this.limitTxnPerday > 0;
  }
}