import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../shared/app-constant";
import { PaymentLink } from "./models/payment-link";
import { map } from "rxjs/operators";
import { ResultModel } from "./models/result.model";
import { Observable } from "rxjs";
import { PaymentLinkSearchRequest } from "./models/payment-link-search-request.model";
import { CreatePaymentLinkResponse } from "./models/create-payment-link-response.model";
import { ConfigCreatePaymentLinkRequest } from "./models/config-create-payment-link-request.model";
import { GetIpanModeRequest, InstallmentTypesResponse, IpanModeInfoResponse } from "./models/installment-type.model";
import { GetSmartpayInfoRequest, SmartpayInfoResponse } from "./models/smartpay-info.model";
import { PaymentLinkCloneResponse } from "./models/payment-link-clone.model";

@Injectable()
export class PaymentLinkService {

    constructor(
        private http: HttpClient
    ) { }

    getPaymentLinks(request: PaymentLinkSearchRequest) {
        return this.http.post(environment.searchPaymentLink, request).pipe(map((response) => response));
    }

    get(linkRef: string) {
        return this.http.get(environment.getPaymentLink + linkRef).pipe(map((response) => response));
    }
    
    clone(linkRef: string): Observable<PaymentLinkCloneResponse> {
        return this.http.get(environment.clonePaymentLink + linkRef).pipe(map((response: PaymentLinkCloneResponse) => response));
    }

    create(paymentLink: PaymentLink) {
        return this.http.post(environment.createPaymentLink, paymentLink).pipe(map((response) => response));
    }

    disable(paymentLink: PaymentLink) {
        return this.http.post(environment.disablePaymentLink, {linkRef: paymentLink.ref}).pipe(map((response) => response));
    }

    getCurrency() {
        return this.http.get(environment.getCurrency).pipe(map((response) => response));
    }

    getMasterMerchantIndicator() {
        return this.http.get(environment.getMasterMerchantIndicator).pipe(map((response) => response));
    }

    checkOrderReference(orderRef: string):Observable<ResultModel> {
        return this.http.get(environment.checkOrderReference + orderRef).pipe(map((response: ResultModel) => {
            return response
        }));
    }

    getMerchantId(currencyCode: string):Observable<ResultModel> {
        return this.http.get(environment.getMerchantId + currencyCode).pipe(map((response: ResultModel) => {
            return response
        }));
    }

    getMaxDateAndAmount():Observable<ResultModel> {
        return this.http.get(environment.getMaxDateAndAmount).pipe(map((response: ResultModel) => {
            return response
        }));
    }

    getConfigCreatePaymentLink(request: ConfigCreatePaymentLinkRequest): Observable<CreatePaymentLinkResponse> {
        return this.http.post(environment.getCurrency, request).pipe(map((response: CreatePaymentLinkResponse) => {
            return response
        }));
    }

    getInstallmentType(): Observable<InstallmentTypesResponse>{
        return this.http.get(environment.getInstallmentType).pipe(map((response: InstallmentTypesResponse) => {
            return response;
        }));
    }

    getListSmartpayInfo(request: GetSmartpayInfoRequest): Observable<SmartpayInfoResponse>{
        return this.http.post(environment.getSmartpayInfo, request).pipe(map((response: SmartpayInfoResponse) => {
            return response;
        }));
    }

}
