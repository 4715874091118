import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { CommonService } from '../../services/common.service';
import { CustomerService } from '../../services/customer.service';

import { CardDetailModel } from '../../services/models/card-detail.model';
import { TransactionRequestModel } from './../../services/models/transaction.request.model';
import { CustomerInfoModel } from './../../services/models/customer-info.model';
import { GetCustomerInfoRequestModel } from '../../services/models/get-customer-info.request.model';
import { GetCardsRequestModel } from '../../services/models/get-cards.request.model';

import { TRANSACTION_STATUS, CACHE_OBSERVABLE_SERVICE } from '../../shared/app-constant';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.css']
})
export class CustomerCardComponent implements OnInit {
  customerObjectId: string;
  customerInfo: CustomerInfoModel;
  cardDetails: CardDetailModel[] = [];

  isShowBtnTransaction = false;

  isClickGoBack = false;

  constructor(private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private commonService: CommonService,
    private customerService: CustomerService,
    private cacheObservableService: CacheObservableService) { }

  ngOnInit() {
    console.log('CustomerCardComponent ngOnInit');
    this.commonService.isLoading(true);
    this.customerObjectId = this.route.snapshot.paramMap.get('customerObjectId');
    this.isShowBtnTransaction = this.isBtnTransactionAllowed();

    // get info and cards
    this.getCustomerInfo();
    this.getCards();
  }

  ngOnDestroy() {
    console.log('CustomerCardComponent ngOnDestroy');
    if (!this.isClickGoBack && this.cacheObservableService.has('customer_card_goback')) {
      this.cacheObservableService.remove('customer_card_goback');
    }
  }

  showTransactions(cardId: string) {
    console.log('showTransactions - cardId: ' + cardId);
    const request = this.createRequestTransactionByCardId(cardId);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.TRANSACTION_DETAIL_GO_BACK, request);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA, []);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SELECTED_EXPORT_CRITERA_VALUE, {});
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.FILTER_TRANSACTION_STATUS, TRANSACTION_STATUS.ALL);
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.SUB_FILTER_REFUNDED_STATUS, TRANSACTION_STATUS.ALL);
    this.router.navigateByUrl('transaction');
  }

  private isBtnTransactionAllowed(): boolean {
    let result = false;
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      menuInfos.forEach(menuInfo => {
        if (menuInfo.screenCode === 'M002') {
          const componentsInfos = menuInfo.componentsInfo;
          if (componentsInfos) {
            result = componentsInfos.some(componentsInfo => componentsInfo.roleActionCode === 'ROLE_B_2001');
          }
        }
      });
    }
    return result;
  }

  private createRequestTransactionByCardId(cardId: string): TransactionRequestModel {
    const request = new TransactionRequestModel();
    const dateRef1 = new Date();
    const dateRef2 = new Date();
    dateRef1.setHours(0,0,0,0);
    dateRef2.setHours(23,59,59,0);
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });
    request.cardId = cardId; // Assign cardId as a criteria

    request.trxState = [];
    request.pageIndex = 1;
    request.pageSize = 15;
    request.searchDate = [];
    request.searchDate.push({
      searchTypeDate : 'created',
      dateRef1 : dateRef1,
      dateRef2 : dateRef2
    });

    request.trxStatusState = [];

    return request;
  }

  private getCustomerInfo() {
    const request = new GetCustomerInfoRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });
    request.customerObjectId = this.customerObjectId;

    this.customerService.getCustomerInfo(request).subscribe(result => {
      if (this.commonService.isSuccess(result)) {
        this.customerInfo = result;
      } else {
        this.commonService.alertError('Fail', result.status_message, null);
      }
      this.commonService.isLoading(false);
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private getCards() {
    const request = new GetCardsRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });
    request.customerObjectId = this.customerObjectId;

    this.customerService.getCards(request).subscribe(result => {
      if (this.commonService.isSuccess(result)) {
        this.cardDetails = result.cardDetails;
      } else {
        this.commonService.alertError('Fail', result.status_message, null);
      }
      this.commonService.isLoading(false);
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }


  goBack() {
    console.log('TransactionDetailComponent goBack');
    this.isClickGoBack = true;
    this.location.back();
  }
}
