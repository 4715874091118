import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {

  constructor(private _auth: AuthenticationService, private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAnonymous();
  }

  isAnonymous() {
    return !this._auth.isAuthenticated();
  }

  checkAnonymous(url?: string): boolean {
    console.log('checkAnonymous');
    if (!this._auth.isAuthenticated()) { return true; }
    const menu = this._auth.getCurrentUserMenuInfo();
    if (menu) {
      // Navigate to the defalut page with extras
      const defaultMenu = menu.find(m => m.defaultScreen);
      if (defaultMenu) {
        this._router.navigateByUrl(defaultMenu.url);
        return;
      }
      // Navigate to the dashboard with extras
      if (menu.some(x => x.url === 'dashboard')) {
        this._router.navigate(['/dashboard']);
      }
    }
    return false;
  }
}
