import { Subject } from 'rxjs';

export class ModalModel {
    modalType: string;
    msgTitle: string;
    // msgBody: string;
    content: any;
    labelButton?: string;
    callback: Subject<any>; // Subject<boolean>
}
