import { Injectable } from '@angular/core';
import { TransactionRequestModel } from './models/transaction.request.model';
import { TransactionService } from './transaction.service';

@Injectable()
export class ReportService {

  constructor(private transactionservice: TransactionService) { }

  downloadFile(fileName: string, request: TransactionRequestModel) {
    this.transactionservice.getTransactionReport(request).subscribe(
      result => {
        const fileType = 'csv';

        const myBlob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), result], { type: 'text/csv; charset=UTF-8' });
        const blobURL = window.URL.createObjectURL(myBlob);
        const anchor = document.createElement('a');
        anchor.download = fileName + '.' + fileType;
        anchor.href = blobURL;
        anchor.click();
      },
      error => { },
      () => { });
  }


}
