import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { PaymentLink } from '../../services/models/payment-link';
import { DATE_PATTERN, PAYMENT_LINK_ERROR_MESSAGE, message, API_ERROR, STATUS } from '../../shared/app-constant';
import { PAYMENT_LINK_TYPE, CACHE_OBSERVABLE_SERVICE } from '../../shared/app-constant';
import { PaymentLinkService } from '../../services/payment-link.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { DateUtil } from '../../shared/date.util';
import { Router, ActivatedRoute } from '@angular/router';
import { ResultModel } from '../../services/models/result.model';
import { CacheObservableService } from '../../services/cache-observable.service';
import { ToasterService } from '../../services/toaster.service';
import { SelectionTime } from '../../services/models/selection-time.model';
import { TimeValidator } from '../../shared/common.util';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ReferenceField } from '../../services/models/reference-field.model';
import { CreatePaymentLinkResponse, SofPaymentLinkInfo } from '../../services/models/create-payment-link-response.model';
import { ConfigCreatePaymentLinkRequest } from '../../services/models/config-create-payment-link-request.model';
import { InstallmentType, IpanModeInfo, TermInfo } from '../../services/models/installment-type.model';
import { CardSmartpay } from '../../services/models/card-smartpay.model';
import { Payplus } from '../../services/models/payplus.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { StringUtil } from '../../shared/string.util';
import { GetSmartpayInfoRequest, ModelNoObj, ProductTypeInfo, SmartpayInfo } from '../../services/models/smartpay-info.model';
import { PaymentLinkClone } from '../../services/models/payment-link-clone.model';
import { PaymentLinkMerchant } from '../../services/models/payment-link';

export const AMOUNT_PATTERN = /^(\d{1,7})(\.\d{1,2}){0,1}?$/g;
export const NUMBER_PATTERN = /^[0-9]*$/;
export const REF_NUMBER_PATTERN = /^[a-zA-Z0-9]*$/;
export const NOT_ALLOW_REF_NAME_PATTERN = /[$#!^\\|“']/;
export const THAI_CHARACTER = /[\u0E00-\u0E7F]/;
export const MAX_POSSIBLE_AMOUNT = 9999999.99;
export const DEFAULT_TIME = {
  START_HOUR: '00',
  START_MINUTE: '00',
  END_HOUR: '23',
  END_MINUTE: '59'
}
export const REFERENCE_FIELDS = ['-', 1, 2, 3]; // Use for dropdown to select reference field
export const DATA_TYPES = {
  ALPHANUMERIC: 'ALPHANUMERIC',
  NUMERIC: 'NUMERIC'
};
export const DEFAULT_NO_OF_REFNO_ON_SCREEN = 3;
export const PLACEHOLDER_DEFAULT = "Alphanumeric : Maximum 20 characters";
export const SOURCE_OF_FUND = {
  CARD_FULL: 'card_full',
  CARD_SMARTPAY: 'card_smartpay',
  THAI_QR: 'thai_qr',
  PAYPLUS_KPLUS: 'payplus_kplus',
  PAYPLUS_PROMPTPAY: 'payplus_promptpay',
  PAYPLUS_APPSWITCH: 'payplus_appswitch'
}
export const IPAN_MODE_03 = '03';
export const DEFAULT_TERM ='All';
export const SOF_STRING = 'sof';

@Component({
  selector: 'app-payment-create-link',
  templateUrl: './payment-create-link.component.html',
  styleUrls: ['./payment-create-link.component.css']
})
export class PaymentCreateLinkComponent implements OnInit, OnDestroy {

  @ViewChild('active') activeDatepicker: NgbInputDatepicker;
  @ViewChild('expire') expireDatepicker: NgbInputDatepicker;
  @ViewChild('inputActiveTime') inputActiveTime: ElementRef;
  @ViewChild('inputExpireTime') inputExpireTime: ElementRef;

  public paymentLinkType: string;
  public currencies: any;
  public paymentLink: PaymentLink;
  public expiredDate: Date;
  public formGroup: FormGroup;

  public today: Date = new Date();

  public minActiveDate: any;
  public maxActiveDate: any;

  public minExpireDate: any;
  public maxExpireDate: any;

  public activeDateFC: FormControl;
  public expireDateFC: FormControl;

  //Error handler
  public emptyProductNameError: string;
  public orderRefError: string;
  public invalidAmountError: string;
  public invalidDateError: string;
  public invalidMinDateError: string;
  public minExpireTimeError: string;
  public activeDateRangeError: string;
  public expireDateRangeError: string;
  public emptyActiveDateError: string;
  public emptyExpireDateError: string;
  public emptyAmountError: string;
  public invalidActiceHour: string;
  public invalidRefNamePattern = PAYMENT_LINK_ERROR_MESSAGE.invalidRefNamePattern;
  public invalidRefValuePattern = PAYMENT_LINK_ERROR_MESSAGE.invalidNumbericPattern;
  public invalidRefMinMax: string;

  //Merchant Id
  public merchantIds: PaymentLinkMerchant[];

  public isOpenActive: boolean;
  public isOpenExpire: boolean;
  public firstTimeActive: boolean;
  public firstTimeExpire: boolean;

  public activeTime: SelectionTime = new SelectionTime();
  public expireTime: SelectionTime = new SelectionTime();

  public loadPage: boolean;

  public referenceOption = REFERENCE_FIELDS;
  public referenceField: any;

  public dataTypes = DATA_TYPES;

  public referenceFields: ReferenceField[];

  public listSOF: SofPaymentLinkInfo[];
  public sofDropdownConfig: IDropdownSettings;
  public sofModel: SofPaymentLinkInfo[];
  public preSofModel: SofPaymentLinkInfo[];
  public payplusPriority: string;
  public changeReferenceName: boolean;
  public thaiQrMid: string;
  public paymentType: IpanModeInfo;
  public configPaymentLinkRequest: ConfigCreatePaymentLinkRequest;
  public sofKey = SOURCE_OF_FUND;
  public installmentTypes: InstallmentType[];
  public installmentTerms: TermInfo[];
  public smartpayInfos: SmartpayInfo[];
  public supplierNo: string;
  public productTypeInfo: ProductTypeInfo;
  public productTypeClone: any;
  public productTypes: ProductTypeInfo[];
  public modelNoList: ModelNoObj[];
  public ipanMode: string;
  public cardSmartpayMid: string;
  public installmentTerm: string;
  public modelNo: string;
  public cardSmartpayMids: string[];
  public smartpayInfoResquest: GetSmartpayInfoRequest;
  public smartpayInfo: SmartpayInfo[];
  public createPaymentLinkResponse: CreatePaymentLinkResponse;
  public payplusKplusList: string[];
  public payplusKplus: string;
  public payplusPromptpayList: string[]
  public payplusPromptpay: string;
  public payplusAppSwitchList: string[]
  public payplusAppSwitch: string;
  public dropDownSelect: boolean;
  public midCardFull: string;
  public dataType = DATA_TYPES;
  public isClone: boolean;
  public requiredTerm: boolean;

  private maxAmount: number;
  private maxActiveDays: number;
  private maxExpireDays: number;
  private focusOrderRef: boolean;
  private paymentLinkClone: PaymentLinkClone;

  constructor(
    private commonService: CommonService,
    private location: Location,
    private paymentLinkService: PaymentLinkService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheObservableService: CacheObservableService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.commonService.isLoading(true);
    this.paymentLink = new PaymentLink();
    this.paymentLink.type = PAYMENT_LINK_TYPE.ONE_TIME;
    const currentTime = new Date();
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    this.activeTime.hour = currentTime.getHours() < 10 ? '0' + currentTime.getHours() : currentTime.getHours();
    this.activeTime.minute = currentTime.getMinutes() < 10 ? '0' + currentTime.getMinutes() : currentTime.getMinutes();
    this.expireTime.hour = this.activeTime.hour;
    this.expireTime.minute = this.activeTime.minute;
    this.loadPage = false;
    this.isOpenActive = false;
    this.firstTimeActive = true;
    this.firstTimeExpire = true;
    this.isOpenExpire = false;


    this.emptyProductNameError = PAYMENT_LINK_ERROR_MESSAGE.emptyProductNameError;
    this.orderRefError = null;
    this.invalidAmountError = null;
    this.invalidDateError = PAYMENT_LINK_ERROR_MESSAGE.invalidDateError;
    this.invalidMinDateError = PAYMENT_LINK_ERROR_MESSAGE.invalidMinDateError;
    this.invalidActiceHour = '';

    this.minActiveDate = DateUtil.dateToNgbDateStructCustom(currentTime);
    this.minExpireDate = DateUtil.dateToNgbDateStructCustom(currentTime);
    this.getMaxDateAndAmount();
    this.minExpireTimeError = null;
    this.activeDateRangeError = null;
    this.expireDateRangeError = null;

    this.referenceField = this.referenceOption[0];
    this.referenceFields = [];
    this.focusOrderRef = false;

    this.initData();
    this.formGroup.get('activeDateFC').valueChanges.subscribe((value) => {
      this.activeDateRangeError = null;
      this.emptyActiveDateError = null;
      if (this.errorDate('activeDateFC')) {
        this.invalidActiceHour = '';
        return
      }
      this.changeActiveTimeOption();

    });

    this.formGroup.get('expireDateFC').valueChanges.subscribe((value) => {
      this.minExpireTimeError = null;
      this.expireDateRangeError = null;
      this.emptyExpireDateError = null;
      if (this.errorDate('expireDateFC')) {
        return
      }
      this.changeExpireTimeOption();
    });

    this.formGroup.get('currency').valueChanges.subscribe((value) => {
      this.getCurrency();
      const amount = this.formGroup.get('amount').value;
      if (amount) {
        this.validateAmount(amount);
      }
    });

    this.formGroup.get('activeDateFC').setValue(DateUtil.formatToNgbDate(currentTime));
    this.formGroup.get('expireDateFC').setValue(DateUtil.formatToNgbDate(tomorrow));

    this.formGroup.get('amount').valueChanges.subscribe((val) => {
      this.validateAmount(val);
    });

    this.formGroup.get('orderReference').valueChanges.subscribe((val) => {
      this.orderRefError = null;
      if (!val) {
        this.orderRefError = PAYMENT_LINK_ERROR_MESSAGE.emptyOrderRefError;
      } else if (!REF_NUMBER_PATTERN.test(val)) {
        this.orderRefError = PAYMENT_LINK_ERROR_MESSAGE.invalidOrderRefError;
      }
    });

    this.formGroup.get('activeTime').valueChanges.subscribe((value) => {
      this.formGroup.get('expireTime').setValue(value);
      const timeArray = value.split(':');
      this.expireTime.hour = timeArray[0];
      this.expireTime.minute = timeArray[1];
      this.changeActiveTimeOption();
    });

    this.formGroup.get('expireTime').valueChanges.subscribe((value) => {
      this.changeExpireTimeOption();
    });
  }

  initData() {
    this.isClone = this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_CLONE);
    this.paymentType = new IpanModeInfo();
    this.smartpayInfoResquest = new GetSmartpayInfoRequest();
    this.ipanMode = undefined;
    this.sofDropdownConfig = {
      singleSelection: false,
      selectAllText: 'Select All',
      idField: 'sof',
      textField: 'sofDesc',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    this.formGroup = new FormGroup({
      serviceName: new FormControl('',  { validators: [Validators.required] }),
      description: new FormControl(''),
      orderReference: new FormControl(null, Validators.pattern(REF_NUMBER_PATTERN)),
      currency: new FormControl(),
      amount: new FormControl(null, { validators: [this.validatorAmount] }),
      activeDateFC: new FormControl(null, { validators: [this.validatorDate] }),
      expireDateFC: new FormControl(null, { validators: [this.validatorDate] }),
      activeTime: new FormControl(this.activeTime.hour + ':' + this.activeTime.minute, { validators: [Validators.required, TimeValidator] }),
      expireTime: new FormControl(this.expireTime.hour + ':' + this.expireTime.minute, { validators: [Validators.required, TimeValidator] }),
      sofListFC: new FormControl('', { validators: [Validators.required] }),
      paymentTypeFC: new FormControl('', { validators: [Validators.required]})
    });

    if (this.isClone) {
      this.clonePaymentLink();
    } else {
      this.reloadConfig(true);
    }
  }

  clonePaymentLink() {
    this.commonService.isLoading(true);
    this.paymentLinkClone = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_CLONE);
    this.paymentLink.type = this.paymentLinkClone.type;
    this.formGroup.get('serviceName').setValue(this.paymentLinkClone.serviceName);
    this.formGroup.get('description').setValue(this.paymentLinkClone.description);
    if (this.paymentLinkClone.amount && this.paymentLinkClone.amount > 0) {
      this.formGroup.get('amount').setValue(this.paymentLinkClone.amount);
    }
    if (this.paymentLinkClone.currency) {
      this.formGroup.get('currency').setValue(this.paymentLinkClone.currency, { emitEvent: false });
    }
    this.midCardFull = this.paymentLinkClone.merchantId ? this.paymentLinkClone.merchantId : undefined;
    // this.thaiQrMid = paymentLink.thaiQrMid;
    this.payplusKplus = this.paymentLinkClone.payPlusKPlus ? this.paymentLinkClone.payPlusKPlus.compcode : undefined;
    this.payplusPromptpay = this.paymentLinkClone.payPlusPromptpay ? this.paymentLinkClone.payPlusPromptpay.compcode : undefined;
    this.payplusAppSwitch = this.paymentLinkClone.payPlusAppswitch ? this.paymentLinkClone.payPlusAppswitch.compcode : undefined;
    if (this.paymentLinkClone.cardSmartPay) {
      this.ipanMode = this.paymentLinkClone.cardSmartPay.ipanMode ? this.paymentLinkClone.cardSmartPay.ipanMode : undefined;
      if (this.paymentLinkClone.cardSmartPay.paymentTerm) {
        this.installmentTerm = this.paymentLinkClone.cardSmartPay.paymentTerm;
      } else {
        this.installmentTerm = this.ipanMode ? DEFAULT_TERM : undefined;
      }
      this.supplierNo = this.paymentLinkClone.cardSmartPay.supplierNo ?  this.paymentLinkClone.cardSmartPay.supplierNo : undefined;
      this.productTypeClone = !isNaN(parseInt(this.paymentLinkClone.cardSmartPay.productType)) ? this.paymentLinkClone.cardSmartPay.productType.toString() : undefined;
      this.modelNo = this.paymentLinkClone.cardSmartPay.modelNo ? this.paymentLinkClone.cardSmartPay.modelNo : undefined;
      this.cardSmartpayMid = this.paymentLinkClone.cardSmartPay.merchantId && this.ipanMode ? this.paymentLinkClone.cardSmartPay.merchantId : undefined;
    }

    if (this.paymentLinkClone.type == PAYMENT_LINK_TYPE.ONE_TIME) {
      document.getElementById('oneTimeBtn').click();
    } else {
      document.getElementById('multipleBtn').click();
    }
    this.reloadConfig();
  }

  onDropDownClose() {
    if ( !this.dropDownSelect) {
      return
    }
    this.dropDownSelect = false;
    this.verifyPayplusPriority();
    this.preSofModel = this.sofModel;
    this.reloadConfig();
  }


  onBlurOrderRef() {
    const orderRef = this.formGroup.controls.orderReference.value;
    this.focusOrderRef = false;
    this.orderRefError = null;
    if (!orderRef) {
      this.orderRefError = PAYMENT_LINK_ERROR_MESSAGE.emptyOrderRefError;
      return
    }

    if (orderRef && !REF_NUMBER_PATTERN.test(orderRef)) {
      this.orderRefError = PAYMENT_LINK_ERROR_MESSAGE.invalidOrderRefError;
      return
    }

    this.paymentLinkService.checkOrderReference(orderRef).subscribe((response: ResultModel) => {
      if (this.commonService.isSuccess(response) && response.properties.isValidOrderRef == "false") {
        this.orderRefError = PAYMENT_LINK_ERROR_MESSAGE.existedOrderRefError;
      } else if (response.status_code == API_ERROR.INTERNAL_PERMISSION_ERR) {
        this.router.navigate(['/nopermission']);
      }
    });
  }

  onFocusOrderRef() {
    this.focusOrderRef = true;
  }

  onBlurAmount() {
    const amountValue = this.formGroup.controls.amount.value;
    this.emptyAmountError = null;
    if (this.paymentLink.type == PAYMENT_LINK_TYPE.ONE_TIME && !amountValue) {
      this.emptyAmountError = PAYMENT_LINK_ERROR_MESSAGE.emptyAmountdError;
    }
  }

  getCurrency() {
    // current code
    // currency change from THB to other currency -> reset all sof only keep card full (as-is: on Bank Portal only card full can config multi currency)
    this.sofModel = this.sofModel && this.sofModel.length > 0 ? this.sofModel.filter(sof => sof.sof === SOURCE_OF_FUND.CARD_FULL) : null;
    this.reloadConfig(null, true);
  }

  create(event: any) {
    this.changeActiveTimeOption();
    this.formGroup.controls.activeTime.updateValueAndValidity({onlySelf: true, emitEvent: false});
    event.target.disabled = this.disableCreateLinkBtn();
    if (event.target.disabled) {
      return;
    }

    this.commonService.isLoading(true);
    this.paymentLink.serviceName = this.formGroup.get('serviceName').value;
    this.paymentLink.description = this.formGroup.get('description').value;
    if (!this.formGroup.get('amount').value) {
      this.paymentLink.amount = null;
    } else {
      this.paymentLink.amount = this.formGroup.get('amount').value;
    }
    this.paymentLink.currency = this.formGroup.get('currency').value;
    this.paymentLink.maxPaymentTime = this.paymentLink.type == PAYMENT_LINK_TYPE.ONE_TIME ? 1 + '' : '';
    if (!this.formGroup.controls.activeDateFC.value) {
      this.paymentLink.activeTime = '';
    } else {
      var activeDate = this.parseToDate(this.formGroup.controls.activeDateFC.value);
      const activeTime = this.formGroup.controls.activeTime.value.split(":");
      activeDate.setHours(+activeTime[0], +activeTime[1]);
      this.paymentLink.activeTime = new Date(activeDate);
    }

    if (!this.formGroup.controls.expireDateFC.value) {
      this.paymentLink.expireTime = '';
    } else {
      var expireDate = this.parseToDate(this.formGroup.controls.expireDateFC.value);
      const expireTime = this.formGroup.controls.expireTime.value.split(":");
      expireDate.setHours(+expireTime[0], +expireTime[1]);
      this.paymentLink.expireTime = new Date(expireDate)
    }
    this.paymentLink.orderReference = this.formGroup.get('orderReference').value;
    for (let i = 0; i < this.referenceFields.length; i++) {
      if (this.referenceFields[i].name && this.referenceFields[i].value) {
        this.paymentLink['refName' + (i+1)] = this.referenceFields[i].name;
        this.paymentLink['ref' + (i+1)] = this.referenceFields[i].value;
        this.paymentLink['refMax' + (i+1)] = this.referenceFields[i].maxLength;
        this.paymentLink['refMin' + (i+1)] = this.referenceFields[i].minLength;
        this.paymentLink['refType' + (i+1)] = this.referenceFields[i].dataType;
      }

    }
    this.mapDataForSof();
    this.paymentLink.sourceOfFund = [...this.sofModel.map(sof => sof.sof)];
    this.paymentLinkService.create(this.paymentLink).subscribe((res: any) => {
      if (res.status_code == API_ERROR.INTERNAL_PERMISSION_ERR) {
        this.router.navigate(['/nopermission']);
      }
      if (this.commonService.isSuccess(res)) {
        this.toasterService.success(message.content.create_success, message.title.success);
        this.router.navigate(['payment-link/' + res.paymentLink.ref]);
      } else if (this.commonService.isHoldMid(res)) {
        this.toasterService.error(message.content.mid_and_tid_hold, message.title.error);
      } else {
        this.toasterService.error(message.content.create_fail, message.title.error);
      }
      this.commonService.isLoading(false);
    },error => {
      this.commonService.isLoading(false);
    }
    );
  }

  parseToISOStringDate(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString();
  }

  goBack() {
    console.log('Payment Create Link goBack');
    this.commonService.isLoading(true);
    this.clearObservableCacheOfClonePL();
    this.location.back();
  }

  validatorDate(fc: AbstractControl): { [key: string]: boolean } | null {
    var value = fc.value;
    var day, month, year;
    if (!value) {
      return
    }
    if (typeof value == 'string' && (!DATE_PATTERN.test(value) || (DATE_PATTERN.test(value) && value.split('/')[2].length > 4))) {
      return {
        'invalidDate': true
      }
    }

    if (value instanceof Object) {
      value = DateUtil.ngbDateStructToDate(value);
      day = value.getDate();
      month = value.getMonth() + 1;
      year = value.getFullYear();
    }

    if (typeof value == 'string') {
      value = DateUtil.convDateToMMDDYYY(value);
      day = value.split('/')[1];
      month = value.split('/')[0];
      year = value.split('/')[2];
    }
    const date = new Date(value);
    const isValid = !isNaN(date.valueOf()) ? date.getDate() != +day || (date.getMonth() + 1) != +month || date.getFullYear() != +year ? false : true : false;
    if (!isValid) {
      return {
        'invalidDate': true
      }
    }
    date.setHours(23, 59, 59);
    var currenDate = new Date();
    if (date.getTime() < currenDate.getTime()) {
      return {
        'minDateError': true
      }
    }
    return null
  }

  // Validate pattern amount
  validatorAmount(fc: AbstractControl): { [key: string]: boolean } | null {
    var value = fc.value;
    if (!value) {
      return
    }
    if (!value.toString().match(AMOUNT_PATTERN)) {
      return {
        'invalidPattern': true
      }
    }
    return null
  }


  errorDate(fcName: string) {
    if (fcName == 'expireDateFC') {
      return (this.formGroup.get(fcName).errors && (this.formGroup.get(fcName).errors.invalidDate || this.formGroup.get(fcName).errors.minDateError)) || this.emptyExpireDateError;
    } else return (this.formGroup.get(fcName).errors && (this.formGroup.get(fcName).errors.invalidDate || this.formGroup.get(fcName).errors.minDateError)) || this.emptyActiveDateError;
  }

  changeActiveTimeOption() {
    this.emptyActiveDateError = null;
    const activeDateFC = this.formGroup.controls.activeDateFC;
    const activeTime = this.formGroup.controls.activeTime.value;
    if (!activeDateFC.value && this.activeTime) {
      this.emptyActiveDateError = PAYMENT_LINK_ERROR_MESSAGE.emptyActiveDateError;
    }
    if (!activeDateFC.value || (activeDateFC.errors && (activeDateFC.errors.invalidDate || activeDateFC.errors.minDateError))) {
      return
    }
    var dateValue: any = activeDateFC.value;
    if (dateValue instanceof Object) {
      dateValue = DateUtil.ngbDateStructToDate(dateValue);
    } else {
      dateValue = new Date(DateUtil.convDateToMMDDYYY(dateValue));
    }
    var splitTime = activeTime.split(":");
    if (dateValue) {
      dateValue.setHours(splitTime[0], splitTime[1]);
    }
    // case date is today and hour < current hour -> show error
    this.invalidActiceHour = DateUtil.isEqualsDate(dateValue, new Date())
     && parseInt(splitTime[0]) < new Date().getHours() ? PAYMENT_LINK_ERROR_MESSAGE.invalidActiveHour : '';
    if (!this.errorDate('expireDateFC')) {
      this.validateRange('activeDateFC');
      this.updateExpireInfo(dateValue);
    }
  }

  // check change reference option
  changeReferenceFileds(referenceOption) {
    // Base on reference field number for loop to check Reference Field FormControl
    const saveRefs = this.referenceFields;
    this.referenceFields = [];
    for(let i = 0; i < referenceOption; i++) {
      let name: string, value: string;
      if (this.isClone) {
        name = this.paymentLinkClone['refName' + (i+1)];
        value = this.paymentLinkClone['ref' + (i+1)];
      } else {
        name = saveRefs && saveRefs.length > 0 && saveRefs.length > i ? saveRefs[i].name : '';
        value = saveRefs && saveRefs.length > 0 && saveRefs.length > i ? saveRefs[i].value : '';
      }
      const tmpRef = new ReferenceField(name, value, 'refName' + (i + 1), 'refValue' + (i + 1), null, null, null, null);
      switch (tmpRef.fieldName) {
        case 'refName1':
          tmpRef.dataType = StringUtil.returnDefaultIfObjNull(this.createPaymentLinkResponse.refType1, DATA_TYPES.ALPHANUMERIC);
          tmpRef.minLength = this.createPaymentLinkResponse.refMin1;
          tmpRef.maxLength = this.createPaymentLinkResponse.refMax1;
          const placeholder1 = this.createPaymentLinkResponse.refType1 && (this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING)
            || this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)
            || this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING)) ?
            StringUtil.uppercaseFirstCharacter(tmpRef.dataType) + ` : Value must be between ${tmpRef.minLength} and ${tmpRef.maxLength}` : PLACEHOLDER_DEFAULT;
          tmpRef.placeholder = placeholder1;
          if (this.payplusPriority && this.changeReferenceName) {
            tmpRef.name = this.createPaymentLinkResponse.refName1;
          }
          break;
        case 'refName2':
          tmpRef.dataType = StringUtil.returnDefaultIfObjNull(this.createPaymentLinkResponse.refType2, DATA_TYPES.ALPHANUMERIC);
          tmpRef.minLength = this.createPaymentLinkResponse.refMin2;
          tmpRef.maxLength = this.createPaymentLinkResponse.refMax2;
          const placeholder2 = this.createPaymentLinkResponse.refType2 && (this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING)
            || this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)
            || this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING)) ?
            StringUtil.uppercaseFirstCharacter(tmpRef.dataType) + ` : Value must be between ${tmpRef.minLength} and ${tmpRef.maxLength}` : PLACEHOLDER_DEFAULT;
          tmpRef.placeholder = placeholder2;
          if (this.payplusPriority && this.changeReferenceName) {
            tmpRef.name = this.createPaymentLinkResponse.refName2
          }
          break;
        case 'refName3':
          tmpRef.dataType = StringUtil.returnDefaultIfObjNull(this.createPaymentLinkResponse.refType3, DATA_TYPES.ALPHANUMERIC);
          tmpRef.minLength = this.createPaymentLinkResponse.refMin3;
          tmpRef.maxLength = this.createPaymentLinkResponse.refMax3;
          const placeholder3 = this.createPaymentLinkResponse.refType3 && (this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING)
           || this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)
           || this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING))
            ? StringUtil.uppercaseFirstCharacter(tmpRef.dataType) + ` : Value must be between ${tmpRef.minLength} and ${tmpRef.maxLength}` : PLACEHOLDER_DEFAULT;
          tmpRef.placeholder = placeholder3;
          if (this.payplusPriority && this.changeReferenceName) {
            tmpRef.name = this.createPaymentLinkResponse.refName3
          }
          break;
      }
      // case referenceFields list not contain tmpRef, this ref will be add into referenceFields and create formControl
      this.referenceFields.push(tmpRef);
    }
    this.dynamicBindReferenceFiledsValidatation();
  }

  // validate value of reference field
  blurValue(referenceField: ReferenceField) {
    this.formGroup.get(referenceField.fieldValue).setValue(referenceField.value.trim());
    this.dynamicBindReferenceFiledsValidatation();
  }

  // trim value when out focus
  blurRefName(referenceField: ReferenceField) {
    this.formGroup.get(referenceField.fieldName).setValue(referenceField.name.trim());
    this.dynamicBindReferenceFiledsValidatation();
  }

  // Validate name of Reference Fields
  validatorName(fc: AbstractControl): { [key: string]: boolean } | null {
    var value = fc.value;
    if(!value) {
      return null
    }
    if (!value.match(/^[\w\d\s"%&'()*+,-./:;<=>?@[\]_`{}~]+$/)) {
      return {
        'invalidPattern': true
      }
    }
    return null
  }

  // Validate min and max length of Reference Fields
  validatorLength(fc: AbstractControl): { [key: string]: boolean } | null {
    var value = fc.value;
    if (!isNaN(value) && (parseInt(value) < 1 || parseInt(value) > 20)) {
      return {
        'invalidLength': true
      }
    }
    return null
  }

  changeComptCode (payplusType: string) {
    this.changeReferenceName = payplusType === this.payplusPriority;
    this.reloadConfig(false, false, payplusType);
  }

  private verifyPayplusPriority() {
    this.changeReferenceName = false;
    if (!this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)
      && !this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING)) {
      this.payplusPriority = null;
      return
    }

    if ((this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.preSofModel, SOF_STRING))
      || (this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.preSofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING))
      || (this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.preSofModel, SOF_STRING))
      || (this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.preSofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING))
      || (this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.preSofModel, SOF_STRING))
      || (this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.preSofModel, SOF_STRING) && !this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING))
      || !this.preSofModel || this.preSofModel.length == 0) {
      this.changeReferenceName = true;
    }

    // logic priority in CR0139: Noti-K Plus -> Noti-PromptPay -> App Switch
    if (this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING)) {
      this.payplusPriority = SOURCE_OF_FUND.PAYPLUS_KPLUS;
      return
    }

    if (this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)) {
      this.payplusPriority = SOURCE_OF_FUND.PAYPLUS_PROMPTPAY;
      return
    }

    if (this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING)) {
      this.payplusPriority = SOURCE_OF_FUND.PAYPLUS_APPSWITCH;
      return
    }
  }

  // Auto set exire date when active time >= expire time
  // and update minExpireDate, maxExpireDate
  private updateExpireInfo(dateValue) {
    const activeDateFC = this.formGroup.controls.activeDateFC;
    const expireDate = this.parseToDate(this.formGroup.controls.expireDateFC.value);
    const expireTime = this.formGroup.controls.expireTime.value;
    const maxActiveDate = this.maxActiveDate ? new Date(DateUtil.ngbDateStructToDate(this.maxActiveDate).setHours(23,59)) : null;
    if (this.errorDate('expireDateFC') || !expireDate || !this.maxExpireDays ||!expireTime
      || (maxActiveDate && dateValue.getTime() > maxActiveDate.getTime())) {
        return
      }
    const tomorrow = this.parseToDate(activeDateFC.value);
    tomorrow.setDate(tomorrow.getDate() + 1);

    expireDate.setHours(expireTime.split(':')[0], expireTime.split(':')[1]);

    const activeTime = this.formGroup.controls.activeTime.value;
    const activeTimeArray = activeTime.split(':');
    const maxExpire = DateUtil.subtractDay(dateValue, -(this.maxExpireDays - 1));
    maxExpire.setHours(23, 59);

    this.maxExpireDate = DateUtil.dateToNgbDateStructCustom(maxExpire);
    this.minExpireDate = DateUtil.dateToNgbDateStructCustom(dateValue);

    if (dateValue.getTime() >= expireDate.getTime() || expireDate.getTime() > maxExpire.getTime()) {
      this.activeDateRangeError = null;
      this.expireDateRangeError = null;
      this.formGroup.get('expireDateFC').setValue(DateUtil.formatToNgbDate(tomorrow));
      this.formGroup.controls.expireTime.setValue(activeTime);
      this.expireTime.hour = activeTimeArray[0];
      this.expireTime.minute = activeTimeArray[1];
    }
  }

  changeExpireTimeOption() {
    this.minExpireTimeError = null;
    this.emptyExpireDateError = null;
    const expireTime = this.formGroup.controls.expireTime.value;
    if (expireTime) {
      const expireDateFC = this.formGroup.controls.expireDateFC;
      if (!expireDateFC.value && expireTime) {
        this.emptyExpireDateError = PAYMENT_LINK_ERROR_MESSAGE.emptyExpireDateError
      }
      if (!expireDateFC.value || (expireDateFC.errors && (expireDateFC.errors.invalidDate || expireDateFC.errors.minDateError))) {
        return
      }
      var dateValue: any = expireDateFC.value;
      if (dateValue instanceof Object) {
        dateValue = DateUtil.ngbDateStructToDate(dateValue);
      } else {
        dateValue = new Date(DateUtil.convDateToMMDDYYY(dateValue));
      }
      var splitTime = this.formGroup.controls.expireTime.value.split(":");
      if (dateValue) {
        dateValue.setHours(splitTime[0], splitTime[1]);
        const currentTime = new Date();
        if (dateValue.getTime() < currentTime.getTime()) {
          this.minExpireTimeError = PAYMENT_LINK_ERROR_MESSAGE.minExpireTimeError;
          return
        }
      }
      if (!this.errorDate('activeDateFC')) {
        this.validateRange('expireDateFC');
      }
    }
  }

  disableCreateLinkBtn() {
    if (!this.formGroup.controls.serviceName.value || !this.formGroup.controls.orderReference.value
      || (this.paymentLink.type == PAYMENT_LINK_TYPE.ONE_TIME && !this.formGroup.controls.amount.value)) {
        return true;
    } else {
      let arrRefError = [];
      this.referenceFields.forEach((ref) => {
       arrRefError.push(!(this.formGroup.get(ref.fieldName).errors || this.isErrorRefValue(ref)));
      });

    return !this.formGroup.controls.serviceName.value.trim() || !this.formGroup.controls.orderReference.value.trim() ||
      this.invalidAmountError || (this.formGroup.controls.amount.errors && this.formGroup.controls.amount.errors.pattern) || this.orderRefError || this.activeDateRangeError || this.expireDateRangeError
      || this.minExpireTimeError || this.errorDate('activeDateFC') || this.errorDate('expireDateFC') || this.focusOrderRef || this.invalidActiceHour|| (arrRefError.length > 0 && arrRefError.some((value) => !value))
      || !this.validateSof()
    }

  }

  //function handle active time
  focusActiveTime() {
    this.isOpenActive = !this.isOpenActive;
    if (!this.isOpenActive) {
      this.inputActiveTime.nativeElement.blur();
    }
  }

  closeTimeSelectionActive(value) {
    if (!this.isOpenActive) {
      this.firstTimeActive = true;
      return
    }
    if (value && !this.firstTimeActive) {
      this.isOpenActive = false;
      this.firstTimeActive = true;
      this.inputActiveTime.nativeElement.blur();
      return
      } else {
      this.firstTimeActive = false
    }
      }

  changeActiveTime(event) {
    this.activeTime.hour = event.hour;
    this.activeTime.minute = event.minute;
    this.formGroup.get('activeTime').setValue((event.hour + ':' + event.minute), { emitEvent: true });
  }

  //function handle expire time
  focusExpireTime() {
    this.isOpenExpire = !this.isOpenExpire;
    if (!this.isOpenExpire) {
      this.inputExpireTime.nativeElement.blur();
      }
  }

  closeTimeSelectionExpire(value) {
    if (!this.isOpenExpire) {
      this.firstTimeExpire = true;
      return
    }
    if (value && !this.firstTimeExpire) {
      this.isOpenExpire = false;
      this.firstTimeExpire = true;
      this.inputExpireTime.nativeElement.blur();
      return
    } else {
      this.firstTimeExpire = false
    }
  }

  changeExpireTime(event) {
    this.expireTime.hour = event.hour;
    this.expireTime.minute = event.minute;
    this.formGroup.get('expireTime').setValue((event.hour + ':' + event.minute), { emitEvent: true });
  }

  //close datepicker
  closeActiveDatePicker(event) {
    if (event) {
      this.activeDatepicker.close();
    }
  }
  closeExpireDatePicker(event) {
    if (event) {
      this.expireDatepicker.close();
    }
  }

  isContain(sofKey: string, array: any[], param: string): boolean {
    return array && array.some(sof => sof[param] == sofKey);
  }

  changePaymentType() {
    this.installmentTerm = undefined;
    this.cardSmartpayMid = undefined;
    this.cardSmartpayMids = [];

    const selectedInstallmentType = this.installmentTypes.find((info) => info.ipanMode == this.ipanMode);
    this.installmentTerms = selectedInstallmentType ? selectedInstallmentType.termObj : [];
    // ipanMode is 03 -> select the first option of supplierNo, productType and modelNo
    // not apply logic term list only show term belong mid is selected (CR0139)
    if (this.ipanMode == IPAN_MODE_03) {
      // always choose the first option when change ipan mode
      this.installmentTerm = this.installmentTerms[0].term;
      this.getSmartpayInfo();
    }

  }

  /**
   * @returns
   */
  changeTerm() {
    this.commonService.isLoading(true);
    if (this.ipanMode === IPAN_MODE_03) {
      this.smartpayInfos = [];
      this.supplierNo = undefined;
      this.productTypeInfo = undefined;
      this.productTypes = [];
      this.modelNo = undefined;
      this.modelNoList = [];
      this.cardSmartpayMid = undefined;
      this.cardSmartpayMids = [];
      this.getSmartpayInfo();
      return
    }
    // change term -> reset mid list field and let user choose again (show please select in MID field)
    this.cardSmartpayMid = undefined;
    this.cardSmartpayMids = [];
    // reload data in mid list
    const installmentType = this.installmentTypes.find((ipanMode) => ipanMode.ipanMode == this.ipanMode);
    this.cardSmartpayMids = installmentType ? installmentType.termObj.find(term => term.term == this.installmentTerm).mid : [];
    this.commonService.isLoading(false);
  }

  changeSupplierNo() {
    if (!this.supplierNo) {
      this.modelNo = undefined;
      this.modelNoList = [];
      this.cardSmartpayMid = undefined;
      this.cardSmartpayMids = [];
      return
    }
    const smartPayInfo = this.smartpayInfos.find((info) => info.supplierNo === this.supplierNo);
    if (smartPayInfo) {
      this.productTypes = smartPayInfo.productTypes;
        if (!this.isClone) {
          this.productTypeInfo = this.productTypes[0];
        }
        if (!this.installmentTerm) {
          this.productTypeInfo = undefined;
        }
    }
    this.changeProductType();
  }

  changeProductType() {
    if (!this.productTypeInfo) {
      this.modelNo = undefined;
      this.modelNoList = [];
      this.cardSmartpayMid = undefined;
      this.cardSmartpayMids = [];
      return
    }
    const typeNumber = this.isClone ? this.productTypeClone : this.productTypeInfo.productType;
    const productType = this.productTypes.find((type) => type.productType === typeNumber);
    if (productType) {
      if (this.isClone) {
        this.productTypeInfo = productType;
      }
      this.modelNoList = productType.modelNoObj;
      this.modelNo = this.isClone ? this.modelNo : this.modelNoList[0].modelNo;
      if (!this.installmentTerm) {
        this.modelNo = undefined;
      }
    }
    this.changeModelNo();
  }

  changeModelNo() {
    const modelNo = this.modelNoList.find((modelNo) => modelNo.modelNo === this.modelNo);
    if (modelNo) {
      this.cardSmartpayMids = modelNo.mid;
        if (!this.isClone) {
          this.cardSmartpayMid = this.cardSmartpayMids && this.cardSmartpayMids.length === 1 ? this.cardSmartpayMids[0] : undefined;
        }
    } else {
      this.cardSmartpayMid = undefined;
      this.cardSmartpayMids = [];
    }
  }

   /**
   * dynamic form control by ref1, ref2, ref3 value
   */
  dynamicBindReferenceFiledsValidatation() {
    var forceRequire = false;
    for(let i = this.referenceFields.length-1; i>=0; i--) {
      const ref = this.referenceFields[i];
      if(ref.name || ref.value || forceRequire){
        // add require validator if name is not empty
        this.createReferenceFieldsForm(ref, true);
        forceRequire = true;
      }
      if(!ref.name && !ref.value && !forceRequire){
        // remove require validator if name empty
        this.createReferenceFieldsForm(ref);
      }
    }
  }

  isInvalidRefMinMax(refField): boolean {
    return this.invalidRefMinMax && !refField.minLength && (this.isContain(this.sofKey.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING)
     || this.isContain(this.sofKey.PAYPLUS_KPLUS, this.sofModel, SOF_STRING) || this.isContain(this.sofKey.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING));
  }

  isErrorRefValue(refField: ReferenceField): boolean {
    const errors = this.formGroup.controls[refField.fieldValue].errors;
    if (!errors) {
      return false;
    }
    return errors && ((errors.maxlength && refField.maxLength === errors.maxlength.requiredLength) || !errors.maxlength) ? true : false;
  }

  /**
   * change mid need to reload term list
   */
  changeMid() {
    this.commonService.isLoading(true);
    const selectedIpanMode = this.installmentTypes.find((info) => info.ipanMode == this.ipanMode);
    this.installmentTerms = [];
    selectedIpanMode.termObj.map(term => {
      if (term.mid.some(mid => mid == this.cardSmartpayMid)) {
        this.installmentTerms.push(term);
      }
    });
    this.commonService.isLoading(false);
  }

  private verifySmartpayInfo() {
    this.smartpayInfoResquest.term = this.installmentTerm;
    this.smartpayInfoResquest.ipanMode = this.ipanMode;
    this.paymentLinkService.getListSmartpayInfo(this.smartpayInfoResquest).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.smartpayInfos = response.smartPayInfos;
        const smartpayInfo = this.smartpayInfos.find(info => info.supplierNo == this.supplierNo);
        if (!smartpayInfo) {
          this.supplierNo = undefined;
          this.productTypeInfo = undefined;
          this.modelNo = undefined;
          return
        }
        this.productTypes = smartpayInfo.productTypes;
        const productType = this.isClone ? this.productTypes.find(type => type.productType == this.productTypeClone) :
        this.productTypes.find(type => type.productType == this.productTypeInfo.productType);
        if (!productType) {
          this.productTypeInfo = undefined;
          this.modelNo = undefined;
          return
        }
        this.productTypeInfo = productType;
        this.modelNoList = productType.modelNoObj;
        if (!this.modelNoList.some(modelNo => modelNo.modelNo == this.modelNo)) {
          this.modelNo = undefined;
          return
        }
        const modelNoInfo = this.modelNoList.find(modelNo => modelNo.modelNo === this.modelNo);
        this.cardSmartpayMid = modelNoInfo.mid.find(mid => mid === this.cardSmartpayMid) ? this.cardSmartpayMid : modelNoInfo.mid[0];
        //mid list get follow modelNo object
        this.cardSmartpayMids = modelNoInfo.mid;
        this.clearObservableCacheOfClonePL();
      }
    });

  }

  /**
   *
   * @param isInitData
   * @param isChangeCurrency
   * @param changePayplus
   */
  private reloadConfig(isInitData?: boolean, isChangeCurrency?: boolean, changePayplus? : string) {
    this.commonService.isLoading(true);
    this.setRequestParam(changePayplus);
    this.paymentLinkService.getConfigCreatePaymentLink(this.configPaymentLinkRequest).subscribe((response: CreatePaymentLinkResponse) => {
      if (this.commonService.isSuccess(response) || response.status_code == API_ERROR.REF_MIN_MAX_ERR) {

        this.invalidRefMinMax = response.status_code == API_ERROR.REF_MIN_MAX_ERR ? response.status_message : null;
        if (this.invalidRefMinMax) {
          this.setMinMaxCaseInvalid()
        }

        if (changePayplus) {
          this.validateComptCode(response, changePayplus);
          this.commonService.isLoading(false);
          return
        }
        // save current response when reload config
        this.createPaymentLinkResponse = response;
        this.listSOF = response.sourceOfFund;
        this.setSofModel();
        this.currencies = response.currency;

        if (isInitData && this.currencies && this.currencies.length > 0 && !this.formGroup.controls.currency.value) {
          const flagTHB = this.currencies.find((currency) => currency == 'THB') ? 'THB' : this.currencies[0];
          this.formGroup.get('currency').setValue(flagTHB, { emitEvent: false });
        }

        this.merchantIds = response.merchantId;
        this.payplusKplusList = response.payplusKplus;
        this.payplusPromptpayList = response.payplusPromptpay;
        this.payplusAppSwitchList = response.payplusAppSwitch;
        if (!this.isClone && this.merchantIds && this.merchantIds.length > 0) {
          this.midCardFull = this.midCardFull && StringUtil.isContainInList(this.midCardFull, this.merchantIds) ? this.midCardFull : this.merchantIds[0].merchantId;
        }
        if (!this.isClone && this.payplusKplusList && this.payplusKplusList.length > 0) {
          this.payplusKplus = this.payplusKplus && StringUtil.isContainInList(this.payplusKplus, this.payplusKplusList) ? this.payplusKplus : this.payplusKplusList[0];
        }
        if (!this.isClone && this.payplusPromptpayList && this.payplusPromptpayList.length > 0) {
          this.payplusPromptpay = this.payplusPromptpay && StringUtil.isContainInList(this.payplusPromptpay, this.payplusPromptpayList)
          ? this.payplusPromptpay : this.payplusPromptpayList[0];
        }
        if (!this.isClone && this.payplusAppSwitchList && this.payplusAppSwitchList.length > 0) {
          this.payplusAppSwitch = this.payplusAppSwitch && StringUtil.isContainInList(this.payplusAppSwitch, this.payplusAppSwitchList)
          ? this.payplusAppSwitch : this.payplusAppSwitchList[0];
        }
        if (this.isContain(SOURCE_OF_FUND.THAI_QR, this.sofModel, SOF_STRING)) {
          this.thaiQrMid = response.thaiQrMid;
        }

        this.changeReferenceFileds(DEFAULT_NO_OF_REFNO_ON_SCREEN);
        if (this.configPaymentLinkRequest.currency && !StringUtil.isContainInList(this.configPaymentLinkRequest.currency, response.currency)) {
          this.formGroup.get('currency').setValue(response.currency[0], { emitEvent: true });
          return;
        }
        if (isChangeCurrency) {
          this.resetDataCaseChangeCurrency();
        }
      }
      this.resetSofData();
      if (this.sofModel && this.sofModel.length > 0 && this.sofModel.some(sof => sof.sof === this.sofKey.CARD_SMARTPAY)) {
        this.getInstallmentType();
      }
      if (this.ipanMode != IPAN_MODE_03) {
        this.clearObservableCacheOfClonePL();
      }
      this.commonService.isLoading(false);
    }, err => {
      this.clearObservableCacheOfClonePL();
      this.commonService.isLoading(false);
    });
  }

  private validateComptCode(response: CreatePaymentLinkResponse, changePayplus: string) {
    this.createPaymentLinkResponse.refMax1 = response.refMax1;
    this.createPaymentLinkResponse.refMax2 = response.refMax2;
    this.createPaymentLinkResponse.refMax3 = response.refMax3;
    this.createPaymentLinkResponse.refMin1 = response.refMin1;
    this.createPaymentLinkResponse.refMin2 = response.refMin2;
    this.createPaymentLinkResponse.refMin3 = response.refMin3;
    this.createPaymentLinkResponse.refType1 = response.refType1;
    this.createPaymentLinkResponse.refType2 = response.refType2;
    this.createPaymentLinkResponse.refType3 = response.refType3;
    this.createPaymentLinkResponse.requireRef1 = response.requireRef1;
    this.createPaymentLinkResponse.requireRef2 = response.requireRef2;
    this.createPaymentLinkResponse.requireRef3 = response.requireRef3;
    this.createPaymentLinkResponse.refName1 = response.refName1;
    this.createPaymentLinkResponse.refName2 = response.refName2;
    this.createPaymentLinkResponse.refName3 = response.refName3;
    const sofPayplus = response.sourceOfFund.find(sof => sof.sof == changePayplus);
      // if payplus not contain in response need reset data on the UI.
      if (!sofPayplus) {
        const indexSelected = this.sofModel.indexOf(sofPayplus);
        const indexListSOF = this.listSOF.indexOf(sofPayplus);
        this.sofModel.splice(indexSelected, 1);
        this.sofModel = [...this.sofModel];
        this.listSOF.splice(indexListSOF, 1);
        this.listSOF = [...this.listSOF];
        switch(changePayplus) {
          case SOURCE_OF_FUND.PAYPLUS_KPLUS:
            this.payplusKplus = null;
            this.payplusKplusList = [];
            break;
          case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
            this.payplusPromptpay = null;
          this.payplusPromptpayList = [];
            break;
          case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
            this.payplusAppSwitch = null;
          this.payplusAppSwitchList = [];
            break;
        }
      } else {
        // verify the data on the UI
        switch(changePayplus) {
          case SOURCE_OF_FUND.PAYPLUS_KPLUS:
            // check compCode still exist in the response or not -> if not -> reset show Please select, let user select again
            this.payplusKplus = this.payplusKplus && StringUtil.isContainInList(this.payplusKplus, this.payplusKplusList) ? this.payplusKplus : this.payplusKplusList[0];
            this.payplusKplusList = response.payplusKplus;
            break;
          case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
            this.payplusPromptpay = this.payplusPromptpay && StringUtil.isContainInList(this.payplusPromptpay, this.payplusPromptpayList)
              ? this.payplusPromptpay : this.payplusPromptpayList[0];
            this.payplusPromptpayList = response.payplusPromptpay;
            break;
            case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
            this.payplusAppSwitch = this.payplusAppSwitch && StringUtil.isContainInList(this.payplusAppSwitch, this.payplusAppSwitchList)
              ? this.payplusAppSwitch : this.payplusAppSwitchList[0];
            this.payplusAppSwitchList = response.payplusAppSwitch;
            break;
        }
      }
    if (this.invalidRefMinMax) {
      this.setMinMaxCaseInvalid();
    }
    this.changeReferenceFileds(DEFAULT_NO_OF_REFNO_ON_SCREEN);
  }

  private setMinMaxCaseInvalid() {
    this.referenceFields.forEach(ref => {
      switch (ref.fieldName) {
        case 'refName1': ref.minLength = this.createPaymentLinkResponse.refMin1;
        break;
        case 'refName2': ref.minLength = this.createPaymentLinkResponse.refMin2;
        break;
        case 'refName3': ref.minLength = this.createPaymentLinkResponse.refMin3;
        break;
      }
    });
  }

  private setRequestParam(changePayplus? : string) {
    this.configPaymentLinkRequest = new ConfigCreatePaymentLinkRequest;
    if (this.isClone) {
      this.configPaymentLinkRequest.sourceOfFund = this.paymentLinkClone.sourceOfFund;
    } else {
      this.configPaymentLinkRequest.sourceOfFund = this.sofModel && this.sofModel.length > 0 ? [...this.sofModel.map(sof => sof.sof)] : null;
    }
    this.configPaymentLinkRequest.currency = this.formGroup && this.formGroup.controls.currency.value ? this.formGroup.controls.currency.value : null;
    this.configPaymentLinkRequest.compCodeKplus = this.payplusKplus;
    this.configPaymentLinkRequest.compCodePromptpay = this.payplusPromptpay;
    this.configPaymentLinkRequest.compCodeAppSwitch = this.payplusAppSwitch;
    this.configPaymentLinkRequest.payplusPriority = this.payplusPriority;
    if (!this.isClone) {
      this.configPaymentLinkRequest.compCodeKplus = this.isContain(SOURCE_OF_FUND.PAYPLUS_KPLUS, this.sofModel, SOF_STRING) ? this.payplusKplus : null;
      this.configPaymentLinkRequest.compCodePromptpay = this.isContain(SOURCE_OF_FUND.PAYPLUS_PROMPTPAY, this.sofModel, SOF_STRING) ? this.payplusPromptpay : null;
      this.configPaymentLinkRequest.compCodeAppSwitch = this.isContain(SOURCE_OF_FUND.PAYPLUS_APPSWITCH, this.sofModel, SOF_STRING) ? this.payplusAppSwitch : null;
    }
    if (changePayplus) {
      this.configPaymentLinkRequest.isClone = this.payplusKplus === undefined || this.payplusPromptpay === undefined || this.payplusAppSwitch === undefined ? true : this.isClone;
    } else {
      this.configPaymentLinkRequest.isClone = this.isClone;
    }
  }

  private setSofModel() {
    if (this.sofModel && this.sofModel.length > 0 && !this.isClone) {
      let sofTmp = [];
      this.listSOF.forEach(sof => {
        if (this.sofModel.some(sofSelected => sofSelected.sof == sof.sof)) {
          sofTmp.push(sof);
        }
      });
      this.sofModel = sofTmp;
    }
    if (this.isClone) {
      this.sofModel = [];
      this.createPaymentLinkResponse.sourceOfFund.forEach(sof => {
        if (this.paymentLinkClone.sourceOfFund.some(sofClone => sof.sof === sofClone)) {
          this.sofModel.push(sof);
        }
      });
    }
  }

  private clearObservableCacheOfClonePL() {
    if (this.isClone) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.PAYMENT_LINK_CLONE);
      this.isClone = false;
    }
  }

  private resetDataCaseChangeCurrency() {
    if (!this.sofModel || this.sofModel.length < 1) {
      this.sofModel = undefined;
      this.midCardFull = undefined;
      this.thaiQrMid = undefined;
      this.payplusKplus = undefined;
      this.payplusPromptpay = undefined;
      this.ipanMode = undefined
      this.installmentTerm = undefined;
      this.supplierNo = undefined;
      this.productTypeInfo = undefined;
      this.modelNo = undefined;
      return
    }
    if (this.sofModel && this.sofModel.length > 0) {
      this.formGroup.get('sofListFC').setValue(this.sofModel, { emitEvent: true });
      this.sofModel.forEach(sof => {
        switch(sof.sof) {
          case SOURCE_OF_FUND.CARD_FULL:
            this.merchantIds = this.createPaymentLinkResponse.merchantId;
            break;
          case SOURCE_OF_FUND.PAYPLUS_KPLUS:
            this.payplusKplusList = this.createPaymentLinkResponse.payplusKplus;
            break;
          case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
            this.payplusPromptpayList = this.createPaymentLinkResponse.payplusPromptpay;
            break;
          case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
            this.payplusAppSwitchList = this.createPaymentLinkResponse.payplusAppSwitch;
            break;
          case SOURCE_OF_FUND.THAI_QR:
            this.thaiQrMid = this.createPaymentLinkResponse.thaiQrMid;
            break;
          case SOURCE_OF_FUND.CARD_SMARTPAY:
            // not call api here because in reload config always check when sof is card_smartpay
            break;
          default:
            break;
        }
      })
    }
  }

  private getSmartpayInfo() {
    this.commonService.isLoading(true);
    this.smartpayInfoResquest.term = this.installmentTerm;
    this.smartpayInfoResquest.ipanMode = this.ipanMode;
    this.paymentLinkService.getListSmartpayInfo(this.smartpayInfoResquest).subscribe(response => {
      if (this.commonService.isSuccess(response)) {
        this.smartpayInfos = response.smartPayInfos;
        this.supplierNo = this.isClone ? this.supplierNo : this.smartpayInfos[0].supplierNo;
        if (!this.installmentTerm) {
          this.supplierNo = undefined;
        }
      }
      this.changeSupplierNo();
      this.commonService.isLoading(false);
    }, err => {
      this.commonService.isLoading(false);
    });
  }

  private resetSofData() {
    if (!this.createPaymentLinkResponse || !this.createPaymentLinkResponse.sourceOfFund || this.createPaymentLinkResponse.sourceOfFund.length < 1) {
      return
    }
    this.createPaymentLinkResponse.sourceOfFund.forEach(sofResponse => {
      if (!this.isContain(sofResponse.sof, this.sofModel, SOF_STRING)) {
        switch (sofResponse.sof) {
          case SOURCE_OF_FUND.CARD_FULL:
            this.midCardFull = undefined;
            this.merchantIds = [];
            break;
          case SOURCE_OF_FUND.CARD_SMARTPAY:
            this.ipanMode = undefined;
            this.installmentTypes = [];
            this.installmentTerm = undefined;
            this.installmentTerms = [];
            this.installmentTerm = undefined;
            this.supplierNo = undefined;
            this.smartpayInfos = [];
            this.productTypeInfo = undefined;
            this.productTypes = [];
            this.modelNo = undefined;
            this.modelNoList = [];
            this.cardSmartpayMid = undefined;
            this.cardSmartpayMids = [];
            break;
          case SOURCE_OF_FUND.PAYPLUS_KPLUS:
            this.payplusKplus = undefined;
            this.payplusKplusList = [];
            break;
          case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
            this.payplusPromptpay = undefined;
            this.payplusPromptpayList = [];
            break;
          case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
            this.payplusAppSwitch = undefined;
            this.payplusAppSwitchList = [];
            break;
          case SOURCE_OF_FUND.THAI_QR:
            this.thaiQrMid = undefined;
            break;
        }
      }
    });
  }

  /**
   * verify data for sof = smartPay when reloadConfig run
   */
  private getInstallmentType() {
    this.commonService.isLoading(true);
    this.paymentLinkService.getInstallmentType().subscribe(response => {
      this.installmentTypes = response.installmentTypes;
      const selectedIpanMode = this.installmentTypes.find(info => info.ipanMode == this.ipanMode);
      if (!selectedIpanMode) {
        this.ipanMode = undefined;
        this.installmentTerm = undefined;
        this.installmentTerms = [];
        this.cardSmartpayMid = undefined;
        this.cardSmartpayMids = [];
        return
      }
      this.installmentTerms = selectedIpanMode.termObj;
      if (this.ipanMode == IPAN_MODE_03) {
        // always choose the first option when term not exist in current term list
        if (!this.installmentTerms.find(term => term.term == this.installmentTerm)) {
          this.installmentTerm = this.installmentTerms[0].term;
        }
        this.verifySmartpayInfo();
        return
      }
      const selectedTerm = this.installmentTerms.find(term => term.term == this.installmentTerm);
      if (this.installmentTerm && !selectedTerm) {
        this.installmentTerm = undefined;
        this.cardSmartpayMid = undefined;
        this.cardSmartpayMids = [];
        return
      }
      this.cardSmartpayMids = selectedTerm.mid;
      if (this.cardSmartpayMid && !this.cardSmartpayMids.find(mid => mid == this.cardSmartpayMid)) {
        this.cardSmartpayMid = undefined;
        return
      }
      // if mid is selected -> filter term list by mid
      if (this.cardSmartpayMid) {
        this.installmentTerms = [];
        selectedIpanMode.termObj.map(term => {
          if (term.mid.some(mid => mid == this.cardSmartpayMid)) {
            this.installmentTerms.push(term);
          }
        });
      }
      this.commonService.isLoading(false);
    });
  }

  // Paste data into model to call api create PL
  private mapDataForSof() {
    this.listSOF.forEach(sof => {
      switch (sof.sof) {
        case SOURCE_OF_FUND.CARD_FULL:
          this.paymentLink.merchantId = this.isContain(sof.sof, this.sofModel, SOF_STRING) ? this.midCardFull : null;
          break;
        case SOURCE_OF_FUND.CARD_SMARTPAY:
          if (!this.isContain(sof.sof, this.sofModel, SOF_STRING) || !this.installmentTypes.some(type => type.ipanMode == this.ipanMode)
            || !this.installmentTerms.some(term => term.term == this.installmentTerm) || !this.cardSmartpayMids.some(mid => mid == this.cardSmartpayMid)) {
            this.paymentLink.cardSmartPay = null;
          } else {
            this.paymentLink.cardSmartPay = new CardSmartpay();
            this.paymentLink.cardSmartPay.ipanMode = this.ipanMode;
            this.paymentLink.cardSmartPay.paymentTerm = this.installmentTerm === DEFAULT_TERM ? '' : this.installmentTerm;
            this.paymentLink.cardSmartPay.merchantId = this.cardSmartpayMid;
            this.paymentLink.cardSmartPay.productType = this.ipanMode === IPAN_MODE_03 ? this.productTypeInfo.productType : null;
            this.paymentLink.cardSmartPay.supplierNo = this.ipanMode === IPAN_MODE_03 ? this.supplierNo : null;
            this.paymentLink.cardSmartPay.modelNo = this.ipanMode === IPAN_MODE_03 ? this.modelNo : null;
          }
         break;
        case SOURCE_OF_FUND.PAYPLUS_KPLUS:
          this.paymentLink.payPlusKPlus = new Payplus();
          this.paymentLink.payPlusKPlus.compcode = this.isContain(sof.sof, this.sofModel, SOF_STRING) ? this.payplusKplus : null;
          break;
        case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
          this.paymentLink.payPlusPromptpay = new Payplus();
          this.paymentLink.payPlusPromptpay.compcode = this.isContain(sof.sof, this.sofModel, SOF_STRING) ? this.payplusPromptpay : null;
          break;
        case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
          this.paymentLink.payPlusAppswitch = new Payplus();
          this.paymentLink.payPlusAppswitch.compcode = this.isContain(sof.sof, this.sofModel, SOF_STRING) ? this.payplusAppSwitch : null;
          break;
        case SOURCE_OF_FUND.THAI_QR:
          break;
      }
    });
  }

  private validateSof(): boolean {
    if (!this.sofModel || this.sofModel.length < 1 || this.invalidRefMinMax) {
      return false
    }
    let result = true;
    this.sofModel.forEach(sof => {
      if (result) {
        switch (sof.sof) {
          case SOURCE_OF_FUND.CARD_SMARTPAY:
            result = result && !(!this.ipanMode || (!this.cardSmartpayMid || (this.ipanMode === IPAN_MODE_03 && (!this.supplierNo || !this.productTypeInfo || !this.modelNo))));
            break;
          case SOURCE_OF_FUND.PAYPLUS_KPLUS:
            result = result && this.validateRef() && !!this.payplusKplus;
            break;
          case SOURCE_OF_FUND.PAYPLUS_PROMPTPAY:
            result = result && this.validateRef() && !!this.payplusPromptpay;
            break;
          case SOURCE_OF_FUND.PAYPLUS_APPSWITCH:
            result = result && this.validateRef() && !!this.payplusAppSwitch;
            break;
          case SOURCE_OF_FUND.CARD_FULL:
            result = result && !!this.midCardFull;
            break;
        }
      }
    });
    return result;
  }

  private validateRef(): boolean {
    let required1 = true, required2 = true, required3 = true;
    this.referenceFields.forEach(ref => {
      // case required is true -> check name and value | required is false -> valid is true
      switch (ref.fieldName) {
        case 'refName1':
          required1 = this.createPaymentLinkResponse.requireRef1 ? (ref.name && ref.value
            && this.checkErrorFormGroup(ref) ? true : false) : true;
          break;
        case 'refName2':
          required2 = this.createPaymentLinkResponse.requireRef2 ? (ref.name && ref.value
            && this.checkErrorFormGroup(ref) ? true : false) : true;
          break;
        case 'refName3':
          required3 = this.createPaymentLinkResponse.requireRef3 ? (ref.name && ref.value
            && this.checkErrorFormGroup(ref) ? true : false) : true;
          break;
      }
    });
    return required1 && required2 && required3;
  }

  private checkErrorFormGroup(ref: ReferenceField) {
    return !(this.formGroup.get(ref.fieldName).errors || this.formGroup.get(ref.fieldValue).errors) || !this.isErrorRefValue(ref)
  }

  private validateRange(field: string) {
    this.activeDateRangeError = field == 'activeDateFC' ? null : this.activeDateRangeError;
    this.expireDateRangeError = field == 'expireDateFC' ? null : this.expireDateRangeError;
    if (this.formGroup.controls.activeDateFC.value && this.formGroup.controls.expireDateFC.value) {
      var activeDate = this.parseToDate(this.formGroup.controls.activeDateFC.value);
      const activeTime = this.formGroup.controls.activeTime.value.split(":");
      activeDate.setHours(+activeTime[0], +activeTime[1]);

      const expireDate = this.parseToDate(this.formGroup.controls.expireDateFC.value);
      const expireTime = this.formGroup.controls.expireTime.value.split(":");
      expireDate.setHours(+expireTime[0], +expireTime[1]);

      const maxActiveDate = this.maxActiveDate ? new Date(DateUtil.ngbDateStructToDate(this.maxActiveDate).setHours(23, 59)) : null;
      const maxExpireDate = this.maxExpireDate ? new Date(DateUtil.ngbDateStructToDate(this.maxExpireDate).setHours(23, 59)) : null;

      if (maxActiveDate && field == 'activeDateFC' && activeDate.getTime() > maxActiveDate.getTime()) {
        this.activeDateRangeError = PAYMENT_LINK_ERROR_MESSAGE.activeDateThanMoreDateAllow.replace('${DAYS}', this.maxActiveDays.toString());
        return
        }
      if (maxExpireDate && field == 'expireDateFC' && expireDate.getTime() > maxExpireDate.getTime()) {
        this.expireDateRangeError = PAYMENT_LINK_ERROR_MESSAGE.expireDateThanMoreDateAllow.replace('${DAYS}', this.maxExpireDays.toString());
        return
      }

      if (activeDate.getTime() >= expireDate.getTime()) {
        if (field == 'activeDateFC') {
          this.activeDateRangeError = PAYMENT_LINK_ERROR_MESSAGE.activeDateRangeError;
        } else {
          this.expireDateRangeError = PAYMENT_LINK_ERROR_MESSAGE.expireDateRangeError;
        }
      }
    }
  }

  private parseToDate(value): Date {
    if (!value) {
      return null;
    }
    if (value instanceof Object) {
      return DateUtil.ngbDateStructToDate(value);
    } else {
      return new Date(DateUtil.convDateToMMDDYYY(value));
    }
  }

  private getMaxDateAndAmount() {
    this.paymentLinkService.getMaxDateAndAmount().subscribe((res) => {
      if (this.commonService.isSuccess(res)) {
        this.maxAmount = +res.properties.maxAllowedAmount;
        this.maxActiveDays = res.properties.maxAllowedActiveDate;
        this.maxExpireDays = res.properties.maxAllowedExpireDate;
        const maxActive = DateUtil.subtractDay(new Date(), -(this.maxActiveDays - 1));
        const maxExpire = DateUtil.subtractDay(new Date(), -(this.maxExpireDays - 1));
        this.maxActiveDate = DateUtil.dateToNgbDateStructCustom(maxActive);
        this.maxExpireDate = DateUtil.dateToNgbDateStructCustom(maxExpire);
      }
      this.loadPage = true;
      this.commonService.isLoading(false);
    }, err => {
      this.loadPage = true;
      this.commonService.isLoading(false);
    })
  }

  private validateAmount (val) {
    this.invalidAmountError = null;
    this.emptyAmountError = null;
    if (val && (+val < 1)) {
      this.invalidAmountError = PAYMENT_LINK_ERROR_MESSAGE.amountGreaterThanOrEqualToOneError;
    }
    if (val && (+val > this.maxAmount) && (this.formGroup.get('currency').value == 'THB')) {
      this.invalidAmountError = PAYMENT_LINK_ERROR_MESSAGE.amountGreaterThanLimitPerTransactionError;
    }
    if(val && (+val > MAX_POSSIBLE_AMOUNT)){
      this.invalidAmountError = PAYMENT_LINK_ERROR_MESSAGE.amountGreaterThanLimitPerTransactionError;
    }
    if(this.paymentLink.type == PAYMENT_LINK_TYPE.ONE_TIME && !val) {
      this.emptyAmountError = PAYMENT_LINK_ERROR_MESSAGE.emptyAmountdError;
    }
    if ((this.formGroup.get('currency').value == 'JPY' || this.formGroup.get('currency').value == 'KRW') && val && val % 1 != 0) {
      this.invalidAmountError = PAYMENT_LINK_ERROR_MESSAGE.invalidSmallAmountError;
    }
  }

  // new form control for reference fields
  private createReferenceFieldsForm(ref, addRequireValidator?) {
    let require = ref.fieldName === 'refName1' ? this.createPaymentLinkResponse.requireRef1
      : ref.fieldName === 'refName2' ? this.createPaymentLinkResponse.requireRef2
        : this.createPaymentLinkResponse.requireRef3;
    this.formGroup.setControl(ref.fieldName, new FormControl(ref.name, { validators: [...addRequireValidator || require ? [Validators.required] : [], this.validatorName] }));
    this.formGroup.setControl(ref.fieldValue, new FormControl(ref.value, {
      validators: [...addRequireValidator || require ? [Validators.required] : [],
      Validators.minLength(ref.minLength ? ref.minLength : 1), Validators.maxLength(ref.maxLength ? ref.maxLength : 20), ref.dataType === DATA_TYPES.NUMERIC ? Validators.pattern(NUMBER_PATTERN) : Validators.pattern(REF_NUMBER_PATTERN)]
    }));
  }

  ngOnDestroy(): void {
    this.clearObservableCacheOfClonePL();
  }
}

