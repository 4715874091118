import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CommonService } from '../../services/common.service';
import { VerifyPaymentLinkService } from '../../services/verify-payment-link.service';
import { VerifyPaymentLinkModel } from './../../services/models/verify-payment-link.model';


@Component({
  selector: 'app-verify-payment-link',
  templateUrl: './verify-payment-link.component.html',
  styleUrls: [
    './verify-payment-link.component.css',
    '../../../assets/verify-link/assets/css/fonts.css',
    '../../../assets/verify-link/assets/css/font-icokbank.css',
    '../../../assets/verify-link/assets/js/magnific-popup/magnific-popup.css',
    '../../../assets/verify-link/assets/css/theme-rwd.css',
    '../../../assets/verify-link/assets-new/css/theme.css',
    '../../../assets/verify-link/k-pgw/css/style.css'
],
  encapsulation: ViewEncapsulation.None
})
export class VerifyPaymentLinkComponent implements OnInit {
  public captchaSiteKey: string;
  public captchaToken: string;
  public verifyStatus: string;

  public isCaptchaDisplayed = true;
  public isVerifyStatusDisplayed = false;
  public isVerifyButtonEnabled = false;
  public isVerifyButtonDisplayed = true;
  public isResetButtonDisplayed = false;
  public isVerified: boolean;

  verifyPaymentLinkForm: FormGroup;

  constructor(
    private verifyPaymentLinkService: VerifyPaymentLinkService,
    private commonService: CommonService,
    private ref: ChangeDetectorRef,
    private titleService:Title
  ) { }

  ngOnInit() {
//     this.loadScript('./assets/verify-link/assets/js/magnific-popup/jquery.magnific-popup.min.js');
//     this.loadScript('./assets/verify-link/k-pgw/js/style.js');
    // this.loadScript('./assets/verify-link/assets/js/jquery/jquery-1.12.1.min.js');
    this.loadScript('./assets/verify-link/assets/js/jquery/jquery.easing.1.3.min.js');
    this.titleService.setTitle('Payment Link - ธนาคารกสิกรไทย');

    this.captchaSiteKey = this.verifyPaymentLinkService.getCaptchaSiteKey();
    this.loadGoogleReCaptchaScript();
    this.setupFormGroup();
    this.captchaToken = null;
    this.isVerified = false;

    window['callbackCaptchaSuccess'] = this.callbackCaptchaSuccess.bind(this);
    window['callbackCaptchaError'] = this.callbackCaptchaError.bind(this);
    window['callbackCaptchaExpired'] = this.callbackCaptchaExpired.bind(this);
  }

  private loadGoogleReCaptchaScript() {
    const header = <HTMLHeadElement> document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.google.com/recaptcha/enterprise.js';
    script.async = true;
    script.defer = true;
    header.appendChild(script);
  }

  setupFormGroup() {
    this.verifyPaymentLinkForm = new FormGroup({
      'paymentLink': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ])
    });
  }

  doVerify() {
    if (this.verifyPaymentLinkForm.valid && this.captchaToken) {
      this.commonService.isLoading(true);
      this.isVerified = true;
      const paymentLink = this.verifyPaymentLinkForm.get('paymentLink').value;
      console.log('# paymentLink=' + paymentLink);

      const verifyPaymentLinkModel = new VerifyPaymentLinkModel();
      verifyPaymentLinkModel.capt = this.captchaToken;
      verifyPaymentLinkModel.link = paymentLink;

      this.verifyPaymentLinkService.verifyPaymentLink(verifyPaymentLinkModel).subscribe(
        result => {
          if (this.commonService.isSuccess(result)) {
            console.log('Success result: ' + result.verifyStatus);
            this.verifyStatus = result.verifyStatus;
            this.isCaptchaDisplayed = false;
            this.isVerifyStatusDisplayed = true;
            this.isVerifyButtonEnabled = false;
            this.isVerifyButtonDisplayed = false;
            this.isResetButtonDisplayed = true;
          } else {
            console.log('Fail result: ' + result);
          }
          this.commonService.isLoading(false);
        }, error => {
          console.log('Error result: ' + error);
          this.commonService.isLoading(false);
        }, () => {
          //
          this.commonService.isLoading(false);
        }
      );
    }
  }

  doResetPaymentLink() {
    console.log('Do doResetPaymentLink..');

    this.isCaptchaDisplayed = true;
    this.isVerifyStatusDisplayed = false;
    this.isVerifyButtonEnabled = false;
    this.isVerifyButtonDisplayed = true;
    this.isResetButtonDisplayed = false;
    this.verifyStatus = null;
    this.isVerified = false;
    this.verifyPaymentLinkForm.reset();

    // reset captcha
    const elm = window['grecaptcha'];
    elm.enterprise.reset();
  }

  callbackCaptchaSuccess(captchaResponse: string) {
    console.log('Success! captchaResponse: ' + captchaResponse);
    this.captchaToken = captchaResponse;
    this.isVerifyButtonEnabled = true;
    this.ref.detectChanges();
  }

  callbackCaptchaError(captchaResponse: string) {
    console.log('Error! captchaResponse: ' + captchaResponse);
    this.captchaToken = captchaResponse;
    this.isVerifyButtonEnabled = false;
    this.ref.detectChanges();
  }

  callbackCaptchaExpired(captchaResponse: string) {
    console.log('Expired! captchaResponse: ' + captchaResponse);
    this.captchaToken = captchaResponse;
    this.isVerifyButtonEnabled = false;
    this.ref.detectChanges();
  }

  loadScript(src) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(script);
    script.src = src;
  }

}
