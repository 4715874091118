import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../shared/app-constant';
import { AuthenticationService } from './auth/authentication.service';
import { BulkPaymentDownloadFileResponse } from './models/bulk-payment-download-file-response.model';
import { BulkRefundFilterResponse } from './models/bulk-payment-filter-response.model';
import { BulkPaymentFilter } from './models/bulk-payment-filter.model';
import { BulkPaymentUploadFileRequest } from './models/bulk-payment-upload-file-request.model';
import { GenerateBulkPaymentIdResponse } from './models/generate-bulk-payment-id-response.model';
import { ResultModel } from './models/result.model';

@Injectable({
  providedIn: 'root'
})
export class BulkPaymentService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public search(request: BulkPaymentFilter): Observable<BulkRefundFilterResponse> {
    const url = environment.bulkPaymentSearch;
    return this.http.post(url, request).pipe(map((response: BulkRefundFilterResponse) => {
      return response
    }));
  }

  public deleteFile(bulkPaymentId: string): Observable<ResultModel> {
    const url = environment.deleteBulkPaymentFile
      .replace("{bulkPaymentId}", bulkPaymentId);
    return this.http.post(url, {})
      .pipe(map((response: ResultModel) => {
        return response
      }));
  }

  public cancelFile(bulkPaymentId: string): Observable<ResultModel> {
    const url = environment.cancelBulkPaymentFile
      .replace("{bulkPaymentId}", bulkPaymentId);
    return this.http.post(url, {})
      .pipe(map((response: ResultModel) => {
        return response
      }));
  }

  public downloadFile(bulkPaymentId: string): Observable<BulkPaymentDownloadFileResponse> {
    const url = environment.downloadBulkPaymentFile
      .replace("{bulkPaymentId}", bulkPaymentId);
    return this.http.post(url, {})
      .pipe(map((response: BulkPaymentDownloadFileResponse) => {
        return response;
      }))
  }

  public generateBulkPaymentId(): Observable<GenerateBulkPaymentIdResponse> {
    return this.http.get(environment.generateBulkPaymentId)
      .pipe(map((response: GenerateBulkPaymentIdResponse) => {
        return response;
      }))
  }

  public uploadFile(bulkPaymentUploadFileRequest: BulkPaymentUploadFileRequest): Observable<ResultModel> {
    const currentUser = this.auth.getCurrentUserInfo();
    const params = new FormData();
    params.append('file', new Blob([bulkPaymentUploadFileRequest.file], { type: bulkPaymentUploadFileRequest.fileType }), bulkPaymentUploadFileRequest.file.name);
    params.append('batchId', bulkPaymentUploadFileRequest.bulkPaymentId);
    params.append('userId', currentUser.userId.toString());
    return this.http.post(environment.bulkPaymentUploadFile, params)
      .pipe(map((response: ResultModel) => {
        return response
      }));
  }
}
