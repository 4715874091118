import { Component, OnInit, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RegistrationService } from '../../services/registration.service';

import { ForgotPasswordRequestModel } from '../../services/models/forgot-password-request.model';
import { ResultModel } from '../../services/models/result.model';
import { RequestResetPasswordModel } from '../../services/models/request-reset-password.model';

@Component({
  selector: 'app-forgot-password-request',
  templateUrl: './forgot-password-request.component.html',
  styleUrls: ['./forgot-password-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordRequestComponent implements OnInit {
  formSubmitAttempt: boolean;
  forgotPasswordRequestForm: FormGroup;

  constructor(private commonService: CommonService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService) {
  }

  ngOnInit() {
    /* Init form */
    this.forgotPasswordRequestForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ])
    });
  }

  clearInfo() {
    this.forgotPasswordRequestForm.reset();
    this.formSubmitAttempt = false;
  }

  onSubmit() {
    this.formSubmitAttempt = true;

    if (this.forgotPasswordRequestForm.valid) {
      console.log('forgot-password-request submit!');
      this.requestResetPassword();
    }
  }

  requestResetPassword() {
    this.commonService.isLoading(true);

    const requestResetPasswordModel = new RequestResetPasswordModel();
    requestResetPasswordModel.email = this.forgotPasswordRequestForm.get('email').value;
    console.log('requestResetPasswordModel.email: ' + requestResetPasswordModel.email);

    this.registrationService.requestResetPassword(requestResetPasswordModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('We sent you a reset password path via your email: ' + requestResetPasswordModel.email);
          this.clearInfo();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  isFieldInvalid(field: string) {
    const aField = this.forgotPasswordRequestForm.get(field);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }
}
