export class RefundConfirmContentModel {
    settledAmount: string;
    amount: string;
    currencyCode: string;
    isPartialRefund: boolean;
    paymentTypeCode: string;
    cardBrand: string;
    disablePartialRefund: boolean;
    isInstallmentCrossBank?: boolean;
}


