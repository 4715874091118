import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../shared/app-constant';
import { AuthenticationService } from './auth/authentication.service';
import { MultipleRefundConfirmation, RefundConfirmationRequest, RefundConfirmationResponse } from './models/multiple-refund-confirmation.model';
import { ResultModel } from './models/result.model';

@Injectable({
  providedIn: 'root'
})
export class MultipleRefundConfirmationService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
  ) { }

  getRefundConfirm(request : RefundConfirmationRequest) : Observable<RefundConfirmationResponse>{
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(environment.getRefundConfirm.replace('{userId}', currentUser.userId.toString()),
    JSON.stringify(request)).pipe(
    map((response: RefundConfirmationResponse) => {
        return response;
    }));
  }

  submitMultipleRefund(request: MultipleRefundConfirmation): Observable<ResultModel> {
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(environment.submitMultipleRefund.replace('{userId}', currentUser.userId.toString()),
      JSON.stringify(request)).pipe(
        map((response: ResultModel) => { return response }))
  }
}
