import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalModel } from './../../services/models/modal.model';
import { SettleConfirmResultModel } from './../../services/models/settle-confirm-result.model';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-settled-confirm-modal',
  templateUrl: './settled-confirm-modal.component.html',
  styleUrls: ['./settled-confirm-modal.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SettledConfirmModalComponent implements OnInit {
  @Input() modalConfirmModel: ModalModel;
  @Output() modalConfirmModelChange = new EventEmitter<SettleConfirmResultModel>();

  isShowPartialAmount: boolean;
  confirmModel: SettleConfirmResultModel;
  isPartialAmountDisabled = false;

  constructor() { }

  ngOnInit() {
    console.log(this.modalConfirmModel);

    this.isShowPartialAmount = false;
    this.confirmModel = new SettleConfirmResultModel();
    this.confirmModel.type = 'full'; // 'full' or 'partial'
    this.confirmModel.amount = null;
    if (this.modalConfirmModel.content.paymentTypeCode) {
      this.isPartialAmountDisabled = this.modalConfirmModel.content.paymentTypeCode === 'SMARTPAY';
    }
    this.modalConfirmModelChange.emit(this.confirmModel);
  }

  handleChange(evt) {
    const target = evt.target;
    if (target) {
      const value = target.value;
      if (value === 'partial') {
        this.isShowPartialAmount = true;
        this.confirmModel.amount = null;
      } else {
        this.isShowPartialAmount = false;
      }
    }
  }

  // checkCurrency(event) {
  //   let result = true;
  //   console.log(event);
  //   if (this.isNoDecimalPointCurrency()) {
  //     const charCode = (event.which) ? event.which : event.keyCode;
  //     if (charCode > 31 && (charCode < 48 || charCode > 57)) { // Number only
  //       result = false;
  //     }
  //   }
  //   return result;
  // }

  isNoDecimalPointCurrency(): boolean {
    return this.modalConfirmModel
      && this.modalConfirmModel.content
      && (this.modalConfirmModel.content.currencyCode === 'JPY'
        || this.modalConfirmModel.content.currencyCode === 'KRW');
  }

  keyupDecimalPoint() {
    console.log(this.confirmModel.amount);
    if (Number.parseFloat(this.confirmModel.amount) != 0 && Number.parseFloat(this.confirmModel.amount) < 0.01) {
      this.confirmModel.amount = '0';
      return;
    }
    const amount = '' + this.confirmModel.amount;
    const index = amount.indexOf('.');
    if (index >= 0) {
      if (this.isNoDecimalPointCurrency()) {
        this.confirmModel.amount = amount.substring(0, index);
      } else {
        this.confirmModel.amount = amount.substring(0, index + 3);
      }
    }
  }
}
