import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { CommonService } from '../../services/common.service';
import { GroupList, MultipleRefundResponse, MultipleTrxInfos, MultipleRefundRequestModel, PageInfo } from '../../services/models/multiple-refund-response.model';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { MultipleRefundService } from '../../services/multiple-refund.service';
import { CACHE_OBSERVABLE_SERVICE, message } from '../../shared/app-constant';
import { cloneObject } from '../../shared/common.util';
import { AdvanceSearchFormInterface } from '../advancesearch/advance-search-form-interface';

export const BUTTON_CONFIRM_LABEL = 'Continue'

@Component({
  selector: 'app-multiple-refund',
  templateUrl: './multiple-refund.component.html',
  styleUrls: ['./multiple-refund.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MultipleRefundComponent implements OnInit, OnDestroy {

  public request: MultipleRefundRequestModel;
  public multipleRefundResponse: MultipleRefundResponse;
  public groupList: GroupList[] = [];
  public limit: number;
  public totalDays: number;
  public collectionSize: number;
  public isCancelSearch: boolean;
  public selectedList = [];
  public selectedListValue = {};
  public selectAll: boolean = false;

  public selectedTrxIds: string[] = [];
  private isConfirmRefund: boolean = false;
  private isBack: boolean = false;

  selectListConfig = [
    'cardNo',
    'productDescription',
    'sourceType',
    'sourceOfFund',
    'paymentType',
    'cardType',
    'bankName',
    'refNumber',
    'objectId',
    'secureTransaction',
    'refTrxId',
    'currencyCode',
    'amount',
    'transactionDate',
    'company',
    'mobileNo',
    'merchantId',
    'refundId',
    'ref1',
    'ref2',
    'ref3',
    'paymentLinkRef',
    'supplierNo',
    'channel',
    'bulkRefundId'
  ];

  constructor(
    private commonService: CommonService,
    private router: Router,
    private multipleRefundService: MultipleRefundService,
    private auth: AuthenticationService,
    private cacheObservableService: CacheObservableService
  ) { }

  ngOnInit() {
    if(this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS)) {
      const trxIds = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS);
      this.selectedTrxIds = trxIds ? cloneObject(trxIds) : [];
    }
    if(this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA)) {
      this.request = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA);
      this.selectedList = this.request && this.request.transactionReq.selectedSearchList ? cloneObject(this.request.transactionReq.selectedSearchList) : [];
      this.selectedListValue = this.request && this.request.transactionReq.selectedListValue ? cloneObject(this.request.transactionReq.selectedListValue) : [];
    } else {
      this.request = this.setRequestTransaction();
    }
    this.initData();
  }

  showAdvanceSearch() {
    this.commonService.visibleSearch(true);
  }

  refund() {
    this.isConfirmRefund = true;
    if (!this.selectedTrxIds || this.selectedTrxIds.length == 0) {
      return
    }
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS, this.selectedTrxIds);
    this.router.navigate(['/multiple-selection/multiple-refund-confirmation']);
  }

  onApplyFilter(event: { request: TransactionRequestModel, selectedCriteria: Array<any>, selectedCriteriaValue: AdvanceSearchFormInterface, isCancel?: boolean }) {
    if (event.isCancel) {
      this.isCancelSearch = event.isCancel;
      return;
    }
    this.selectAll = false;
    this.selectedTrxIds = [];
    this.isCancelSearch = false;
    this.request.transactionReq = event.request;
    this.request.transactionReq.pageIndex = 1;
    this.request.selectedTrxIds = this.selectedTrxIds;
    this.selectedList = event.selectedCriteria;
    this.selectedListValue = event.selectedCriteriaValue;
    this.request.transactionReq.selectedSearchList = JSON.parse(JSON.stringify(this.selectedList));
    this.request.transactionReq.selectedListValue = JSON.parse(JSON.stringify(this.selectedListValue));
    this.getTransaction();
  }

  changeCheckbox(transaction: MultipleTrxInfos) {
    if (transaction.isSelected && this.selectedTrxIds && this.selectedTrxIds.length >= this.multipleRefundResponse.maxMultipleRefund) {
      transaction.isSelected = false;
      this.showWarning();
      return
    }
    if (this.selectedTrxIds && (!this.selectedTrxIds.find(trxId => trxId === transaction.trxId) || this.selectedTrxIds.length == 0) && transaction.isSelected) {
      this.selectedTrxIds.push(transaction.trxId);
      this.countSelectedAll()
    } else {
      this.selectedTrxIds = this.selectedTrxIds.filter(trxId => trxId != transaction.trxId);
      this.selectAll = false;
    }
  }

  changeSelectAll() {
    var countSelectedRecords = 0;
    this.groupList.forEach(group => {
      group.trxInfos.forEach((trxInfo) => {
        if(!this.selectedTrxIds.some(trxId => trxInfo.trxId === trxId)){
          countSelectedRecords ++;
        }
      });
    });
    if (this.selectedTrxIds.length + countSelectedRecords > this.multipleRefundResponse.maxMultipleRefund && this.selectAll) {
      this.showWarning();
    } else {
      this.groupList.forEach(group => {
        group.trxInfos.forEach(trxInfo => {
          trxInfo.isSelected = this.selectAll;
          if (trxInfo.isSelected) {
            if(!this.selectedTrxIds.some(trxId => trxId == trxInfo.trxId)) {
              this.selectedTrxIds.push(trxInfo.trxId);
            }
          } else {
            this.selectedTrxIds = this.selectedTrxIds.filter(trxId => trxId != trxInfo.trxId);
          }
        });
      });
    }
  }

  loadPage(pageIndex: number) {
    this.request.transactionReq.pageIndex = cloneObject(pageIndex);
    this.getTransaction();
  }

  goBack() {
    this.isBack = true;
    this.router.navigateByUrl('/multiple-selection');
  }

  private initData() {
    this.getTransaction();
  }

  private setRequestTransaction(): MultipleRefundRequestModel {
    const request = new MultipleRefundRequestModel();
    request.transactionReq = new TransactionRequestModel();
    const dateRef1 = new Date();
    const dateRef2 = new Date();
    dateRef1.setHours(0, 0, 0, 0);
    dateRef2.setHours(23, 59, 59, 0);
    request.transactionReq.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });

    request.transactionReq.trxState = [];
    request.transactionReq.pageIndex = 1;
    request.transactionReq.pageSize = 20;
    request.transactionReq.searchDate = []
    request.transactionReq.searchDate.push({
      searchTypeDate: 'created',
      dateRef1: dateRef1,
      dateRef2: dateRef2
    });
    request.selectedTrxIds = [];

    return request;
  }

  private getTransaction() {
    this.commonService.isLoading(true);
    this.request.selectedTrxIds = this.selectedTrxIds;
    const clone = JSON.parse(JSON.stringify(this.request));
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA, clone);
    this.multipleRefundService.getMultipleRefundSelect(this.request).subscribe(
      result => {
        this.clearTransactionList();
        if (this.commonService.isSuccess(result)) {
          this.multipleRefundResponse = result;
          this.selectedTrxIds = this.multipleRefundResponse.validSelectedTrxIds && this.multipleRefundResponse.validSelectedTrxIds.length > 0 ? this.multipleRefundResponse.validSelectedTrxIds : [];
          this.groupingTransaction();
        }
        this.limit = result && result.totalLimit ? result.totalLimit : 0;
        this.totalDays = result && result.totalDays ? result.totalDays : 0;
        this.commonService.isLoading(false);
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }


  private groupingTransaction() {
    this.collectionSize = +this.multipleRefundResponse.totalElements;
    for (const group of this.multipleRefundResponse.trxInfoSummaries) {
      const groupName = group.groupDate.split(' ').shift();

      if (!this.groupList.find(x => x.groupName === groupName)) {
        const transactionList = this.multipleRefundResponse.multipleRefundTrxInfos.filter(m =>
          m.createDate.split(' ').shift() === groupName);

        transactionList.forEach(transaction => {
          transaction.isSelected = this.selectedTrxIds.some(trxId => trxId == transaction.trxId);
        });
        var modelGroup: GroupList = new GroupList();
        modelGroup.groupDate = group.groupDate;
        modelGroup.groupName = groupName;
        modelGroup.trxInfos = transactionList;
        this.groupList.push(modelGroup);
      }
    }
    this.countSelectedAll();
  }

  private clearTransactionList() {
    this.collectionSize = 0;
    this.groupList = [];
  }

  private countSelectedAll() {
    var selectedRecords = 0;
    var totalRecords = 0;
    this.groupList.forEach(group => {
      selectedRecords += group.trxInfos.filter(trxInfo => trxInfo.isSelected).length;
      totalRecords += group.trxInfos.length;
    });
    this.selectAll = selectedRecords == totalRecords;
  }

  private showWarning() {
    const callback = new Subject<any>();
      callback.asObservable().subscribe(result => {
        this.selectAll = false;
        this.getTransaction();
      });
      this.commonService.alertWarn(message.title.warning, message.content.max_multiple_refund.replace('{value}', this.multipleRefundResponse.maxMultipleRefund.toString()), callback, BUTTON_CONFIRM_LABEL);
  }

  ngOnDestroy(): void {
    if (!this.isConfirmRefund && !this.isBack) {
      this.cacheObservableService.clear();
    }
    if (this.isBack) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA);
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS);
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_PAGE_INFO);
    }
  }

}

