import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { PayPlusData } from '../../services/models/merchant-setting-payplus.model';

@Component({
  selector: 'app-merchant-configuration-payplus',
  templateUrl: './merchant-configuration-payplus.component.html',
  styleUrls: ['./merchant-configuration-payplus.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationPayplusComponent implements OnInit {

  @Input() companyId: string;

  isOpenPayPlusSof: boolean = false;
  payplusItem: PayPlusData[];
  loadFlag: boolean = false;

  constructor(
    private merchantSetting: MerchantSettingService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  onClickMoreDetail() {
    this.isOpenPayPlusSof = !this.isOpenPayPlusSof;
    if(this.loadFlag){
      return;
    }
    this.getMerchantPayPlus();
    this.loadFlag = true;
  }

  getMerchantPayPlus() {
    this.commonService.isLoading(true);
    this.merchantSetting.getMerchantSettingPayPlus(this.companyId).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.payplusItem = result.payplusData;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }
}
