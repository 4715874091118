import { trigger, transition, style, query, animate } from '@angular/animations';


export const fadeAnimation = trigger('fadeFrame', [
    transition('* => *', [
        query(
            ':enter',
            [
                style({ opacity: 0 })
            ],
            {optional: true}
        ),
        query(
            ':leave',
            [
                style({ opacity: 1 }),
                animate('50ms', style({ opacity: 0 }))
            ],
            {optional: true}
        ),
        query(
            ':enter',
            [
                style({ opacity: 0 }),
                animate('50ms', style({ opacity: 1 }))
            ],
            {optional: true}
        )
    ])
]);
