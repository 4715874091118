import { Request } from "./request";

export class PaymentLinkSearchRequest extends Request {
    linkRef: string;
    type: string[];
    status: string;
    serviceName: string;
    linkDesc: string;
    orderRef: string;
    paidTime: string;
    maxPaymentTime: string;
    activeDateOpt: string;
    activeTimeFrom: any;
    activeTimeTo: any;
    expireDateOpt: string;
    expireTimeFrom: any;
    expireTimeTo: any;
    createDateOpt: string;
    createDateFrom: any;
    createDateTo: any;
    amountOpr: string;
    amountRef1: string;
    amountRef2: string;

    constructor(
      linkRef?: string,
      serviceName?: string,
      linkDesc?: string,
      orderRef?: string,
      amountOpr?: string,
      createDateFrom?: string,
      createDateTo?: string,
      paidTime?: string,
      maxPaymentTime?: string,
      type?: string[],
      activeTimeFrom?: string,
      activeTimeTo?: string,
      expireTimeFrom?: string,
      expireTimeTo?: string,
      amountRef1?: string,
      amountRef2?: string,
      pageIndex?: number,
      pageSize?: number
      ){
        super(pageIndex, pageSize);
        this.linkRef = linkRef;
        this.serviceName = serviceName;
        this.linkDesc = linkDesc;
        this.orderRef = orderRef;
        this.amountOpr = amountOpr;
        this.createDateFrom = createDateFrom;
        this.createDateTo = createDateTo;
        this.paidTime = paidTime;
        this.maxPaymentTime = maxPaymentTime;
        this.type = type;
        this.activeTimeFrom = activeTimeFrom;
        this.activeTimeTo = activeTimeTo;
        this.expireTimeFrom = expireTimeFrom;
        this.expireTimeTo = expireTimeTo;
        this.amountOpr = amountOpr;
        this.amountRef1 = amountRef1;
        this.amountRef2 = amountRef2
      }
  }
