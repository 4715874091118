import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { LocationService } from './../../services/location.service';

import { ProvinceInfoRequestModel } from '../../services/models/province-info-request.model';
import { ProvinceInfoModel } from '../../services/models/province-info.model';
import { AmphurInfoModel } from '../../services/models/amphur-info.model';
import { DistrictInfoModel } from '../../services/models/district-info.model';
import { PostcodeInfoModel } from '../../services/models/postcode-info.model';

import { BusinessInfo, CardScheme, ContactAddress, MerchantSettingCompanyInfoResultModel } from './../../services/models/merchant-setting-company-info-result.model';
import { MerchantSettingCompanyInfoSaveModel } from './../../services/models/merchant-setting-company-info-save.model';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-merchant-setting-company-info-entry',
  templateUrl: './merchant-setting-company-info-entry.component.html',
  styleUrls: ['./merchant-setting-company-info-entry.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantSettingCompanyInfoEntryComponent implements OnInit {

  @Input() companyId: string;
  @Output() companyFetched = new EventEmitter<any>();

  faLock = faLock;

  private formSubmitAttempt: boolean;
  merchantSettingCompanyInfoEntryForm: FormGroup;

  editEnabled = false;
  editPermit = false;
  allowRecurringPayment = false;
  formValueChange = false;
  address1: string = '';
  address2: string = '';

  merchantSettingCompanyInfoEntry: MerchantSettingCompanyInfoResultModel;
  businessInfo: BusinessInfo;
  contactAddress: ContactAddress;
  originCompanyInfo: MerchantSettingCompanyInfoResultModel;

  provinces: ProvinceInfoModel[];
  amphurs: AmphurInfoModel[];
  districts: DistrictInfoModel[];
  postcodes: PostcodeInfoModel[];

  public cardScheme: CardScheme[];

  editableFieldList = ['companyIdName', 'companyNameTh', 'companyNameEn', 'tradingNameTh', 'tradingNameEn', 'firstName', 'lastName', 'email',
    'mobileNo', 'phoneHome', 'phoneOffice', 'fax', 'addressNo', 'buildingName', 'roomNo', 'floorNo', 'village',
    'soi', 'road', 'moo', 'provinceCode', 'amphurCode', 'districtCode', 'postalCode']

  constructor(private commonService: CommonService, private toasterService: ToasterService,
      private auth: AuthenticationService, private locationService: LocationService,
      private merchantSettingService: MerchantSettingService) {
  }

  ngOnInit() {
    this.commonService.isLoading(true);
    this.setupFormGroup();
    this.merchantSettingGetCompanyInfo();
    this.setupPermit();
  }

  setupFormGroup() {
    this.merchantSettingCompanyInfoEntryForm = new FormGroup({
      'companyIdName': new FormControl(null),
      'companyNameTh': new FormControl(null),
      'companyNameEn': new FormControl(null),
      'tradingNameTh': new FormControl(null),
      'tradingNameEn': new FormControl(null),
      'firstName': new FormControl(null),
      'lastName': new FormControl(null),
      'email': new FormControl(null),
      'mobileNo': new FormControl(null),
      'phoneHome': new FormControl(null),
      'phoneOffice': new FormControl(null),
      'fax': new FormControl(null),
      'addressNo': new FormControl(null, [
        Validators.maxLength(20),
        this.addressNoValidator()
      ]),
      'buildingName': new FormControl(null, [
        Validators.maxLength(40),
        Validators.pattern(CommonService.VALID_BUILDING_NAME_INPUT_CHARACTERS)
      ]),
      'roomNo': new FormControl(null, [
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_ROOM_NO_INPUT_CHARACTERS)
      ]),
      'floorNo': new FormControl(null, [
        Validators.maxLength(5),
        Validators.pattern(CommonService.VALID_ROOM_NO_INPUT_CHARACTERS)
      ]),
      'village': new FormControl(null, [
        Validators.maxLength(40),
        Validators.pattern(CommonService.VALID_BUILDING_NAME_INPUT_CHARACTERS)
      ]),
      'soi': new FormControl(null, [
        Validators.maxLength(40),
        Validators.pattern(CommonService.VALID_SOI_INPUT_CHARACTERS)
      ]),
      'road': new FormControl(null, [
        Validators.maxLength(40),
        Validators.pattern(CommonService.VALID_SOI_INPUT_CHARACTERS)
      ]),
      'moo': new FormControl(null, [
        Validators.maxLength(5),
        Validators.pattern(CommonService.VALID_MOO_INPUT_CHARACTERS)
      ]),
      'addressLine1': new FormControl(null, [
        Validators.maxLength(200),
      ]),
      'addressLine2': new FormControl(null, [
        Validators.maxLength(200),
      ]),
      'provinceCode': new FormControl(null, [
        Validators.required
      ]),
      'amphurCode': new FormControl(null, [
        Validators.required
      ]),
      'districtCode': new FormControl(null, [
        Validators.required
      ]),
      'postalCode': new FormControl(null, [
        Validators.required
      ])
    });
    this.editableFieldList.forEach((field) => {
      this.merchantSettingCompanyInfoEntryForm.get(field).valueChanges.subscribe((value) => {
        this.merchantSettingCompanyInfoEntry[field] = value;
      })
    });
  }

  private merchantSettingGetCompanyInfo() {
    this.merchantSettingService.getCompanyInfo(this.companyId).subscribe(
      result => {
        this.merchantSettingCompanyInfoEntry = result;
        this.businessInfo = this.merchantSettingCompanyInfoEntry.businessInfo;
        this.contactAddress = this.merchantSettingCompanyInfoEntry.contactAddress;
        this.cardScheme = this.merchantSettingCompanyInfoEntry.businessInfo.cardSchemes;
        this.merchantSettingCompanyInfoEntry.enableEWallet ? this.merchantSettingCompanyInfoEntryForm.disable() : this.merchantSettingCompanyInfoEntryForm.enable()
        if (this.cardScheme && this.cardScheme.length > 0) {
          this.cardScheme.map((scheme) => {
            scheme.cardBrandName = scheme.cardBrandName.toLocaleUpperCase();
          });
        }
        this.originCompanyInfo = JSON.parse(JSON.stringify(result.businessInfo));
        this.companyFetched.emit(this.originCompanyInfo);
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private getCompanyIsSyncEWallet() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getCompanyIsSyncEWallet(this.companyId).subscribe(
      result => {
        result ? this.merchantSettingCompanyInfoEntryForm.disable() : this.merchantSettingCompanyInfoEntryForm.enable()
        this.editEnabled = true;
        this.formSubmitAttempt = false;
        this.buildCompanyInfoSettingForm();
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private buildCompanyInfoSettingForm() {
    if (this.merchantSettingCompanyInfoEntry) {
      this.merchantSettingCompanyInfoEntryForm.get('companyIdName').patchValue(this.businessInfo.companyIdName);
      this.merchantSettingCompanyInfoEntryForm.get('companyNameTh').patchValue(this.businessInfo.companyNameTh);
      this.merchantSettingCompanyInfoEntryForm.get('companyNameEn').patchValue(this.businessInfo.companyNameEn);
      this.merchantSettingCompanyInfoEntryForm.get('tradingNameTh').patchValue(this.businessInfo.tradingNameTh);
      this.merchantSettingCompanyInfoEntryForm.get('tradingNameEn').patchValue(this.businessInfo.tradingNameEn);
      this.merchantSettingCompanyInfoEntryForm.get('firstName').patchValue(this.businessInfo.firstName);
      this.merchantSettingCompanyInfoEntryForm.get('lastName').patchValue(this.businessInfo.lastName);
      this.merchantSettingCompanyInfoEntryForm.get('email').patchValue(this.businessInfo.email);
      this.merchantSettingCompanyInfoEntryForm.get('mobileNo').patchValue(this.businessInfo.mobileNo);
      this.merchantSettingCompanyInfoEntryForm.get('phoneHome').patchValue(this.businessInfo.phoneHome);
      this.merchantSettingCompanyInfoEntryForm.get('phoneOffice').patchValue(this.businessInfo.phoneOffice);
      this.merchantSettingCompanyInfoEntryForm.get('fax').patchValue(this.businessInfo.fax);
      this.merchantSettingCompanyInfoEntryForm.get('addressNo').patchValue(this.contactAddress.addressNo);
      this.merchantSettingCompanyInfoEntryForm.get('buildingName').patchValue(this.contactAddress.buildingName);
      this.merchantSettingCompanyInfoEntryForm.get('roomNo').patchValue(this.contactAddress.roomNo);
      this.merchantSettingCompanyInfoEntryForm.get('floorNo').patchValue(this.contactAddress.floorNo);
      this.merchantSettingCompanyInfoEntryForm.get('village').patchValue(this.contactAddress.village);
      this.merchantSettingCompanyInfoEntryForm.get('soi').patchValue(this.contactAddress.soi);
      this.merchantSettingCompanyInfoEntryForm.get('road').patchValue(this.contactAddress.road);
      this.merchantSettingCompanyInfoEntryForm.get('moo').patchValue(this.contactAddress.moo);
      this.merchantSettingCompanyInfoEntryForm.get('addressLine1').patchValue(this.contactAddress.address1);
      this.merchantSettingCompanyInfoEntryForm.get('addressLine2').patchValue(this.contactAddress.address2);
      this.merchantSettingCompanyInfoEntryForm.get('provinceCode').patchValue(this.contactAddress.provinceCode);
      this.merchantSettingCompanyInfoEntryForm.get('amphurCode').patchValue(this.contactAddress.districtCode);
      this.merchantSettingCompanyInfoEntryForm.get('districtCode').patchValue(this.contactAddress.subDistrictCode);
      this.merchantSettingCompanyInfoEntryForm.get('postalCode').patchValue(this.contactAddress.postalId);

      /* Init dropdown - ProvinceInfo */
      this.getProvinces();
      if (this.contactAddress.provinceCode) {
        this.getAmphurs(this.contactAddress.provinceCode);
      }
      if (this.contactAddress.provinceCode && this.contactAddress.districtCode) {
        this.getDistricts(this.contactAddress.provinceCode, this.contactAddress.districtCode);
      }
      if (this.contactAddress.provinceCode && this.contactAddress.districtCode && this.contactAddress.subDistrictCode) {
        this.getPostcode(this.contactAddress.provinceCode, this.contactAddress.districtCode, this.contactAddress.subDistrictCode);
      }
    }
  }

  private setupPermit() {
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      const menuInfo = menuInfos.find(m => m.screenCode === 'M009');
      if (menuInfo) {
        const componentsInfos = menuInfo.componentsInfo;
        if (componentsInfos) {
          componentsInfos.forEach(componentsInfo => {
            if (componentsInfo.roleActionCode === 'ROLE_B_2901') {
              this.editPermit = true;
            }
          });
        }
      }
    }
  }

  doEdit() {
    this.getCompanyIsSyncEWallet();
  }

  doSave() {
    if (this.formValueChange) {
      this.formSubmitAttempt = true;
      if (this.merchantSettingCompanyInfoEntryForm.valid) {
        this.merchantSettingSaveCompanyInfo();
      }
    } else {
      this.doCancelEdit();
    }
  }

  doCancelEdit() {
    this.merchantSettingCompanyInfoEntry = JSON.parse(JSON.stringify(this.originCompanyInfo));
    this.editEnabled = false;
    this.formSubmitAttempt = false;
    this.merchantSettingCompanyInfoEntryForm.reset();
    this.merchantSettingCompanyInfoEntryForm.controls['addressNo'].setValidators([
      Validators.maxLength(20),
      this.addressNoValidator()
    ]);
    this.formValueChange = false;
  }

  private merchantSettingSaveCompanyInfo() {
    this.commonService.isLoading(true);

    const merchantSettingCompanyInfoSaveModel = new MerchantSettingCompanyInfoSaveModel();
    merchantSettingCompanyInfoSaveModel.companyId = this.companyId;
    merchantSettingCompanyInfoSaveModel.companyIdName = this.merchantSettingCompanyInfoEntryForm.get('companyIdName').value;
    merchantSettingCompanyInfoSaveModel.companyNameTh = this.merchantSettingCompanyInfoEntryForm.get('companyNameTh').value;
    merchantSettingCompanyInfoSaveModel.companyNameEn = this.merchantSettingCompanyInfoEntryForm.get('companyNameEn').value;
    merchantSettingCompanyInfoSaveModel.tradingNameTh = this.merchantSettingCompanyInfoEntryForm.get('tradingNameTh').value;
    merchantSettingCompanyInfoSaveModel.tradingNameEn = this.merchantSettingCompanyInfoEntryForm.get('tradingNameEn').value;
    merchantSettingCompanyInfoSaveModel.firstName = this.merchantSettingCompanyInfoEntryForm.get('firstName').value;
    merchantSettingCompanyInfoSaveModel.lastName = this.merchantSettingCompanyInfoEntryForm.get('lastName').value;
    merchantSettingCompanyInfoSaveModel.email = this.merchantSettingCompanyInfoEntryForm.get('email').value;
    merchantSettingCompanyInfoSaveModel.mobileNo = this.merchantSettingCompanyInfoEntryForm.get('mobileNo').value;
    merchantSettingCompanyInfoSaveModel.phoneHome = this.merchantSettingCompanyInfoEntryForm.get('phoneHome').value;
    merchantSettingCompanyInfoSaveModel.phoneOffice = this.merchantSettingCompanyInfoEntryForm.get('phoneOffice').value;
    merchantSettingCompanyInfoSaveModel.fax = this.merchantSettingCompanyInfoEntryForm.get('fax').value;
    merchantSettingCompanyInfoSaveModel.addressLine1 = this.merchantSettingCompanyInfoEntryForm.get('addressLine1').value;
    merchantSettingCompanyInfoSaveModel.addressLine2 = this.merchantSettingCompanyInfoEntryForm.get('addressLine2').value;
    merchantSettingCompanyInfoSaveModel.postalId = this.merchantSettingCompanyInfoEntryForm.get('postalCode').value;
    merchantSettingCompanyInfoSaveModel.addressNo = this.merchantSettingCompanyInfoEntryForm.get('addressNo').value;
    merchantSettingCompanyInfoSaveModel.buildingName = this.merchantSettingCompanyInfoEntryForm.get('buildingName').value;
    merchantSettingCompanyInfoSaveModel.roomNo = this.merchantSettingCompanyInfoEntryForm.get('roomNo').value;
    merchantSettingCompanyInfoSaveModel.floorNo = this.merchantSettingCompanyInfoEntryForm.get('floorNo').value;
    merchantSettingCompanyInfoSaveModel.village = this.merchantSettingCompanyInfoEntryForm.get('village').value;
    merchantSettingCompanyInfoSaveModel.soi = this.merchantSettingCompanyInfoEntryForm.get('soi').value;
    merchantSettingCompanyInfoSaveModel.road = this.merchantSettingCompanyInfoEntryForm.get('road').value;
    merchantSettingCompanyInfoSaveModel.moo = this.merchantSettingCompanyInfoEntryForm.get('moo').value;
    // merchantSettingCompanyInfoSaveModel.wechatCallback = this.merchantSettingCompanyInfoEntryForm.get('wechatCallback').value;
    // merchantSettingCompanyInfoSaveModel.auth3dCallback = this.merchantSettingCompanyInfoEntryForm.get('auth3dCallback').value;
    // merchantSettingCompanyInfoSaveModel.threeDNotifyCallback = this.merchantSettingCompanyInfoEntryForm.get('threeDNotifyCallback').value;
    // merchantSettingCompanyInfoSaveModel.qrCallback = this.merchantSettingCompanyInfoEntryForm.get('qrCallback').value;
    // merchantSettingCompanyInfoSaveModel.upopCallback = this.merchantSettingCompanyInfoEntryForm.get('upopCallback').value;
    // merchantSettingCompanyInfoSaveModel.upopNotifyCallback = this.merchantSettingCompanyInfoEntryForm.get('upopNotifyCallback').value;
    // merchantSettingCompanyInfoSaveModel.alipayCallback = this.merchantSettingCompanyInfoEntryForm.get('alipayCallback').value;
    // merchantSettingCompanyInfoSaveModel.alipayNotifyCallback = this.merchantSettingCompanyInfoEntryForm.get('alipayNotifyCallback').value;

    this.merchantSettingService.saveCompanyInfo(merchantSettingCompanyInfoSaveModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Save company info setting.');

          this.editEnabled = false;
          // this.updateCompanyInfoEntryFormWhenSuccess();
          this.merchantSettingGetCompanyInfo();
          this.auth.renewCurrentUser();
        } else {
          this.toasterService.error(result.status_message);
          this.merchantSettingCompanyInfoEntry = JSON.parse(JSON.stringify(this.originCompanyInfo));
        }
      }, error => {
        this.merchantSettingCompanyInfoEntry = JSON.parse(JSON.stringify(this.originCompanyInfo));
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  private updateCompanyInfoEntryFormWhenSuccess() {
    // this.merchantSettingUser.firstNameTh = this.merchantSettingUserForm.get('firstNameTh').value;
    // this.merchantSettingUser.lastNameTh = this.merchantSettingUserForm.get('lastNameTh').value;
    // this.merchantSettingUser.firstNameEn = this.merchantSettingUserForm.get('firstNameEn').value;
    // this.merchantSettingUser.lastNameEn = this.merchantSettingUserForm.get('lastNameEn').value;
    // this.merchantSettingUser.mobileNo = this.merchantSettingUserForm.get('mobileNo').value;
    // this.merchantSettingUser.telNo = this.merchantSettingUserForm.get('telNo').value;
    // this.merchantSettingUser.email = this.merchantSettingUserForm.get('email').value;
  }

  isFieldInvalid(field: string) {
    const aField = this.merchantSettingCompanyInfoEntryForm.get(field);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  /* Workaround for email validator that makes the form invalid when the field is empty */
  customEmailValidator(control: AbstractControl): ValidationErrors | null {
    return control.value ? Validators.email(control) : null;
  }

  getProvinces() {
    const provinceInfoRequest = new ProvinceInfoRequestModel(null, null, null, null);
    this.locationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.provinces = result.provinces;
        }
      }
    );
  }

  getAmphurs(provinceCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, null, null, null);
    this.locationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0] && result.provinces[0].amphurs) {
            this.amphurs = result.provinces[0].amphurs;
          }
        }
      }
    );
  }

  getDistricts(provinceCode, amphurCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, amphurCode, null, null);
    this.locationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0] && result.provinces[0].amphurs[0]
            && result.provinces[0].amphurs[0].districts) {
            this.districts = result.provinces[0].amphurs[0].districts;
          }
        }
      }
    );
  }

  getPostcode(provinceCode, amphurCode, districtCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, amphurCode, districtCode, null);
    this.locationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0]
            && result.provinces[0].amphurs[0]
            && result.provinces[0].amphurs[0].districts[0]
            && result.provinces[0].amphurs[0].districts[0].postcodes) {
              this.postcodes = result.provinces[0].amphurs[0].districts[0].postcodes;
          }
        }
      }
    );
  }

  onProvinceChange(value) {
    this.clearAmphurs();

    const provinceCode = this.merchantSettingCompanyInfoEntryForm.get('provinceCode').value;
    if (provinceCode) {
      this.getAmphurs(provinceCode);
    }
  }

  onAmphurChange(value) {
    this.clearDistricts();

    const provinceCode = this.merchantSettingCompanyInfoEntryForm.get('provinceCode').value;
    const amphurCode = this.merchantSettingCompanyInfoEntryForm.get('amphurCode').value;
    if (amphurCode) {
      this.getDistricts(provinceCode, amphurCode);
    }
  }

  onDistrictChange(value) {
    this.clearPostalCode();

    const provinceCode = this.merchantSettingCompanyInfoEntryForm.get('provinceCode').value;
    const amphurCode = this.merchantSettingCompanyInfoEntryForm.get('amphurCode').value;
    const districtCode = this.merchantSettingCompanyInfoEntryForm.get('districtCode').value;
    if (districtCode) {
      this.getPostcode(provinceCode, amphurCode, districtCode);
    }
  }

  clearProvince() {
    this.provinces = null;
    this.merchantSettingCompanyInfoEntryForm.get('provinceCode').reset();

    this.clearAmphurs();
  }

  clearAmphurs() {
    this.amphurs = null;
    this.merchantSettingCompanyInfoEntryForm.get('amphurCode').reset();

    this.clearDistricts();
  }

  clearDistricts() {
    this.districts = null;
    this.merchantSettingCompanyInfoEntryForm.get('districtCode').reset();

    this.clearPostalCode();
  }

  clearPostalCode() {
    this.postcodes = null;
    this.merchantSettingCompanyInfoEntryForm.get('postalCode').reset();
  }

  getInvalidTextInputCharacters(): string {
    return CommonService.INVALID_TEXT_INPUT_CHARACTERS;
  }
  getInvalidPhoneInputCharacters(): string {
    return CommonService.INVALID_PHONE_INPUT_CHARACTERS;
  }
  getInvalidUrlInputCharacters(): string {
    return CommonService.INVALID_URL_INPUT_CHARACTERS;
  }

  fillAddress() {
    this.formValueChange = true;
    this.merchantSettingCompanyInfoEntryForm.controls['addressNo'].setValidators([
      Validators.required,
      this.addressNoValidator(),
      Validators.maxLength(20)
    ]);
    const roomNoPrefix = 'ห้อง';
    const floorNoPrefix = 'ชั้น';
    const soiPrefix = 'ซ.';
    const roadPrefix = 'ถ.';
    const mooPrefix = 'หมู่';
    this.address1 = '';
    this.address2 = '';
    let addressNo = this.merchantSettingCompanyInfoEntryForm.get('addressNo').value;
    let buildingName = this.merchantSettingCompanyInfoEntryForm.get('buildingName').value;
    let roomNo = this.merchantSettingCompanyInfoEntryForm.get('roomNo').value;
    let floorNo = this.merchantSettingCompanyInfoEntryForm.get('floorNo').value;
    let village = this.merchantSettingCompanyInfoEntryForm.get('village').value;
    let soi = this.merchantSettingCompanyInfoEntryForm.get('soi').value;
    let road = this.merchantSettingCompanyInfoEntryForm.get('road').value;
    let moo = this.merchantSettingCompanyInfoEntryForm.get('moo').value;
    let addressNoInvalid = this.merchantSettingCompanyInfoEntryForm.get('addressNo').invalid;
    let buildingNameInvalid = this.merchantSettingCompanyInfoEntryForm.get('buildingName').invalid;
    let roomNoInvalid = this.merchantSettingCompanyInfoEntryForm.get('roomNo').invalid;
    let floorNoInvalid = this.merchantSettingCompanyInfoEntryForm.get('floorNo').invalid;
    let villageInvalid = this.merchantSettingCompanyInfoEntryForm.get('village').invalid;
    let soiInvalid = this.merchantSettingCompanyInfoEntryForm.get('soi').invalid;
    let roadInvalid = this.merchantSettingCompanyInfoEntryForm.get('road').invalid;
    let mooInvalid = this.merchantSettingCompanyInfoEntryForm.get('moo').invalid;

    this.fillAddressValue(addressNo, addressNoInvalid);
    this.fillAddressValue(buildingName, buildingNameInvalid);
    this.fillAddressValue(roomNo, roomNoInvalid, roomNoPrefix);
    this.fillAddressValue(floorNo, floorNoInvalid, floorNoPrefix);
    this.fillAddressValue(village, villageInvalid);
    this.fillAddressValue(moo, mooInvalid, mooPrefix);
    this.fillAddressValue(soi, soiInvalid, soiPrefix);
    this.fillAddressValue(road, roadInvalid, roadPrefix);
  }

  fillAddressValue(value: string, valueInvalid: boolean, prefix? :string){
    const address1NoValue = (!this.address1 && this.address1.length == 0)
    value = value ? value.replace(/\s+/g, ' ').trim() : value;
    if (value && value != '' && !valueInvalid) {
      const prefixValue = prefix ? prefix : '';
      const whiteSpace = address1NoValue ? '' : ' ';
      const valueAppend : string = this.address1 + whiteSpace + prefixValue + value
      if (valueAppend.length <= 200 && !this.address2 && this.address2.length == 0) {
        this.address1 = valueAppend;
      } else {
        this.address2 = this.address2 + whiteSpace + prefixValue + value;
      }
    }
    this.merchantSettingCompanyInfoEntryForm.controls['addressLine1'].setValue(this.address1, {emitEvent: false});
    this.merchantSettingCompanyInfoEntryForm.controls['addressLine2'].setValue(this.address2, {emitEvent: false});
  }

  addressNoValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const firstCharacterRegex = /^[\-/\(\),.]/;
      const addressNoRegex = /^[0-9\-\/().,\ ]*$/;
      const notValidFirstCharacter = firstCharacterRegex.test((control.value || '').trim());
      const validAddressNo = addressNoRegex.test(control.value);
      const isWhitespace = (control.value || '').trim().length === 0;
      if(isWhitespace) {
        return { 'whitespace': true }
      }
      if(notValidFirstCharacter) {
        return { 'notValidFirstCharacter' : true }
      }
      if(!validAddressNo){
        return { 'pattern' : true }
      }
      return null;
    };
  }

  trimAddressField(addressContactField: string){
    const value = this.merchantSettingCompanyInfoEntryForm.get(addressContactField).value.replace(/\s+/g, ' ').trim();
    this.merchantSettingCompanyInfoEntryForm.controls[addressContactField].setValue(value, {emitEvent: false});
  }
}
