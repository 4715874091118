export class CountReportRequest {
    from: Date;
    to: Date;
    reportConfigId: number;
    companyIds: number[];
    supplierNo: string[];
    modelNo: string[];
    productNo: number[];

    constructor(from: Date, to: Date, reportConfigId: number, companyIds: number[], supplierNo?: string[], modelNo?: string[], productNo?: number[]) {
        this.from = from;
        this.to = to;
        this.reportConfigId = reportConfigId;
        this.companyIds = companyIds;
        this.supplierNo = supplierNo;
        this.modelNo = modelNo;
        this.productNo = productNo;
    }
}
