import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RegistrationService } from '../../services/registration.service';

import { ResultModel } from '../../services/models/result.model';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {
  activatedResultMessage: string;
  private activatedToken: string;

  constructor(private commonService: CommonService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedResultMessage = 'Not Activated';

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params);
      this.activatedToken = params['activatedToken'];
    });

    this.activate();
  }

  private activate() {
    this.commonService.isLoading(true);

    this.registrationService.activateUser(this.activatedToken).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          // this.toasterService.success('Activate success.');
          this.activatedResultMessage = 'Activate success.';
        } else {
          // this.toasterService.error(result.status_message);
          this.activatedResultMessage = 'Activate fail. ' + result.status_message;
        }
      }, error => {
        this.commonService.isLoading(false);
        // this.toasterService.error('Please contact administrator.' , 'Server API Error');
        this.activatedResultMessage = 'Please contact administrator. ' + 'Server API Error';
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

}
