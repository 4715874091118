import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';

import { environment } from '../shared/app-constant';
import { PaymentTypeModel, SourceOfFundModel } from './models/payment-type.model';

@Injectable()
export class DataService {
  constructor(private http: HttpClient) { }

  /**
   * Return a list of payment types in master table.
   */
  listPaymentTypes(paymentType: string): Observable<PaymentTypeModel[]> {
    let params = new HttpParams().set('category', paymentType === 'Card' ? 'Credit': paymentType);
    return this.http.get<PaymentTypeModel[]>(
      environment.listPaymentTypesApiUrl, {params: params});
  }

  listSourceOfFunds(paymentType: string): Observable<SourceOfFundModel[]> {
    let params = new HttpParams().set('category', paymentType === 'Card' ? 'Credit': paymentType);
    return this.http.get<SourceOfFundModel[]>(
      environment.listSourceOfFundApiUrl, {params: params});
  }

  /**
   * Return a list of currency codes which are from the merchant profiles in the user's companies.
   */
  listCompanyCurrencyCodes(): Observable<string[]> {
    return this.http.get<string[]>(
      environment.listCompanyCurrencyCodes);
  }
}
