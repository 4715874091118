import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CustomReuseStrategy } from './shared/routing';

// Routing
import { AppRoutingModule } from './app-routing.module';

// 3rd Party
import * as $ from 'jquery';
import { MomentModule, DateFormatPipe } from 'angular2-moment';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormsModule } from 'ng4-validators';
import { ToastrModule } from 'ngx-toastr';
import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// HeaderInterceptor
import { HeaderInterceptor } from './services/header-interceptor';

// Service
import { AuthenticationService } from './services/auth/authentication.service';
import { FingerprintService } from './services/auth/fingerprint.service';
import { TransactionService } from './services/transaction.service';
import { RegistrationService } from './services/registration.service';
import { CommonService } from './services/common.service';
import { LocationService } from './services/location.service';
import { DataService } from './services/data.service';
import { TransactionDetailService } from './services/transaction-detail.service';
import { SummaryService } from './services/summary.service';
import { ReportConfigService } from './services/report-config.service';
import { ReportService } from './services/report.service';
import { ToasterService } from './services/toaster.service';
import { ApiKeyInfoService } from './services/api-key-info.service';
import { MerchantSettingService } from './services/merchant-setting.service';
import { CacheObservableService } from './services/cache-observable.service';
import { CustomerService } from './services/customer.service';
import { QrSimulatorService } from './services/qr-simulator.service';
import { NgbDateCustomParserFormatter } from './services/datepicker/ngb-date-custom-parser-formatter';
import { TransactionExportsService } from './services/transaction-exports.services';
import { TokenService } from './services/auth/token.service';
import { RecurringPaymentService } from './services/recurring-payment.service';
import { MultipleRefundService } from './services/multiple-refund.service';

// Pipe
import { FilterAvailableSearchListPipe } from './pipes/filter-available-search-list.pipe';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FillInBlankPipe } from './pipes/fill-in-blank.pipe';
import { ToDateObjPipe } from './pipes/to-date-obj.pipe';

// Directive
import { DateValidatorDirective } from './directives/advance-search/date-validator.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';

// Page
import { HomeComponent } from './components/home/home.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { DocumentComponent } from './components/document/document.component';

// Page: Main
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { RequestGoliveComponent } from './components/request-golive/request-golive.component';
import { QrSimulatorComponent } from './components/qr-simulator/qr-simulator.component';
import { CustomerComponent } from './components/customer/customer.component';
import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';

// Page: Setting
import { MerchantSettingUserComponent } from './components/merchant-setting-user/merchant-setting-user.component';
import { MerchantSettingKeyComponent } from './components/merchant-setting-key/merchant-setting-key.component';
import { MerchantSettingCompanyInfoComponent } from './components/merchant-setting-company-info/merchant-setting-company-info.component';
import { MerchantSettingPaymentComponent } from './components/merchant-setting-payment/merchant-setting-payment.component';

// Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { AdvancesearchComponent } from './components/advancesearch/advancesearch.component';
import { ModalComponent } from './components/modal/modal.component';
import { MerchantSettingCompanyInfoSelectorComponent } from './components/merchant-setting-company-info-selector/merchant-setting-company-info-selector.component';
import { MerchantSettingCompanyInfoEntryComponent } from './components/merchant-setting-company-info-entry/merchant-setting-company-info-entry.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { ForgotPasswordRequestComponent } from './components/forgot-password-request/forgot-password-request.component';
import { ForgotPasswordResetComponent } from './components/forgot-password-reset/forgot-password-reset.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';
import { SettledConfirmModalComponent } from './components/settled-confirm-modal/settled-confirm-modal.component';
import { RefundedConfirmModalComponent } from './components/refunded-confirm-modal/refunded-confirm-modal.component';
import { ResetPasswordConfirmModalComponent } from './components/reset-password-confirm-modal/reset-password-confirm-modal.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { SettingComponent } from './components/setting/setting.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AdvancesearchSelectCompanyComponent } from './components/advancesearch-select-company/advancesearch-select-company.component';
import { SummaryComponent } from './components/summary/summary.component';
import { NoPermissionComponent } from './pages/nopermission/nopermission.component';
import { LoginComponent } from './components/login/login.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { MerchantSettingSftpAccInfoComponent } from './components/merchant-setting-sftp-acc-info/merchant-setting-sftp-acc-info.component';
import { MerchantSettingCampaignInfoComponent } from './components/merchant-setting-campaign-info/merchant-setting-campaign-info.component';
import { MessageService } from './services/message.service';
import { TransactionCreateReportComponent } from './components/transaction-create-report/transaction-create-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TransactionExportsComponent } from './components/transaction-exports/transaction-exports.component';
import { MerchantSettingTransactionLimitComponent } from './components/merchant-setting-transaction-limit/merchant-setting-transaction-limit.component';
import { PositiveNumberDirective } from './directives/positive-number.directive';
import { ThaiQRInquiryComponent } from './components/thai-qr-inquiry/thai-qr-inquiry.component';
import { ThaiQrInquiryDetailComponent } from './components/thai-qr-inquiry-detail/thai-qr-inquiry-detail.component';
import { ThaiQrInquiryService } from './services/thai-qr-inquiry.service';
import { ThaiQrInquiryDetailService } from './services/thai-qr-inquiry-detail.service';
import { InputTimeDirective } from './directives/input-time.directive';
import { TimeSelectionComponent } from './components/time-selection/time-selection.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PaymentLinksComponent } from './components/payment-links/payment-links.component';
import { PaymentLinkService } from './services/payment-link.service';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';
import { PaymentCreateLinkComponent } from './components/payment-create-link/payment-create-link.component';
import { PaymentLinkSearchComponent } from './components/payment-link-search/payment-link-search.component';
import { VerifyPaymentLinkComponent } from './components/verify-payment-link/verify-payment-link.component';
import { VerifyPaymentLinkService } from './services/verify-payment-link.service';
import { AdjustUiDirective } from './directives/adjust-ui.directive';
import { MultiselectDropdownCustomComponent } from './components/multiselect-dropdown-custom/multiselect-dropdown-custom.component';
import { RecurringPaymentComponent } from './components/recurring-payment/recurring-payment.component';
import { RecurringUploadFileComponent } from './components/recurring-upload-file/recurring-upload-file.component';
import { MerchantSettingCompanyInstallmentCrossBankComponent } from './components/merchant-setting-company-installment-cross-bank/merchant-setting-company-installment-cross-bank.component';
import { MultipleSelectionComponent } from './components/multiple-selection/multiple-selection.component';
import { MultipleRefundComponent } from './components/multiple-refund/multiple-refund.component';
import { MultipleRefundResultComponent } from './components/multiple-refund-result/multiple-refund-result.component';
import { MultipleRefundConfirmationComponent } from './components/multiple-refund-confirmation/multiple-refund-confirmation.component';
import { MerchantSettingConfigurationComponent } from './components/merchant-setting-configuration/merchant-setting-configuration.component';
import { MerchantConfigurationMccComponent } from './components/merchant-configuration-mcc/merchant-configuration-mcc.component';
import { MerchantConfigurationQrComponent } from './components/merchant-configuration-qr/merchant-configuration-qr.component';
import { MerchantConfigurationInstallmentInfoComponent } from './components/merchant-configuration-installment-info/merchant-configuration-installment-info.component';
import { MerchantConfigurationResponseUrlComponent } from './components/merchant-configuration-response-url/merchant-configuration-response-url.component';
import { MerchantConfigurationTokenizationComponent } from './components/merchant-configuration-tokenization/merchant-configuration-tokenization.component';
import { MerchantConfigurationPayplusComponent } from './components/merchant-configuration-payplus/merchant-configuration-payplus.component';
import { MerchantConfigurationDccComponent } from './components/merchant-configuration-dcc/merchant-configuration-dcc.component';
import { MerchantConfigurationAlipayComponent } from './components/merchant-configuration-alipay/merchant-configuration-alipay.component';
import { MerchantConfigurationWechatComponent } from './components/merchant-configuration-wechat/merchant-configuration-wechat.component';
import { BulkRefundComponent } from './components/bulk-refund/bulk-refund.component';
import { BulkRefundUploadFileComponent } from './components/bulk-refund-upload-file/bulk-refund-upload-file.component';
import { BulkPaymentComponent } from './components/bulk-payment/bulk-payment.component';
import { BulkPaymentService } from './services/bulk-payment.service';
import { BulkPaymentUploadFileComponent } from './components/bulk-payment-upload-file/bulk-payment-upload-file.component';
import { BookMarkComponent } from './components/book-mark/book-mark.component';
import { NgSelectModule } from '@ng-select/ng-select';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

const toastrOptions = {
  timeOut: 2000,
  positionClass: 'toast-top-right',
  closeButton: true,
  newestOnTop: true,
  titleClass: 'toast-custom-title'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PagenotfoundComponent,
    NoPermissionComponent,
    DashboardComponent,
    HomeComponent,
    MenuComponent,
    PricingComponent,
    DocumentComponent,
    TransactionComponent,
    AdvancesearchComponent,
    FilterAvailableSearchListPipe,
    RegistrationComponent,
    RequestGoliveComponent,
    ForgotPasswordComponent,
    TransactionDetailComponent,
    SettingComponent,
    LoadingComponent,
    AdvancesearchSelectCompanyComponent,
    SummaryComponent,
    DateValidatorDirective,
    ModalComponent,
    MerchantSettingUserComponent,
    MerchantSettingCompanyInfoComponent,
    MerchantSettingCompanyInfoSelectorComponent,
    MerchantSettingCompanyInfoEntryComponent,
    MerchantSettingCompanyInstallmentCrossBankComponent,
    MerchantSettingPaymentComponent,
    MerchantSettingKeyComponent,
    ActivateUserComponent,
    ForgotPasswordRequestComponent,
    ForgotPasswordResetComponent,
    CustomerComponent,
    CustomerCardComponent,
    QrSimulatorComponent,
    SettledConfirmModalComponent,
    RefundedConfirmModalComponent,
    ResetPasswordConfirmModalComponent,
    CreditCardNumberPipe,
    TransactionReportComponent,
    LoginComponent,
    FillInBlankPipe,
    SafePipe,
    ToDateObjPipe,
    NumberOnlyDirective,
    MerchantSettingSftpAccInfoComponent,
    MerchantSettingCampaignInfoComponent,
    TransactionCreateReportComponent,
    TransactionExportsComponent,
    MerchantSettingTransactionLimitComponent,
    PositiveNumberDirective,
    ThaiQRInquiryComponent,
    ThaiQrInquiryDetailComponent,
    InputTimeDirective,
    TimeSelectionComponent,
    ClickOutsideDirective,
    PaymentLinksComponent,
    PaymentLinkComponent,
    PaymentCreateLinkComponent,
    PaymentLinkSearchComponent,
    VerifyPaymentLinkComponent,
    PaymentLinkSearchComponent,
    AdjustUiDirective,
    MultiselectDropdownCustomComponent,
    RecurringPaymentComponent,
    RecurringUploadFileComponent,
    MultipleSelectionComponent,
    MultipleRefundComponent,
    MultipleRefundResultComponent,
    MultipleRefundConfirmationComponent,
    MerchantSettingConfigurationComponent,
    MerchantConfigurationMccComponent,
    MerchantConfigurationQrComponent,
    MerchantConfigurationDccComponent,
    MerchantConfigurationAlipayComponent,
    MerchantConfigurationWechatComponent,
    MerchantConfigurationResponseUrlComponent,
    MerchantConfigurationInstallmentInfoComponent,
    MerchantConfigurationTokenizationComponent,
    MerchantConfigurationPayplusComponent,
    BulkRefundComponent,
    BulkRefundUploadFileComponent,
    BulkPaymentComponent,
    BulkPaymentUploadFileComponent,
    BookMarkComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CustomFormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    AppRoutingModule,
    TextMaskModule,
    ClipboardModule,
    FontAwesomeModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(toastrOptions),
    // Ng2DeviceDetectorModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgSelectModule
  ],
  providers: [
    AuthenticationService,
    FingerprintService,
    CommonService,
    TransactionService,
    DecimalPipe,
    RegistrationService,
    LocationService,
    DataService,
    SummaryService,
    ReportConfigService,
    TransactionDetailService,
    MerchantSettingService,
    ReportService,
    ToasterService,
    ApiKeyInfoService,
    CacheObservableService,
    CustomerService,
    QrSimulatorService,
    DateFormatPipe,
    MessageService,
    TransactionExportsService,
    PaymentLinkService,
    VerifyPaymentLinkService,
    TokenService,
    ThaiQrInquiryService,
    ThaiQrInquiryDetailService,
    RecurringPaymentService,
    MultipleRefundService,
    BulkPaymentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    }
    /* {
         provide: RouteReuseStrategy,
         useClass: CustomReuseStrategy
       } */
  ],
  bootstrap: [AppComponent],
  entryComponents: [TransactionExportsComponent]
})
export class AppModule { }
