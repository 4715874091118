import { MerchantSettingUserChangePasswordModel } from './../../services/models/merchant-setting-user-change-password.model';
import { Component, OnInit, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule, ValidationErrors, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';

import { MerchantSettingUserResultModel } from '../../services/models/merchant-setting-user-result.model';
import { MerchantSettingUserSaveModel } from '../../services/models/merchant-setting-user-save.model';
import { StringUtil } from '../../shared/string.util';
import { ERROR_CODE } from '../../shared/app-constant';

@Component({
  selector: 'app-merchant-setting-user',
  templateUrl: './merchant-setting-user.component.html',
  styleUrls: ['./merchant-setting-user.component.css']
})
export class MerchantSettingUserComponent implements OnInit {
  private formSubmitAttempt: boolean;
  merchantSettingUserForm: FormGroup;
  editEnabled = false;
  passswordLinkEditEnabled = false;

  editPermit = false;

  merchantSettingUser: MerchantSettingUserResultModel;

  isPasswordValid: {
    lowerCase: boolean,
    upperCase: boolean,
    specialCharacter: boolean,
    number: boolean,
    errorMessage: string
  };

  // private oldPassword: string;
  // private password: string;

  constructor(private commonService: CommonService, private toasterService: ToasterService,
    private auth: AuthenticationService, private merchantSettingService: MerchantSettingService) {
  }

  ngOnInit() {
    this.commonService.isLoading(true);
    /* Init form */
    const oldPasswordCtrl = new FormControl(null, { updateOn: 'blur', validators: Validators.required });
    const passwordCtrl = new FormControl(null, {
      updateOn: 'blur',
      validators: [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15),
      Validators.pattern(CommonService.VALID_PASSWORD_FORMAT),
      CustomValidators.notEqualTo(oldPasswordCtrl)]
    });

    this.isPasswordValid = {
      lowerCase: false,
      upperCase: false,
      specialCharacter: false,
      number: false,
      errorMessage: null
    };

    this.merchantSettingUserForm = new FormGroup({
      'oldPassword': oldPasswordCtrl,
      'password': passwordCtrl,
      'rePassword': new FormControl(null, { updateOn: 'blur', validators: CustomValidators.equalTo(passwordCtrl) }),
      'firstNameTh': new FormControl(null, [
        Validators.maxLength(50),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'lastNameTh': new FormControl(null, [
        Validators.maxLength(50),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'firstNameEn': new FormControl(null, [
        Validators.maxLength(50),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'lastNameEn': new FormControl(null, [
        Validators.maxLength(50),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'mobileNo': new FormControl(null, [
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ]),
      'telNo': new FormControl(null, [
        Validators.maxLength(10),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ])
    });

    this.merchantSettingUserForm.get('rePassword').valueChanges.subscribe((value) => {
      this.isPasswordValid.errorMessage = null;
    });
    this.merchantSettingUserForm.get('password').valueChanges.subscribe((value) => {
      this.isPasswordValid.errorMessage = null;
      this.isPasswordValid.lowerCase = StringUtil.hasLowerCase(value);
      this.isPasswordValid.upperCase = StringUtil.hasUpperCase(value);
      this.isPasswordValid.number = StringUtil.hasNumber(value);
      this.isPasswordValid.specialCharacter = StringUtil.hasSpecialCharacter(value);
    });

    /* Not validate password fields: Disable them */
    this.disablePasswordFields();
    this.merchantSettingGetUser();
    this.setupPermit();
  }

  doEdit() {
    console.log('Do doEdit');
    this.editEnabled = true;
    this.passswordLinkEditEnabled = true;
    this.formSubmitAttempt = false;
    this.buildUserSettingForm();
  }

  doPasswordEdit() {
    console.log('Do doPasswordEdit');
    this.passswordLinkEditEnabled = false;

    this.enablePasswordFields();
  }

  doCancelChangePassword() {
    console.log('Do doCancelChagePassword');
    this.passswordLinkEditEnabled = true;
    this.disablePasswordFields();
  }

  doSaveChangePassword() {
    console.log('Do doSaveChangePassword');

    const oldPassword = this.merchantSettingUserForm.get('oldPassword');
    const password = this.merchantSettingUserForm.get('password');
    const rePassword = this.merchantSettingUserForm.get('rePassword');
    oldPassword.markAsTouched();
    password.markAsTouched();
    rePassword.markAsTouched();

    if (oldPassword.valid && password.valid && rePassword.valid) {
      this.merchantSettingChangePassword();
    }
  }

  doSave() {
    console.log('Do doSave');
    this.formSubmitAttempt = true;

    if (this.merchantSettingUserForm.valid) {
      this.merchantSettingSaveUser();
    }
  }

  doCancelEdit() {
    console.log('Do doCancelEdit');
    this.buildUserSettingForm();
    this.doCancelChangePassword();

    this.editEnabled = false;
    this.passswordLinkEditEnabled = false;
    this.formSubmitAttempt = false;
  }

  private merchantSettingGetUser() {
    this.merchantSettingService.getUser().subscribe(
      result => {
        this.merchantSettingUser = result;
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private buildUserSettingForm() {
    if (this.merchantSettingUser) {
      this.merchantSettingUserForm.get('firstNameTh').patchValue(this.merchantSettingUser.firstNameTh);
      this.merchantSettingUserForm.get('lastNameTh').patchValue(this.merchantSettingUser.lastNameTh);
      this.merchantSettingUserForm.get('firstNameEn').patchValue(this.merchantSettingUser.firstNameEn);
      this.merchantSettingUserForm.get('lastNameEn').patchValue(this.merchantSettingUser.lastNameEn);
      this.merchantSettingUserForm.get('mobileNo').patchValue(this.merchantSettingUser.mobileNo);
      this.merchantSettingUserForm.get('telNo').patchValue(this.merchantSettingUser.telNo);
    }
  }

  private setupPermit() {
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      const menuInfo = menuInfos.find(m => m.screenCode === 'M007');
      if (menuInfo) {
        const componentsInfos = menuInfo.componentsInfo;
        if (componentsInfos) {
          componentsInfos.forEach(componentsInfo => {
            if (componentsInfo.roleActionCode === 'ROLE_B_2701') {
              this.editPermit = true;
            }
          });
        }
      }
    }
  }

  private updateUserSettingFormWhenSuccess() {
    this.merchantSettingUser.firstNameTh = this.merchantSettingUserForm.get('firstNameTh').value;
    this.merchantSettingUser.lastNameTh = this.merchantSettingUserForm.get('lastNameTh').value;
    this.merchantSettingUser.firstNameEn = this.merchantSettingUserForm.get('firstNameEn').value;
    this.merchantSettingUser.lastNameEn = this.merchantSettingUserForm.get('lastNameEn').value;
    this.merchantSettingUser.mobileNo = this.merchantSettingUserForm.get('mobileNo').value;
    this.merchantSettingUser.telNo = this.merchantSettingUserForm.get('telNo').value;
    this.merchantSettingUser.email = this.merchantSettingUserForm.get('email').value;
  }

  private disablePasswordFields() {
    const oldPassword = this.merchantSettingUserForm.get('oldPassword');
    const password = this.merchantSettingUserForm.get('password');
    const rePassword = this.merchantSettingUserForm.get('rePassword');
    this.isPasswordValid.errorMessage = null;
    oldPassword.reset();
    oldPassword.disable();
    password.reset();
    password.disable();
    rePassword.reset();
    rePassword.disable();
  }

  private enablePasswordFields() {
    const oldPassword = this.merchantSettingUserForm.get('oldPassword');
    const password = this.merchantSettingUserForm.get('password');
    const rePassword = this.merchantSettingUserForm.get('rePassword');

    oldPassword.reset();
    oldPassword.enable();
    password.reset();
    password.enable();
    rePassword.reset();
    rePassword.enable();
  }

  private merchantSettingChangePassword() {
    this.commonService.isLoading(true);

    const changePasswordModel = new MerchantSettingUserChangePasswordModel();
    changePasswordModel.oldPassword = this.merchantSettingUserForm.get('oldPassword').value;
    changePasswordModel.password = this.merchantSettingUserForm.get('password').value;

    this.merchantSettingService.saveUserChangePassword(changePasswordModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Change password.');

          this.passswordLinkEditEnabled = true;
          this.disablePasswordFields();
          this.merchantSettingUserForm.get('oldPassword').setValue('');
          this.merchantSettingUserForm.get('password').setValue('');
          this.merchantSettingUserForm.get('rePassword').setValue('');
        } else {
          this.isPasswordValid.errorMessage = result.status_message;
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  private merchantSettingSaveUser() {
    this.commonService.isLoading(true);

    const merchantSettingSaveUserModel = new MerchantSettingUserSaveModel();
    merchantSettingSaveUserModel.firstNameTh = this.merchantSettingUserForm.get('firstNameTh').value;
    merchantSettingSaveUserModel.lastNameTh = this.merchantSettingUserForm.get('lastNameTh').value;
    merchantSettingSaveUserModel.firstNameEn = this.merchantSettingUserForm.get('firstNameEn').value;
    merchantSettingSaveUserModel.lastNameEn = this.merchantSettingUserForm.get('lastNameEn').value;
    merchantSettingSaveUserModel.mobileNo = this.merchantSettingUserForm.get('mobileNo').value;
    merchantSettingSaveUserModel.telNo = this.merchantSettingUserForm.get('telNo').value;

    this.merchantSettingService.saveUser(merchantSettingSaveUserModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Save user setting.');

          this.editEnabled = false;
          this.passswordLinkEditEnabled = false;

          // this.updateUserSettingFormWhenSuccess();
          this.merchantSettingGetUser();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  isFieldInvalid(field: string) {
    const aField = this.merchantSettingUserForm.get(field);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  displayFieldCssArray(fields: string[]) {
    const isFieldInvalidFlag = fields.some(field => this.isFieldInvalid(field));
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  getInvalidTextInputCharacters(): string {
    return CommonService.INVALID_TEXT_INPUT_CHARACTERS;
  }
  getInvalidPhoneInputCharacters(): string {
    return CommonService.INVALID_PHONE_INPUT_CHARACTERS;
  }
}
