import { Injectable, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import { ToastsManager, ToastOptions } from 'ng2-toastr/ng2-toastr';

@Injectable()
export class ToasterService {

  toastOpts: any = {};
  constructor(private toastr: ToastrService) {}

  success(message: string, title?: string) {
    this.toastr.success(message, title || 'Success', this.toastOpts);
  }
  info(message: string, title?: string) {
    this.toastr.info(message, title || 'Info', this.toastOpts);
  }
  warning(message: string, title?: string) {
    this.toastr.warning(message, title || 'Warning', this.toastOpts);
  }
  error(message: string, title?: string) {
    this.toastr.error(message, title || 'Error', this.toastOpts);
  }

}
