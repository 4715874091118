import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export const faWeChatpay: IconDefinition = {
  prefix: "fac",
  iconName: "wechatpay",
  icon: [
    14, // SVG view box width
    13, // SVG view box height
    [],
    "U+E002",
    `M5.09165 8.46133C5.02843 8.49325 4.95739 8.51171 4.88166 8.51171C4.70672 8.51171 4.55448 8.41548 4.47452 8.27293L4.444 8.20596L3.16968 5.40963C3.15591 5.37911 3.14731 5.34469 3.14731 5.3112C3.14731 5.18227 3.25183 5.0779 3.38061 5.0779C3.43303 5.0779 3.48138 5.09527 3.52034 5.12422L5.02405 6.19497C5.13405 6.26679 5.26518 6.30904 5.40647 6.30904C5.49066 6.30904 5.57093 6.29323 5.64572 6.26601L12.7179 3.11838C11.4501 1.62437 9.36244 0.647979 6.99984 0.647979C3.13385 0.647979 0 3.25968 0 6.48147C0 8.23914 0.94291 9.82124 2.41861 10.8906C2.53706 10.9752 2.61452 11.114 2.61452 11.2708C2.61452 11.3226 2.60341 11.37 2.58995 11.4195C2.47197 11.859 2.28342 12.5631 2.27466 12.5961C2.25995 12.6514 2.23695 12.709 2.23695 12.7667C2.23695 12.8956 2.34131 13 2.4704 13C2.52095 13 2.56241 12.9812 2.60497 12.9567L4.13747 12.0718C4.25279 12.0053 4.37484 11.9641 4.50925 11.9641C4.58076 11.9641 4.64992 11.9751 4.71502 11.995C5.42994 12.2006 6.2012 12.3148 6.99984 12.3148C10.8657 12.3148 14 9.7031 14 6.48147C14 5.50554 13.7107 4.58657 13.2025 3.77823L5.14266 8.43191L5.09165 8.46133Z`,
  ],
} as any;
