import { OnInit, Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { TokenModel } from "../models/token.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../shared/app-constant";
import { map } from "rxjs/operators";
import { ResultModel } from "../models/result.model";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class TokenService implements OnInit {
  private TIME_RENEW = 300;
  private TIME_BUFFER = 20;
  private timeout: any;
  private timer: any;
  private expiredAt: number;
  constructor(private http: HttpClient,
              private inj: Injector) { } 

  ngOnInit(): void {
  }

  setTimer() {
    this.clearTimer();
    this.expiredAt = +sessionStorage.getItem('expiredAt');
    const delay = this.expiredAt - new Date().getTime() - this.TIME_RENEW * 1000;

    if (delay > 0) {
      console.log("Setting timer with: " + Math.round(delay / 1000));
      this.timeout = setTimeout(() => {
        const lastApiCall = +sessionStorage.getItem('lastApiCall');
        const lastTokenCheck = +sessionStorage.getItem('lastTokenCheck');
        const auth = this.inj.get(AuthenticationService);
        if (lastApiCall > lastTokenCheck + this.TIME_BUFFER * 1000) {
          console.log("Last Call: " + lastApiCall + ", Last Token Check: " + lastTokenCheck + ", Calling Refresh");
          auth.renewToken().subscribe(
            result => {
              if (result.token) {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                currentUser.token = result.token;
                const currentTime = new Date().getTime();
                sessionStorage.setItem('expiredAt', result.expiredAt + '');
                sessionStorage.setItem('lastTokenCheck', currentTime + '');
                sessionStorage.setItem('lastApiCall', currentTime + '');
                sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
              } else {
                this.clearTimer();
                auth.logout();
              }
            });
        } else {
          console.log("Last Call: " + lastApiCall + ", Last Token Check: " + lastTokenCheck + ", Let token expire");
          this.clearTimer();
          auth.logout();
        }
      }, delay);
      // if (!environment.production) {
      //   this.timer = setInterval(() => {
      //     console.log("timer: " + Math.round((this.expiredAt - new Date().getTime() - this.TIME_RENEW * 1000) / 1000));
      //   }, 1000);
      // }
    }
  }

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
