import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '../../../../node_modules/@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CacheObservableService } from '../../services/cache-observable.service';
import { CommonService } from '../../services/common.service';
import { MessageService } from '../../services/message.service';
import { MenuInfoModel } from '../../services/models/menuinfo.model';
import { MessageModel } from '../../services/models/message.model';
import { UserModel } from '../../services/models/user.model';
import { ToasterService } from '../../services/toaster.service';
import { ResetPasswordConfirmContentModel } from './../../services/models/reset-password-confirm-content.model';
import { ResetPasswordConfirmResultModel } from './../../services/models/reset-password-confirm-result.model';
import { ResetPasswordRequestModel } from './../../services/models/reset-password.request.model';
import { ERROR_CODE } from '../../shared/app-constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  isLoggedIn = false;
  model: any = {};
  menuInfo: MenuInfoModel[];
  companyInfos: any = {};
  userInfo: any = {};
  resetPasswordSessionId: string;
  messages: MessageModel[];

  constructor(
    private zone: NgZone,
    private router: Router,
    private auth: AuthenticationService,
    private toasterService: ToasterService,
    private cacheObservableService: CacheObservableService,
    private commonService: CommonService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    console.log("Login OnInit...")
    this.messageService.getMessages().subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.messages = result.messages;
        }
      }
    );
  }

  onClickGo() {
    this.commonService.isLoading(true);
    this.login();
  }

  login() {
    if (!this.isLoggedIn) {
      console.log('login user: ' + this.model.email);

      this.auth.login(this.model.email, this.model.password).subscribe(
        profileResponse => {
          console.log('profileResponse.status_code=' + profileResponse.status_code);
          if (this.commonService.isSuccess(profileResponse)) {
            console.log('profileResponse=' + profileResponse);
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            currentUser.userInfo = profileResponse.userInfo;
            currentUser.menuInfo = profileResponse.menuInfo;
            currentUser.companyInfos = profileResponse.companyInfos;
            currentUser.switchCompanyInfos = profileResponse.switchCompanyInfos;
            currentUser.activeCompany = profileResponse.activeCompany;
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.auth.setCurrentUserInfo(new UserModel(currentUser.userInfo, currentUser.menuInfo, currentUser.companyInfos,
              currentUser.switchCompanyInfos, currentUser.activeCompany));

            // clear
            this.model.email = null;
            this.model.password = null;
            this.cacheObservableService.clear();

            if (profileResponse.passwordWillExpire <= 15) {
              const callback = new Subject<boolean>();
              callback.asObservable().subscribe(result => {
                if (result === true) {
                  this.router.navigateByUrl('merchant-setting-user');
                }
              });

              let msg = null;
              if (profileResponse.passwordWillExpire === 0) {
                msg = 'Your password will expire today.';
              } else {
                msg = 'Your password will expire in ' + profileResponse.passwordWillExpire + (profileResponse.passwordWillExpire == 1 ? ' day.' : ' days.');
              }
              msg = msg + ' Click confirm button to change password.';
              this.commonService.alertConfirm('Warning', msg, callback);
            }
          } else {
            this.toasterService.error(profileResponse.status_message);
          }
          this.commonService.isLoading(false);
        },
        error => {
          console.log(error);
          this.commonService.isLoading(false);
          console.log('error code: ' + error.error.code);
          if (error.error.code === 'internal_user_password_must_changed') {
            if (error.error.properties && error.error.properties.sessionId) {
              this.resetPasswordSessionId = error.error.properties.sessionId;
            }

            const callback = new Subject<any>();
            callback.asObservable().subscribe(result => {
              if (result instanceof ResetPasswordConfirmResultModel) {
                this.doProcessResetPassword(result);
              }
            });

            const content = new ResetPasswordConfirmContentModel();
            content.userName = this.model.email;
            content.oldPassword = this.model.password;
            if (window.innerWidth > 1025) {
              content.modalSize = 'md';
            }
            this.commonService.alertConfirm('Reset Password', content, callback);
          } else {
            this.toasterService.error(error.error.message);
          }
          this.commonService.isLoading(false);
        },
        () => {
          this.toasterService.success('Login success.');
          this.updateHeader();

          console.log('router navigate to default or dashboard.');
          this.zone.run(() => this.navigateToDefault());
          this.commonService.isLoading(false);
        });
    }
  }

  updateHeader() {
    this.auth.isAuthenticated();
    this.userInfo = this.auth.getCurrentUserInfo();
    this.companyInfos = this.auth.getCurrentUserCompanyInfos();
    this.menuInfo = this.auth.getCurrentUserMenuInfo();
    if (this.menuInfo && this.menuInfo.some(m => m.screenCode === 'C007')) {
      this.commonService.setVisibleLinkTransactionDetail(true);
    } else {
      this.commonService.setVisibleLinkTransactionDetail(false);
    }
  }

  navigateToDefault() {
    if (this.auth.redirectUrl) {
      console.log('auth.redirectUrl is not empty');
      console.log('auth.redirectUrl :' + this.auth.redirectUrl);
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (this.auth.getCurrentUserSwitchCompanyInfos().length <= 1) {
        currentUser.isSelectedCompany = true;
      } else {
        currentUser.isSelectedCompany = false;
      }
      sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.router.navigateByUrl(this.auth.redirectUrl);
    } else {
      console.log('auth.redirectUrl is empty');
      if (this.auth.getCurrentUserSwitchCompanyInfos().length <= 1) {
        // use default from userRole, otherwise use dashboard
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        currentUser.isSelectedCompany = true;
        sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.auth.isSelectedCompany();
        let defaultUrl = '/';
        const menu = this.auth.getCurrentUserMenuInfo();
        if (menu) {
          const defaultMenu = menu.find(m => m.defaultScreen);
          if (defaultMenu) {
            defaultUrl = defaultMenu.url;
          } else {
            defaultUrl = 'dashboard';
          }
        }
        console.log('default url is: ' + defaultUrl);
        this.router.navigateByUrl(defaultUrl);
      } else {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        currentUser.isSelectedCompany = false;
        sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.auth.isSelectedCompany();
        this.router.navigateByUrl('book-mark');
      }
    }
  }

  private doProcessResetPassword(resultModel: ResetPasswordConfirmResultModel) {
    this.commonService.isLoading(true);

    console.log(resultModel);
    const resetPasswordRequestModel = new ResetPasswordRequestModel();
    resetPasswordRequestModel.sessionId = this.resetPasswordSessionId;
    resetPasswordRequestModel.username = this.model.email;
    resetPasswordRequestModel.oldPassword = this.model.password;
    resetPasswordRequestModel.newPassword = resultModel.newPassword;

    this.auth.resetPassword(resetPasswordRequestModel).subscribe(result => {
      console.log(result);
      if (this.commonService.isSuccess(result)) {
        this.commonService.isLoading(false);
        // this.toasterService.success('Please login using new password.', 'Reset Password Success.');

        this.model.password = resetPasswordRequestModel.newPassword;
        this.commonService.closeModal();
        this.login();
      } else {
        this.commonService.isLoading(false);
        this.commonService.sendResetPwdError(result.status_message);
      }
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });

  }
}
