import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { DataService } from './../../services/data.service';
import { TransactionRequestDateModel } from '../../services/models/transaction.request.model.date';
import { CacheObservableService } from '../../services/cache-observable.service';
import { AdvanceSearchFormInterface } from './advance-search-form-interface';
import { SEARCH_ADVANCE, PATTERN, AMOUNT_OPTION, SOURCE_TYPES, CARD_TYPES, AMOUNTS, DATE_OPTIONS, SECURE_TRANSACTIONS, TRANSACTION_STATUS_DATA, message, DateRegExp, TRANSACTION_STATUS, SUB_FILTER, DATE_OPTION, SOURCE_OF_FUND, SOURCE_OF_FUND_DISPLAY } from '../../shared/app-constant';
import { CheckboxModel } from '../../services/models/checkbox.model';
import { FormControl, Validators } from '@angular/forms';
import { TransactionUtil } from '../../shared/transaction.util';
import { TransactionService } from '../../services/transaction.service';
import { ToasterService } from '../../services/toaster.service';
import { cloneObject } from '../../shared/common.util';
import { DateUtil } from '../../shared/date.util';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { debounceTime } from 'rxjs/operators';
import { SelectionTime } from '../../services/models/selection-time.model';
import { DEFAULT_TIME } from '../thai-qr-inquiry/thai-qr-inquiry.component';

interface SelectedSearch {
  showName: string;
  name: string;
  value: boolean;
}

const SOURCE_OF_FUND_ID = {
  NOTIFICATION_ID: 10,
  APP_SWITCH_ID: 11
}

const DATE_VALIDATION_MSG = {
  REQUIRED: "Transaction date is required",
  INVALID: "Transaction date is invalid",
  FROM_AFTER_TO: "From date cannot after To date",
  TO_BEFORE_FROM: "To date cannot before From date",
  AFTER_TODAY: "Transaction date cannot after Today",
  DATE_RANGE: "Transaction Date cannot over than 6 months",
  END_BEFORE_TO: "End Time cannot before Start Time.",
  TO_DATETIME_BEFORE_DATETIME: "To date/time cannot before date/time."
}

const TIME = {
  START: "00:00",
  END: "23:59"
}

@Component({
  selector: 'app-advancesearch',
  templateUrl: './advancesearch.component.html',
  styleUrls: ['./advancesearch.component.css']
})
export class AdvancesearchComponent implements OnInit {

  @Input() selectedCriteria: any[];
  @Input() selectedCriteriaValue: AdvanceSearchFormInterface;
  @Input() selectedExportCriteria: any[];
  @Input() selectedExportCriteriaValue: AdvanceSearchFormInterface;
  @Input() config: any[];
  @Input() requestTransaction: TransactionRequestModel;
  @Output() onApplyFilter = new EventEmitter<object>();

  isShow: boolean;
  isShowAddButton: boolean; // = true;
  isExport: boolean;

  isShowPaymentTypeError: boolean;

  availableSearchList = [];
  temporaryList: any[];
  isPayPlusNotification: CheckboxModel;
  amountRef1Ctrl: FormControl;
  amountRef2Ctrl: FormControl;
  mobileNoCtrl: FormControl;
  exportFileNameCtrl: FormControl;
  model: AdvanceSearchFormInterface;

  public maskFirstAndLastDigit: Array<string | RegExp>;
  public maskLastDigit: Array<string | RegExp>;
  public timeFrom: SelectionTime = new SelectionTime();
  public timeTo: SelectionTime = new SelectionTime();

  sourceTypeList = cloneObject(SOURCE_TYPES);
  cardTypes = cloneObject(CARD_TYPES);
  exportCardTypes = cloneObject(CARD_TYPES);
  currencyCodes: any[];
  exportCurrencyCodes: any[];
  amountList = cloneObject(AMOUNTS)
  dateOptionsList = cloneObject(DATE_OPTIONS);
  companies: any[];
  exportCompanies: any[];
  secureTransactions = cloneObject(SECURE_TRANSACTIONS);
  exportSecureTransactions = cloneObject(SECURE_TRANSACTIONS);
  paymentTypes: CheckboxModel[];
  exportPaymentTypes: CheckboxModel[];
  sourceOfFunds: CheckboxModel[];
  exportSourceOfFunds: CheckboxModel[];
  public transactionStatus = cloneObject(TRANSACTION_STATUS_DATA);
  public exportTransactionStatus = cloneObject(TRANSACTION_STATUS_DATA);

  public maxDate: NgbDateStruct;
  public maxDateMobile: Date;

  public trxCustomDateValidationMgs: string;

  public fromDateCtrl: FormControl;
  public toDateCtrl: FormControl;
  public customDateCtrl: FormControl;
  public timeFromCtrl: FormControl;
  public timeToCtrl: FormControl;

  public channelList: string[];
  public isOpenTimeFrom: boolean;
  public isOpenTimeTo: boolean;

  public isDateMobile: boolean = false;
  public isDateLaptop: boolean = false;

  private amountOption: string;

  advanceTrxDateError = {
    fromDate: "",
    toDate: "",
    dateRange: ""
  };

  exportTrxDateError = {
    fromDate: "",
    toDate: "",
    dateRange: ""
  };

  displayTrxDateError = {
    fromDate: "",
    toDate: "",
    dateRange: "",
    endBeforeTo: ""
  };

  constructor(
    private commonService: CommonService,
    private auth: AuthenticationService,
    private dataService: DataService,
    private cacheObservableService: CacheObservableService,
    private transactionService: TransactionService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    console.log('Advance search : (ngOnInit)... ');
    this.initFormControl();
    this.model = {};
    this.isShowAddButton = true;
    this.sourceOfFunds = [];
    this.paymentTypes = [];
    this.exportPaymentTypes = [];
    this.temporaryList = [];
    this.maxDate = DateUtil.dateToNgbDateStructCustom(new Date());
    this.maxDateMobile = new Date();
    this.setConfig();
    this.timeFrom.hour = DEFAULT_TIME.START_HOUR;
    this.timeFrom.minute = DEFAULT_TIME.START_MINUTE;
    this.timeTo.hour = DEFAULT_TIME.END_HOUR;
    this.timeTo.minute = DEFAULT_TIME.END_MINUTE;

    this.commonService.isVisibleAdvanceSearch$.subscribe(isShow => {
      this.isShow = isShow;
    });

    this.commonService.advanceOptionSearch$.subscribe((option) => {
      this.isExport = option.isExport;
      if (option.isExport) {
        this.removeTrxDateMgs(this.exportTrxDateError);
        this.displayTrxDateError = cloneObject(this.exportTrxDateError);
        this.temporaryList = this.selectedExportCriteria;
        if (!this.temporaryList || this.temporaryList.length == 0) {
          this.temporaryList.push({
            id: 10,
            name: 'transactionDate',
            cardType: this.exportCardTypes,
            currencyCode: this.exportCurrencyCodes,
            company: this.exportCompanies,
            secureTransaction: this.exportSecureTransactions,
            paymentType: this.exportPaymentTypes,
            sourceOfFund: this.exportSourceOfFunds,
            transactionStatus: this.exportTransactionStatus
          });
          this.initFormControl();
          this.model = {};
        }
        this.model = this.selectedExportCriteriaValue;
        this.model.fileName = this.model.fileName ? this.model.fileName : 'transaction-' + new Date().toDateString();
        this.exportFileNameCtrl = new FormControl(this.model.fileName.trim(), [Validators.pattern('^[0-9A-Za-z _-]+$'), Validators.required]);
        const trxStatusCriteria = this.selectedExportCriteria.find((criteria) => criteria.name == SEARCH_ADVANCE.TRANSACTION_STATUS);
        if (trxStatusCriteria) {
          this.exportTransactionStatus = trxStatusCriteria.transactionStatus;
        }
      } else {
        this.removeTrxDateMgs(this.advanceTrxDateError);
        this.displayTrxDateError = cloneObject(this.advanceTrxDateError);
        this.temporaryList = this.selectedCriteria;
        if (!this.temporaryList || this.temporaryList.length == 0) {
          this.temporaryList.push({
            id: 10,
            name: 'transactionDate',
            cardType: this.cardTypes,
            currencyCode: this.currencyCodes,
            company: this.companies,
            secureTransaction: this.secureTransactions,
            paymentType: this.paymentTypes,
            sourceOfFund: this.sourceOfFunds,
            transactionStatus: this.transactionStatus
          });
          this.initFormControl();
          this.model = {};
        }
        this.model = this.selectedCriteriaValue;
        this.setTransactionStatus(this.transactionStatus);
      }
      if (this.model.channel) {
        this.setChannelConfig();
      }
      this.declareFormControl();
      if (!this.model.dateOpr) {
        this.model.dateOpr = this.dateOptionsList[0].value;
        this.model.dateRef1 = new Date();
        this.model.dateRef2 = new Date();
        this.model.timeFrom = TIME.START;
        this.model.timeTo = TIME.END;
        this.model.dateRef1.setHours(0,0,0,0);
        this.model.dateRef2.setHours(23,59,59,0);
      }
      this.timeFromCtrl.setValue( this.model.timeFrom, {emitEvent: false});
      this.timeToCtrl.setValue( this.model.timeTo, {emitEvent: false});
      this.trxCustomDateValidationMgs = null;
      this.setTrxDate();
      this.timeFrom.hour = this.model.timeFrom.split(':')[0];
      this.timeFrom.minute = this.model.timeFrom.split(':')[1];
      this.timeTo.hour = this.model.timeTo.split(':')[0];
      this.timeTo.minute = this.model.timeTo.split(':')[1];

    });
    this.companies = this.getCompany();
    this.exportCompanies = this.getCompany();
    this.getCurrencyCodes();
  }

  changeTrxDateValue() {
    this.isOpenTimeFrom = false;
    this.isOpenTimeTo = false;
    const yesterday = DateUtil.subtractDay(new Date(), 1)
    switch (this.model.dateOpr){
      case DATE_OPTION.BETWEEN:
      case DATE_OPTION.TODAY:
        this.model.dateRef1 = new Date();
        this.model.dateRef2 = new Date();
        break;
      case DATE_OPTION.YESTERDAY:
        this.model.dateRef1 = DateUtil.subtractDay(new Date(), 1);
        this.model.dateRef2 = yesterday;
        break;
      case DATE_OPTION.LASTWEEK:
        this.model.dateRef1 = DateUtil.subtractDay(new Date(), 7);
        this.model.dateRef2 = yesterday;
        break;
      case DATE_OPTION.TWOWEEK:
        this.model.dateRef1 = DateUtil.subtractDay(new Date(), 14);
        this.model.dateRef2 = yesterday;
        break;
      case DATE_OPTION.MONTH:
        this.model.dateRef1 = new Date();
        this.model.dateRef1.setDate(1);
        this.model.dateRef2 = yesterday;
        if(this.model.dateRef1 > this.model.dateRef2){
          this.model.dateRef2 = this.model.dateRef1;
        }
        break;
      case DATE_OPTION.LASTMONTH:
        this.model.dateRef1 = new Date();
        this.model.dateRef1.setDate(1);
        this.model.dateRef1.setMonth(new Date().getMonth() - 1);
        this.model.dateRef2 = yesterday;
        break;
      case DATE_OPTION.CUSTOM:
        this.model.dateRef1 = null;
        this.model.dateRef2 = null;
        break;
    }
    this.timeFrom.hour = DEFAULT_TIME.START_HOUR;
    this.timeFrom.minute = DEFAULT_TIME.START_MINUTE;
    this.timeFromCtrl.setValue(TIME.START, { emitEvent: false });

    this.timeTo.hour = DEFAULT_TIME.END_HOUR;
    this.timeTo.minute = DEFAULT_TIME.END_MINUTE;
    this.timeToCtrl.setValue(TIME.END, { emitEvent: false });

    if (this.model.dateRef1 && this.model.dateRef2) {
      this.setTrxDate();
      this.model.dateRef1.setHours(this.timeFrom.hour, this.timeFrom.minute, 0, 0)
      this.fromDateCtrl.setValue(DateUtil.dateToNgbDateStruct(this.model.dateRef1), { emitEvent: false });
      this.model.dateRef2.setHours(this.timeTo.hour, this.timeTo.minute, 59, 0)
      this.toDateCtrl.setValue(DateUtil.dateToNgbDateStruct(this.model.dateRef2), { emitEvent: false });
    }
    this.customDateCtrl.setValue(this.model.dateRef1 ? DateUtil.dateToNgbDateStruct(this.model.dateRef1) : null, { emitEvent: false });
    this.model.timeFrom = this.timeFromCtrl.value;
    this.model.timeTo = this.timeToCtrl.value;
    this.displayTrxDateError.fromDate = null;
    this.displayTrxDateError.dateRange = null;
    this.displayTrxDateError.endBeforeTo = null;
    this.displayTrxDateError.toDate = null;
    this.advanceTrxDateError = this.displayTrxDateError;
    this.exportTrxDateError = this.displayTrxDateError;
  }

  setTrxDate() {
    this.removeTrxDateMgs(this.displayTrxDateError);
    if (this.trxCustomDateValidationMgs == DATE_VALIDATION_MSG.REQUIRED || this.trxCustomDateValidationMgs == DATE_VALIDATION_MSG.INVALID) {
      this.trxCustomDateValidationMgs = '';
    }

    if (this.fromDateCtrl) {
      this.fromDateCtrl.setValue(this.model.dateRef1 ? DateUtil.dateToNgbDateStruct(this.getDateFrom(this.model.dateRef1)) : this.model.dateRef2 ? null : DateUtil.formatToNgbDate(new Date()), { emitEvent: false });
    }

    if (this.toDateCtrl) {
      this.toDateCtrl.setValue(this.model.dateRef2 ? DateUtil.dateToNgbDateStruct(this.getDateFrom(this.model.dateRef2)) : this.model.dateRef1 ? null : DateUtil.formatToNgbDate(new Date()), { emitEvent: false});
    }

    if (this.customDateCtrl && this.model.dateOpr == DATE_OPTION.CUSTOM) {
      this.customDateCtrl.setValue(this.model.dateRef1 ? DateUtil.dateToNgbDateStruct(this.getDateFrom(this.model.dateRef1)) : null);
    }
  }

  initFormControl() {
    this.fromDateCtrl = new FormControl();
    this.toDateCtrl = new FormControl();
    this.timeFromCtrl = new FormControl();
    this.timeToCtrl = new FormControl();
    this.customDateCtrl = new FormControl();
    this.fromDateCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.validateFromDate(value);
        if (this.isExport) {
          this.exportTrxDateError = this.displayTrxDateError;
        } else {
          this.advanceTrxDateError = this.displayTrxDateError;
        }
    });

    this.toDateCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.validateToDate(value);
        if (this.isExport) {
          this.exportTrxDateError = this.displayTrxDateError;
        } else {
          this.advanceTrxDateError = this.displayTrxDateError;
        }
    });

    this.customDateCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        if(value == null){
          // To prevent show error case just change date option to 'Custom'
          return;
        }
        if (!value) {
          // when entered value is null
          this.trxCustomDateValidationMgs = DATE_VALIDATION_MSG.REQUIRED;
        } else {
          // when entered value is invalid date
          value = this.getDateFrom(value);
          this.model.dateRef1 = cloneObject(value);
          this.model.dateRef2 = cloneObject(value);
          this.trxCustomDateValidationMgs = !value || isNaN(value.getTime()) ? DATE_VALIDATION_MSG.INVALID : null;
          if (!this.trxCustomDateValidationMgs) {
            this.model.dateRef1 = new Date(this.model.dateRef1);
            this.model.dateRef1.setHours(this.timeFrom.hour, this.timeFrom.minute, 0, 0);
            this.model.dateRef2 = new Date(this.model.dateRef2);
            this.model.dateRef2.setHours(this.timeTo.hour, this.timeTo.minute, 59, 0);
            this.validateFromDate(this.model.dateRef1);
          }
        }
    });

    this.timeFromCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) =>{
        this.model.timeFrom = value;
        this.validateTime();
        if (this.isExport) {
          this.exportTrxDateError = this.displayTrxDateError;
        } else {
          this.advanceTrxDateError = this.displayTrxDateError;
        }
    });

    this.timeToCtrl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) =>{
        this.model.timeTo = value;
        this.validateTime();
        if (this.isExport) {
          this.exportTrxDateError = this.displayTrxDateError;
        } else {
          this.advanceTrxDateError = this.displayTrxDateError;
        }
    });
  }

  private validateFromDate(value?: any) {
    this.displayTrxDateError.fromDate = null;
    this.displayTrxDateError.dateRange = null;
    this.displayTrxDateError.endBeforeTo = null;
    // if To date valid, hide error message
    if (this.displayTrxDateError.toDate == DATE_VALIDATION_MSG.TO_BEFORE_FROM || this.displayTrxDateError.toDate == DATE_VALIDATION_MSG.TO_DATETIME_BEFORE_DATETIME) {
      this.displayTrxDateError.toDate = null;
    }
    if (!value) {
      // when entered value is null
      this.displayTrxDateError.fromDate = DATE_VALIDATION_MSG.REQUIRED;
      return;
    }
    value = this.getDateFrom(value);
    if (!value || isNaN(value.getTime())) {
      // when entered value is invalid date
      this.displayTrxDateError.fromDate = DATE_VALIDATION_MSG.INVALID;
      return;
    }
    this.model.dateRef1 = value;
    // when entered value is valid date, run before-after validation
    value.setHours(this.timeFrom.hour, this.timeFrom.minute, 0, 0);
    if (DateUtil.isAfterToday(value) && this.model.dateOpr == DATE_OPTION.BETWEEN) {
      // when entered value is after Today
      this.displayTrxDateError.fromDate = DATE_VALIDATION_MSG.AFTER_TODAY;
      return;
    }
    this.model.dateRef2 = this.getDateFrom(this.model.dateRef2);
    if (this.model.dateRef2 instanceof Date && this.model.dateRef2.getTime()) {
      // when entered value is after From Date
      this.model.dateRef2.setHours(Number(this.timeTo.hour), Number(this.timeTo.minute), 59, 0);
      if (this.model.dateRef2.getTime() < value.getTime()) {
        if (this.model.dateOpr == DATE_OPTION.BETWEEN) {
          this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.TO_DATETIME_BEFORE_DATETIME;
        } else {
          this.displayTrxDateError.endBeforeTo = DATE_VALIDATION_MSG.END_BEFORE_TO;
        }
      } else if (DateUtil.subtractDay(this.model.dateRef2, 179).getTime() > value.getTime()) {
        // when entered value is after 180 days Date From
        this.displayTrxDateError.dateRange = DATE_VALIDATION_MSG.DATE_RANGE;
      }
    }
  }

  private validateToDate(value: any) {
    this.displayTrxDateError.toDate = null;
    this.displayTrxDateError.dateRange = null;
    this.displayTrxDateError.endBeforeTo = null;
    // if From date valid, hide error message
    if (this.displayTrxDateError.fromDate == DATE_VALIDATION_MSG.FROM_AFTER_TO) {
      this.displayTrxDateError.fromDate = null;
    }
    if (!value) {
      // when entered value is null
      this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.REQUIRED;
      return;
    }
    value = this.getDateFrom(value);
    if (!value || isNaN(value.getTime())) {
      // when entered value is invalid date
      this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.INVALID;
      return;
    }
    this.model.dateRef2 = value;
    // when entered value is valid date, run before-after validation
    value.setHours(this.timeTo.hour, this.timeTo.minute, 59, 0)
    if (DateUtil.isAfterToday(value) && this.model.dateOpr == DATE_OPTION.BETWEEN) {
      // when entered value is after Today
      this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.AFTER_TODAY;
      return;
    }
    this.model.dateRef1 = this.getDateFrom(this.model.dateRef1);
    if (this.model.dateRef1 instanceof Date  && this.model.dateRef1.getTime()) {
      // when entered value is before or equals Date From
      this.model.dateRef1.setHours(Number(this.timeFrom.hour), Number(this.timeFrom.minute), 0, 0);
      if (this.model.dateRef1.getTime() > value.getTime()) {
        if (this.model.dateOpr == DATE_OPTION.BETWEEN) {
          this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.TO_DATETIME_BEFORE_DATETIME;
        } else {
          this.displayTrxDateError.endBeforeTo = DATE_VALIDATION_MSG.END_BEFORE_TO;
        }
      } else if (DateUtil.subtractDay(value, 179).getTime() > this.model.dateRef1.getTime()) {
        // when entered value is after 180 days Date From
        this.displayTrxDateError.dateRange = DATE_VALIDATION_MSG.DATE_RANGE;
      }
    }
  }

  private validateTime(){
    this.displayTrxDateError.endBeforeTo = null;
    this.displayTrxDateError.toDate = null;
    const date1 = this.model.dateOpr == DATE_OPTION.CUSTOM && !this.model.dateRef1 ? new Date() : this.getDateFrom(this.model.dateRef1);
    date1.setHours(this.timeFrom.hour, this.timeFrom.minute, 0, 0);
    const date2 = this.model.dateOpr == DATE_OPTION.CUSTOM && !this.model.dateRef2 ? new Date() : this.getDateFrom(this.model.dateRef2);
    date2.setHours(this.timeTo.hour, this.timeTo.minute, 59, 0);
    if (!date1 || !date2 || isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      return;
    }
    if (date2.getTime() < date1.getTime()) {
      if (this.model.dateOpr == DATE_OPTION.BETWEEN) {
        this.displayTrxDateError.toDate = DATE_VALIDATION_MSG.TO_DATETIME_BEFORE_DATETIME;
      } else {
        this.displayTrxDateError.endBeforeTo = DATE_VALIDATION_MSG.END_BEFORE_TO;
      }
    }
  }

  getDateFrom(dateStr: string | NgbDateStruct): Date {
    let result: Date;
    if (dateStr instanceof  Date) {
      return dateStr;
    }
    if (typeof dateStr == "string") {
      result = DateUtil.createDate(dateStr, this.isDateLaptop ? DateRegExp.DAY_MONTH_YEAR : DateRegExp.YEAR_MONTH_DAY, this.isDateLaptop);
    } else {
      result = DateUtil.ngbDateStructToDate(dateStr);
    }
    return result;
  }

  getCompany() {
    return this.auth.getCurrentUserCompanyInfos().map(x => {
      return {
        id: x.companyId,
        name: x.companyIdName,
        value: false
      };
    });
  }

  changeSourceType(sourceType: string) {
    this.getPaymentTypes(sourceType);
    this.getSourceOfFunds(sourceType);
  }

  getPaymentTypes(paymentType: string) {
    if (paymentType != SEARCH_ADVANCE.BILL_PAYMENT && paymentType != SEARCH_ADVANCE.CREDIT_CARD) {
      return;
    }
    this.dataService.listPaymentTypes(paymentType)
      .subscribe(result => {
        this.paymentTypes = result.map(x => {
          return new CheckboxModel(x.paymentTypeId, x.paymentTypeDesc, false);
        });
        this.exportPaymentTypes = result.map(x => {
          return new CheckboxModel(x.paymentTypeId, x.paymentTypeDesc, false);
        });
        for (let i = 0; i < this.temporaryList.length; i++) {
          if (this.temporaryList[i].name === SEARCH_ADVANCE.PAYMENT_TYPE) {
            this.temporaryList[i].paymentType = this.isExport ? this.exportPaymentTypes : this.paymentTypes;
          }
        }
      });
  }

  getSourceOfFunds(paymentType: string) {
    if (paymentType != SEARCH_ADVANCE.BILL_PAYMENT) {
      this.isShowPaymentTypeError = false;
      return;
    }
    // this.commonService.isLoading(true);
    this.dataService.listSourceOfFunds(paymentType)
      .subscribe(result => {
        this.sourceOfFunds = result.map(x => {
          return new CheckboxModel(x.sourceOfFundId, x.brand, false);
        });
        this.exportSourceOfFunds = result.map(x => {
          return new CheckboxModel(x.sourceOfFundId, x.brand, false);
        });
        for (let i = 0; i < this.temporaryList.length; i++) {
          if (this.temporaryList[i].name === SEARCH_ADVANCE.SOURCE_OF_FUND) {
            this.temporaryList[i].sourceOfFund = this.isExport ? this.exportSourceOfFunds : this.sourceOfFunds;
          }
        }
        // this.commonService.isLoading(false);
      });
  }

  getCurrencyCodes() {
    this.cacheObservableService.get('currencyCodes', this.dataService.listCompanyCurrencyCodes())
      .subscribe(result => {
        this.currencyCodes = result.map(x => {
          return {
            name: x,
            value: false
          };
        });
        this.exportCurrencyCodes = result.map(x => {
          return {
            name: x,
            value: false
          };
        });
      });
  }

  applyFilter() {
    const request = this.bindNewRequest();
    request.isSearching = true;
    this.onApplyFilter.emit({ request: request, selectedCriteria: this.temporaryList, selectedCriteriaValue: this.model });
    this.exportTrxDateError = this.displayTrxDateError;
    this.isShow = false;
  }

  bindNewRequest(): TransactionRequestModel {
    console.log('Advance search : (bind new request model)... ');
    console.log(this.model);

    const request = new TransactionRequestModel();

    if (this.companies) {
      request.companyId = [];
      for (const company of this.companies) {
        request.companyId.push(company.id);
      }
    } else {
      request.companyId = this.requestTransaction.companyId;
    }
    request.pageIndex = this.requestTransaction.pageIndex;
    request.pageSize = this.requestTransaction.pageSize;
    request.fileName = this.model.fileName;
    request.searchDate = [];
    // this.selectedCriteria = Object.assign([], this.temporaryList);
    if (!this.temporaryList) {
      return;
    }

    for (const criteria of this.temporaryList) {
      switch (criteria.name) {
        case 'cardNoFirstAndLast':
          request.cardNo = this.model.cardNo;
          request.spec = 'F';
          break;
        case 'cardNoLast':
          request.cardNo = this.model.cardNo;
          request.spec = 'L';
          break;
        case 'productDescription':
          request.productDescription = this.model.productDescription;
          break;
        case 'sourceType':
          request.sourceType = this.model.sourceType;
          break;
        case 'refTrxId':
          request.refTrxId = this.model.refTrxId;
          break;
        case 'objectId':
          request.objectId = this.model.objectId;
          break;
        case 'secureTransaction':
          request.secureTransaction = [];
          for (const secureTransaction of criteria.secureTransaction) {
            if (secureTransaction.value === true) {
              request.secureTransaction.push(secureTransaction.name);
            }
          }
          break;
        case 'paymentType':
          request.paymentType = [];
          if (!criteria.paymentType) {
            break;
          }
          for (const paymentType of criteria.paymentType) {
            if (paymentType.value === true) {
              request.paymentType.push(paymentType.id);
            }
          }
          break;
        case 'refNumber':
          request.refNumber = this.model.refNumber;
          break;
        case 'cardType':
          request.cardType = [];
          for (const type of criteria.cardType.filter(card => card.value)) {
            // if (type.name === SEARCH_ADVANCE.UNION_PAY) {
            //   // Check selected in unionPays
            //   for (const union of criteria.unionPays.filter(union => union.value)) {
            //     request.cardType.push(union.name)
            //   }
            // }
            request.cardType.push(type.name);
          }
          break;
        case 'currencyCode':
          request.currencyCode = [];
          for (const code of criteria.currencyCode) {
            if (code.value === true) {
              request.currencyCode.push(code.name);
            }
          }
          break;
        case 'bankName':
          request.bankName = this.model.bankName;
          break;
        case 'amount':
          // request.searchTypeAmount = 'Total';
          request.amountOpr = this.model.amountOpr;
          request.amountRef1 = +this.model.amountRef1;
          if (request.amountOpr === 'between') {
            request.amountRef2 = +this.model.amountRef2;
          }
          break;
        case 'transactionDate':
          this.model.dateRef1 = this.getDateFrom(this.model.dateRef1);
          this.model.dateRef1.setHours(this.timeFrom.hour, this.timeFrom.minute, 0, 0);
          this.model.dateRef2 = this.getDateFrom(this.model.dateRef2);
          this.model.dateRef2.setHours(this.timeTo.hour, this.timeTo.minute, 59, 0);
          request.searchDate.push({searchTypeDate: 'created', dateRef1: this.model.dateRef1, dateRef2: this.model.dateRef2});
          break;
        case 'company':
          request.companyId = [];
          for (const company of criteria.company) {
            if (company.value === true) {
              request.companyId.push(company.id);
            }
          }
          break;
        case 'customerEmail':
          request.customerEmail = criteria.customerEmail;
          break;
        case 'customerName':
          request.customerName = criteria.customerName;
          break;
        case 'customerObjectId':
          request.customerObjectId = criteria.customerObjectId;
          break;
        case 'customerCreateDate':
          // request.searchDate.push(this.createTransactionRequestDateModel('customerCreateDate', criteria));
          break;
        case 'sourceOfFund':
          request.sourceOfFund = [];
          if (!criteria.sourceOfFund) {
            break;
          }
          for (const sourceOfFund of criteria.sourceOfFund) {
            if (sourceOfFund.value === true) {
              request.sourceOfFund.push(sourceOfFund.id);
            }
          }
          break;
        case 'transactionStatus':
          request.trxStatusState = [];
          if (!criteria.transactionStatus) {
            break;
          }
          for (const status of criteria.transactionStatus) {
            if (status.value) {
              request.refundStatus = status.name == TRANSACTION_STATUS.REFUNDED ? SUB_FILTER.ALL : null;
              request.trxStatusState = request.trxStatusState.concat(TransactionUtil.setTrxStatusState(status.name, request.refundStatus).value);
              request.status = !request.status ? [status.name] : request.status.concat(status.name)
            }
          }
          break;
        case 'paymentLinkRef':
          request.paymentLinkRef = this.model.paymentLinkRef.trim();
          break;
        case SEARCH_ADVANCE.CHANNEL:
          request.channel = this.model.channel == 'all' ? '' : this.model.channel;
          break;
        case 'bulkRefundId':
          request.bulkRefundId = this.model.bulkRefundId;
          break;
        default: {
          request[criteria.name] = this.model[criteria.name];
          break;
        }
      }
    }
    return request;
  }

  disableApply(): boolean {
    if (!this.temporaryList || this.temporaryList.length === 0) {
      return this.isExport;
    }
    if (this.isExport && this.exportFileNameCtrl.errors) {
      return true;
    }
    for (const item of this.temporaryList) {
      if (!this.validateAdvanceSearchItem(item)) {
        return true;
      }
    }
    return false;
  }

  public isReadOnly(): boolean {
    return this.model.dateOpr === 'twoweek' || this.model.dateOpr === 'month' || this.model.dateOpr === 'lastmonth' || this.model.dateOpr === 'lastweek'
  }

  public changeOnMobileDate() {
    console.log("change Date on Mobile");
    this.isDateMobile = true;
    this.isDateLaptop = false;
  }

  public changeOnLaptopDate() {
    console.log("change Date on Laptop");
    this.isDateLaptop = true;
    this.isDateMobile = false;
  }

  private validateAdvanceSearchItem(advanceSearchItem: any) {
    let result = true;
    switch (advanceSearchItem.name) {
      case SEARCH_ADVANCE.SOURCE_OF_FUND:
        result = this.model.sourceType === SEARCH_ADVANCE.BILL_PAYMENT
          && this.temporaryList.find((searchItem) => searchItem.name === SEARCH_ADVANCE.PAYMENT_METHOD);
        break;
      case SEARCH_ADVANCE.PAYMENT_TYPE:
        const checkSourceType = this.model.sourceType === SEARCH_ADVANCE.CREDIT_CARD || this.model.sourceType === SEARCH_ADVANCE.BILL_PAYMENT;
        result = !this.isShowPaymentTypeError && checkSourceType
          && this.temporaryList.find((searchItem) => searchItem.name === SEARCH_ADVANCE.PAYMENT_METHOD);
        break;
      case SEARCH_ADVANCE.AMOUNT:
        result = this.model.amountRef1 >= 0 && !this.amountRef1Ctrl.errors;
        if (this.amountOption === AMOUNT_OPTION.BETWEEN) {
          result = result && this.model.amountRef2 >= 0 && !this.amountRef2Ctrl.errors;
        }
        break;
      case SEARCH_ADVANCE.MOBILE_NO:
        result = this.model.mobileNo && this.model.mobileNo.length > 0 && !this.mobileNoCtrl.errors;
        break;
      case SEARCH_ADVANCE.COMPANY:
        result = !(advanceSearchItem.company.filter((company) => company.value === false).length === advanceSearchItem.company.length);
        break;
      case SEARCH_ADVANCE.TRANSACTION_DATE:
        result = !this.displayTrxDateError.fromDate && !this.displayTrxDateError.toDate && !this.displayTrxDateError.dateRange && !this.displayTrxDateError.endBeforeTo
         && this.model.dateRef1 && this.model.dateRef2;
        break;
    }
    return result;
  }

  changeSof(sof: CheckboxModel[]) {
    this.isShowPaymentTypeError = sof[0].id == SOURCE_OF_FUND_ID.NOTIFICATION_ID ? sof[0].value == false && sof[1].value == true : sof[0].value == true && sof[1].value == false;
  }

  showSof(sof: string): string {
    var result = sof;
    switch (sof) {
      case SOURCE_OF_FUND.NOTIFICATION:
        result = SOURCE_OF_FUND_DISPLAY.NOTIFICATION;
        break;
      case SOURCE_OF_FUND.APP_SWITCH:
        result = SOURCE_OF_FUND_DISPLAY.APP_SWITCH;
        break;
    }
    return result;
  }

  private createTransactionRequestDateModel(searchTypeDate: string, dateOpr: string, dateRef1: any, dateRef2: any) {
    const result = new TransactionRequestDateModel();
    result.searchTypeDate = searchTypeDate;
    result.dateRef1 = this.changeDateInterfaceStringToDate(dateRef1);
    result.dateRef2 = this.changeDateInterfaceStringToDate(dateRef2);
    return result;
  }

  private changeDateInterfaceStringToDate(dateInput: any): Date {
    if (new Date(dateInput).getTime()) {
      return new Date(dateInput);
    }
    if (typeof dateInput === 'string' || dateInput instanceof String) {
      const dateInputArray = dateInput.split('-');
      return new Date(+dateInputArray[0], +dateInputArray[1] - 1, +dateInputArray[2]);
    } else if (typeof dateInput.getMonth === 'function' || dateInput instanceof Date) {
      return dateInput;
    } else {
      return new Date(dateInput.year, dateInput.month - 1, dateInput.day);
    }
  }

  addMoreFilter() {
    const available = this.availableSearchList.find(x =>
      !this.temporaryList.some(m => m.id === x.id || (m.id <= 2 && x.id <= 2))
    );
    if (this.model.sourceType) {
      if (this.sourceOfFunds.length == 0) {
        this.getSourceOfFunds(this.model.sourceType);
      } else if (this.paymentTypes.length == 0) {
        this.getPaymentTypes(this.model.sourceType)
      }
    }
    if (this.isExport) {
      this.temporaryList.push({
        id: available.id,
        name: available.name,
        cardType: this.exportCardTypes,
        currencyCode: this.exportCurrencyCodes,
        company: this.exportCompanies,
        secureTransaction: this.exportSecureTransactions,
        paymentType: this.exportPaymentTypes,
        sourceOfFund: this.exportSourceOfFunds,
        transactionStatus: this.exportTransactionStatus
      });
    } else {
      this.temporaryList.push({
        id: available.id,
        name: available.name,
        cardType: this.cardTypes,
        currencyCode: this.currencyCodes,
        company: this.companies,
        secureTransaction: this.secureTransactions,
        paymentType: this.paymentTypes,
        sourceOfFund: this.sourceOfFunds,
        transactionStatus: this.transactionStatus
      });
    }

    // this.selectedCriteria = JSON.parse(JSON.stringify(this.temporaryList));
    this.declareFormControl();
    if (this.temporaryList.some(x => x.id <= 2) && this.temporaryList.length === this.availableSearchList.length - 1) {
      this.isShowAddButton = false;
    }
    if (this.temporaryList.find(value => value.id == available.id && available.name == SEARCH_ADVANCE.CHANNEL)) {
      this.setChannelConfig();
    }
  }

  modelChanged(searchItemId: number) {
    for (let i = 0; i < this.temporaryList.length; i++) {
      if (this.temporaryList[i].id == searchItemId) {
        this.temporaryList[i].name = this.availableSearchList.find((searchItem) => searchItem.id === searchItemId).name;
      }
    }
    if (this.model.sourceType && !this.sourceOfFunds) {
      this.getSourceOfFunds(this.model.sourceType);
    }
    const paymentTypeSearch = this.temporaryList.find((itemSearch) => itemSearch.name === SEARCH_ADVANCE.PAYMENT_TYPE);
    if (this.model.sourceType && paymentTypeSearch && paymentTypeSearch.paymentType.indexOf(this.paymentTypes[0]) === -1) {
      this.getPaymentTypes(this.model.sourceType);
    }
    // Call api to get channel list follow config on bank portal
    if (this.temporaryList.find(value => value.id == searchItemId && value.name == SEARCH_ADVANCE.CHANNEL)) {
      this.setChannelConfig();
    }

    this.declareFormControl();
  }

  changeAmountValue(event: string) {
    this.amountOption = event;
    this.amountRef1Ctrl.reset();
    this.amountRef2Ctrl.reset();
  }

  removeFilter(selectedSearchId) {
    if (!this.temporaryList) {
      return;
    }
    const index = this.temporaryList.findIndex(x => x.id === selectedSearchId);
    this.temporaryList.splice(index, 1);

    if (this.temporaryList.length !== this.availableSearchList.length) {
      this.isShowAddButton = true;
    }
  }

  hideAdvanceSearchFilter() {
    this.isShow = false;
    if (this.temporaryList.some(x => x.id <= 2) && this.temporaryList.length === this.availableSearchList.length - 1) {
      this.isShowAddButton = false;
    } else {
      this.isShowAddButton = true;
    }
    this.onApplyFilter.emit({isCancel: true});
  }

  uncheckOthers(at: number, list: SelectedSearch[]) {
    list.forEach((value, index) => value.value = (index === at));
  }

  hideMesSelectPaymentMethod(): boolean {
    if (!this.model.sourceType) {
      return false;
    }
    return this.temporaryList.find((searchItem) => searchItem.name === SEARCH_ADVANCE.PAYMENT_METHOD);
  }

  export() {
    if (this.model.fileName.trim() === '') {
      this.exportFileNameCtrl.setErrors({ 'incorrect': true });
      return;
    }
    const request = this.bindNewRequest();
    this.commonService.isLoading(true);
    this.transactionService.exportTransaction(request).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success(message.content.processing);
        } else {
          this.toasterService.error(message.content.create_fail);
        }
        this.isShow = false;
        this.commonService.isLoading(false);
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  blurField(field: string) {
    if (!this.model[field]) {
      return
    }
    this.model[field] = this.model[field].trim();
  }

  generateFileName() {
    this.model.fileName = this.model.fileName.trim();
    if (!this.model.fileName) {
      this.model.fileName = 'transaction-' + new Date().toDateString();
    }
  }

  isShowUnionPayOption() {
    if (this.temporaryList.length == 0 || !this.temporaryList.find((itemSearch) => itemSearch.name === 'cardType')) {
      return false;
    }
    const itemSearch = this.temporaryList.find((itemSearch) => itemSearch.name === 'cardType');
    return itemSearch.cardType.some((card) => card.name === SEARCH_ADVANCE.UNION_PAY && card.value);
  }

  changeTimeTo(event: SelectionTime) {
    this.timeTo.hour = event.hour;
    this.timeTo.minute = event.minute;
    this.timeToCtrl.setValue((event.hour + ':' + event.minute), { emitEvent: true });
    this.model.timeTo = this.timeToCtrl.value;
  }

  changeTimeFrom(event: SelectionTime) {
    this.timeFrom.hour = event.hour;
    this.timeFrom.minute = event.minute;
    this.timeFromCtrl.setValue((event.hour + ':' + event.minute), { emitEvent: true });
    this.model.timeFrom = this.timeFromCtrl.value;
  }

  closeTimeFromSelection(isOutSide: boolean) {
    this.isOpenTimeFrom = !isOutSide;
  }

  closeTimeToSelection(isOutSide: boolean) {
    this.isOpenTimeTo = !isOutSide;
  }

  private setChannelConfig() {
    this.transactionService.getChannelConfig().subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.channelList = response.channels;
      }
    });
  }

  private setTransactionStatus(trxStatus) {
    if (!trxStatus) {
      return
    }
    for (const status of trxStatus) {
      status.value = this.requestTransaction.status ? this.requestTransaction.status.includes(status.name) : false;
    }
  }

  private declareFormControl() {
    if (this.temporaryList.some((searchItem) => searchItem.name === SEARCH_ADVANCE.AMOUNT)) {
      this.amountRef1Ctrl = this.amountRef1Ctrl ? this.amountRef1Ctrl : new FormControl('', [Validators.pattern(new RegExp(PATTERN.NUMBER_WITH_DOT_PATTERN)), Validators.required]);
      this.amountRef2Ctrl = this.amountRef2Ctrl ? this.amountRef2Ctrl : new FormControl('', [Validators.pattern(new RegExp(PATTERN.NUMBER_WITH_DOT_PATTERN)), Validators.required]);
    }
    if (this.temporaryList.some((searchItem) => searchItem.name === SEARCH_ADVANCE.MOBILE_NO)) {
      this.mobileNoCtrl = this.mobileNoCtrl ? this.mobileNoCtrl : new FormControl('', [Validators.pattern(new RegExp(PATTERN.NUMBER_ONLY)), Validators.required]);
    }
  }

  setConfig() {
    this.maskFirstAndLastDigit = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, 'x', 'x', '-', 'x', 'x', 'x', 'x', '-', /\d/, /\d/, /\d/, /\d/];
    this.maskLastDigit = ['x', 'x', 'x', 'x', '-', 'x', 'x', 'x', 'x', '-', 'x', 'x', 'x', 'x', '-', /\d/, /\d/, /\d/, /\d/];
    // 1234-45xx-xxxx-6789

    for (const name of this.config) {
      switch (name) {
        case 'cardNo':
          this.availableSearchList.push({
            id: 1,
            name: 'cardNoFirstAndLast',
            showName: 'First 6 and Last 4 Digits',
            operation: 'F',
            value: ''
          });
          this.availableSearchList.push({
            id: 2,
            name: 'cardNoLast',
            showName: 'Last 4 Digits',
            operation: 'L',
            value: ''
          });
          break;
        case 'productDescription':
          this.availableSearchList.push({
            id: 3,
            name: 'productDescription',
            showName: 'Description',
            value: ''
          });
          break;
        case 'sourceType':
          this.availableSearchList.push({
            id: 4,
            name: 'sourceType',
            showName: 'Payment Method',
            value: ''
          });
          break;
        case 'sourceOfFund':
          this.availableSearchList.push({
            id: 5,
            name: 'sourceOfFund',
            showName: 'Source Of Fund',
            value: '',
          });
          break;
        case 'paymentType':
          this.availableSearchList.push({
            id: 6,
            name: 'paymentType',
            showName: 'Payment Type',
            value: '',
          });
          break;
        case 'cardType':
          this.availableSearchList.push({
            id: 7,
            name: 'cardType',
            showName: 'Card Type',
            isShowLabel: true,
            value: ''
          });
          break;
        case 'currencyCode':
          this.availableSearchList.push({
            id: 8,
            name: 'currencyCode',
            showName: 'Currency Code',
            value: ''
          });
          break;
        case 'amount':
          this.availableSearchList.push({
            id: 9,
            name: 'amount',
            showName: 'Amount',
            value: '',
          });
          break;
        case 'transactionDate':
          this.availableSearchList.push({
            id: 10,
            name: 'transactionDate',
            showName: 'Transaction Date/Time',
            value: '',
          });
          break;
        case 'authorizedDate':
          this.availableSearchList.push({
            id: 11,
            name: 'authorizedDate',
            showName: 'Authorized Date',
            value: '',
          });
          break;
        case 'company':
          this.availableSearchList.push({
            id: 12,
            name: 'company',
            showName: 'Company',
            value: '',
          });
          break;
        case 'bankName':
          this.availableSearchList.push({
            id: 13,
            name: 'bankName',
            showName: 'Issuer Bank Name',
            value: '',
          });
          break;
        case 'refTrxId':
          this.availableSearchList.push({
            id: 14,
            name: 'refTrxId',
            showName: 'Provider ID',
            value: '',
          });
          break;
        case 'objectId':
          this.availableSearchList.push({
            id: 15,
            name: 'objectId',
            showName: 'Object ID',
            value: '',
          });
          break;
        case 'secureTransaction':
          this.availableSearchList.push({
            id: 16,
            name: 'secureTransaction',
            showName: '3D Secure',
            value: '',
          });
          break;
        case 'refNumber':
          this.availableSearchList.push({
            id: 18,
            name: 'refNumber',
            showName: 'Ref Number',
            value: '',
          });
          break;
        case 'objectId':
          this.availableSearchList.push({
            id: 19,
            name: 'objectId',
            showName: 'Charge ID',
            value: '',
          });
          break;
        case 'customerEmail':
          this.availableSearchList.push({
            id: 20,
            name: 'customerEmail',
            showName: 'Customer Email',
            value: ''
          });
          break;
        case 'customerName':
          this.availableSearchList.push({
            id: 21,
            name: 'customerName',
            showName: 'Customer Name',
            value: ''
          });
          break;
        case 'customerObjectId':
          this.availableSearchList.push({
            id: 22,
            name: 'customerObjectId',
            showName: 'Customer Object Id',
            value: ''
          });
          break;
        case 'customerCreateDate':
          this.availableSearchList.push({
            id: 23,
            name: 'customerCreateDate',
            showName: 'Create Date',
            value: '',
          });
          break;
        case 'mobileNo':
          this.availableSearchList.push({
            id: 24,
            name: 'mobileNo',
            showName: 'Mobile No',
            value: '',
          });
          break;
        case 'ref1':
          this.availableSearchList.push({
            id: 25,
            name: 'ref1',
            showName: 'Ref1',
            value: '',
          });
          break;
        case 'ref2':
          this.availableSearchList.push({
            id: 26,
            name: 'ref2',
            showName: 'Ref2',
            value: '',
          });
          break;
        case 'ref3':
          this.availableSearchList.push({
            id: 27,
            name: 'ref3',
            showName: 'Ref3',
            value: '',
          });
          break;
        case 'merchantId':
          this.availableSearchList.push({
            id: 28,
            name: 'merchantId',
            showName: 'Merchant ID',
            value: '',
          });
          break;
        case 'transactionStatus':
          this.availableSearchList.push({
            id: 29,
            name: 'transactionStatus',
            showName: 'Transaction Status',
            value: ''
          });
          break;
        case 'paymentLinkRef':
          this.availableSearchList.push({
            id: 30,
            name: 'paymentLinkRef',
            showName: 'Payment Link Ref.',
            value: ''
          });
          break;
        case 'refundId':
          this.availableSearchList.push({
            id: 31,
            name: 'refundId',
            showName: 'RefundId',
            value: ''
          });
          break;
        case 'supplierNo':
          this.availableSearchList.push({
            id: 32,
            name: 'supplierNo',
            showName: 'Supplier No.',
            value: ''
          });
          break;
        case SEARCH_ADVANCE.CHANNEL:
          this.availableSearchList.push({
            id: 33,
            name: SEARCH_ADVANCE.CHANNEL,
            showName: 'Channel',
            value: ''
          });
          break;
        case 'bulkRefundId':
          this.availableSearchList.push({
            id: 34,
            name: 'bulkRefundId',
            showName: 'Bulk Refund ID',
            value: ''
          });
          break;
        default: {
          break;
        }
      }
    }
  }

  private removeTrxDateMgs(error: {fromDate: string, toDate: string}) {
    if (error.fromDate == DATE_VALIDATION_MSG.REQUIRED || error.fromDate == DATE_VALIDATION_MSG.INVALID) {
      error.fromDate = '';
    }
    if (error.toDate == DATE_VALIDATION_MSG.REQUIRED || error.toDate == DATE_VALIDATION_MSG.INVALID) {
      error.toDate = '';
    }
  }
}
