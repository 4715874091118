import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

import { environment } from '../shared/app-constant';

import { CustomerModel } from './models/customer.model';
import { CustomerRequestModel } from './models/customer.request.model';
import { CardModel } from './models/card.model';
import { CustomerInfoModel } from './models/customer-info.model';
import { GetCustomerInfoRequestModel } from './models/get-customer-info.request.model';
import { GetCardsRequestModel } from './models/get-cards.request.model';
import { map } from "rxjs/operators";

@Injectable()
export class CustomerService {

    constructor(private http: HttpClient, private auth: AuthenticationService) { }

    searchCustomer(request: CustomerRequestModel): Observable<CustomerModel> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.customersApiUrl.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request))
            .pipe(map((response: CustomerModel) => { return response; }))
                // .map((response: CustomerModel) => {
                //     return response;
                    // });
    }

    getCustomerInfo(request: GetCustomerInfoRequestModel): Observable<CustomerInfoModel> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.getCustomerInfoApiUrl.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request))
            .pipe(map((response: CustomerInfoModel) => { return response; }))
        // .map((response: CustomerInfoModel) => {
        //     return response;
        // });
    }

    getCards(request: GetCardsRequestModel): Observable<CardModel> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.cardsApiUrl.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request))
            .pipe(map((response: CardModel) => { return response; }))
            // .map((response: CardModel) => {
            //     return response;
            // });
    }
}
