import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CustomerService } from '../../services/customer.service';

import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { CustomerModel } from '../../services/models/customer.model';
import { CustomerRequestModel } from '../../services/models/customer.request.model';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CustomerComponent implements OnInit {
  currentMenuInfo: any = {};

  selectedSearchList = [];
  selectListConfig = [
    'customerCreateDate',
    'customerEmail',
    'customerName',
    'customerObjectId'
  ];
  request: TransactionRequestModel;
  customerModel = new CustomerModel();

  isShowCustomerNameColumn = false;
  isShowLinkCustomerCard = false;

  constructor(private commonService: CommonService,
              private auth: AuthenticationService,
              private customerService: CustomerService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentMenuInfo = this.auth.getCurrentUserMenuInfo().find(x => x.url === 'customer');
    this.isShowLinkCustomerCard = this.isLinkCustomerCardAllowed();
    this.request = this.createTransactionRequest();
    this.searchCustomer();
  }

  private isLinkCustomerCardAllowed(): boolean {
    let result = false;
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      menuInfos.forEach(menuInfo => {
        if (menuInfo.screenCode === 'C008') {
          const componentsInfos = menuInfo.componentsInfo;
          if (componentsInfos) {
            result = componentsInfos.some(componentsInfo => componentsInfo.roleActionCode === 'ROLE_B_3300');
          }
        }
      });
    }
    return result;
  }

  private searchCustomer() {
    this.commonService.isLoading(true);
    const customerRequestModel = this.toCustomerRequestModel(this.request);
    this.customerService.searchCustomer(customerRequestModel).subscribe(
      result => {
        this.clearCustomer();
        if (this.commonService.isSuccess(result)) {
          this.customerModel = result;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.setShowDynamicColumns();
        this.commonService.isLoading(false);
      });
  }

  loadPage(pageIndex: number) {
    this.request.pageIndex = pageIndex;
    this.searchCustomer();
  }

  private createTransactionRequest(): TransactionRequestModel {
    const request = new TransactionRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });

    request.pageIndex = 1;
    request.pageSize = 10;

    return request;
  }

  onApplyFilter(request: TransactionRequestModel) {
    this.request = request;
    this.searchCustomer();
  }

  viewCards(customerObjectId: string) {
    console.log('viewCards ' + customerObjectId);
    this.request.selectedSearchList = this.selectedSearchList;
    // this.cacheObservableService.set('transaction_detail_goback', this.request);
    this.router.navigate(['/customer-card/' + customerObjectId]);
  }

  private clearCustomer() {
    this.customerModel = new CustomerModel();
    this.customerModel.total_elements = '0';
  }

  private toCustomerRequestModel(request: TransactionRequestModel): CustomerRequestModel {
    const customerRequestModel = new CustomerRequestModel();
    customerRequestModel.companyId = request.companyId;

    customerRequestModel.customerEmail = request.customerEmail;
    customerRequestModel.customerName = request.customerName;
    customerRequestModel.customerObjectId = request.customerObjectId;
    customerRequestModel.searchDate = request.searchDate;

    customerRequestModel.pageIndex = request.pageIndex;
    customerRequestModel.pageSize = request.pageSize;
    return customerRequestModel;
  }

  private setShowDynamicColumns() {
    this.isShowCustomerNameColumn = this.selectedSearchList.some(x => x.name === 'customerName');
  }
}
