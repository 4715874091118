import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FILE_TYPES, message } from '../../shared/app-constant';
import { Location } from '@angular/common';
import { ToasterService } from '../../services/toaster.service';
import { RecurringPaymentService } from '../../services/recurring-payment.service';
import { RecurringPaymentUploadFileRequest } from '../../services/models/Recurring-payment-upload-file-request.model';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recurring-upload-file',
  templateUrl: './recurring-upload-file.component.html',
  styleUrls: ['./recurring-upload-file.component.css']
})
export class RecurringUploadFileComponent implements OnInit {
  
  @ViewChild('inputFile') inputFile: ElementRef;
  public fileTypes: any;
  public invalidFile: boolean;
  public recurringUploadModel: RecurringPaymentUploadFileRequest;
  public fileName: string;
  public maxRecurringRecords: string;

  constructor(
    private location: Location,
    private toasterService: ToasterService,
    private recurringPaymentService: RecurringPaymentService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fileTypes = FILE_TYPES;
    this.recurringUploadModel = new RecurringPaymentUploadFileRequest();
    this.fileName = '';
    this.generateBatchId();
  }

  public chooseFile(element) {
    this.inputFile.nativeElement.click();
    element.blur();
  }

  public changeFile(event) {
    const fileInfo = event.target.files[0]
    const fileName = fileInfo.name.split('.');
    this.fileName = fileInfo.name;
    if (fileName[fileName.length - 1] != 'csv') {
      this.invalidFile = true;
      return
    } else {
      this.invalidFile = false;
    }
    this.recurringUploadModel.file = fileInfo;
  }

  public uploadFile() {
    this.commonService.isLoading(true);
    this.recurringPaymentService.uploadFile(this.recurringUploadModel).subscribe((response) => {
      if(this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.upload_success);
        this.router.navigate(['/recurring-payment']);
      } else {
        this.toasterService.error(response.status_message);
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }

  public goBack() {
    this.location.back();
  }

  private generateBatchId() {
    this.commonService.isLoading(true);
    this.recurringPaymentService.generateBatchId().subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.recurringUploadModel.batchId = response.batchId;
        this.maxRecurringRecords = response.maxRecurringRecords;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }

}
