import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CommonService } from '../../services/common.service';
import { TransactionService } from '../../services/transaction.service';
import { TransactionDetailService } from '../../services/transaction-detail.service';
import { TransactionDetailRequestModel } from '../../services/models/transaction-detail-request.model';
import { TransactionDetailModel } from '../../services/models/transaction-detail.model';
import { TransactionInfoModel } from '../../services/models/transaction-info.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CacheObservableService } from '../../services/cache-observable.service';
import { TransactionStatus } from '../../shared/transaction-status.enum';
import { TransactionStatusState } from '../../shared/transaction-status-state.enum';
import { SettleConfirmContentModel } from '../../services/models/settle-confirm-content.model';
import { SettleConfirmResultModel } from '../../services/models/settle-confirm-result.model';
import { RefundConfirmContentModel } from '../../services/models/refund-confirm-content.model';
import { RefundConfirmResultModel } from '../../services/models/refund-confirm-result.model';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { PAY_BY, PAYMENT_METHOD, SOURCE_TYPE, SOURCE_OF_FUND, SOURCE_OF_FUND_DISPLAY } from '../../shared/app-constant';
import { TransactionDetailLogModel } from '../../services/models/transaction-detail-log.model';
import { DateUtil } from '../../shared/date.util';

const TRANSACTION_STATE = {
  SETTLED: 'Settled'
}

const REFUND_CONST = {
  REFUND: 'refund',
  SUCCESS: 'success',
  FAIL: 'fail',
  PROCESSING: 'processing'
}

const REFUND_ERROR_CODE = ['01', '02', '03', '04', '05', '600', '', null]

const SMARTPAY = 'SMARTPAY';
const ADJUST = 'ADJUST';
const IPAN_MODE_DISABLE_PARTIAL_REFUND = ['02', '03', '04'];

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css']
})
export class TransactionDetailComponent implements OnInit, OnDestroy {

  @ViewChildren('listLog') listLog: QueryList<ElementRef>;

  faLock = faLock;

  chargeId: string;
  request: TransactionDetailRequestModel;
  transactionDetail: TransactionDetailModel;
  msgBtnActins = 'Actions';
  isFetchDataCompleted = false;
  isShowBtnActions = false;
  // isDisableBtnActions = false;
  isShowBtnVoid = false;
  isShowBtnRefund = false;
  isShowBtnSettle = false;
  isShow3DSecure = false;
  isShowSmartpayInfo = false;
  isClickGoBack = false;
  isShowCardInfo = false;
  isShowQrInfo = false;
  isShowRedirectInfo = false;
  isShowPayPlusInfo = false;
  isShowCustomer = false;
  txtColorAmount: {};
  isShowTokenId = false;
  isThaiQRType = false;
  isShowBillerID = false;
  isShowUnionPay = false;
  // isShowUnionPayXPC = false;

  constructor(
    private auth: AuthenticationService,
    private transactionDetailService: TransactionDetailService,
    private transactionService: TransactionService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonService,
    private translate: TranslateService,
    private cacheObservableService: CacheObservableService
  ) { }

  ngOnInit() {
    console.log('TransactionDetailComponent ngOnInit');
    this.commonService.isLoading(true);
    this.transactionDetail = new TransactionDetailModel();
    this.chargeId = this.route.snapshot.paramMap.get('chargeId');
    this.request = this.setRequestTransactionDetail();
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      menuInfos.forEach(menuInfo => {
        if (menuInfo.screenCode === 'C007') {
          const componentsInfos = menuInfo.componentsInfo;
          if (componentsInfos) {
            componentsInfos.forEach(componentsInfo => {
              if (componentsInfo.roleActionCode === 'ROLE_B_2501') {
                this.isShowBtnVoid = true;
              }
              if (componentsInfo.roleActionCode === 'ROLE_B_2502') {
                this.isShowBtnRefund = true;
              }
              if (componentsInfo.roleActionCode === 'ROLE_B_2503') {
                this.isShowBtnSettle = true;
              }
              if (this.isShowBtnVoid === true || this.isShowBtnRefund === true || this.isShowBtnSettle === true) {
                this.isShowBtnActions = true;
              }
            });
          }
        }
      });
    }
    this.getTransactionDetail(this.request, this.chargeId);
  }

  ngOnDestroy() {
    console.log('TransactionDetailComponent ngOnDestroy');
    if (!this.isClickGoBack) {
      this.cacheObservableService.clear();
    }
  }

  goBack() {
    console.log('TransactionDetailComponent goBack');
    this.isClickGoBack = true;
    this.location.back();
  }

  clickActions(actionName: string) {

    if (actionName === 'void') {
      const callback = new Subject<boolean>();
      callback.asObservable().subscribe(result => {
        if (result === true) {
          this.doProcessVoid();
        }
      });
      this.commonService.alertConfirm('Confirm Void', 'Please Confirm Void', callback);

    } else if (actionName === 'refund') {
      const callback = new Subject<any>();
      callback.asObservable().subscribe(result => {
        if (result instanceof RefundConfirmResultModel) {
          this.doProcessRefund(result);
        }
      });
      // this.commonService.alertConfirm('Confirm Refund', 'Please Confirm Refund', callback);
      const content = new RefundConfirmContentModel();
      const actualSettledAmount = this.isDccPaymentType() ? this.transactionDetail.bahtSettledAmount : this.transactionDetail.settledAmount;
      const actualCurrencyCode = this.isDccPaymentType() ? 'THB' : this.transactionDetail.currencyCode;

      content.settledAmount = actualSettledAmount;
      if (this.transactionDetail.refundSentAmount && parseFloat(this.transactionDetail.refundSentAmount)) { // partial refund
        content.amount = '' + (parseFloat(actualSettledAmount) - parseFloat(this.transactionDetail.refundSentAmount)).toFixed(2);
        content.isPartialRefund = true;
      } else { // full refund
        content.amount = actualSettledAmount;
        content.isPartialRefund = false;
      }

      content.disablePartialRefund = this.transactionDetail.paymentTypeCode == SMARTPAY && IPAN_MODE_DISABLE_PARTIAL_REFUND.some((ipan) => ipan == this.transactionDetail.smartpayIpanMode);
      content.isInstallmentCrossBank =  this.transactionDetail.installmentCrossBank;
      content.currencyCode = actualCurrencyCode;
      content.paymentTypeCode = this.transactionDetail.paymentTypeCode;
      content.cardBrand = this.transactionDetail.cardBrand;
      this.commonService.alertConfirm('Confirm Refund', content, callback);
    } else if (actionName === 'settle') {
      const callback = new Subject<any>();
      callback.asObservable().subscribe(result => {
        if (result instanceof SettleConfirmResultModel) {
          this.doProcessSettle(result);
        }
      });
      // this.commonService.alertConfirm('Confirm Settle', 'Please Confirm Settle', callback);
      const content = new SettleConfirmContentModel();
      const actualAmount = this.isDccPaymentType() ? this.transactionDetail.bahtAmount : this.transactionDetail.amount;
      const actualCurrencyCode = this.isDccPaymentType() ? 'THB' : this.transactionDetail.currencyCode;

      content.amount = actualAmount;
      content.currencyCode = actualCurrencyCode;
      content.paymentTypeCode = this.transactionDetail.paymentTypeCode;
      this.commonService.alertConfirm('Confirm Settle', content, callback);
    }

  }

  public isDccPaymentType(): boolean {
    return this.transactionDetail.paymentTypeCode === 'DCC';
  }

  canExpandRefundDetail(log: TransactionDetailLogModel) {
    return log.source == REFUND_CONST.REFUND
      && (log.status == REFUND_CONST.SUCCESS || (log.status == REFUND_CONST.FAIL && !REFUND_ERROR_CODE.includes(log.errorCode)))
      && this.transactionDetail.sourceType != SOURCE_TYPE.ALIPAY
      && this.transactionDetail.sourceType != SOURCE_TYPE.WECHAT;
  }

  showRefundId(log: TransactionDetailLogModel) {
    return log.source === REFUND_CONST.REFUND
      && (log.status === REFUND_CONST.SUCCESS || log.status === REFUND_CONST.FAIL || log.status === REFUND_CONST.PROCESSING)
      && this.transactionDetail.sourceType != SOURCE_TYPE.ALIPAY
      && this.transactionDetail.sourceType != SOURCE_TYPE.WECHAT;
  }

  toggleLogRecord(index: number) {
    const list = this.listLog.toArray();
    list.forEach((row) => {
      if (list.indexOf(row) == index) {
        if (row.nativeElement.childNodes[0].childNodes[0].classList.contains('first-column-active')) {
          row.nativeElement.childNodes[0].childNodes[0].classList.remove('first-column-active')
        } else {
          row.nativeElement.childNodes[0].childNodes[0].className += ' first-column-active';
        }
      }
    })
  }

  displaySOF(name: string): string {
    var result = name;
    switch(name) {
      case SOURCE_OF_FUND.NOTIFICATION:
        result = SOURCE_OF_FUND_DISPLAY.NOTIFICATION;
        break;
      case SOURCE_OF_FUND.APP_SWITCH:
        result = SOURCE_OF_FUND_DISPLAY.APP_SWITCH;
        break;
    }
    return result
  }

  private setRequestTransactionDetail(): TransactionDetailRequestModel {
    const request = new TransactionDetailRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });
    return request;
  }

  private getTransactionDetail(request: TransactionDetailRequestModel, chargeId: string) {
    this.transactionDetailService.getTransactionDetail(request, chargeId).subscribe(result => {
      if (this.commonService.isSuccess(result)) {
        console.log(result);
        this.transactionDetail = result;
        this.isThaiQRType = result.qrType === PAYMENT_METHOD.THAI_QR;
        this.isShowPayPlusInfo = result.sourceType === SOURCE_TYPE.BILL_PAYMENT;
        this.isShowBillerID = result.billpaymentSof === SOURCE_OF_FUND.NOTIFICATION;
        if (this.isShowBtnActions === true) {
          this.doProcessShowActionsBtn(result);
        }
        if (result.secureTransaction === 'true') {
          this.isShow3DSecure = true;
        }
        if (result.paymentTypeCode === 'SMARTPAY') {
          this.isShowSmartpayInfo = true;
        }
        if (result.sourceType === 'qr') {
          this.isShowQrInfo = true;
        } else if ((result.sourceType === 'card')) {
          this.isShowCardInfo = true;
          if (result.customerId) {
            this.isShowCustomer = true;
          }
        } else if (result.sourceType === 'redirect' && result.redirectType === 'UnionPay') {
          this.isShowCardInfo = true;
          this.isShowUnionPay = true;
        } else if (result.sourceType === 'redirect') {
          this.isShowRedirectInfo = true;
        }
        // if (result.cardBrand === 'UnionPay') {
        //   this.isShowUnionPayXPC = true;
        // }
        this.txtColorAmount = this.getStatusColorClass(result);
        if (result.tokenId) {
          this.isShowTokenId = result.tokenId.toLowerCase().startsWith('tokn');
        }
        this.isFetchDataCompleted = true;
      } else {
        this.commonService.alertError('Fail', result.status_message, null);
      }
      this.commonService.isLoading(false);
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private doProcessSettle(confirmResult: SettleConfirmResultModel) {
    this.commonService.isLoading(true);
    const settlementType = confirmResult.type;
    const trans = new TransactionInfoModel();
    let errorMsg = null;

    trans.chargeId = this.transactionDetail.chargeId;
    const actualAmount = this.isDccPaymentType() ? this.transactionDetail.bahtAmount : this.transactionDetail.amount;
    if (settlementType === 'full') {
      trans.Amount = actualAmount;
    } else if (settlementType === 'partial') {
      const fullAmount = actualAmount;
      const partialAmount = confirmResult.amount;
      if (!partialAmount || isNaN(Number(partialAmount)) || parseFloat(partialAmount) <= 0) {
        errorMsg = 'Invalid partial amount';
      } else if (parseFloat(partialAmount) >= parseFloat(fullAmount)) {
        errorMsg = 'Partial amount ' + partialAmount + ' >= ' + fullAmount;
      } else {
        trans.Amount = partialAmount;
      }
    } else {
      errorMsg = 'Invalid settlement type';
    }

    if (errorMsg) {
      this.commonService.isLoading(false);
      this.alertFail('Settlement', errorMsg);
      return;
    }

    this.transactionService.settleTransaction([trans], settlementType).subscribe(json => {
      const result = json['data'][0];
      if (this.commonService.isSuccess(result)) {
        this.refreshTransactionDetail();
        this.alertSuccess('Settlement');
      } else {
        this.commonService.isLoading(false);
        // this.alertFail('Settlement', result.status_code);
        this.alertFailMessage('Settlement', result.status_code, result.status_message);
      }
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private doProcessVoid() {
    this.commonService.isLoading(true);
    const trans = new TransactionInfoModel();
    trans.chargeId = this.transactionDetail.chargeId;
    this.transactionService.voidTransaction([trans]).subscribe(json => {
      const result = json['data'][0];
      if (this.commonService.isSuccess(result)) {
        this.refreshTransactionDetail();
        this.alertSuccess('Void');
      } else {
        this.commonService.isLoading(false);
        // if (result.status_code.indexOf('qr_void') > -1) {
        //   this.alertFailMessage('Void', result.status_code, result.status_message);
        // } else {
        //   this.alertFail('Void', result.status_code);
        // }
        this.alertFailMessage('Void', result.status_code, result.status_message);
      }
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private doProcessRefund(confirmResult: RefundConfirmResultModel) {
    this.commonService.isLoading(true);
    const refundType = confirmResult.type;
    const trans = new TransactionInfoModel();
    let errorMsg = null;

    trans.chargeId = this.transactionDetail.chargeId;
    const actualSettledAmount = this.isDccPaymentType() ? this.transactionDetail.bahtSettledAmount : this.transactionDetail.settledAmount;
    if (refundType === 'full') {
      trans.Amount = actualSettledAmount;
    } else if (refundType === 'partial') {
      const fullAmount = '' + (parseFloat(actualSettledAmount) - parseFloat(this.transactionDetail.refundSentAmount)).toFixed(2);
      const partialAmount = confirmResult.amount;
      if (!partialAmount || isNaN(Number(partialAmount)) || parseFloat(partialAmount) <= 0) {
        errorMsg = 'Invalid partial amount';
      } else if (parseFloat(partialAmount) > parseFloat(fullAmount)) {
        errorMsg = 'Partial amount ' + partialAmount + ' > ' + fullAmount;
      } else {
        trans.Amount = partialAmount;
      }
    } else {
      errorMsg = 'Invalid settlement type';
    }

    if (errorMsg) {
      this.commonService.isLoading(false);
      this.alertFail('Refund', errorMsg);
      return;
    }

    this.transactionService.refundTransaction([trans]).subscribe(json => {
      const result = json['data'][0];
      if (this.commonService.isSuccess(result)) {
        this.refreshTransactionDetail();
        this.alertSuccess('Refund');
      } else {
        this.commonService.isLoading(false);
        // this.alertFail('Refund', result.status_code + ': ' + result.status_message);
        this.alertFailMessage('Refund', result.status_code, result.status_message);
      }
    },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  private doProcessShowActionsBtn(result: TransactionDetailModel) {
    const statusId = parseInt(result.statusId, 10);
    const statusStateId = parseInt(result.statusStateId, 10);
    // If payment method is Wechat or Alipay,
    // set refundable of this transaction by allowWechatRefund/allowAlipayRefund
    if (result.qrType === PAYMENT_METHOD.WECHAT) {
      this.isShowBtnRefund = result.allowWechatRefund ? this.isShowBtnRefund && result.allowWechatRefund : false;
    }
    if (result.redirectType === PAYMENT_METHOD.ALIPAY) {
      this.isShowBtnRefund = result.allowAlipayRefund ? this.isShowBtnRefund && result.allowAlipayRefund : false;
    }
    // Authorized_Process, Authorized_Complete
    if (statusId === TransactionStatus.Authorized
      && (statusStateId === TransactionStatusState.Process || statusStateId === TransactionStatusState.Complete)) {
      // this.isShowBtnVoid = default by permission
      this.isShowBtnRefund = false;
      if (result.settlementType === 'AUTO') {
        this.isShowBtnSettle = false;
      } else if (result.sourceType === 'redirect') {
        this.isShowBtnSettle = false;
      } else {
        // this.isShowBtnSettle = default by permission
      }

      // Qr_Authorized_Complete
    } else if (statusId === TransactionStatus.Qr_Authorized && statusStateId === TransactionStatusState.Complete) {
      // this.isShowBtnVoid = default by permission
      this.isShowBtnRefund = false;
      this.isShowBtnSettle = false;

      // Settled_Process
    } else if (statusId === TransactionStatus.Settled && statusStateId === TransactionStatusState.Process) {
      if (this.isShowBtnVoid === true && result.voidAfterSettlement === 'true') {
        this.isShowBtnVoid = true;
      } else {
        this.isShowBtnVoid = false;
      }
      this.isShowBtnRefund = false;
      this.isShowBtnSettle = false;

      // Settled_Complete
    } else if ((statusId === TransactionStatus.Settled || statusId === TransactionStatus.Qr_Settled)
      && statusStateId === TransactionStatusState.Complete) {
      this.isShowBtnVoid = false;
      // this.isShowBtnRefund = default by permission
      this.isShowBtnSettle = false;

      if (this.isThisRedirectNotAllowToRefund(result.redirectType) || this.isSmartpayNotRefundable()
        || this.isNotEnoughToRefund() || result.qrType === PAYMENT_METHOD.THAI_QR
        || result.convFee || result.sourceType === SOURCE_TYPE.BILL_PAYMENT) { // result.sourceType === 'redirect' ||
        this.isShowBtnRefund = false;
      }

      // Refund_Process or Refund_Complete
    } else if ((statusId === TransactionStatus.Refunded || statusId === TransactionStatus.Qr_Refunded) && (statusStateId === TransactionStatusState.Process || statusStateId === TransactionStatusState.Complete)) {
      this.isShowBtnVoid = false;
      this.isShowBtnSettle = false;

      if (this.isThisRedirectNotAllowToRefund(result.redirectType) || this.isNotEnoughToRefund()
        || result.qrType === PAYMENT_METHOD.THAI_QR || this.isSmartpayNotRefundable() || this.isRefundFailed()) { // result.sourceType === 'redirect' ||
        this.isShowBtnRefund = false;
      }
      // otherwise
    } else {
      this.isShowBtnVoid = false;
      this.isShowBtnRefund = false;
      this.isShowBtnSettle = false;
    }

    if (result.qrType === PAYMENT_METHOD.THAI_QR && !!result.allowQrVoid) {
      this.isShowBtnVoid = this.isShowBtnVoid && result.allowQrVoid === 'Y';
    } else {
      this.isShowBtnVoid = this.isShowBtnVoid && result.payBy != PAY_BY.NON_KBANK;
    }
    let dateCutOffTime = null;
    let authorizeTime = new Date(result.authorizeTime);
    let endOfDate = new Date();
    endOfDate.setHours(23, 59, 0);
    // verify if check follow cut-off time or not
    if ((result.cutOffTime && parseInt(result.cutOffTime.split(':')[0]) < 25 && parseInt(result.cutOffTime.split(':')[1]) < 60) && (result.authorizeTime && authorizeTime.getTime() > 0)) {
      dateCutOffTime = new Date();
      dateCutOffTime.setHours(parseInt(result.cutOffTime.split(':')[0]), parseInt(result.cutOffTime.split(':')[1]), 0);
      if (this.isShowBtnVoid && DateUtil.isTimeBetween(authorizeTime, dateCutOffTime, endOfDate) && DateUtil.isBeforeDate(authorizeTime, dateCutOffTime)) {
        // case authorizeTime is between cut-off time and 23:59 of yesterday => void btn will enable until cut-off time of tommorow
        authorizeTime.setDate(authorizeTime.getDate() + 1);
        this.isShowBtnVoid = DateUtil.isEqualsDate(authorizeTime, dateCutOffTime) && DateUtil.isTimeBefore(authorizeTime, dateCutOffTime);
      } else if (this.isShowBtnVoid && DateUtil.isEqualsDate(authorizeTime, dateCutOffTime)
        && ((DateUtil.isTimeBefore(authorizeTime, dateCutOffTime) && (DateUtil.isTimeAfter(dateCutOffTime, new Date()))) || DateUtil.isTimeBetween(authorizeTime, dateCutOffTime, endOfDate))) {
        // case authorizeTime is before cut-off time or between cut-off time and 23:59 of today 
        this.isShowBtnVoid = true;
      } else if ((DateUtil.isTimeBefore(authorizeTime, dateCutOffTime) && DateUtil.isBeforeDate(authorizeTime, dateCutOffTime)) || DateUtil.isTimeBefore(dateCutOffTime, new Date())) {
        // case authorizeTime is over cut-off time
        this.isShowBtnVoid = false;
      }
    }
    // when MAX_ALLOWED_REFUND_DAYS is reached (configuration on BP), then the button will not be shown.
    this.isShowBtnRefund = this.isShowBtnRefund && !this.transactionDetail.refundExpired;
    this.isShowBtnSettle = this.isShowBtnSettle && !this.transactionDetail.installmentCrossBank;
    //Not show Void button for Adjusted transaction
    if(result.qrPaymentType == ADJUST){
      this.isShowBtnVoid = false;
    }

    if (this.isShowBtnVoid === true || this.isShowBtnRefund === true || this.isShowBtnSettle === true) {
      this.isShowBtnActions = (result.syncInsertApi === null);
    } else {
      this.isShowBtnActions = false;
    }

  }

  private refreshTransactionDetail() {
    this.getTransactionDetail(this.request, this.chargeId);
  }

  private isThisRedirectNotAllowToRefund(redirectType: string): boolean {
    return redirectType === 'UnionPay' || redirectType === 'PayPal';
  }

  private isNotEnoughToRefund(): boolean {
    const actualSettledAmount = this.isDccPaymentType() ? this.transactionDetail.bahtSettledAmount : this.transactionDetail.settledAmount;
    console.log('actualSettledAmount: ' + actualSettledAmount + ', refundSentAmount: ' + this.transactionDetail.refundSentAmount);
    return parseFloat(actualSettledAmount) <= parseFloat(this.transactionDetail.refundSentAmount);
  }

  // check if smartpay transaction can't be refunded.
  private isSmartpayNotRefundable(): boolean {
    return this.transactionDetail.paymentTypeCode == 'SMARTPAY' && !this.transactionDetail.smartPayRefundable
  }

  private isRefundFailed(): boolean {
    return this.transactionDetail.trxState == 'Refund Failed'
  }

  private alertSuccess(action: string) {
    this.translate.get('components.transaction-detail.actionSuccess', { action: action }).subscribe((res: string) => {
      this.commonService.alertSuccess('Success', res, null);
    });
  }

  private alertFail(action: string, code: string) {
    this.translate.get('components.transaction-detail.actionFail', { action: action, code: code }).subscribe((res: string) => {
      this.commonService.alertSuccess('Fail', res, null);
    });
  }

  private alertFailMessage(action: string, code: string, message: string) {
    this.translate.get('components.transaction-detail.actionFailMessage', { action: action, code: code, message: message }).subscribe((res: string) => {
      this.commonService.alertSuccess('Fail', res, null);
    });
  }

  private getStatusColorClass(transaction: TransactionDetailModel) {
    const statusId = parseInt(transaction.statusId, 10);
    const statusStateId = parseInt(transaction.statusStateId, 10);
    let colorClass = {};

    if (statusId === TransactionStatus.Settled || statusId === TransactionStatus.Qr_Settled) {
      colorClass = { 'text-green': true };

    } else if (statusId === TransactionStatus.Refunded) {
      colorClass = { 'text-red': true };

    } else if (statusId === TransactionStatus.Declined || statusId === TransactionStatus.Voided
      || statusId === TransactionStatus.Reversed || statusId === TransactionStatus.Qr_Voided) {
      colorClass = { 'text-darklight': true };

    } else if ((statusId === TransactionStatus.Authorized)
      && (statusStateId === TransactionStatusState.Wait_For_Authentication
        || statusStateId === TransactionStatusState.Wait_For_Confirm
        || statusStateId === TransactionStatusState.Fail)) {
      colorClass = { 'text-darklight': true };

    } else {
      // otherwise: black font color.
    }

    return colorClass;
  }

}
