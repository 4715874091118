import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../shared/app-constant';
import { map } from 'rxjs/operators';
import { RecurringDownloadTemplateResponse } from './models/recurring-download-template-response.model';
import { RecurringPaymentUploadFileRequest } from './models/Recurring-payment-upload-file-request.model';
import { GenerateBatchIdResponse } from './models/generate-batch-id-response.model';
import { ResultModel } from './models/result.model';
import { RecurringPaymentFilter } from './models/recurring-payment-filter.model';
import { RecurringPaymentFilterResponse } from './models/recurring-payment-filter-response.model';

@Injectable({
  providedIn: 'root'
})
export class RecurringPaymentService {

  constructor(private http: HttpClient) { }

  public search(request: RecurringPaymentFilter): Observable<RecurringPaymentFilterResponse> {
    return this.http.post(environment.recurringPaymentSearch, request).pipe(map((response: RecurringPaymentFilterResponse) => {
      return response
    }));
  }

  public downloadFileTemplate(fileName): Observable<RecurringDownloadTemplateResponse> {
    return this.http.get(environment.downloadTemplate.replace('{templateName}', fileName))
      .pipe(map((response: RecurringDownloadTemplateResponse) => {
        return response;
      }))
  }

  public generateBatchId(): Observable<GenerateBatchIdResponse> {
    return this.http.get(environment.generateBatchId)
      .pipe(map((response: GenerateBatchIdResponse) => {
        return response;
      }))
  }

  public uploadFile(recurringPaymentUploadFileRequest: RecurringPaymentUploadFileRequest): Observable<RecurringDownloadTemplateResponse> {
    const params = new FormData();
    params.append('file', new Blob([recurringPaymentUploadFileRequest.file], { type: 'text/csv' }), recurringPaymentUploadFileRequest.file.name);
    params.append('fileType', recurringPaymentUploadFileRequest.fileType);
    params.append('batchId', recurringPaymentUploadFileRequest.batchId);
    return this.http.post(environment.recurringPaymentUploadFile, params)
      .pipe(map((response: RecurringDownloadTemplateResponse) => {
        return response
      }));
  }

  public deleteFile(idFile: string): Observable<ResultModel> {
    return this.http.post(environment.deleteFile.replace('{idFile}', idFile), {})
      .pipe(map((response: RecurringDownloadTemplateResponse) => {
        return response
      }));
  }

  public cancelFile(idFile: string): Observable<ResultModel> {
    return this.http.post(environment.cancelFile.replace('{idFile}', idFile), {})
      .pipe(map((response: RecurringDownloadTemplateResponse) => {
        return response
      }));
  }

  public downloadFile(idFile: string): Observable<ResultModel> {
    return this.http.post(environment.downloadRecurringFile.replace('{idFile}', idFile), {})
    .pipe(map((response: ResultModel) => {
      return response;
    }))
  }
}
