import { Component, OnInit, HostListener } from '@angular/core';
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { TransactionExports } from '../../services/models/transaction-export-result.model';
import { TransactionExportsService } from '../../services/transaction-exports.services';
import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { message } from '../../shared/app-constant';
import { Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-transaction-export',
  templateUrl: './transaction-exports.component.html',
  styleUrls: ['./transaction-exports.component.css']
})
export class TransactionExportsComponent implements OnInit {

  faDownload = faDownload;
  faTrash = faTrashAlt;

  failedMessage: string = message.content.failMessage;
  orClickRegenerate: string = message.content.orClickRegenerate;
  isLoading: boolean;
  loading: boolean;

  transactions: TransactionExports[];
  
  constructor(
    public transactionExportsService: TransactionExportsService,
    private commonService: CommonService,
    private toasterService: ToasterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.commonService.ctrlExportDialog(false);
      }
    })
    this.isLoading = true;
    this.getTransactions();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (event.target == document.getElementById("exports-background")) {
      this.commonService.ctrlExportDialog(false);
    }
  }

  stopIsLoading() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  
  getTransactions() {
    this.loading = true;
    this.transactionExportsService.getTransactions()
    .subscribe((transactions: TransactionExports[]) => {
      this.transactions = transactions;
      this.isLoading = false;
      this.stopIsLoading();
      this.commonService.isLoading(false);
    }, err => {
      this.transactions = [];
      this.isLoading = false;
      this.stopIsLoading();
      this.commonService.isLoading(false);
    })
  }

  downloadTransaction(transaction: TransactionExports) {
    this.commonService.isLoading(true);
    this.transactionExportsService.downloadTransaction(transaction.transactionExportId).subscribe(
      (result: boolean) => {
        if(result) {
          this.toasterService.success(message.content.download_success);
        } else {
          this.toasterService.error(message.content.download_fail);
        }
        this.commonService.isLoading(false);
      }, err => {
        this.commonService.isLoading(false);
      }
    )
  }

  regenerateTransaction(transaction: TransactionExports) {
    this.commonService.isLoading(true);
    this.transactionExportsService.regenerateTransaction(transaction.transactionExportId).subscribe(
      (result: boolean) => {
        if(result) {
          this.toasterService.success(message.content.processing);
          this.commonService.isLoading(true);
          this.getTransactions();
        } else {
          this.commonService.isLoading(false);
          this.toasterService.error(message.content.regenegate_fail);
        }
      }, err => {
        this.commonService.isLoading(false);
      }
    )
  }

  deleteTransaction(transaction: TransactionExports) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doDelete(transaction);
      }
    });
    this.commonService.alertDelete(message.title.confirm_delete, message.content.confirm_delete, callback);
  }
  
  doDelete(transaction: TransactionExports) {
    this.commonService.isLoading(true);
    this.transactionExportsService.deleteTransaction(transaction.transactionExportId).subscribe(
      (result: boolean) => {
        if(result) {
          this.getTransactions();
          this.toasterService.success(message.content.delete_success);
        } else {
          this.toasterService.error(message.content.delete_fail);
          this.commonService.isLoading(false);
        }
      }, err => {
        this.commonService.isLoading(false);
      }
    )
  }

}
