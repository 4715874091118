import { Directive, Output, EventEmitter, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<boolean>();

  @Input('datePicker') datePicker: boolean;
  @Input('ignoreElement') ignoreElement: any[];

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickInsided = this.elementRef.nativeElement.contains(target);
    if (this.ignoreElement && this.ignoreElement.find(item => item.contains(target))) {
      return;
    }
    if (clickInsided) {
      this.clickOutside.emit(false);
    } else {
      if (this.datePicker && target.offsetParent && target.offsetParent.nodeName === 'NGB-DATEPICKER') {
      this.clickOutside.emit(false);
    } else {
      this.clickOutside.emit(true);
    }
  }
  }

}
