import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { TransactionService } from '../../services/transaction.service';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { TransactionInfoModel } from '../../services/models/transaction-info.model';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { faCreditCard, faQrcode, faLock, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faWeixin, faAlipay, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { ELEMENT } from '../../shared/app-constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  faCreditCard = faCreditCard;
  faQrcode = faQrcode;
  faLock = faLock;
  faWeixin = faWeixin;
  faAlipay = faAlipay;
  faPaypal = faPaypal;
  faFileInvoiceDollar = faFileInvoiceDollar;

  lastTransaction: TransactionInfoModel[] = [];
  today: Date;
  yesterday: Date;
  monthly: boolean;
  companyId: number[];
  dataNotFound = false;
  isShowLinkTransactionDetail = false;

  constructor(
    private commonService: CommonService,
    private authService: AuthenticationService,
    private transactionService: TransactionService
  ) { }

  ngOnInit() {
    this.commonService.scrollToTopPage(ELEMENT.MAIN_CONTENT_ID);
    this.today = new Date();
    this.yesterday = new Date(this.today);
    this.yesterday.setDate(this.today.getDate() - 1);
    this.monthly = true;
    this.isShowLinkTransactionDetail = this.commonService.isVisibleLinkTransactionDetail();

    // default
    const companyId = this.authService.getCurrentUserCompanyInfos().map(c => c.companyId);
    this.updateAndRefresh(companyId);
  }

  onApplyFilter(event) {
    this.updateAndRefresh(event);
  }

  updateAndRefresh(companyId: number[]) {
    this.commonService.isLoading(true);
    this.companyId = companyId;
  }

}
