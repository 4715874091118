import { Component, OnInit, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RegistrationService } from '../../services/registration.service';

import { ChangePasswordModel } from '../../services/models/change-password.model';
import { ChangePasswordResultModel } from '../../services/models/change-password-result.model';
import { message } from '../../shared/app-constant';
import { StringUtil } from '../../shared/string.util';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {
  formSubmitAttempt: boolean;
  displayPasswordPanel: boolean;

  forgotPasswordForm: FormGroup;
  isPasswordValid: {
    lowerCase: boolean,
    upperCase: boolean,
    specialCharacter: boolean,
    number: boolean
  };

  constructor(private commonService: CommonService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService) {
  }

  ngOnInit() {
    /* Init form */
    this.forgotPasswordForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ])
    });

    this.displayPasswordPanel = false;
  }

  clearInfo() {
    if (this.displayPasswordPanel) {
      this.forgotPasswordForm.get('password').reset();
      this.forgotPasswordForm.get('rePassword').reset();
    } else {
      this.forgotPasswordForm.get('email').reset();
    }

    this.formSubmitAttempt = false;
  }


  onSubmit() {
    this.formSubmitAttempt = true;

    if (this.forgotPasswordForm.valid) {
      if (this.displayPasswordPanel) {
        this.changePassword();
      } else {
        this.checkChangePassword();
      }
    }
  }

  checkChangePassword() {
    this.commonService.isLoading(true);

    const email =  this.forgotPasswordForm.get('email').value;
    this.registrationService.checkChangePassword(email).subscribe(
      result => {
        if (!result.emailExists) {
          this.toasterService.error('This email is not registered.');
        } else if (result.locked) {
          this.toasterService.error('User account of this email is locked.');
        } else {
          this.forgotPasswordForm.clearValidators();
          const password = new FormControl(null, {
            updateOn: 'blur',
            validators: [Validators.required,
            Validators.minLength(8),
            Validators.maxLength(15),
            Validators.pattern(CommonService.VALID_PASSWORD_FORMAT)]
          });
          const rePassword = new FormControl(null, CustomValidators.equalTo(password));
          this.forgotPasswordForm.registerControl('password', password);
          this.forgotPasswordForm.registerControl('rePassword', rePassword);

          this.displayPasswordPanel = true;
          this.formSubmitAttempt = false;
          this.forgotPasswordForm.get('email').disable();
          this.forgotPasswordForm.get('password').valueChanges.subscribe((value) => {
            this.isPasswordValid.lowerCase = StringUtil.hasLowerCase(value);
            this.isPasswordValid.upperCase = StringUtil.hasUpperCase(value);
            this.isPasswordValid.number = StringUtil.hasNumber(value);
            this.isPasswordValid.specialCharacter = StringUtil.hasSpecialCharacter(value);
          });
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  changePassword() {
    this.commonService.isLoading(true);

    const changePasswordModel = new ChangePasswordModel();
    changePasswordModel.email = this.forgotPasswordForm.get('email').value;
    changePasswordModel.password = this.forgotPasswordForm.get('password').value;
    changePasswordModel.rePassword = this.forgotPasswordForm.get('rePassword').value;

    this.registrationService.changePassword(changePasswordModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Password changed.');

          this.forgotPasswordForm.removeControl('password');
          this.forgotPasswordForm.removeControl('rePassword');
          this.displayPasswordPanel = false;

          this.forgotPasswordForm.get('email').enable();
          this.clearInfo();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  isFieldInvalid(field: string) {
    const aField = this.forgotPasswordForm.get(field);
    // return (aField.invalid && (aField.touched || aField.dirty))
    //   ||  (aField.untouched && this.formSubmitAttempt);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

}
