import { ResultModel } from "./result.model";

export class SmartpayInfo {
    supplierNo: string;
    supplierName: string;
    productTypes: ProductTypeInfo[];
}

export class ProductTypeInfo {
    productType: string;
    productName: string;
    modelNoObj: ModelNoObj[];
}

export class GetSmartpayInfoRequest {
    term: string;
    ipanMode: string;
}

export class SmartpayInfoResponse extends ResultModel {
    smartPayInfos: SmartpayInfo[]
}

export class ModelNoObj {
    modelNo: string;
    modelName: string;
    mid: string[]
}