import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CACHE_OBSERVABLE_SERVICE, message } from '../../shared/app-constant';
import { SearchThaiQRInquiry } from '../../services/models/search-thai-qr-inquiry.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { faChevronUp, faChevronDown, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { DateUtil } from '../../shared/date.util';
import { CommonService } from '../../services/common.service';
import { ThaiQrInquiryService } from '../../services/thai-qr-inquiry.service';
import { ThaiQRInquiryResponse } from '../../services/models/thai-qr-inquiry-response.model';
import { ThaiQRInquiryInfo } from '../../services/models/thai-qr-inquiry-info.model';
import { Router } from '@angular/router';
import { CacheObservableService } from '../../services/cache-observable.service';
import { TransactionStatus } from '../../shared/transaction-status.enum';
import { TransactionStatusState } from '../../shared/transaction-status-state.enum';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { SelectionTime } from '../../services/models/selection-time.model';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DATE_OPTIONS_THAIQR, FORM_CONTROL, TIME_PATTERN, ERROR_MESSAGES, DATE_PATTERN, EXCEED_THAIQR_TOTAL_RECORD } from '../../shared/app-constant'
import { cloneObject, DateValidator, TimeValidator } from '../../shared/common.util';
import { Subject } from 'rxjs';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { TransactionService } from '../../services/transaction.service';
import { ToasterService } from '../../services/toaster.service';

export const DEFAULT_TIME = {
  START_HOUR: '00',
  START_MINUTE: '00',
  END_HOUR: '23',
  END_MINUTE: '59'
}

export const DATE_RANGE_USE_FOR_SUBTRACT = 29;
export const DATE_RANGE_USE_FOR_UI = 30;
export const STATUS = [
  {id: 2001, name: 'Requested'},
  {id: 2003, name: 'Cancelled'},
  {id: 2004, name: 'Expired'},
  {id: 2002, name: 'Authorized'},
  {id: 2005, name: 'Voided'},
  {id: 2002, name: 'Settled'}
]
export const DEFAULT_DATE_OPTION = 'today';
export const SETTLED = 'Settled';
export const CHANNEL = [
  {value: '', name: 'All'},
  {value: 'K_PAYMENT_LINK', name: 'K_PAYMENT_LINK'},
  {value: 'K_SOCIAL_PAY', name: 'K_SOCIAL_PAY'},
  {value: 'K_PAYMENT_GATEWAY', name: 'K_PAYMENT_GATEWAY'}
]
export const PAYMENT_TYPE = [
  {value: null, name: 'All'},
  {value: 'ADJUST', name: 'Adjusted'},
  {value: 'NON-ADJUST', name: 'Non-adjusted'}
]

@Component({
  selector: 'app-thai-qr-inquiry',
  templateUrl: './thai-qr-inquiry.component.html',
  styleUrls: ['./thai-qr-inquiry.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class ThaiQRInquiryComponent implements OnInit, OnDestroy {

  @ViewChild('today') todayDatepicker: NgbInputDatepicker;
  @ViewChild('from') fromDatepicker: NgbInputDatepicker;
  @ViewChild('to') toDatepicker: NgbInputDatepicker;
  @ViewChild('inputStartTime') inputStartTime: ElementRef;
  @ViewChild('inputEndTime') inputEndTime: ElementRef;
  faQrcode = faQrcode;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  public isOpen: boolean;
  public isOpenStart: boolean;
  public dateOptionsList = DATE_OPTIONS_THAIQR;
  public currentMenuInfo: any;
  public modelSearch: SearchThaiQRInquiry;
  public saveSearchModel: SearchThaiQRInquiry;

  public groupList: any;

  public dateOption: string;
  public date: any;

  public errDateRange: string;
  public invalidTime: string;

  public collectionSize: number;

  public startTime: SelectionTime = new SelectionTime();
  public endTime: SelectionTime = new SelectionTime();

  public minDate: NgbDateStruct;
  public maxDate: NgbDateStruct;

  public searchFormGroup: FormGroup;

  public isSearch: boolean;
  public dateRange = DATE_RANGE_USE_FOR_UI;
  public statusList = STATUS;
  public channelList = CHANNEL;
  public paymentTypeList = PAYMENT_TYPE;
  public statusName: string;
  public channelName: string;
  public paymentTypeName: string;

  private firstTime: boolean;
  private firstTimeStart: boolean;

  private isClickViewTransactiondetail: boolean;
  private isNextPage: boolean;
  public isExceedLimit: boolean;

  constructor(
    private auth: AuthenticationService,
    private commonService: CommonService,
    private thaiQRInquiryService: ThaiQrInquiryService,
    private router: Router,
    private cacheObservableService: CacheObservableService,
    private ref: ChangeDetectorRef,
    private transactionService: TransactionService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.initData();
    if (this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY)) {
      const preData = this.cacheObservableService.getAnyObject(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY);
      this.modelSearch = preData.currentModel;
      this.dateOption = preData.dateOption;
      this.errDateRange = preData.errorDate;
      this.invalidTime = preData.errorTime;
      if (this.modelSearch.statusId) {
        this.statusName = preData.statusName;
      }
      this.channelName = preData.channelName;
      this.paymentTypeName = preData.paymentTypeName;
      this.initPreForm(preData.form);
      this.search(true, preData.modelAllreadySearch);
    }
  }

  initData() {
    this.firstTimeStart = true;
    this.firstTime = true;
    this.isOpen = false;
    this.isOpenStart = false;
    this.isSearch = false;
    this.modelSearch = new SearchThaiQRInquiry();
    this.modelSearch.pageIndex = 1;
    this.modelSearch.pageSize = 15;
    this.modelSearch.refNumber = "";
    this.statusName = undefined;
    this.channelName = this.channelList[0].name
    this.paymentTypeName = this.paymentTypeList[0].name
    this.dateOption = DEFAULT_DATE_OPTION;
    this.groupList = [];
    this.isClickViewTransactiondetail = false;
    this.isNextPage = false;
    this.isExceedLimit = false;
    const today = new Date();
    this.maxDate = DateUtil.dateToNgbDateStructCustomForThaiQR(today);
    const minDate = DateUtil.subtractDay(today, DATE_RANGE_USE_FOR_SUBTRACT);
    this.minDate = DateUtil.dateToNgbDateStructCustomForThaiQR(minDate);
    this.invalidTime = null;
    this.errDateRange = null;

    this.endTime.hour = DEFAULT_TIME.END_HOUR;
    this.endTime.minute = DEFAULT_TIME.END_MINUTE;
    this.startTime.hour = DEFAULT_TIME.START_HOUR;
    this.startTime.minute = DEFAULT_TIME.START_MINUTE;
    this.initSearchForm();
  }

  search(isBackFromDetail?, model?) {
    this.commonService.isLoading(true);
    this.groupList = [];
    this.collectionSize = 0;
    if (!this.isNextPage && !isBackFromDetail) {
      this.modelSearch.pageIndex = 1;
    }
    this.modelSearch.companyId = this.auth.getCurrentUserCompanyInfos().map((element) => {
      return element.companyId;
    });
    this.setDateToModelSearch(isBackFromDetail);
    this.modelSearch.refNumber = this.searchFormGroup.get(FORM_CONTROL.REF_NUMBER).value;
    this.modelSearch.amount = this.searchFormGroup.get(FORM_CONTROL.AMOUNT).value;
    if (this.statusName) {
      this.modelSearch.statusId = this.statusList.find((status) => status.name == this.statusName).id;
      this.modelSearch.isSettled = this.statusList.find((status) => status.name == this.statusName).name == SETTLED;
    }
    this.modelSearch.channel = this.channelList.find((channel) => channel.name == this.channelName).value;
    this.modelSearch.paymentType = this.paymentTypeList.find((paymentType) => paymentType.name == this.paymentTypeName).value;
    this.saveSearchModel = cloneObject(this.modelSearch);
    this.searchTransaction(model);
    this.isNextPage = false;
  }

  searchTransaction(model?) {
    const request = model ? model : this.modelSearch;
    this.thaiQRInquiryService.search(request).subscribe(
      (response: ThaiQRInquiryResponse) => {
        if (this.commonService.isSuccess(response)) {
          this.isExceedLimit = false;
          this.collectionSize = response.total;
          for (const group of response.orderDateGroups) {
            const transactionList = response.orderSummaries.filter((m: ThaiQRInquiryInfo) => m.createdDate.split(' ').shift() === group);
            const modelGroup = {
              groupDate: group,
              transactionList: transactionList
            };
            this.groupList.push(modelGroup);
          }
        } else if (response.status_code == EXCEED_THAIQR_TOTAL_RECORD) {
          this.isExceedLimit = true;
          const callback = new Subject<boolean>();
          callback.asObservable().subscribe(result => {});
          this.commonService.alertError(message.title.error, response.status_message, callback);
        }

        this.isSearch = true;
        this.commonService.isLoading(false);
      },
      error => {
        this.isSearch = true;
        this.commonService.isLoading(false);
      });
  }

  exportThaiQrTransaction(){
    const request = this.bindNewRequest();
    this.commonService.isLoading(true);
    this.transactionService.exportTransaction(request).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.isExceedLimit = false;
          this.toasterService.success(message.content.processing);
        } else if (result.status_code == EXCEED_THAIQR_TOTAL_RECORD) {
          this.isExceedLimit = true;
          const callback = new Subject<boolean>();
          callback.asObservable().subscribe(result => {});
          this.commonService.alertError(message.title.error, result.status_message, callback);
        } else {
          this.toasterService.error(message.content.create_fail);
        }
        this.commonService.isLoading(false);
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  bindNewRequest(): TransactionRequestModel {
    const request = new TransactionRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element) => {
      return element.companyId;
    });
    request.pageIndex = 1;
    request.pageSize = 15;
    request.fileName = 'THQRtransaction-' + new Date().toDateString();
    request.searchDate = [];
    if(this.searchFormGroup.get(FORM_CONTROL.REF_NUMBER).value){
      request.refNumber = this.searchFormGroup.get(FORM_CONTROL.REF_NUMBER).value;
    }
    if(this.statusName){
      request.status = [this.statusName];
    }
    if(this.searchFormGroup.get(FORM_CONTROL.AMOUNT).value){
      request.amountOpr = 'equal';
      request.amountRef1 = this.searchFormGroup.get(FORM_CONTROL.AMOUNT).value;
    }
    var startDate: any = this.searchFormGroup.get(FORM_CONTROL.START_DATE).value;
    var endDate: any = this.searchFormGroup.get(FORM_CONTROL.END_DATE).value;
    if (this.dateOption == 'custom') {
      startDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).value;
      endDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).value;
    }
    startDate = this.parseToDate(startDate);
    endDate = this.parseToDate(endDate);
    startDate.setHours(parseInt(this.startTime.hour), parseInt(this.startTime.minute));
    endDate.setHours(parseInt(this.endTime.hour), parseInt(this.endTime.minute), 59, 999);
    const from = new Date(startDate);
    const to = new Date(endDate);
    request.searchDate.push({searchTypeDate: 'thaiqr', dateRef1: from, dateRef2: to });
    request.channel = this.channelList.find((channel) => channel.name == this.channelName).value;
    request.paymentTypeThaiQR = this.paymentTypeList.find((paymentType) => paymentType.name == this.paymentTypeName).value;
    request.transactionThaiQR = true;
    return request;
  }

  clear() {
    this.initData();
  }

  dateSelected(event, field) {
    this.searchFormGroup.get(field).setValue(event, { emitEvent: true });
  }

  closeDatepicker(event, field) {
    if (event) {
      field.close();
    }
  }

  viewTransactiondetail(transaction: ThaiQRInquiryInfo) {
    this.isClickViewTransactiondetail = true;
    const saveObject = {
      modelAllreadySearch: cloneObject(this.saveSearchModel),
      dateOption: this.dateOption,
      form: this.searchFormGroup,
      currentModel: this.modelSearch,
      errorDate: this.errDateRange,
      errorTime: this.invalidTime,
      statusName: this.statusName,
      channelName: this.channelName,
      paymentTypeName: this.paymentTypeName
    }
    this.cacheObservableService.set(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY, saveObject);
    this.router.navigate(['thaiqr-inquiry/' + transaction.qrId + '/' + transaction.orderTransactionId]);
  }

  loadPage(pageIndex: number) {
    this.isNextPage = true;
    this.modelSearch.pageIndex = pageIndex;
    this.search();
  }

  closeTimeSelection(value) {
    if (!this.isOpen) {
      this.firstTime = true;
      return
    }
    if (value && !this.firstTime) {
      this.isOpen = false;
      this.firstTime = true;
      this.inputEndTime.nativeElement.blur();
      return
    } else {
      this.firstTime = false
    }
  }

  closeTimeSelectionStart(value) {
    if (!this.isOpenStart) {
      this.firstTimeStart = true;
      return
    }
    if (value && !this.firstTimeStart) {
      this.isOpenStart = false;
      this.firstTimeStart = true;
      this.inputStartTime.nativeElement.blur();
      return
    } else {
      this.firstTimeStart = false
    }
  }

  focusStartTime() {
    this.isOpenStart = !this.isOpenStart;
    if(!this.isOpenStart) {
      this.inputStartTime.nativeElement.blur();
    }
  }

  focusEndTime() {
    this.isOpen = !this.isOpen;
    if(!this.isOpen) {
      this.inputEndTime.nativeElement.blur();
    }
  }


  changeDateOption(dateOption) {
    var startDate: any;
    var endDate: any;
    switch (dateOption) {
      case 'custom':
        startDate = this.maxDate;
        endDate = this.maxDate;
        break;
      case 'between':
        startDate = this.maxDate;
        endDate = this.maxDate;
        break;
      case 'yesterday':
        startDate = DateUtil.dateToNgbDateStructCustomForThaiQR(DateUtil.subtractDay(new Date(), 1));
        endDate = DateUtil.dateToNgbDateStructCustomForThaiQR(DateUtil.subtractDay(new Date(), 1));
        break;
      case 'today':
        startDate = DateUtil.dateToNgbDateStructCustomForThaiQR(new Date());
        endDate = DateUtil.dateToNgbDateStructCustomForThaiQR(new Date());
        break;
      case 'all':
        const minDate = DateUtil.subtractDay(new Date(), DATE_RANGE_USE_FOR_SUBTRACT);
        startDate = DateUtil.dateToNgbDateStructCustomForThaiQR(minDate);
        endDate = DateUtil.dateToNgbDateStructCustomForThaiQR(new Date());
        this.searchFormGroup.get(FORM_CONTROL.START_TIME).disable();
        this.searchFormGroup.get(FORM_CONTROL.END_TIME).disable();
        break;
    }
    this.searchFormGroup.get(FORM_CONTROL.START_DATE).setValue(startDate, { emitEvent: true });
    this.searchFormGroup.get(FORM_CONTROL.END_DATE).setValue(endDate, { emitEvent: true });
    this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).setValue(startDate, { emitEvent: true });
    this.searchFormGroup.get(FORM_CONTROL.START_TIME).setValue(DEFAULT_TIME.START_MINUTE + ':' + DEFAULT_TIME.START_MINUTE, { emitEvent: true });
    this.searchFormGroup.get(FORM_CONTROL.END_TIME).setValue(DEFAULT_TIME.END_HOUR + ':' + DEFAULT_TIME.END_MINUTE, { emitEvent: true });
  }

  changeStartTime(event) {
    this.startTime.hour = event.hour;
    this.startTime.minute = event.minute;
    this.searchFormGroup.get(FORM_CONTROL.START_TIME).setValue((event.hour + ':' + event.minute), { emitEvent: true });
  }

  changeEndTime(event) {
    this.endTime.hour = event.hour;
    this.endTime.minute = event.minute;
    this.searchFormGroup.get(FORM_CONTROL.END_TIME).setValue((event.hour + ':' + event.minute), { emitEvent: true });
  }

  isFieldInvalid(field: string) {
    const aField = this.searchFormGroup.get(field);
    return (aField.errors && (aField.errors.required || aField.errors.isValid));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  validateDateRange(field) {
    this.errDateRange = null;
    const startDate = this.searchFormGroup.get(FORM_CONTROL.START_DATE);
    const endDate = this.searchFormGroup.get(FORM_CONTROL.END_DATE);
    const customDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE);
    var dateStart: Date;
    var dateEnd: Date;
    var dateCustom: Date;
    dateStart = this.parseToDate(startDate.value);
    dateEnd = this.parseToDate(endDate.value);
    dateCustom = this.parseToDate(customDate.value);
    if (field != FORM_CONTROL.CUSTOM_DATE) {
      if (!dateStart || !dateEnd || isNaN(dateStart.getTime()) || isNaN(dateEnd.getTime())) {
        return
      }
      if ( dateStart.getTime() > new Date().getTime()
      || dateEnd.getTime() > new Date().getTime() ) {
        return
      }
      const minDate = DateUtil.ngbDateStructToDate(this.minDate);
      const maxDate = DateUtil.ngbDateStructToDate(this.maxDate);
      if (!DateUtil.isBetween(dateEnd, minDate, maxDate) || !DateUtil.isBetween(dateStart, minDate, maxDate)) {
        this.errDateRange = ERROR_MESSAGES.OVER_30_DAYS;
        return
      }

      if (dateStart.getTime() > dateEnd.getTime()) {
        this.errDateRange = field == FORM_CONTROL.START_DATE ? ERROR_MESSAGES.FROM_BEFORE_TO : ERROR_MESSAGES.TO_AFTER_FROM;
        this.ref.markForCheck();
        return
      }
    } else {
      if (isNaN(dateCustom.getTime())) {
        return
      }
      if (dateCustom.getTime() > new Date().getTime()) {
        this.errDateRange = null;
        return
      }
      if (!DateUtil.isBetween(dateCustom, DateUtil.ngbDateStructToDate(this.minDate), DateUtil.ngbDateStructToDate(this.maxDate))) {
        this.errDateRange = ERROR_MESSAGES.OVER_30_DAYS;
      }
    }
  }

  validateTime(field) {
    this.invalidTime = null;
    const startTime = this.searchFormGroup.get(FORM_CONTROL.START_TIME);
    const endTime = this.searchFormGroup.get(FORM_CONTROL.END_TIME);
    var startDate: any = this.searchFormGroup.get(FORM_CONTROL.START_DATE);
    var endDate: any = this.searchFormGroup.get(FORM_CONTROL.END_DATE);
    if (this.dateOption == FORM_CONTROL.CUSTOM_DATE) {
      startDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE);
      endDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE);
    }
    if ( (startTime.errors && (startTime.errors.required || startTime.errors.isValid)) ||
      (endTime.errors && (endTime.errors.required || endTime.errors.isValid))
    ) {
      return
    }
    if ((startDate.errors && (startDate.errors.required || startDate.errors.isValid)) ||
      (endDate.errors && (endDate.errors.required || endDate.errors.isValid)) ||
      this.errDateRange
    ) {
      return
    }
    startDate = this.parseToDate(startDate.value);
    endDate = this.parseToDate(endDate.value);
    if (!startDate || !endDate) {
      return
    }
    startDate.setHours(parseInt(this.startTime.hour), parseInt(this.startTime.minute));
    endDate.setHours(parseInt(this.endTime.hour), parseInt(this.endTime.minute));
    const from = new Date(startDate);
    const to = new Date(endDate);
    if (from.getTime() > to.getTime()) {
      this.invalidTime = field == FORM_CONTROL.START_TIME ? ERROR_MESSAGES.START_TIME_AFTER_END_TIME : ERROR_MESSAGES.END_TIME_BEFORE_START_TIME;
    }
  }

  disableSearchButton() {
    const customDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE);
    const startDate = this.searchFormGroup.get(FORM_CONTROL.START_DATE);
    const endDate = this.searchFormGroup.get(FORM_CONTROL.END_DATE);
    const startTime = this.searchFormGroup.get(FORM_CONTROL.START_TIME);
    const endTime = this.searchFormGroup.get(FORM_CONTROL.END_TIME);
    return (this.invalidTime != null) || (this.errDateRange != null) ||
      ((customDate.errors && (customDate.errors.required || customDate.errors.isValid)) ||
        (startDate.errors && (startDate.errors.required || startDate.errors.isValid)) ||
        (startTime.errors && (startTime.errors.required || startTime.errors.isValid)) ||
        (endTime.errors && (endTime.errors.required || endTime.errors.isValid)) ||
        (endDate.errors && (endDate.errors.required || endDate.errors.isValid)))
        && this.searchFormGroup.touched
  }

  private setDateToModelSearch(isBackFromDetail?) {
    if (isBackFromDetail) {
      return;
    }
    var startDate: any = this.searchFormGroup.get(FORM_CONTROL.START_DATE).value;
    var endDate: any = this.searchFormGroup.get(FORM_CONTROL.END_DATE).value;
    if (this.dateOption == 'custom') {
      startDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).value;
      endDate = this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).value;
    }
    startDate = this.parseToDate(startDate);
    endDate = this.parseToDate(endDate);
    startDate.setHours(parseInt(this.startTime.hour), parseInt(this.startTime.minute));
    endDate.setHours(parseInt(this.endTime.hour), parseInt(this.endTime.minute), 59, 999);
    const from = new Date(startDate);
    const to = new Date(endDate);
    this.modelSearch.fromDate = from;
    this.modelSearch.toDate = to;
  }
  private parseToDate(value): Date {
    if (!value) {
      return null;
    }
    if (value instanceof Object) {
      return DateUtil.ngbDateStructToDate(value);
    } else {
      return new Date(DateUtil.convDateToMMDDYYY(value));
    }
  }

  initSearchForm() {
    this.searchFormGroup = new FormGroup({
      customDate: new FormControl(this.maxDate, { validators: [Validators.required, DateValidator] }),
      startDate: new FormControl(this.maxDate, { validators: [Validators.required, DateValidator] }),
      endDate: new FormControl(this.maxDate, { validators: [Validators.required, DateValidator] }),
      startTime: new FormControl(this.startTime.hour + ':' + this.startTime.minute, { validators: [Validators.required, TimeValidator] }),
      endTime: new FormControl(this.endTime.hour + ':' + this.endTime.minute, { validators: [Validators.required, TimeValidator] }),
      refNumber: new FormControl('', { validators: [] }),
      amount: new FormControl('', { validators: [] })
    }, { updateOn: 'blur' });
    this.searchFormGroup.get(FORM_CONTROL.START_DATE).valueChanges.subscribe((value) => {
      this.errDateRange = null;
      if ((value instanceof Object || DATE_PATTERN.test(value))) {
        this.validateDateRange(FORM_CONTROL.START_DATE);
        this.validateTime(FORM_CONTROL.START_TIME);
      }
    });
    this.searchFormGroup.get(FORM_CONTROL.END_DATE).valueChanges.subscribe((value) => {
      this.errDateRange = null;
      if ((value instanceof Object || DATE_PATTERN.test(value))) {
        this.validateDateRange(FORM_CONTROL.END_DATE);
        this.validateTime(FORM_CONTROL.END_TIME);
      }
    });
    this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).valueChanges.subscribe((value) => {
      this.errDateRange = null;
      if ((value instanceof Object || DATE_PATTERN.test(value))) {
        this.validateDateRange(FORM_CONTROL.CUSTOM_DATE);
        this.validateTime(FORM_CONTROL.START_TIME);
      }
    });
    this.searchFormGroup.get(FORM_CONTROL.START_TIME).valueChanges.subscribe((value) => {
      this.invalidTime = null;
      if (TIME_PATTERN.test(value) && value.split(':')[0] < 24 && value.split(':')[1] < 60) {
        this.startTime.hour = value.split(':')[0];
        this.startTime.minute = value.split(':')[1];
        this.validateTime(FORM_CONTROL.START_TIME);
      }
    });
    this.searchFormGroup.get(FORM_CONTROL.END_TIME).valueChanges.subscribe((value) => {
      this.invalidTime = null;
      if (TIME_PATTERN.test(value) && value.split(':')[0] < 24 && value.split(':')[1] < 60) {
        this.endTime.hour = value.split(':')[0];
        this.endTime.minute = value.split(':')[1];
        this.validateTime(FORM_CONTROL.END_TIME);
      }
    });
  }

  getStatusColorClass(transaction: ThaiQRInquiryInfo) {
    const statusIdNumber = transaction.statusId ? toInteger(transaction.statusId) : 0;
    const statusStateIdNumber = transaction.statusStateId ? toInteger(transaction.statusStateId) : 0;
    let colorClass = {};
    if ((statusIdNumber === TransactionStatus.Settled || statusIdNumber === TransactionStatus.Qr_Settled)
      && statusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-green': true };
    } else if ((statusIdNumber === TransactionStatus.Refunded || statusIdNumber === TransactionStatus.Qr_Refunded)
      && statusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-red': true };
    } else if ((statusIdNumber === TransactionStatus.Declined
      || statusIdNumber === TransactionStatus.Voided
      || statusIdNumber === TransactionStatus.Reversed
      || statusIdNumber === TransactionStatus.Qr_Voided)
      && statusStateIdNumber === TransactionStatusState.Complete) {
      colorClass = { 'text-darklight': true };
    } else if ((statusIdNumber === TransactionStatus.Authorized)
      && (statusStateIdNumber === TransactionStatusState.Wait_For_Authentication
        || statusStateIdNumber === TransactionStatusState.Wait_For_Confirm
        || statusStateIdNumber === TransactionStatusState.Fail)) {
      colorClass = { 'text-darklight': true };
    }
    return colorClass;
  }

  private initPreForm(form) {
    const startDate = form.get(FORM_CONTROL.START_DATE).value;
    const endDate = form.get(FORM_CONTROL.END_DATE).value;
    if (startDate) {
      const date = this.parseToDate(startDate);
      this.searchFormGroup.get(FORM_CONTROL.START_DATE).setValue(DateUtil.formatToNgbDate(date), { emitEvent: false})
    }
    if (endDate) {
      const date = this.parseToDate(endDate);
      this.searchFormGroup.get(FORM_CONTROL.END_DATE).setValue(DateUtil.formatToNgbDate(date), { emitEvent: false})
    }
    const customDate = form.get(FORM_CONTROL.CUSTOM_DATE).value
    if (customDate) {
      const date = this.parseToDate(customDate);
      this.searchFormGroup.get(FORM_CONTROL.CUSTOM_DATE).setValue(DateUtil.formatToNgbDate(date), { emitEvent: false})
    }

    const startTime = form.get(FORM_CONTROL.START_TIME).value;
    this.searchFormGroup.get(FORM_CONTROL.START_TIME).setValue(startTime, {emitEvent: false});
    const startTimeArr = form.get(FORM_CONTROL.START_TIME).value.split(':');
    if (startTimeArr.length > 0) {
      this.startTime.hour = startTimeArr[0];
      this.startTime.minute = startTimeArr[1];
    }

    const endTime = form.get(FORM_CONTROL.END_TIME).value;
    this.searchFormGroup.get(FORM_CONTROL.END_TIME).setValue(endTime, {emitEvent: false});
    const endTimeArr = form.get(FORM_CONTROL.END_TIME).value.split(':');
    if (endTimeArr.length > 0) {
      this.endTime.hour =  endTimeArr[0];
      this.endTime.minute = endTimeArr[1];
    }

    const refNumber = form.get(FORM_CONTROL.REF_NUMBER).value;
    const amount = form.get(FORM_CONTROL.AMOUNT).value;
    this.searchFormGroup.get(FORM_CONTROL.REF_NUMBER).setValue(refNumber, {emitEvent: false});
    this.searchFormGroup.get(FORM_CONTROL.AMOUNT).setValue(amount, {emitEvent: false});
  }

  ngOnDestroy() {
    if (!this.isClickViewTransactiondetail && this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY)) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY);
    }
  }

}
