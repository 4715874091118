import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common.service';
import { ModalModel } from '../../services/models/modal.model';
import { SettleConfirmContentModel } from '../../services/models/settle-confirm-content.model';
import { RefundConfirmContentModel } from '../../services/models/refund-confirm-content.model';
import { ResetPasswordConfirmContentModel } from './../../services/models/reset-password-confirm-content.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @ViewChild('modalAlertContent')
  modalAlertContent: TemplateRef<any>;

  @ViewChild('modalConfirmContent')
  modalConfirmContent: TemplateRef<any>;

  modalAlertModel: ModalModel;
  modalConfirmModel: ModalModel;

  confirmModel: any;

  isDisableResetPwd: boolean;

  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.visibleModal$.subscribe(modal => {
      this.open(modal);
    });
    this.commonService.invisibleModal.subscribe(() => {
      this.modalRef.close();
    })
  }

  private open(modal: ModalModel) {
    let content: TemplateRef<any>;
    if (modal.modalType === 'confirm' || modal.modalType === 'delete') {
      this.modalConfirmModel = modal;
      content = this.modalConfirmContent;
    } else {
      this.modalAlertModel = modal;
      content = this.modalAlertContent;
    }
    this.modalRef = this.modalService.open(content, {
      backdrop: 'static',
      windowClass: modal.modalType + '-modal',
      size: modal.content.modalSize
    });
    this.modalRef.result.then(
    (result) => {
      if (modal.callback) {
        if (result === 'cancel') {
          console.log('callback: false');
          modal.callback.next(false);
        } else {
          console.log('callback: true');
          if (this.modalConfirmModel) {
            if (typeof this.modalConfirmModel.content === 'string') {
              modal.callback.next(true);
            } else {
              modal.callback.next(this.confirmModel);
            }
          }
        }
      }
      console.log('Closed Modal with: ' + result);
    },
    (reason) => {
      console.log('Closed Modal with: ' + this.getDismissReason(reason));
    });
  }

  doConfirm() {
    if(this.isResetPasswordConfirm() && this.modalConfirmModel.callback) {
      this.modalConfirmModel.callback.next(this.confirmModel);
    } else {
      this.modalRef.close();
    }
  }
  
  doClose() {
    if (this.modalAlertModel.callback) {
      this.modalAlertModel.callback.next(this.confirmModel);
    }
    this.modalRef.close();
  }

  isTextConfirm(): boolean {
    // console.log(this.modalConfirmModel.content);
    return this.modalConfirmModel
            && this.modalConfirmModel.content
            && (typeof this.modalConfirmModel.content === 'string');
  }

  isSettleConfirm(): boolean {
    return this.modalConfirmModel
            && this.modalConfirmModel.content
            && (typeof this.modalConfirmModel.content === 'object')
            && (this.modalConfirmModel.content instanceof SettleConfirmContentModel);
  }

  isRefundConfirm(): boolean {
    return this.modalConfirmModel
            && this.modalConfirmModel.content
            && (typeof this.modalConfirmModel.content === 'object')
            && (this.modalConfirmModel.content instanceof RefundConfirmContentModel);
  }

  isResetPasswordConfirm(): boolean {
    return this.modalConfirmModel
            && this.modalConfirmModel.content
            && (typeof this.modalConfirmModel.content === 'object')
            && (this.modalConfirmModel.content instanceof ResetPasswordConfirmContentModel);
  }

  validateResetPwd(isValid: boolean) {
    this.isDisableResetPwd = !isValid;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ' + reason;
    }
  }

}
