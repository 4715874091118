import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RequestGoliveModel } from '../../services/models/request-golive.model';
import { RegistrationService } from '../../services/registration.service';
import { AuthenticationService } from '../../services/auth/authentication.service'

import { ItemSelectModel } from '../../services/models/item-select.model';
import { ProvinceInfoRequestModel } from '../../services/models/province-info-request.model';
import { ProvinceInfoModel } from '../../services/models/province-info.model';
import { AmphurInfoModel } from '../../services/models/amphur-info.model';
import { DistrictInfoModel } from '../../services/models/district-info.model';
import { PostcodeInfoModel } from '../../services/models/postcode-info.model';
import { StringUtil } from '../../shared/string.util';

@Component({
  selector: 'app-request-golive',
  templateUrl: './request-golive.component.html',
  styleUrls: ['./request-golive.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RequestGoliveComponent implements OnInit {
  private formSubmitAttempt: boolean;

  requestGoliveForm: FormGroup;

  isPasswordValid: {
    lowerCase: boolean,
    upperCase: boolean,
    specialCharacter: boolean,
    number: boolean
  };

  businessTypeDescs: ItemSelectModel[];
  provinces: ProvinceInfoModel[];
  amphurs: AmphurInfoModel[];
  districts: DistrictInfoModel[];
  postcodes: PostcodeInfoModel[];
  // postcode: PostcodeInfoModel;

  constructor(private commonService: CommonService, private toasterService: ToasterService,
    private authenticationService: AuthenticationService,
    private registrationService: RegistrationService) {
  }

  ngOnInit() {
    /* Init dropdown - BusinessTypeDescs */
    this.getBusinessTypeDescs();
    /* Init dropdown - ProvinceInfo */
    this.getProvinces();

    this.isPasswordValid = {
      lowerCase: false,
      upperCase: false,
      specialCharacter: false,
      number: false
    };

    /* Init form */
    const password = new FormControl(null, {
      updateOn: 'blur',
      validators: [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15),
      Validators.pattern(CommonService.VALID_PASSWORD_FORMAT)]
    });
    const rePassword = new FormControl(null, CustomValidators.equalTo(password));

    this.requestGoliveForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      'password': password,
      'rePassword': rePassword,
      'companyName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'companyNameEn': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'companyIdName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        // Validators.pattern(CommonService.VALID_CSV_INJECTION_FORMAT)
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'companyTradeName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'firstName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'lastName': new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'addressLn1': new FormControl(null, [
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'addressLn2': new FormControl(null, [
        Validators.maxLength(255),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ]),
      'provinceCode': new FormControl(null, [
        Validators.required
      ]),
      'amphurCode': new FormControl(null, [
        Validators.required
      ]),
      'districtCode': new FormControl(null, [
        Validators.required
      ]),
      'postalCode': new FormControl(null, [
        Validators.required
      ]),
      'homePhoneNo': new FormControl(null, [
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ]),
      'officePhoneNo': new FormControl(null, [
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ]),
      'mobilePhoneNo': new FormControl(null, [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ]),
      'faxNo': new FormControl(null, [
        Validators.maxLength(20),
        Validators.pattern(CommonService.VALID_PHONE_INPUT_FORMAT)
      ]),
      'emailCompany': new FormControl(null, [
        this.customEmailValidator,
        Validators.maxLength(50)
      ]),
      'businessTypeId': new FormControl(null, [
        Validators.required
      ]),
      'remark': new FormControl(null, [
        Validators.maxLength(1024),
        Validators.pattern(CommonService.VALID_TEXT_INPUT_FORMAT)
      ])
    });

    this.requestGoliveForm.get('password').valueChanges.subscribe((value) => {
      this.isPasswordValid.lowerCase = StringUtil.hasLowerCase(value);
      this.isPasswordValid.upperCase = StringUtil.hasUpperCase(value);
      this.isPasswordValid.number = StringUtil.hasNumber(value);
      this.isPasswordValid.specialCharacter = StringUtil.hasSpecialCharacter(value);
    });

    this.commonService.visibleBtnAdvanceSearch(false); // disable advance search
  }

  /* Workaround for email validator that makes the form invalid when the field is empty */
  customEmailValidator(control: AbstractControl): ValidationErrors | null {
    return control.value ? Validators.email(control) : null;
  }

  clearInfo() {
    this.requestGoliveForm.reset();
    this.clearAmphurs();

    this.formSubmitAttempt = false;
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    if (this.requestGoliveForm.valid) {
      this.requestGolive();
    }
  }

  requestGolive() {
    this.commonService.isLoading(true);

    const requestGoliveModel = new RequestGoliveModel();
    requestGoliveModel.email = this.requestGoliveForm.get('email').value;
    requestGoliveModel.password = this.requestGoliveForm.get('password').value;
    requestGoliveModel.rePassword = this.requestGoliveForm.get('rePassword').value;
    requestGoliveModel.companyName = this.requestGoliveForm.get('companyName').value;
    requestGoliveModel.companyNameEn = this.requestGoliveForm.get('companyNameEn').value;
    requestGoliveModel.companyIdName = this.requestGoliveForm.get('companyIdName').value;
    requestGoliveModel.companyTradeName = this.requestGoliveForm.get('companyTradeName').value;
    requestGoliveModel.postcodeId = this.requestGoliveForm.get('postalCode').value;
    requestGoliveModel.firstName = this.requestGoliveForm.get('firstName').value;
    requestGoliveModel.lastName = this.requestGoliveForm.get('lastName').value;
    requestGoliveModel.addressLn1 = this.requestGoliveForm.get('addressLn1').value;
    requestGoliveModel.addressLn2 = this.requestGoliveForm.get('addressLn2').value;
    requestGoliveModel.homePhoneNo = this.requestGoliveForm.get('homePhoneNo').value;
    requestGoliveModel.officePhoneNo = this.requestGoliveForm.get('officePhoneNo').value;
    requestGoliveModel.mobilePhoneNo = this.requestGoliveForm.get('mobilePhoneNo').value;
    requestGoliveModel.faxNo = this.requestGoliveForm.get('faxNo').value;
    requestGoliveModel.emailCompany = this.requestGoliveForm.get('emailCompany').value;
    requestGoliveModel.businessTypeId = this.requestGoliveForm.get('businessTypeId').value;
    requestGoliveModel.remark = this.requestGoliveForm.get('remark').value;
    requestGoliveModel.createBy = this.authenticationService.getCurrentUserInfo().email;

    this.registrationService.requestGolive(requestGoliveModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Register ' + this.requestGoliveForm.get('email').value + ' success.');
          this.clearInfo();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  isFieldInvalid(field: string) {
    const aField = this.requestGoliveForm.get(field);
    // return (aField.invalid && (aField.touched || aField.dirty))
    //   || (aField.invalid && this.formSubmitAttempt);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  getBusinessTypeDescs() {
    this.registrationService.getBusinessTypeDescs().subscribe(
      result => {
        this.businessTypeDescs = result;
      }
    );
  }

  getProvinces() {
    const provinceInfoRequest = new ProvinceInfoRequestModel(null, null, null, null);
    this.registrationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.provinces = result.provinces;
        }
      }
    );
  }

  getAmphurs(provinceCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, null, null, null);
    this.registrationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0] && result.provinces[0].amphurs) {
            this.amphurs = result.provinces[0].amphurs;
          }
        }
      }
    );
  }

  getDistricts(provinceCode, amphurCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, amphurCode, null, null);
    this.registrationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0] && result.provinces[0].amphurs[0]
            && result.provinces[0].amphurs[0].districts) {
            this.districts = result.provinces[0].amphurs[0].districts;
          }
        }
      }
    );
  }

  getPostcode(provinceCode, amphurCode, districtCode) {
    const provinceInfoRequest = new ProvinceInfoRequestModel(provinceCode, amphurCode, districtCode, null);
    this.registrationService.getProvinceInfo(provinceInfoRequest).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          if (result.provinces[0]
            && result.provinces[0].amphurs[0]
            && result.provinces[0].amphurs[0].districts[0]
            && result.provinces[0].amphurs[0].districts[0].postcodes) {
              this.postcodes = result.provinces[0].amphurs[0].districts[0].postcodes;
          }
        }
      }
    );
  }

  onProvinceChange(value) {
    this.clearAmphurs();

    const provinceCode = this.requestGoliveForm.get('provinceCode').value;
    if (provinceCode) {
      this.getAmphurs(provinceCode);
    }
  }

  onAmphurChange(value) {
    this.clearDistricts();

    const provinceCode = this.requestGoliveForm.get('provinceCode').value;
    const amphurCode = this.requestGoliveForm.get('amphurCode').value;
    if (amphurCode) {
      this.getDistricts(provinceCode, amphurCode);
    }
  }

  onDistrictChange(value) {
    this.clearPostalCode();

    const provinceCode = this.requestGoliveForm.get('provinceCode').value;
    const amphurCode = this.requestGoliveForm.get('amphurCode').value;
    const districtCode = this.requestGoliveForm.get('districtCode').value;
    if (districtCode) {
      this.getPostcode(provinceCode, amphurCode, districtCode);
    }
  }

  clearProvince() {
    this.provinces = null;
    this.requestGoliveForm.get('provinceCode').reset();

    this.clearAmphurs();
  }

  clearAmphurs() {
    this.amphurs = null;
    this.requestGoliveForm.get('amphurCode').reset();

    this.clearDistricts();
  }

  clearDistricts() {
    this.districts = null;
    this.requestGoliveForm.get('districtCode').reset();

    this.clearPostalCode();
  }

  clearPostalCode() {
    this.postcodes = null;
    this.requestGoliveForm.get('postalCode').reset();
  }

  getInvalidTextInputCharacters(): string {
    return CommonService.INVALID_TEXT_INPUT_CHARACTERS;
  }
  getInvalidPhoneInputCharacters(): string {
    return CommonService.INVALID_PHONE_INPUT_CHARACTERS;
  }
  getInvalidUrlInputCharacters(): string {
    return CommonService.INVALID_URL_INPUT_CHARACTERS;
  }

  // validateAllFormFields(formGroup: FormGroup) {
  //   Object.keys(formGroup.controls).forEach(field => {
  //     const control = formGroup.get(field);
  //     if (control instanceof FormControl) {
  //       control.markAsTouched({ onlySelf: true });
  //     } else if (control instanceof FormGroup) {
  //       this.validateAllFormFields(control);
  //     }
  //   });
  // }

  // get email() { return this.requestGoliveForm.get('email'); }

}
