export class StringUtil {
    public static hasLowerCase(str: string) {
        return (/[a-z]/.test(str));
    }

    public static hasUpperCase(str: string) {
        return (/[A-Z]/.test(str));
    }

    public static hasNumber(str: string) {
        return (/[0-9]/.test(str));
    }
    
    public static hasSpecialCharacter(str: string) {
        return (/[=()*#$%&?:@+\-,"\'\/!]/.test(str));
    }    
    
    public static isNotEmpty(str: string) {
        return str && str.length > 0;
    }

    public static uppercaseFirstCharacter(text: string): string {
        return text ? text.charAt(0) + text.slice(1).toLowerCase() : '';
    }

    public static returnDefaultIfObjNull(object: any, defaultValue: any) {
        return object ? object : defaultValue;
    }
    
    public static isContainInList(object: any, list: any, param?: string) {
        return list && list.length > 0 && list.find(i => param ? i[param] == object : i == object) ? true : false;
    }

}