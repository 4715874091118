import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';

import { environment } from '../shared/app-constant';
import { ProvinceInfoRequestModel } from './models/province-info-request.model';
import { ProvinceInfoResultModel } from './models/province-info-result.model';

@Injectable()
export class LocationService {
    constructor(private http: HttpClient) { }

    getProvinceInfo(request: ProvinceInfoRequestModel): Observable<ProvinceInfoResultModel> {
        let params = new HttpParams();
        if (request.provinceCode) {
            params = params.append('provinceCode', request.provinceCode.toString());
        }
        if (request.amphurCode) {
            params = params.append('amphurCode', request.amphurCode.toString());
        }
        if (request.districtCode) {
            params = params.append('districtCode', request.districtCode.toString());
        }
        if (request.postcodeId) {
            params = params.append('postcodeId', request.postcodeId.toString());
        }

        return this.http.get<ProvinceInfoResultModel>(
            environment.getProvinceInfoApiUrl,
            {params: params});
    }
}
