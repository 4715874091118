import { ResetPasswordConfirmContentModel } from './../../services/models/reset-password-confirm-content.model';
import { CommonService } from './../../services/common.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalModel } from './../../services/models/modal.model';
import { ControlContainer, NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { ResetPasswordConfirmResultModel } from './../../services/models/reset-password-confirm-result.model';
import { StringUtil } from '../../shared/string.util';
import { CustomValidators } from 'ng4-validators';

@Component({
  selector: 'app-reset-password-confirm-modal',
  templateUrl: './reset-password-confirm-modal.component.html',
  styleUrls: ['./reset-password-confirm-modal.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ResetPasswordConfirmModalComponent implements OnInit {
  @Input() modalConfirmModel: ModalModel;
  @Output() modalConfirmModelChange = new EventEmitter<ResetPasswordConfirmResultModel>();
  @Output() validateResetPwd = new EventEmitter<boolean>();

  confirmModel: ResetPasswordConfirmResultModel;
  resetPasswordForm: FormGroup;
  isPasswordValid: {
    lowerCase: boolean,
    upperCase: boolean,
    specialCharacter: boolean,
    number: boolean
  };

  errorMessage: string;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    console.log(this.modalConfirmModel);
    const oldPassword = new FormControl(this.getOldPasswordValue());
    const password = new FormControl(null, {
      updateOn: 'blur',
      validators: [Validators.required,
      Validators.minLength(8),
      Validators.maxLength(15),
      Validators.pattern(CommonService.VALID_PASSWORD_FORMAT),
      CustomValidators.notEqualTo(oldPassword)]
    });
    const rePassword = new FormControl(null, { updateOn: 'blur', validators: CustomValidators.equalTo(password) });

    this.resetPasswordForm = new FormGroup({
      'newPassword': password,
      'confirmPassword': rePassword
    });
    this.isPasswordValid = {
      lowerCase: false,
      upperCase: false,
      specialCharacter: false,
      number: false
    };
    this.resetPasswordForm.get('newPassword').valueChanges.subscribe((value) => {
      this.isPasswordValid.lowerCase = StringUtil.hasLowerCase(value);
      this.isPasswordValid.upperCase = StringUtil.hasUpperCase(value);
      this.isPasswordValid.number = StringUtil.hasNumber(value);
      this.isPasswordValid.specialCharacter = StringUtil.hasSpecialCharacter(value);
    });
    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.errorMessage = null;
      this.validateResetPwd.emit(this.resetPasswordForm.valid);
    })
    this.confirmModel = new ResetPasswordConfirmResultModel();
    this.confirmModel.newPassword = null;
    this.modalConfirmModelChange.emit(this.confirmModel);
    this.commonService.resetPwdError.subscribe((message) => {
      this.errorMessage = message;
    });
  }

  getValidPasswordFormat(): string {
    return CommonService.VALID_PASSWORD_FORMAT;
  }

  getOldPasswordValue(): string {
    let oldPasswordValue = null;
    if (this.modalConfirmModel && this.modalConfirmModel.content instanceof ResetPasswordConfirmContentModel) {
      oldPasswordValue = this.modalConfirmModel.content.oldPassword;
    }
    return oldPasswordValue;
  }

  isFieldInvalid(field: string) {
    const aField = this.resetPasswordForm.get(field);
    return aField.invalid && (aField.touched || aField.dirty);
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }
}
