import { Request } from "./request";

export class RecurringPaymentFilter extends Request {
    from?: Date;
    to?: Date;
    status?: string;
    fileType?: string;
    batchId?: string;

    constructor(from?: Date, to?: Date, status?: string, fileType?: string, batchId?: string) {
        super();
        this.from = from;
        this.to = to;
        this.status = status;
        this.fileType = fileType;
        this.batchId = batchId;
    }
}