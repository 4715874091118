export class RequestGoliveModel {
  email: string;
  password: string;
  rePassword: string;

	companyName: string;
	companyNameEn: string;
	companyIdName: string;
	companyTradeName: string;
	firstName: string;
	lastName: string;
	addressLn1: string;
	addressLn2: string;
	postcodeId: number;
	homePhoneNo: string;
	officePhoneNo: string;
	mobilePhoneNo: string;
	faxNo: string;
	emailCompany: string;
	businessTypeId: number;
	accountNo: string;
	remark: string;
	createBy: string;
}
