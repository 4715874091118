import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { MerchantConfigurationResponse } from '../../services/models/merchant-configuration.model';
import { MCCData } from '../../services/models/merchant-setting-mcc.model';

@Component({
  selector: 'app-merchant-setting-configuration',
  templateUrl: './merchant-setting-configuration.component.html',
  styleUrls: ['./merchant-setting-configuration.component.css']
})
export class MerchantSettingConfigurationComponent implements OnInit {

  companyId: string;
  enabledSof: MerchantConfigurationResponse;
  mccItem: MCCData[];

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private merchantSettingService: MerchantSettingService,
  ) { }

  ngOnInit() {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.loadMerchantConfiguration();
  }

  loadMerchantConfiguration() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getMerchantConfiguration(this.companyId).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.enabledSof = result;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }
}