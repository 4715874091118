import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbDateParserFormatter, NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ReportConfigModel } from '../../services/models/report-config.model';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { TransactionService } from '../../services/transaction.service';
import { CommonService } from '../../services/common.service';
import { ReportConfigService } from '../../services/report-config.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { Router } from '@angular/router';
import { TransactionReportLogFileModel } from '../../services/models/transaction-report-log-file.module';
import { CacheObservableService } from '../../services/cache-observable.service';
import { STATUS, message, FORMAT, HTTP_ERROR_MESSAGE, COMMON_TEXT } from '../../shared/app-constant';
import { Subject } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';
import { NgbDateCustomParserFormatter } from '../../pipes/ngb-date-parser-formatter';
import { DateUtil } from '../../shared/date.util';
import { Authority, AUTHORITY } from '../../shared/authority.util';
import { FormDateModel } from '../../services/models/form-date.model';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const HOVER_MESSAGE_INSTALLMENT_CROSS_BANK = "This report can be downloaded once. Please create the report again";

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class TransactionReportComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  reportStatus: DropdownElement;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDate;
  toDate: NgbDate;
  model: FormDateModel = {};
  request: any;
  reportConfigData = {};
  reportConfigModels: ReportConfigModel[];
  reportConfigModel: ReportConfigModel;
  reportStatuses: DropdownElement[];
  totalRecords = 0;

  // Pagination
  collectionSize: number;

  logFileList: TransactionReportLogFileModel[] = [];
  maxDate: any;
  minDate: any;
  modelDatePiker: string;
  parsed: string;
  isClickCreateReport: boolean = false;
  flagSelectedDate: boolean = false;

  failedMessage: string = message.content.failMessage;
  orClickRegenerate: string = message.content.orClickRegenerate;
  isAllowed: boolean;
  isHovered = inputDate => this.fromDate && !this.toDate && this.hoveredDate && after(inputDate, this.fromDate) && before(inputDate, this.hoveredDate);
  isInside = inputDate => after(inputDate, this.fromDate) && before(inputDate, this.toDate);
  isFrom = inputDate => equals(inputDate, this.fromDate);
  isTo = inputDate => equals(inputDate, this.toDate);
  isDisabled = (date: NgbDate, current: { year: number }) => date.day <= this.maxDate;
  today: Date;
  public hoverMessInstallmentCrossBank = HOVER_MESSAGE_INSTALLMENT_CROSS_BANK;

  @ViewChild('datePicker') datePicker: NgbInputDatepicker;
  @ViewChild('rangInputDate') rangInputDate: ElementRef;
  @ViewChild('dateTemplate') dateTemplate: ElementRef;

  constructor(
    private _commonService: CommonService,
    private _transactionService: TransactionService,
    private _reportConfigService: ReportConfigService,
    private renderer: Renderer2,
    private _parserFormatter: NgbDateParserFormatter,
    private _router: Router,
    private _calendar: NgbCalendar,
    private _cacheObservableService: CacheObservableService,
    private _toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.today = new Date();
    this.fromDate = this._calendar.getPrev(DateUtil.formatToNgbDate(this.today), 'd', 89);
    this.toDate = DateUtil.formatToNgbDate(this.today);
    if (this._cacheObservableService.has('transaction_report_goback')) {
      this.request = this._cacheObservableService.getAnyObject('transaction_report_goback');
      this.fromDate = DateUtil.formatToNgbDate(new Date(this.request.from));
      this.toDate = DateUtil.formatToNgbDate(new Date(this.request.to));
    }
    this.parsed = 'Date: ' + DateUtil.formatDate(DateUtil.ngbDateStructToDate(this.fromDate)) + ' - ' + DateUtil.formatDate(DateUtil.ngbDateStructToDate(this.toDate));
    this.initFilterCondition();
    this.initTableLogFile();
    this.isAllowed = Authority.hasAuthorities([AUTHORITY.REPORT_TRANSACTION]);
    this.isLoading = false;
  }

  /**
   * init table
   */
  initTableLogFile() {
    this.maxDate = {
      year: DateUtil.formatToNgbDate(this.today).year,
      month: DateUtil.formatToNgbDate(this.today).month,
      day: DateUtil.formatToNgbDate(this.today).day
    };
    this.minDate = {
      year: this.getMindate().getFullYear(),
      month: this.getMindate().getMonth() + 1,
      day: this.getMindate().getDate()
    }
    let from = DateUtil.formatDate(new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day), '-', FORMAT.YYYYMMDD_FORMAT);
    let to = DateUtil.formatDate(new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day), '-', FORMAT.YYYYMMDD_FORMAT);
    if (this._cacheObservableService.has('transaction_report_goback')) {
      this.request = this._cacheObservableService.getAnyObject('transaction_report_goback');
    } else {
      this.request = this.setRequestTransaction({
        from: from,
        to: to,
        reportConfigId: null,
        status: { key: null, value: '' }
      });
    }

    this.loadListLogFile(this.request);

  }

  /**
   * init filter condition
   */
  initFilterCondition() {
    let listDropdown: DropdownElement[] = [];
    this.reportConfigModels = [{
      reportConfigId: null,
      reportConfigName: "All",
      reportConfigType: "",
    }];
    this.reportStatuses = [{ key: null, value: 'All' }];

    this._reportConfigService.getReportConfig()
      .subscribe(response => {
        this.reportConfigModels.push(...response.reportConfig);
        if (response.reportStatus) {
          response.reportStatus.forEach(status => {
            if (status.split('_').length < 2) {
              listDropdown.push(new DropdownElement(status.toLowerCase(), status.toLowerCase()));
            }
          });
        }

        this.reportStatuses.push(...listDropdown);

        // load old status when back from create report
        if (this._cacheObservableService.has('transaction_report_goback')) {
          this.request = this._cacheObservableService.getAnyObject('transaction_report_goback');

          this.reportConfigModels.map(report => {
            if (report.reportConfigId === this.request.reportConfigId) {
              this.reportConfigModel = report;
            }
          });

          this.reportStatuses.map(report => {
            if (report.key === this.request.status) {
              this.reportStatus = report;
            }
          })
        } else {
          this.reportConfigModel = this.reportConfigModels[0];
          this.reportStatus = this.reportStatuses[0];
        }

      },
        err => console.error('_reportConfigService error='));
  }

  /**
   * select date
   * @param inputDate 
   */
  onDateSelection(inputDate: NgbDate) {
    this.flagSelectedDate = true;
    this.parsed = ''; // initializing with empty string
    if (!this.fromDate && !this.toDate) {
      this.fromDate = inputDate;
    } else if (this.fromDate && !this.toDate && !after(this.fromDate, inputDate)) {
      this.toDate = inputDate;
    } else {
      this.toDate = null;
      this.model.to = null;
      this.fromDate = inputDate;
    }

    if (this.fromDate) {
      // if fromDate is set: add the first date
      this.parsed += this._parserFormatter.format(this.fromDate);
      this.renderer.setProperty(this.rangInputDate
        .nativeElement, 'value', this.parsed);
    }
    if (this.toDate) {
      // if toDate is set: add the second date with separator
      this.parsed += ' - ' + DateUtil.formatDate(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day));
    }
    if (this.fromDate && this.toDate) {
      // here we update the input value with the new this.parsed value
      this.writtenValue(this.parsed);
    }

  }

  closeDatepicker(event) {
    if ((event.target.offsetParent && event.target.offsetParent.nodeName === "NGB-DATEPICKER")) {
      return
    }
    else if (this.flagSelectedDate) {
      if (!this.toDate) {
        let countDay = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime() + 90 * 24 * 60 * 60 * 1000;
        let day = new Date(countDay);
        this.toDate = day < this.today ? DateUtil.formatToNgbDate(day) : DateUtil.formatToNgbDate(this.today);
      }
      this.parsed += ' - ' + DateUtil.formatDate(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day));
      this.writtenValue(this.parsed);
    } else if (event.target.id != "range-input-date" && event.target.id != "date-shower") {
      this.datePicker.close();
    }
  }

  /**
   * filter
   * @param event 
   */
  changeFilterCondition(event?) {
    this.isLoading = true;
    this._commonService.isLoading(true);
    this.request = this.setRequestTransaction({
      from: this.parseToIOSString(this.toModel(this.fromDate)),
      to: this.parseToIOSString(this.toModel(this.toDate)),
      reportConfigId: this.reportConfigModel,
      status: this.reportStatus
    });
    this.loadListLogFile(this.request);

  }

  stopIsLoading() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  /**
   * load log file
   * @param request 
   */
  loadListLogFile(request) {
    this._commonService.isLoading(true);
    this.isLoading = true;
    this._transactionService.getFileReport(request).subscribe(
      result => {
        this.logFileList = [];
        if (result.report_logs) {
          this.collectionSize = +result.total_elements;
          result.report_logs.map(log => log.status = log.status ? log.status.split('_')[0].toLowerCase() : '')
          this.logFileList = result.report_logs;
        } else {
          this._commonService.isLoading(false);
          this.stopIsLoading();
        }
      },
      error => {
        this._commonService.isLoading(false);
        this.stopIsLoading();
      },
      () => {
        this._commonService.isLoading(false);
        this.stopIsLoading();
      });
  }

  loadPage(pageIndex: number) {
    this._commonService.isLoading(true);
    this.request.pageIndex = pageIndex;
    this.loadListLogFile(this.request);
  }

  gotoCreateReport() {
    this._commonService.isLoading(true);
    this.isClickCreateReport = true;
    this._cacheObservableService.set('transaction_report_goback', this.request);
    this._router.navigate(['/transaction-create-report']);
  }

  /**
   * deleteRecord
   * @param transaction 
   */
  deleteRecord(transaction) {
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result === true) {
        this.doDelete(transaction);
      }
    });
    this._commonService.alertDelete(message.title.confirm_delete, message.content.confirm_delete, callback);

  }

  /**
   * downloadFile
   * @param transaction 
   */
  downloadFile(transaction: TransactionReportLogFileModel) {
    if (this.isInstallmentCrossBank(transaction)) {
      const callback = new Subject<boolean>();
      callback.asObservable().subscribe(result => {
        if (result) {
          this.doDownloadFile(transaction);
        }
      });
      this._commonService.alertConfirm(message.title.information, message.content.inform_installment_cross_bank, callback, COMMON_TEXT.DOWNLOAD);
      return
    }
    this.doDownloadFile(transaction);
  }

  /**
   * regenerate
   * @param transaction 
   */
  regenerateRecord(transaction) {
    this._commonService.isLoading(true);
    this._transactionService.regenerate({ reportLogId: transaction.reportLogId }).subscribe(response => {
      if (response.status_code === STATUS.SUCCESS) {
        this._toasterService.success(message.content.processing);
        this.loadListLogFile(this.request);
      } else {
        this._toasterService.error(message.content.regenegate_fail);
        this._commonService.isLoading(false);
      }
    },
      error => {
        this._commonService.isLoading(false);
        if (error.status != '401') {
          this._toasterService.error(HTTP_ERROR_MESSAGE[error.status] || message.content.common_error);
        }
      });
  }

  isInstallmentCrossBank(transaction: TransactionReportLogFileModel): boolean {
    return transaction.reportType && this._transactionService.isInstallmentCrossBank(transaction.reportType);
  }

  /**
   * doDelete
   * @param transaction 
   */
  private doDelete(transaction) {
    this._commonService.isLoading(true);
    this._transactionService.deleteRecordLog({ reportLogId: transaction.reportLogId }).subscribe(response => {
      if (response && response.status_code === STATUS.SUCCESS) {
        this._toasterService.success(message.content.delete_success);
      } else {
        this._commonService.isLoading(false);
        this._toasterService.error(message.content.delete_fail);
      }
      this.loadListLogFile(this.request);
    },
      error => {
        this._commonService.isLoading(false);
        if (error.status != '401') {
          this._toasterService.error(HTTP_ERROR_MESSAGE[error.status] || message.content.common_error);
        }
      },
      () => { });
  }

  private doDownloadFile (transaction: TransactionReportLogFileModel) {
    this._transactionService.downloadFile({ reportLogId: transaction.reportLogId }).subscribe(response => {
      if (response.status_code === STATUS.SUCCESS) {
        const anchor = document.createElement('a');
        anchor.href = response.properties.preSignUrl;
        anchor.setAttribute('download', '');
        anchor.click();
        this._toasterService.success(message.content.download_success);
      } else {
        this._toasterService.error(message.content.download_fail);
      }
      if (this.isInstallmentCrossBank(transaction)) {
        this.loadListLogFile(this.request);
      }
    });
  }

  /**
   * update the input value for datepicker
   * @param value 
   */
  private writtenValue(value) {
    this.renderer.setProperty(this.rangInputDate
      .nativeElement, 'value', value);
    this.datePicker.close();
    this.changeFilterCondition()
    this.flagSelectedDate = false;
  }

  private toModel(date: NgbDateStruct): Date {
    return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
  }

  /**
  *  Get min date: 90 days previous (count from today) 
  */
  private getMindate(): Date {
    let d = new Date();
    let result;
    d = new Date(d.getTime() - 89 * 24 * 60 * 60 * 1000);
    result = d;
    return result;
  }

  /**
   * parse date to IOS time to call api
   * @param year 
   * @param month 
   * @param day 
   */
  private parseToIOSString(day): String {
    return day.toISOString();
  }

  /**
   * setRequestTransaction
   * @param model 
   */
  private setRequestTransaction(model): Object {
    const data = {
      from: model.from.split("T")[0],
      to: model.to.split("T")[0],
      reportConfigId: model && model.reportConfigId ? model.reportConfigId.reportConfigId : null,
      status: model && model.status ? model.status.key : null,
      pageIndex: 1,
      pageSize: 15
    }

    return data;
  }

  ngOnDestroy() {
    if (!this.isClickCreateReport && this._cacheObservableService.has('transaction_report_goback')) {
      this._cacheObservableService.remove('transaction_report_goback');
    }
    delete this.today
  }
}

export class DropdownElement {
  key?: string;
  value?: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}
