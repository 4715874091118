import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CompanyInfoModel } from '../../services/models/companyinfo.model';

@Component({
  selector: 'app-merchant-setting-company-info',
  templateUrl: './merchant-setting-company-info.component.html',
  styleUrls: ['./merchant-setting-company-info.component.css']
})
export class MerchantSettingCompanyInfoComponent implements OnInit {
  companyList: CompanyInfoModel[] = [];

  constructor(private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.auth.currentUser.subscribe((currentUser) => {
      this.companyList = currentUser ? currentUser.companyInfos : null ;
    });
  }
}
