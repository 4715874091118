export class RecurringPaymentUploadFileRequest {
    file?: File;
    fileType?: string;
    batchId?: string;

    constructor(file?: File, fileType?: string, batchId?: string) {
        this.file = file;
        this.fileType = fileType;
        this.batchId = batchId;
    }
}