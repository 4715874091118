import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CommonService } from '../../services/common.service';
import { ResponseUrlModel, ResponseUrlSaveDataRequest } from '../../services/models/response-url.model';
import { ResponseUrlService } from '../../services/response-url.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-merchant-configuration-response-url',
  templateUrl: './merchant-configuration-response-url.component.html',
  styleUrls: ['./merchant-configuration-response-url.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationResponseUrlComponent implements OnInit {

  @Input() companyId: string;

  isOpenResponseUrlSof: boolean = false;
  loadFlag: boolean = false;
  editEnabled = false;
  editPermit = false;
  private formSubmitAttempt: boolean;
  merchantResponseUrlForm: FormGroup;
  responseUrlModel: ResponseUrlModel;
  originResponseUrlInfo: ResponseUrlModel;
  
  editableFieldList = ['cardPaymentCallback', 'tokenCallback', 'alipayCallback', 'unionpayTPNCallback']

  constructor(
    private commonService: CommonService,
    private responseUrlService: ResponseUrlService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.commonService.isLoading(true);
    this.setupFormGroup();
    this.setupPermit();
  }

  setupFormGroup() {
    this.merchantResponseUrlForm = new FormGroup({
      'cardPaymentCallback': new FormControl(null, [
        Validators.pattern(CommonService.VALID_URL_INPUT_FORMAT)
      ]),
      'tokenCallback': new FormControl(null, [
        Validators.pattern(CommonService.VALID_URL_INPUT_FORMAT)
      ]),
      'alipayCallback': new FormControl(null, [
        Validators.pattern(CommonService.VALID_URL_INPUT_FORMAT)
      ]),
      'unionpayTPNCallback': new FormControl(null, [
        Validators.pattern(CommonService.VALID_URL_INPUT_FORMAT)
      ]),
    });
  }

  private setupPermit() {
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      const menuInfo = menuInfos.find(m => m.screenCode === 'M009');
      if (menuInfo) {
        const componentsInfos = menuInfo.componentsInfo;
        if (componentsInfos) {
          componentsInfos.forEach(componentsInfo => {
            if (componentsInfo.roleActionCode === 'ROLE_B_2901') {
              this.editPermit = true;
            }
          });
        }
      }
    }
  }

  onClickMoreDetail() {
    this.isOpenResponseUrlSof = !this.isOpenResponseUrlSof;
    if(this.loadFlag){
      return;
    }
    this.getMerchantResponseUrl();
    this.loadFlag = true;
  }

  getMerchantResponseUrl(){
    this.commonService.isLoading(true);
    this.responseUrlService.getResponseUrl(this.companyId).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.responseUrlModel = result;
          this.originResponseUrlInfo = JSON.parse(JSON.stringify(result));
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  doEdit() {
    this.commonService.isLoading(true);
    this.editEnabled = true;
    this.formSubmitAttempt = false;
    this.buildResponseUrlSettingForm();
    this.commonService.isLoading(false);
  }
  
  doSave() {
    this.formSubmitAttempt = true;
    if (this.merchantResponseUrlForm.valid) {
      this.merchantResponseUrlSave();
    }
  }

  doCancelEdit() {
    this.responseUrlModel = JSON.parse(JSON.stringify(this.originResponseUrlInfo));
    this.editEnabled = false;
    this.formSubmitAttempt = false;
  }

  private merchantResponseUrlSave() {
    this.commonService.isLoading(true);
    const merchantResponseUrlSaveModel = new ResponseUrlSaveDataRequest;
    merchantResponseUrlSaveModel.companyId = Number(this.companyId);
    merchantResponseUrlSaveModel.cardPaymentCallback = this.merchantResponseUrlForm.get('cardPaymentCallback').value;
    merchantResponseUrlSaveModel.tokenCallback = this.merchantResponseUrlForm.get('tokenCallback').value;
    merchantResponseUrlSaveModel.alipayCallback = this.merchantResponseUrlForm.get('alipayCallback').value;
    merchantResponseUrlSaveModel.unionpayTPNCallback = this.merchantResponseUrlForm.get('unionpayTPNCallback').value;

    this.responseUrlService.updateResponseUrl(merchantResponseUrlSaveModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Save company info setting.');

          this.editEnabled = false;
          // this.updateCompanyInfoEntryFormWhenSuccess();
          this.getMerchantResponseUrl();
          this.auth.renewCurrentUser();
        } else {
          this.toasterService.error(result.status_message);
          this.responseUrlModel = JSON.parse(JSON.stringify(this.originResponseUrlInfo));
        }
      }, error => {
        this.responseUrlModel = JSON.parse(JSON.stringify(this.originResponseUrlInfo));
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
    this.commonService.isLoading(false);
  }

  private buildResponseUrlSettingForm() {
    if (this.responseUrlModel) {
      this.merchantResponseUrlForm.get('cardPaymentCallback').patchValue(this.responseUrlModel.cardPaymentCallback);
      this.merchantResponseUrlForm.get('tokenCallback').patchValue(this.responseUrlModel.tokenCallback);
      this.merchantResponseUrlForm.get('alipayCallback').patchValue(this.responseUrlModel.alipayCallback);
      this.merchantResponseUrlForm.get('unionpayTPNCallback').patchValue(this.responseUrlModel.unionpayTPNCallback);
    }
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }

  isFieldInvalid(field: string) {
    const aField = this.merchantResponseUrlForm.get(field);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  getInvalidUrlInputCharacters(): string {
    return CommonService.INVALID_URL_INPUT_CHARACTERS;
  }

  checkNotNull(value:string){
    return value != null;
  }

}
