
import { tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './auth/authentication.service';
import { FingerprintService } from './auth/fingerprint.service';

import { HttpResponse } from '@angular/common/http';
import { TokenModel } from './models/token.model';
import { TokenService } from './auth/token.service';
import { CommonService } from './common.service';
import { BY_PASS_CONTENT_TYPE_URL } from '../shared/app-constant';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  // auth: any;
  constructor(
    private router: Router,
    private inj: Injector,
    private fingerprintService: FingerprintService,
    private tokenService: TokenService,
    private commonService: CommonService
    ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const fingerprintDigest = this.fingerprintService.getFingerprint();

    // Clone the request to add the new header
    let clonedRequest = req.clone(this.byPassContentType(req.url) ? {
      params: req.params.append('fingerprintDigest', fingerprintDigest)
    } : {
      params: req.params.append('fingerprintDigest', fingerprintDigest),
      setHeaders: { 'Content-Type': 'application/json' }
    });

    // Add Header Authorization.
    const currentuser = sessionStorage.getItem('currentUser');
    if (currentuser) {
      const token = JSON.parse(sessionStorage.getItem('currentUser')).token;
      const authHeader = 'Bearer ' + token;
      clonedRequest = clonedRequest.clone({ headers: clonedRequest.headers.append('Authorization', authHeader) });
    }

    return next.handle(clonedRequest).pipe(tap(
      event => {
        if (event instanceof HttpResponse) {
          // update last api call time
          sessionStorage.setItem('lastApiCall', new Date().getTime() + '');
          this.tokenService.setTimer();
        }
      },
      err => {
        this.commonService.isLoading(false);
        if (err instanceof HttpErrorResponse) {
          console.log('Error status: ' + err.status + ' By Interceptor');
          if (err.status === 401) {
            const auth = this.inj.get(AuthenticationService);
            auth.logout().subscribe(
              result => {
                console.log('logout success: ' + result);
              }, error => {
                console.log('error:' + error);
              }, () => {
                console.log('');
              }
            );
            // auth.logout();
            // auth.isAuthenticated();
            // this.router.navigate(['home']);
          } else if (err.status === 403) {
            this.router.navigate(['nopermission']);
          }
        }
      }));
  }

  private byPassContentType(url: string): boolean {
    return BY_PASS_CONTENT_TYPE_URL.includes(url)
  }
}
