import { ResultModel } from './result.model';

export class TransactionInfoModel extends ResultModel {
    Amount: string;
    SettledAmount: string;
    Rate: string;
    BahtAmount: string;
    AuthorizeTime: string;
    CardBrand: string;
    CreateDate: string;
    UpdateDate: string;
    CurrencyCode: string;
    RefTrxId: string;
    TrxId: string;
    TrxState: string;
    StatusId: number;
    StatusStateId: number;
    CompanyId: number;
    InvoiceNo: string;
    RefNumber: string;
    ObjectId: string;
    SourceType: string;
    ProductDescription: string;
    SecureTransaction: boolean;
    PaymentTypeDesc: string;
    chargeId: string;

    hasConvFee: boolean;
    hasMultiClearing: boolean;
}


