export class TimeSelector {
    public hour: number;
    public minute: number;

    constructor(hour?: number, minute?: number) {
        this.hour = hour || 0;
        this.minute = minute || 0;
    }

    public increaseHour(hour?: number) {
        const tmpHour = this.hour + hour || this.hour + 1;
        this.hour = tmpHour > 23 ? tmpHour - 24 : tmpHour;
    }
    public decreaseHour(hour?: number) {
        const tmpHour = this.hour - hour || this.hour - 1;
        this.hour = tmpHour < 0 ? 24 + tmpHour : tmpHour;
    }
    public increaseMinute(minute?: number) {
        const tmpMinute = this.minute + minute || this.minute + 30;
        this.minute = tmpMinute >= 30 && tmpMinute < 60 ? 30 : 0;
    }
    public decreaseMinute(minute?: number) {
        const tmpMinute = this.minute - minute || this.minute - 30;
        this.minute = tmpMinute < 0 ? minute || 30 : tmpMinute;
    }
}
