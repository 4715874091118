import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { HistoryInfo, MultipleSelectionResponse } from "../../services/models/multiple-selection.model";
import { Router } from '@angular/router';
import { MultipleSelectionService } from '../../services/multiple-selection.service';
import { AUTHORITY, Authority } from '../../shared/authority.util';
import {  CACHE_OBSERVABLE_SERVICE, DateRegExp, STATUS } from '../../shared/app-constant';
import { DateUtil } from '../../shared/date.util';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CacheObservableService } from '../../services/cache-observable.service';

export const STATUS_DONE = 'Done';

@Component({
  selector: 'app-multiple-selection',
  templateUrl: './multiple-selection.component.html',
  styleUrls: ['./multiple-selection.component.css']
})
export class MultipleSelectionComponent implements OnInit {

  multipleSelectionList: HistoryInfo[] = [];
  pageSize : number;
  pageIndex : number;
  collectionSize: number;
  isAllowMultipleRefund: boolean;
  isDisableMultipleRefund: boolean;
  isLoading: boolean;
  currentDate : Date;
  currentMenuInfo: any = {};
  isMultipleRefund: boolean =false;

  multipleSelection: MultipleSelectionResponse;

  constructor(
    private commonService: CommonService,
    private multipleSelectionService: MultipleSelectionService,
    private router : Router,
    private auth: AuthenticationService,
    private cache : CacheObservableService,
  ) { }

  ngOnInit() {
    this.currentMenuInfo = this.auth.getCurrentUserMenuInfo().find(x => x.url === 'multiple-selection');
    this.currentDate = new Date();
    if (this.cache.has(CACHE_OBSERVABLE_SERVICE.MULTIPLE_SELECTION_GO_BACK)) {
      this.pageIndex = this.cache.getAnyObject(CACHE_OBSERVABLE_SERVICE.MULTIPLE_SELECTION_GO_BACK);
    } else {
      this.pageIndex = 1;
    }
    this.isAllowMultipleRefund =  Authority.hasAuthorities([AUTHORITY.MULTIPLE_REFUND]);
    this.initTableMultipleSelection()
    this.isLoading = false;
  }

  initTableMultipleSelection(){
    this.loadMultipleSelectList();
  }

  loadMultipleSelectList(){
    this.commonService.isLoading(true);
    this.isLoading = true;
    this.multipleSelectionService.getMultipleSelection(this.pageIndex).subscribe(
      result => {
        this.multipleSelectionList = [];
        this.isDisableMultipleRefund = result.allowMultipleRefund;
        this.pageSize = result.pageSize ? result.pageSize : 0;
        this.collectionSize = result.totalElements ? result.totalElements : 0;
        this.multipleSelectionList = result.historyInfos && result.historyInfos.length > 0 ? result.historyInfos : [];
      },
      error => {
        this.commonService.isLoading(false);
        this.stopIsLoading();
      },
      () => {
        this.commonService.isLoading(false);
        this.stopIsLoading();
      });
  }

   refreshPage(pageIndex : number) {
    this.pageIndex = pageIndex
    this.loadMultipleSelectList();
  }

  loadPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.loadMultipleSelectList();
  }

  viewMultipleResult(tran : HistoryInfo){
    this.commonService.isLoading(true);
    this.isMultipleRefund = true;
    this.setCache();
    this.router.navigate(['/multiple-selection/multiple-refund-result/' + tran.historyId]);
  }

  isAllowView(tran : HistoryInfo){
    const actionDate = tran.actionDateTime.split(" ")[0];
    const dateMonthYear = DateRegExp.DAY_MONTH_YEAR.test(actionDate);
    const date = DateUtil.createDate(actionDate, dateMonthYear ? DateRegExp.DAY_MONTH_YEAR : DateRegExp.YEAR_MONTH_DAY, dateMonthYear);
    const dateAllowView = DateUtil.subtractDay(this.currentDate, 2);
    return tran.status == STATUS_DONE && (DateUtil.isAfterDate(date, dateAllowView) || DateUtil.isEqualsDate(date, dateAllowView));
  }

  gotoMultipleRefund() {
    this.commonService.isLoading(true);
    this.isMultipleRefund = true;
    this.setCache();
    this.router.navigate(['/multiple-selection/multiple-refund']);
  }

  private setCache() {
    this.cache.set(CACHE_OBSERVABLE_SERVICE.MULTIPLE_SELECTION_GO_BACK, this.pageIndex);
  }

  ngOnDestroy(): void {
    if(!this.isMultipleRefund){
      this.cache.clear();
    }
  }

  stopIsLoading() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

}
