import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { InstallmentInfoDetailRequest, Smartpay, SmartpayConfigDetail } from '../../services/models/merchant-setting-installment-info.model';

@Component({
  selector: 'app-merchant-configuration-installment-info',
  templateUrl: './merchant-configuration-installment-info.component.html',
  styleUrls: ['./merchant-configuration-installment-info.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationInstallmentInfoComponent implements OnInit {

  @Input() companyId: string;

  isOpenInstallmentInfoSof: boolean = false;
  isOpenSmartPay: boolean = false;
  isOpenSmartPayOld: boolean = false;
  loadFlag: boolean = false;
  showSmartpayDescription: boolean;
  totalElement: number;
  pageSize: number;
  pageIndex: number;
  totalElementSmartPayOld: number;
  pageSizeSmartPayOld: number;
  pageIndexSmartPayOld: number;
  listSmartPayOld: Smartpay[];
  listSmartPay: Smartpay[];
  listSmartpayConfigDetail : SmartpayConfigDetail[];
  listSmartpayOldConfigDetail : SmartpayConfigDetail[];
  selectedSmartPay: Smartpay;
  selectedSmartPayOld: Smartpay;
  request: InstallmentInfoDetailRequest = new InstallmentInfoDetailRequest();

  constructor(
    private commonService: CommonService,
    private merchantSetting: MerchantSettingService,
  ) { }

  ngOnInit() {
    this.totalElement = 0;
    this.pageIndex = 1;
    this.totalElementSmartPayOld = 0;
    this.pageIndexSmartPayOld = 1;
    this.selectedSmartPayOld = null;
  }

  onClickMoreDetail() {
    this.isOpenInstallmentInfoSof = !this.isOpenInstallmentInfoSof;
    if(this.loadFlag){
      return;
    }
    this.getMerchantInstallmetnInfo()
    this.loadFlag = true;
  }

  getMerchantInstallmetnInfo(){
    this.commonService.isLoading(true);
    this.merchantSetting.getInstallmentInfo(this.companyId).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.listSmartPay = result.smartpayConfig;
          this.listSmartPayOld = result.smartpayOldConfig;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  getInstallmentInfoDetail(request: InstallmentInfoDetailRequest){
    this.commonService.isLoading(true);
    this.merchantSetting.getInstallmentInfoDetail(request).subscribe(
      result => {
        if(request.smartpayOld){
          this.listSmartpayOldConfigDetail = null;
          this.totalElementSmartPayOld = 0;
          this.pageSizeSmartPayOld = 0;
        } else {
          this.listSmartpayConfigDetail = null;
          this.totalElement = 0;
          this.pageSize = 0;
        }
        this.showSmartpayDescription = null;
        if (this.commonService.isSuccess(result)) {
          if(request.smartpayOld){
            this.listSmartpayOldConfigDetail = result.smartpayConfigDetail;
            this.totalElementSmartPayOld = result.totalElement
            this.pageSizeSmartPayOld = result.pageSize
          } else {
            this.listSmartpayConfigDetail = result.smartpayConfigDetail;
            this.totalElement = result.totalElement
            this.pageSize = result.pageSize
          }
          this.showSmartpayDescription = result.showSmartpayDescription;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  prepareRequestInstallmentInfoDetail(item: Smartpay, isSmartpayOld: boolean){
    this.request.companyId = Number(this.companyId);
    this.request.smartpayOld = isSmartpayOld;
    this.request.pageIndex = isSmartpayOld ? this.pageIndexSmartPayOld : this.pageIndex;
    this.request.merchantId = item.merchantId;
    this.request.terminalId = item.terminalId;
    this.request.manualData = item.manualData;
  }

  onClickInstallmentInfo(item: Smartpay, isSmartpayOld: boolean){
    if (isSmartpayOld) {
      this.selectedSmartPayOld = item;
      this.isOpenSmartPayOld = true;
      this.totalElementSmartPayOld = 0;
      this.pageIndexSmartPayOld = 1;
    } else {
      this.selectedSmartPay = item;
      this.isOpenSmartPay = true;
      this.totalElement = 0;
      this.pageIndex = 1;
    }
    this.prepareRequestInstallmentInfoDetail(item, isSmartpayOld);
    this.getInstallmentInfoDetail(this.request);
  }

  loadSmartPayOldPage(pageIndex: number) {
    this.pageIndexSmartPayOld = pageIndex;
    this.request.smartpayOld = true;
    this.request.pageIndex = this.pageIndexSmartPayOld;
    this.getInstallmentInfoDetail(this.request);
  }

  loadSmartPayPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.request.smartpayOld = false;
    this.request.pageIndex = this.pageIndex;
    this.getInstallmentInfoDetail(this.request);
  }

}
