export class MerchantSettingCompanyInfoSaveModel {
	companyId: string;
	companyIdName: string;
	companyNameTh: string;
	companyNameEn: string;
	tradingNameTh: string;
	tradingNameEn: string;
	firstName: string;
	lastName: string;
	email: string;
	mobileNo: string;
	phoneHome: string;
	phoneOffice: string;
	fax: string;
	addressLine1: string;
	addressLine2: string;
	postalId: number;
	wechatCallback: string;
	auth3dCallback: string;
	threeDNotifyCallback: string;
	qrCallback: string;
	upopCallback: string;
	upopNotifyCallback: string;
	alipayCallback: string;
	alipayNotifyCallback: string;
	addressNo: string;
	buildingName: string;
	roomNo: string;
	floorNo: string;
	village: string;
	soi: string;
	road: string;
	moo: string;
}
