import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReportConfigResponse } from './models/report-config-response.model';
import { environment } from '../shared/app-constant';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PromotionInfoResponse } from './models/promotion-info-response.model';
import { PromotionRequest } from './models/promotion-request.model';
import { SupplierNumberListResponse } from './models/supplier-number-list-response.model';

@Injectable()
export class ReportConfigService {

  constructor(private http: HttpClient) { 

  }

  getReportConfig(): Observable<ReportConfigResponse> {
    return this.http.post(environment.getReportConfig,null).pipe( map((response: ReportConfigResponse) => {
        return response;
      }));
  }

  getSupplierNumberList(): Observable<SupplierNumberListResponse> {
    return this.http.post(environment.getSupplierNumberList,null).pipe( map((response: SupplierNumberListResponse) => {
      return response;
    }));
  }

  getProductTypeList(promotionRequest: PromotionRequest): Observable<PromotionInfoResponse> {
    return this.http.post(environment.getProductTypeList, promotionRequest).pipe( map((response: PromotionInfoResponse) => {
      return response;
    }));
  }
  getModelNoList(promotionRequest: PromotionRequest): Observable<PromotionInfoResponse> {
    return this.http.post(environment.getModelNoList, promotionRequest).pipe( map((response: PromotionInfoResponse) => {
      return response;
    }));
  }

  
}
