import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CommonService } from '../../services/common.service';
import { CompanyInfoModel } from '../../services/models/companyinfo.model';
import { SwitchProfileRequest } from '../../services/models/switch-profile-request.model';
import { UserModel } from '../../services/models/user.model';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-book-mark',
  templateUrl: './book-mark.component.html',
  styleUrls: ['./book-mark.component.css']
})
export class BookMarkComponent implements OnInit {

  switchCompanys: CompanyInfoModel[];
  switchProfileRequest: SwitchProfileRequest;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private commonService: CommonService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.initAvailableSwitchCompanys();
  }

  public onClickProfile(company: CompanyInfoModel){
    this.commonService.isLoading(true);
    const currentActiveCompany = this.auth.getCurrentUserActiveCompany();
    const user = this.auth.getCurrentUserInfo();
    if(currentActiveCompany === company.companyId){
      this.navigateAfterChoooseProfile()
    } else {
      this.switchProfileRequest = new SwitchProfileRequest();
      this.switchProfileRequest.companyId = company.companyId;
      this.switchProfileRequest.userId = user.userId;
      this.auth.getUserSwitchInfo(this.switchProfileRequest).subscribe(
        response => {
          if(this.commonService.isSuccess(response)){
            const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            currentUser.menuInfo = response.menuInfo;
            currentUser.companyInfos = response.companyInfos;
            currentUser.activeCompany = response.activeCompany;
            currentUser.token = response.token;
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.auth.setCurrentUserInfo(new UserModel(currentUser.userInfo, currentUser.menuInfo, currentUser.companyInfos, currentUser.switchCompanyInfos, currentUser.activeCompany, currentUser.token));
          }
        }, error => {
          this.commonService.isLoading(false);
          this.toasterService.error('Please contact administrator.', 'Server API Error');
        }, () => {
          this.commonService.isLoading(false);
            this.navigateAfterChoooseProfile()
        }
      );
    }
  }

  private navigateAfterChoooseProfile(){
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser.isSelectedCompany = true;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.auth.isSelectedCompany();
    let defaultUrl = '/';
    const menu = this.auth.getCurrentUserMenuInfo();
    if (menu) {
      const defaultMenu = menu.find(m => m.defaultScreen);
      if (defaultMenu) {
        defaultUrl = defaultMenu.url;
      } else {
        defaultUrl = 'dashboard';
      }
    }
    console.log('default url is: ' + defaultUrl);
    this.router.navigateByUrl(defaultUrl);
  }

  private initAvailableSwitchCompanys(){
    this.switchCompanys = this.auth.getCurrentUserSwitchCompanyInfos();
    console.log(this.switchCompanys)
  }
}
