import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { cloneObject } from '../../shared/common.util';
import { MultiselectDropdownConfig } from '../../services/models/multiselect-dropdown-config.model';

@Component({
  selector: 'app-multiselect-dropdown-custom',
  templateUrl: './multiselect-dropdown-custom.component.html',
  styleUrls: ['./multiselect-dropdown-custom.component.css']
})
export class MultiselectDropdownCustomComponent implements OnInit {

  @Output() closeDropDown = new EventEmitter<any>();
  @Input() config: MultiselectDropdownConfig;

  public openDropdown: boolean;
  public isSearching: boolean;
  public isClickCheckboxArea: boolean;
  public selectItems: any[]; //show item in multiselect-dropdown class 
  public searchList: any[]; // show item in dropdown
  public selectedAll: boolean;
  public selectedItems: any[]; // contain all item is selected
  public searchField: string;

  constructor() { }

  ngOnInit() {
    this.openDropdown = false;
    this.isSearching = false;
    this.isClickCheckboxArea = false;
    this.selectItems = [];
    this.initData();
  }

  initData() {
    this.config.list.forEach((item) => {
      item.selected = this.config.selectAll;
      this.selectItems.push(item);
    });
    this.selectedAll = this.config.selectAll;
    this.selectedItems = this.selectedAll ? cloneObject(this.selectItems) : [];
    this.searchList = cloneObject(this.selectItems);
  }

  checkOrUncheckAll() {
    this.selectedAll = !this.selectedAll;
    this.searchList.map((item) => item.selected = this.selectedAll);
    this.selectItems.map((i) => i.selected = this.selectedAll);
    if (this.selectedAll) {
      this.selectedItems = cloneObject(this.selectItems);
    } else {
      this.selectedItems = [];
    }
  }

  checkOrUncheckItems(item) {
    this.searchList.forEach((i) => {
      if (Array.isArray(this.config.idField)) {
        let count = 0;
        this.config.idField.forEach((field) => {
          if (item[field] != i[field]) {
            count++;
          }
        });
        if (count > 0) {
          return
        }
      }
      if (item[this.config.idField] != i[this.config.idField]) {
        return
      }
      i.selected = !item.selected;
      const itemInSelectItems = this.findItemInList(this.selectItems, item);
      if (itemInSelectItems) {
        // check/uncheck item in selectItem to display in multiselect-dropdown class
        this.selectItems.forEach((selectItem) => {
          if (selectItem == itemInSelectItems) {
            selectItem.selected = i.selected;
          }
        });
      }
      if (i.selected) {
        const itemInSelectedList = this.findItemInList(this.selectedItems, item);
        if (!itemInSelectedList) {
          this.selectedItems.push(i);
        }
      } else {
        const itemSelect = this.findItemInList(this.selectedItems, item)
        if (itemSelect) {
          this.selectedItems.splice(this.selectedItems.indexOf(itemSelect), 1);
        }
      }
    });
    this.selectedAll = this.selectedItems.length == this.config.list.length;
  }

  uncheckItems(item) {
    const itemInSelectedList = this.findItemInList(this.selectedItems, item);
    if (itemInSelectedList) {
      this.selectedItems.splice(this.selectedItems.indexOf(itemInSelectedList), 1);
    }
    this.selectedAll = false;
    setTimeout(() => {
      this.openDropdown = false;
      this.searchList.map(element => {
        if (Array.isArray(this.config.idField)) {
          let count = 0;
          this.config.idField.forEach((field) => {
            if (element[field] == item[field]) {
              count++
            }
          });
          if (count == this.config.idField.length) {
            element.selected = false;
          }
        } else {
          if (element[this.config.idField] == item[this.config.idField]) {
            element.selected = false;
          }
        }
      });
      this.selectItems = cloneObject(this.searchList);
      this.closeDropDown.emit(this.selectedItems);
    }, 0);
  }

  searchCustom() {
    if (!this.searchField) {
      this.searchList = cloneObject(this.selectItems);
      return
    }
    let searchList = [];
    this.selectItems.forEach((item) => {
      const value = item[this.config.searchOn].toString().toLowerCase();
      const search = this.searchField.toString().toLowerCase();
      if (value.search(search) >= 0) {
        const selectedItem = this.findItemInList(this.selectedItems, item); // Search item in selectedItem to check/uncheck on searchList (case dropdown list)
        item.selected = selectedItem ? true : false;
        searchList.push(item);
      }
    });
    this.searchList = searchList;
  }

  focus() {
    if (this.isSearching) {
      return
    }
    this.openDropdown = !this.openDropdown;
    if (!this.openDropdown) {
      this.searchField = '';
      this.searchCustom();
      this.closeDropDown.emit(this.selectedItems);
    }
  }

  clickOutside(event) {
    if (event && this.openDropdown) {
      this.openDropdown = !event;
      this.isSearching = false;
      this.searchField = '';
      this.searchCustom();
      this.closeDropDown.emit(this.selectedItems);
    }
  }

  isHide(item) {
    let count = 0;
    let result: any;
    this.selectItems.forEach((itemInList) => {
      if (itemInList.selected && this.config.itemsShowLimit > count) {
        count++;
        result = itemInList;
      }
    });
    return this.selectItems.indexOf(item) > this.selectItems.indexOf(result);
  }

  private findItemInList(list: any[], item: any) {
    let result: any;
    if (Array.isArray(this.config.idField)) {
      list.forEach((itemSelected) => {
        let itemInSelectedList = false;
        let count = 0;
        if (!itemInSelectedList) {
          this.config.idField.forEach((field) => {
            if (item[field] == itemSelected[field]) {
              count++;
            }
          });
          itemInSelectedList = count == this.config.idField.length;
        }
        if (itemInSelectedList) {
          result = itemSelected;
        }
      });
    } else {
      result = list.find((element) => element[this.config.idField] == item[this.config.idField]);
    }
    return result;
  }

}

