import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderInfo } from '../../services/order-info.model';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ThaiQrInquiryDetailService } from '../../services/thai-qr-inquiry-detail.service';
import { LocationStrategy } from '@angular/common';
import { THAI_QR_STATUS, TRANSACTION_STATUS, CACHE_OBSERVABLE_SERVICE } from '../../shared/app-constant';
import { CacheObservableService } from '../../services/cache-observable.service';

@Component({
  selector: 'app-thai-qr-inquiry-detail',
  templateUrl: './thai-qr-inquiry-detail.component.html',
  styleUrls: ['./thai-qr-inquiry-detail.component.css']
})
export class ThaiQrInquiryDetailComponent implements OnInit {

  public thaiQrDetail: OrderInfo;
  private qrId: string;
  private orderTransactionId: string;
  private companyId: number[];
  private isClickGoBack: boolean;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private auth: AuthenticationService,
    private thaiQRInquiryDetailService: ThaiQrInquiryDetailService,
    private cacheObservableService: CacheObservableService,
    private router: Router
  ) { }

  ngOnInit() {
    this.qrId = this.route.snapshot.paramMap.get('qrId');
    this.orderTransactionId = this.route.snapshot.paramMap.get('orderTransactionId');
    this.companyId = this.auth.getCurrentUserCompanyInfos().map((element) => {
      return element.companyId;
    });
    this.isClickGoBack = false;
    this.thaiQrDetail = new OrderInfo();
    this.getThaiQRDetail();
  }

  getThaiQRDetail() {
    this.commonService.isLoading(true);
    this.thaiQRInquiryDetailService.getDetail(this.qrId, this.companyId, this.orderTransactionId).subscribe(
      (response) => {
        if (this.commonService.isSuccess(response)) {
          this.thaiQrDetail = response.detail;
        }
        this.commonService.isLoading(false);
      }, error => {
        this.commonService.isLoading(false);
      });
  }

  inquiry() {
    this.commonService.isLoading(true);
    this.thaiQRInquiryDetailService.inquire(this.qrId, this.companyId).subscribe(
      (response) => {
        if (this.commonService.isSuccess(response)) {
          this.thaiQrDetail = response.detail;
        }
        this.commonService.isLoading(false);
      }, error => {
        this.commonService.isLoading(false);
      });
  }

  showInquiry() {
    return this.thaiQrDetail
    && this.thaiQrDetail.qrStatus != THAI_QR_STATUS.PAID
    && this.thaiQrDetail.qrStatus != THAI_QR_STATUS.VOIDED
    && this.thaiQrDetail.qrStatus != THAI_QR_STATUS.REFUNDED
    && this.thaiQrDetail.trxStatus != TRANSACTION_STATUS.AUTHORIZED
  }

  ngOnDestroy() {
    console.log('ThaiQRInquiryDetailComponent ngOnDestroy');
    if (!this.isClickGoBack && this.cacheObservableService.has(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY)) {
      this.cacheObservableService.remove(CACHE_OBSERVABLE_SERVICE.THAI_QR_INQUIRY);
    }
  }

  goBack() {
    console.log('ThaiQRInquiryDetailComponent goBack');
    this.isClickGoBack = true;
    this.router.navigate(['/thaiqr-inquiry']);
  }

}
