import { TransactionInfoSummaries } from "./multiple-refund-confirmation.model";
import { ResultModel } from "./result.model";

export class RefundResultRequest {
    historyId: number;
    pageIndex: number;
}

export class RefundResultResponse extends ResultModel {
    refundedTransaction: string;
    totalElements: number;
    pageSize: number;
    trxInfoSummaries: TransactionInfoSummaries[];
    multipleRefundResultTrxInfos: MultipleRefundResultTrxInfos[];
}

export class MultipleRefundResultTrxInfos {
    objectId : string;
    createDateTime: string;
    refNumber: string;
    productDescription: string;
    trxState: string;
    paymentTypeDesc: string;
    currencyCode: string;
    settledAmount: string;
    refundAmount: string;
    result: string;
    failMess: string;
}