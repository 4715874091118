import { Component, OnInit, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../../services/common.service';
import { ToasterService } from '../../services/toaster.service';
import { RegistrationService } from '../../services/registration.service';

import { ForgotPasswordRequestModel } from '../../services/models/forgot-password-request.model';
import { ResultModel } from '../../services/models/result.model';
import { FormResetPasswordModel } from '../../services/models/form-reset-password.model';
import { ProcessResetPasswordModel } from '../../services/models/process-reset-password.model';

@Component({
  selector: 'app-forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  styleUrls: ['./forgot-password-reset.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordResetComponent implements OnInit {
  formSubmitAttempt: boolean;
  forgotPasswordResetForm: FormGroup;
  submitEnabled = false;

  private token: string;

  constructor(private commonService: CommonService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    /* Get token from email link */
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
    console.log('token: ' + this.token);

    /* Init form */
    const password = new FormControl(null, [ Validators.required,
      Validators.minLength(8), Validators.maxLength(15),
      Validators.pattern(CommonService.VALID_PASSWORD_FORMAT) ]);
    const rePassword = new FormControl(null, CustomValidators.equalTo(password));

    this.forgotPasswordResetForm = new FormGroup({
      'password': password,
      'rePassword': rePassword
    });

    /* Check token */
    this.formResetPassword();
  }

  clearInfo() {
    this.forgotPasswordResetForm.reset();
    this.formSubmitAttempt = false;
  }

  onSubmit() {
    this.formSubmitAttempt = true;

    if (this.forgotPasswordResetForm.valid) {
      console.log('forgot-password-reset submit!');
      this.processResetPassword();
    }
  }

  private formResetPassword() {
    this.commonService.isLoading(true);

    // disable password fields, enable when token is valid.
    this.forgotPasswordResetForm.get('password').disable();
    this.forgotPasswordResetForm.get('rePassword').disable();

    const formResetPasswordModel = new FormResetPasswordModel();
    formResetPasswordModel.token = this.token;
    console.log('formResetPasswordModel.token: ' + formResetPasswordModel.token);
    if (!formResetPasswordModel.token) {
      this.commonService.isLoading(false);
      this.toasterService.error('Invalid token.');
      return;
    }

    this.registrationService.formResetPassword(formResetPasswordModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.submitEnabled = true;
          this.forgotPasswordResetForm.get('password').enable();
          this.forgotPasswordResetForm.get('rePassword').enable();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }

  private processResetPassword() {
    this.commonService.isLoading(true);

    const processResetPasswordModel = new ProcessResetPasswordModel();
    processResetPasswordModel.token = this.token;
    processResetPasswordModel.password = this.forgotPasswordResetForm.get('password').value;
    console.log('processResetPasswordModel.token: ' + processResetPasswordModel.token);
    console.log('processResetPasswordModel.password: ' + processResetPasswordModel.password);

    this.registrationService.processResetPassword(processResetPasswordModel).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.toasterService.success('Change password success.');
          this.clearInfo();
        } else {
          this.toasterService.error(result.status_message);
        }
      }, error => {
        this.commonService.isLoading(false);
        this.toasterService.error('Please contact administrator.', 'Server API Error!');
      }, () => {
        this.commonService.isLoading(false);
      }
    );
  }


  isFieldInvalid(field: string) {
    const aField = this.forgotPasswordResetForm.get(field);
    return aField.invalid && (this.formSubmitAttempt || (aField.touched || aField.dirty));
  }

  displayFieldCss(field: string) {
    const isFieldInvalidFlag = this.isFieldInvalid(field);
    return {
      'has-error': isFieldInvalidFlag,
      'has-feedback': isFieldInvalidFlag
    };
  }
}
