import { transition } from '@angular/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// All
// import { HomeComponent } from './components/home/home.component';
// import { PricingComponent } from './components/pricing/pricing.component';
// import { DocumentComponent } from './components/document/document.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { NoPermissionComponent } from './pages/nopermission/nopermission.component';

// Anonymous
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { VerifyPaymentLinkComponent } from './components/verify-payment-link/verify-payment-link.component';

// Authenticated (Main menu)
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';

import { RequestGoliveComponent } from './components/request-golive/request-golive.component';
import { QrSimulatorComponent } from './components/qr-simulator/qr-simulator.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';

import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
import { TransactionCreateReportComponent } from './components/transaction-create-report/transaction-create-report.component';

import { ThaiQRInquiryComponent } from './components/thai-qr-inquiry/thai-qr-inquiry.component';
import { ThaiQrInquiryDetailComponent } from './components/thai-qr-inquiry-detail/thai-qr-inquiry-detail.component';

import { PaymentLinksComponent } from './components/payment-links/payment-links.component';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';
import { PaymentCreateLinkComponent } from './components/payment-create-link/payment-create-link.component';
import { BookMarkComponent } from './components/book-mark/book-mark.component';

import { BulkRefundComponent } from './components/bulk-refund/bulk-refund.component';
import { BulkRefundUploadFileComponent } from './components/bulk-refund-upload-file/bulk-refund-upload-file.component';
import { BulkPaymentComponent } from './components/bulk-payment/bulk-payment.component';

// Authenticated (Setting menu)
import { SettingComponent } from './components/setting/setting.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { MerchantSettingUserComponent } from './components/merchant-setting-user/merchant-setting-user.component';
import { MerchantSettingKeyComponent } from './components/merchant-setting-key/merchant-setting-key.component';
import { MerchantSettingPaymentComponent } from './components/merchant-setting-payment/merchant-setting-payment.component';
import { MerchantSettingCompanyInfoComponent } from './components/merchant-setting-company-info/merchant-setting-company-info.component';
import { MerchantSettingCompanyInfoSelectorComponent } from './components/merchant-setting-company-info-selector/merchant-setting-company-info-selector.component';
import { RecurringPaymentComponent } from './components/recurring-payment/recurring-payment.component';
import { RecurringUploadFileComponent } from './components/recurring-upload-file/recurring-upload-file.component';

// Guard
import { AnonymousGuard } from './services/auth/anonymous.guard';
import { AuthenticationGuard as AuthGuard } from './services/auth/authentication.guard';
import { RoleGuard } from './services/auth/role.guard';
import { ActiveGuard as RouteActivate } from './services/auth/active.guard';
import { MultipleSelectionComponent } from './components/multiple-selection/multiple-selection.component';
import { MultipleRefundComponent } from './components/multiple-refund/multiple-refund.component';
import { MultipleRefundResultComponent } from './components/multiple-refund-result/multiple-refund-result.component';
import { MultipleRefundConfirmationComponent } from './components/multiple-refund-confirmation/multiple-refund-confirmation.component';
import { BulkPaymentUploadFileComponent } from './components/bulk-payment-upload-file/bulk-payment-upload-file.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'home', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AnonymousGuard] },
  // { path: 'home', component: HomeComponent, canActivate: [RouteActivate] },
  // { path: 'pricing', component: PricingComponent, canActivate: [RouteActivate] },
  // { path: 'document', component: DocumentComponent, canActivate: [RouteActivate] },
  { path: 'register', component: RegistrationComponent, canActivate: [RouteActivate] },
  { path: 'activate-user', component: ActivateUserComponent, canActivate: [RouteActivate] },
  // { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [RouteActivate] },
  // { path: 'forgot-password-request', component: ForgotPasswordRequestComponent, canActivate: [RouteActivate] },
  // { path: 'forgot-password-reset', component: ForgotPasswordResetComponent, canActivate: [RouteActivate] },
  { path: 'setting', component: SettingComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'request-golive', component: RequestGoliveComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'transaction', component: TransactionComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'transactiondetail/:chargeId', component: TransactionDetailComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'customer-card/:customerObjectId', component: CustomerCardComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'merchant-setting-user', component: MerchantSettingUserComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  {
    path: 'merchant-setting-company-info', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: MerchantSettingCompanyInfoComponent },
      { path: 'selector/:companyId', component: MerchantSettingCompanyInfoSelectorComponent }
    ]
  },
  {
    path: 'payment-link', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: PaymentLinksComponent },
      { path: 'create', component: PaymentCreateLinkComponent },
      { path: ':linkRef', component: PaymentLinkComponent }
    ]
  },
  { path: 'verify-payment-link', component: VerifyPaymentLinkComponent, canActivate: [AnonymousGuard] },
  //{ path: 'merchant-setting-payment', component: MerchantSettingPaymentComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'merchant-setting-payment', component: MerchantSettingPaymentComponent },
  { path: 'merchant-setting-key', component: MerchantSettingKeyComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'qrsimulator', component: QrSimulatorComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'transaction-report', component: TransactionReportComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  { path: 'transaction-create-report', component: TransactionCreateReportComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate] },
  {
    path: 'thaiqr-inquiry', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: ThaiQRInquiryComponent },
      { path: ':qrId/:orderTransactionId', component: ThaiQrInquiryDetailComponent }
    ]
  },
  {
    path: 'recurring-payment', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: RecurringPaymentComponent },
      { path: 'upload', component: RecurringUploadFileComponent }
    ]
  },
  {
    path: 'multiple-selection', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: MultipleSelectionComponent },
      { path: 'multiple-refund', component: MultipleRefundComponent},
      { path: 'multiple-refund-result/:historyId', component: MultipleRefundResultComponent},
      { path: 'multiple-refund-confirmation', component: MultipleRefundConfirmationComponent}
    ]
  },
  {
    path: 'bulk-refund', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: BulkRefundComponent },
      { path: 'upload', component: BulkRefundUploadFileComponent }
    ]
  },
  {
    path: 'bulk-payment', canActivate: [AuthGuard, RoleGuard, RouteActivate],
    children: [
      { path: '', component: BulkPaymentComponent },
      { path: 'upload', component: BulkPaymentUploadFileComponent }
    ]
  },

  { path: 'book-mark', component: BookMarkComponent, canActivate: [AuthGuard, RoleGuard, RouteActivate]},
  { path: 'nopermission', component: NoPermissionComponent },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
    // RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
