import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BulkPaymentService } from '../../services/bulk-payment.service';
import { CommonService } from '../../services/common.service';
import { BulkPaymentUploadFileRequest } from '../../services/models/bulk-payment-upload-file-request.model';
import { ToasterService } from '../../services/toaster.service';
import { message } from '../../shared/app-constant';

@Component({
  selector: 'app-bulk-payment-upload-file',
  templateUrl: './bulk-payment-upload-file.component.html',
  styleUrls: ['./bulk-payment-upload-file.component.css']
})
export class BulkPaymentUploadFileComponent implements OnInit {

  @ViewChild('inputFile') inputFile: ElementRef;
  public fileTypes: any;
  public invalidFile: boolean;
  public bulkPaymentUploadModel: BulkPaymentUploadFileRequest;
  public fileName: string;
  public errorMsg: string;
  public limitUploadRecord: number;

  constructor(
    private location: Location,
    private toasterService: ToasterService,
    private commonService: CommonService,
    private router: Router,
    private bulkPaymentService: BulkPaymentService
  ) { }

  ngOnInit() {
    this.bulkPaymentUploadModel = new BulkPaymentUploadFileRequest();
    this.fileName = '';
    this.generateBatchId();
  }

  public chooseFile(element) {
    this.inputFile.nativeElement.click();
    element.blur();
  }

  public changeFile(event) {
    this.invalidFile = false;
    const fileInfo = event.target.files[0]
    this.fileName = fileInfo.name;
    this.bulkPaymentUploadModel.file = fileInfo;
    this.bulkPaymentUploadModel.fileType = fileInfo.type;
  }

  public uploadFile() {
    this.commonService.isLoading(true);
    this.bulkPaymentService.uploadFile(this.bulkPaymentUploadModel).subscribe((response) => {
      if(this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.upload_success);
        this.router.navigate(['/bulk-payment']);
        this.invalidFile = false;
      } else {
        this.errorMsg = response.status_message;
        this.invalidFile = true;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }

  public goBack() {
    this.location.back();
  }

  private generateBatchId() {
    this.commonService.isLoading(true);
    this.bulkPaymentService.generateBulkPaymentId().subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.bulkPaymentUploadModel.bulkPaymentId = response.batchId;
        this.limitUploadRecord = response.limitUploadRecord;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }

}
