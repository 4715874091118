import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';

import { environment } from '../shared/app-constant';
import { RequestGoliveModel } from './models/request-golive.model';
import { RequestGoliveResultModel } from './models/request-golive-result.model';
import { SignupModel } from './models/signup.model';
import { SignupResultModel } from './models/signup-result.model';
import { CheckChangePasswordResultModel } from './models/check-change-password-result.model';
import { ChangePasswordModel } from './models/change-password.model';
import { ChangePasswordResultModel } from './models/change-password-result.model';
import { ItemSelectModel } from './models/item-select.model';
import { ProvinceInfoRequestModel } from './models/province-info-request.model';
import { ProvinceInfoResultModel } from './models/province-info-result.model';
import { ResultModel } from './models/result.model';
import { RequestResetPasswordModel } from './models/request-reset-password.model';
import { ProcessResetPasswordModel } from './models/process-reset-password.model';
import { FormResetPasswordModel } from './models/form-reset-password.model';

@Injectable()
export class RegistrationService {
    constructor(private http: HttpClient) { }

    requestGolive(request: RequestGoliveModel): Observable<RequestGoliveResultModel> {
        console.log('## invoke service requestGolive...');
        return this.http.post<RequestGoliveResultModel>(
            environment.requestGoliveApiUrl,
            JSON.stringify(request));
    }

    signup(request: SignupModel): Observable<SignupResultModel> {
        console.log('## invoke service signup...');
        return this.http.post<SignupResultModel>(
            environment.signupApiUrl,
            JSON.stringify(request));
    }

    activateUser(activatedToken: string):  Observable<ResultModel> {
      console.log('## invoke service activatedToken...');
      const params = new HttpParams().set('activatedToken', activatedToken);
      return this.http.get<ResultModel>(
        environment.activateUserApiUrl,
        {params: params});
    }

    emailExists(email: string): Observable<boolean> {
        console.log('## invoke service emailExists...');
        const params = new HttpParams().set('email', email);
        return this.http.get<boolean>(
            environment.dUrl,
            {params: params});
    }

    checkChangePassword(email: string): Observable<CheckChangePasswordResultModel> {
      console.log('## invoke service checkChangePassword...');
      const params = new HttpParams().set('email', email);
      return this.http.get<CheckChangePasswordResultModel>(
          environment.dUrl,
          {params: params});
    }

    changePassword(request: ChangePasswordModel): Observable<ChangePasswordResultModel> {
        console.log('## invoke service changePassword...');
        return this.http.post<ChangePasswordResultModel>(
            environment.dUrl,
            JSON.stringify(request));
    }

    requestResetPassword(request: RequestResetPasswordModel): Observable<ResultModel> {
      console.log('## invoke service requestResetPassword...');
      return this.http.post<ResultModel>(
        environment.requestResetPasswordApiUrl,
        JSON.stringify(request));
    }

    formResetPassword(request: FormResetPasswordModel): Observable<ResultModel> {
      console.log('## invoke service formResetPassword...');
      let params = new HttpParams();
      if (request.token) {
          params = params.set('token', request.token);
      }
      return this.http.get<ResultModel>(
        environment.formResetPasswordApiUrl,
        {params: params});
    }

    processResetPassword(request: ProcessResetPasswordModel): Observable<ResultModel> {
      console.log('## invoke service processResetPassword...');
      return this.http.post<ResultModel>(
        environment.processResetPasswordApiUrl,
        JSON.stringify(request));
    }

    getBusinessTypeDescs(): Observable<ItemSelectModel[]> {
        return this.http.get<ItemSelectModel[]>( environment.getBusinessTypeDescsRegisterApiUrl );
    }

    getProvinceInfo(request: ProvinceInfoRequestModel): Observable<ProvinceInfoResultModel> {
        console.log('## invoke service requestGolive...');
        let params = new HttpParams();
        if (request.provinceCode) {
            params = params.set('provinceCode', request.provinceCode.toString());
        }
        if (request.amphurCode) {
            params = params.set('amphurCode', request.amphurCode.toString());
        }
        if (request.districtCode) {
            params = params.set('districtCode', request.districtCode.toString());
        }
        if (request.postcodeId) {
            params = params.set('postcodeId', request.postcodeId.toString());
        }

        return this.http.get<ProvinceInfoResultModel>(
            environment.getProvinceInfoRegisterApiUrl,
            {params: params});
    }
}
