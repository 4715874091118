// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// === START SIT SANDBOX ===
export const profile = {
    url: 'https://sit-dev-kpaymentgateway-services.new-kpgw.com',
    liveUrl: 'https://sit-kpaymentgateway-services.new-kpgw.com',
    production: false,
    envName: 'sandbox',
    apiKeyType: 'test',
    captchaSiteKey: '6Lf9b9YbAAAAAG5enUeAqfmNbixyplXR9PHaoeAO',
    version: '20230720-001'
};
// === END SIT SANDBOX ===
