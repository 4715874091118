import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { ModalModel } from './models/modal.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../shared/app-constant';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class CommonService {
  public static readonly VALID_PASSWORD_FORMAT = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[=()*#$%&?:@+\/!",\'-])([a-zA-Z0-9=()*#$%&?:@+\/!",\'-]*)$'; // '^[A-Za-z\d+-=()*#$%&?:@]+$';
  public static readonly VALID_PASSWORD_FORMAT_LENGTH = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[=()*#$%&?:@+-])([a-zA-Z0-9=()*#$%&?:@+-]{8,15})$'; // '^[A-Za-z\d+-=()*#$%&?:@]{8,15}$';
  public static readonly VALID_CSV_INJECTION_FORMAT = '^(?![=@+-]).+$';

  public static readonly VALID_TEXT_INPUT_FORMAT = '^(?!.*?(?:[`<">\'\\\\;&*%!+{}\\[\\]|])).*$';
  public static readonly INVALID_TEXT_INPUT_CHARACTERS = '`<">\'\\;&*%!+{}[]|';

  // same as INVALID_INPUT_CHARACTERS excepts +() are allowed.
  public static readonly VALID_PHONE_INPUT_FORMAT = '^(?!.*?(?:[`<\">\'/\\\\;&*%!{}\\[\\]|])).*$'; // `<">'/\;&*%!{}[]|
  public static readonly INVALID_PHONE_INPUT_CHARACTERS = '`<\">\'/\\;&*%!{}[]|';

  // same as INVALID_INPUT_CHARACTERS excepts +&%!/ are allowed.
  public static readonly VALID_URL_INPUT_FORMAT = '^(?!.*?(?:[`<\">\'\\\\;*(){}\\[\\]|])).*$'; // `<">'/\;&*%!{}[]|
  public static readonly INVALID_URL_INPUT_CHARACTERS = '`<\">\'\\;*(){}[]|';

  // validate rule Contact Address
  public static readonly VALID_BUILDING_NAME_INPUT_CHARACTERS = '^[A-Za-zก-๛0-9\-\/().,\ ]*$';
  public static readonly VALID_ROOM_NO_INPUT_CHARACTERS = '^[A-Za-z0-9\-\/().,\ ]*$';
  public static readonly VALID_SOI_INPUT_CHARACTERS = '^[ก-๛0-9\-\/().,\ ]*$';
  public static readonly VALID_MOO_INPUT_CHARACTERS = '^[0-9]*$';

  private static readonly SUCCESS_CODE = 'success';
  private static readonly HOLD_MID_CODE = 'mid_and_tid_hold';

  private visibleAdvanceSearchSource = new Subject<boolean>();
  private advanceSearchOptionSource = new Subject<any>();
  private isLoadingSource = new Subject<boolean>();
  private visibleModalSource = new Subject<ModalModel>();
  private invisibleModalSource = new Subject<any>();
  private visibleBtnAdvanceSearchSource = new Subject<boolean>();
  private handleExportDialog = new Subject<boolean>();
  private resetPwdErrorSource = new Subject<string>();

  private visibleLinkTransactionDetail = false;

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  isVisibleAdvanceSearch$ = this.visibleAdvanceSearchSource.asObservable();
  advanceOptionSearch$ = this.advanceSearchOptionSource.asObservable();
  isLoading$ = this.isLoadingSource.asObservable();
  visibleModal$ = this.visibleModalSource.asObservable();
  invisibleModal = this.invisibleModalSource.asObservable();
  visibleBtnAdvanceSearch$ = this.visibleBtnAdvanceSearchSource.asObservable();
  handleExportDialog$ = this.handleExportDialog.asObservable();
  resetPwdError = this.resetPwdErrorSource.asObservable();

  // Service message commands
  visibleSearch(isVisible: boolean, option?: {isExport?: boolean}) {
    this.visibleAdvanceSearchSource.next(isVisible);
    if (option) {
      this.advanceSearchOptionSource.next(option);
    } else {
      this.advanceSearchOptionSource.next({isExport: false});
    }
  }

  isLoading(showLoading: boolean) {
    this.isLoadingSource.next(showLoading);
  }

  visibleBtnAdvanceSearch(isVisible: boolean) {
    this.visibleBtnAdvanceSearchSource.next(isVisible);
  }

  setVisibleLinkTransactionDetail(isVisible: boolean) {
    this.visibleLinkTransactionDetail = isVisible;
  }

  isVisibleLinkTransactionDetail() {
    return this.visibleLinkTransactionDetail;
  }

  isEnvironmentSandBox(): boolean {
    return environment.envName === 'sandbox'  ;
  }

  isEnvironmentLive(): boolean {
    const isPrdEnv =  environment.envName === 'prod' || environment.envName === 'prod-sandbox' ;
    return isPrdEnv;
  }

  ctrlExportDialog(isOpenDialog: boolean) {
    this.handleExportDialog.next(isOpenDialog);
  }

  switchLanguage(): string {
    const currentLang = this.getCurrentLang();
    let lang: string;
    if (currentLang === 'en') {
      lang = 'th';
      this.changeLanguage('th');
    } else {
      lang = 'en';
      this.changeLanguage('en');
    }
    return lang;
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }

  getCurrentLang(): string {
    return this.translate.currentLang;
  }

  getLangs(): string[] {
    return this.translate.getLangs();
  }

  alertInfo(msgTitle: string, msgBody: string, callback: Subject<boolean>) {
    this.openModal('info', msgTitle, msgBody, callback);
  }

  alertWarn(msgTitle: string, msgBody: string, callback: Subject<boolean>, buttonLabel?: string) {
    this.openModal('warn', msgTitle, msgBody, callback, buttonLabel);
  }

  alertSuccess(msgTitle: string, msgBody: string, callback: Subject<boolean>) {
    this.openModal('success', msgTitle, msgBody, callback);
  }

  alertError(msgTitle: string, msgBody: string, callback: Subject<boolean>) {
    this.openModal('error', msgTitle, msgBody, callback);
  }

  alertConfirm(msgTitle: string, content: any, callback: Subject<boolean>, buttonLabel?:string) {
    this.openModal('confirm', msgTitle, content, callback, buttonLabel);
  }

  alertDelete(msgTitle: string, content: any, callback: Subject<boolean>) {
    this.openModal('delete', msgTitle, content, callback);
  }

  private openModal(modalType: string, msgTitle: string, content: any, callback: Subject<any>, buttonLabel?: string) {
    const modal = new ModalModel();
    modal.modalType = modalType;
    modal.msgTitle = msgTitle;
    modal.content = content;
    modal.labelButton = buttonLabel;
    modal.callback = callback;
    this.visibleModalSource.next(modal);
  }

  closeModal() {
    this.invisibleModalSource.next();
  }

  sendResetPwdError(message: string) {
    this.resetPwdErrorSource.next(message);
  }

  isSuccess(result: any): boolean {
    return result && result.status_code && this.isSuccessCode(result.status_code);
  }

  isSuccessCode(status_code: string): boolean {
    return status_code === CommonService.SUCCESS_CODE;
  }

  isHoldMid(result: any): boolean {
    return result && result.status_code && this.isHoldMidCode(result.status_code);
  }

  isHoldMidCode(status_code: string): boolean {
    return status_code === CommonService.HOLD_MID_CODE;
  }

  scrollToTopPage(elementId: string) {
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart && document.getElementById(elementId)) {
        document.getElementById(elementId).scrollTop = 0;
      }
    });
  }

}
