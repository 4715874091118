import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../shared/app-constant";
import { ResponseUrlModel, ResponseUrlSaveDataRequest } from "./models/response-url.model";
import { ResultModel } from "./models/result.model";

@Injectable({
    providedIn: 'root'
})
export class ResponseUrlService {

    constructor(
        private http: HttpClient
    ) { }

    getResponseUrl(companyId: string): Observable<ResponseUrlModel> {
        const url = environment.getResponseUrl + `?companyId=${companyId}`
        return this.http.get(url).pipe(
            map((response: ResponseUrlModel) => {
                return response;
            }));
    }

    updateResponseUrl(request: ResponseUrlSaveDataRequest): Observable<ResultModel> {
        return this.http.post(
            environment.updateResponseUrl,
            JSON.stringify(request)).pipe(
            map((response: ResultModel) => {
              return response;
          }));
    }
}
