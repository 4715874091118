export class MultiselectDropdownConfig {
    // singleSelection: false;
    idField: any;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean
    list: any[]
    searchOn: string;
    selectAll: boolean;
    preventNumberOnSearch: boolean;
    constructor(
        idField: any,
        textField: string,
        selectAllText: string,
        unSelectAllText: string,
        itemsShowLimit: number,
        allowSearchFilter: boolean,
        list: any[],
        searchOn: string,
        selectAll: boolean,
        preventNumberOnSearch: boolean
    ) {
        this.idField = idField;
        this.textField = textField;
        this.selectAllText = selectAllText;
        this.unSelectAllText = unSelectAllText;
        this.itemsShowLimit = itemsShowLimit;
        this.allowSearchFilter = allowSearchFilter;
        this.list = list;
        this.searchOn = searchOn;
        this.selectAll = selectAll;
        this.preventNumberOnSearch = preventNumberOnSearch;
    }
}
