import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { PATTERN } from '../shared/app-constant';

@Directive({
  selector: '[numberOnly]'
})
export class NumberOnlyDirective {

  @Input() numberOnly: boolean;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'Del', 'ArrowLeft', 'ArrowRight', 'Left', 'Right'];
  private functionKeys: Array<number> = [65, 67, 86, 88]; // 65 -> a; 76 -> c; 86 -> v; 88 -> x

  constructor(private element: ElementRef) { }
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if ((event.ctrlKey || event.metaKey) && (this.functionKeys.indexOf(event.keyCode) !== -1)){
      return;
    }
    const current: string = this.element.nativeElement.value;
    const indexStart: number = event.target['selectionStart'];
    const next: string = current.substring(0, indexStart) + event.key + current.substring(indexStart,current.length);
    const regex = this.numberOnly ? new RegExp(PATTERN.NUMBER_ONLY) : new RegExp(PATTERN.NUMBER_WITH_DOT_PATTERN);
    if (next && !String(next).match(regex) && (this.numberOnly || this.validateDot(next))) {
      event.preventDefault();
    }
  }

  private validateDot(value: string) {
    return (value && value.length > 0 && (value.indexOf('.') !== value.length - 1 )) || (value.replace('.', '').length == 0);
  }

}
