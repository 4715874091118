import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../shared/app-constant';
import { QrSimulatorOrderTransaction } from './models/qr-simulator-order-transaction';
import { map } from "rxjs/operators";

@Injectable()
export class QrSimulatorService {

  constructor(private http: HttpClient) { }

  getOrderTransactionDetail(qrId: string): Observable<QrSimulatorOrderTransaction> {
    return this.http.get<QrSimulatorOrderTransaction>(
      environment.orderTransactionAPIUrl.replace('{qrId}', qrId)
    );
  }

  pay(qrId: string): Observable<any> {
    const result = {};
    return this.http.post(
      environment.qrSimulatorInvokeAPIUrl.replace('{qrId}', qrId),
      JSON.stringify(qrId))
      .pipe(map((response: any) => { return response; }))
    // .map((response: any) => {
    //   return response;
    // });
  }
}
