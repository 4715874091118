
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { ThaiQRInquiryResponse } from './models/thai-qr-inquiry-response.model';
import { environment } from '../shared/app-constant';
import { Observable } from 'rxjs';
import { SearchThaiQRInquiry } from './models/search-thai-qr-inquiry.model';

@Injectable({
  providedIn: 'root'
})
export class ThaiQrInquiryService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  search(requestSearch: SearchThaiQRInquiry): Observable<ThaiQRInquiryResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(
      environment.searchThaiQRInquiry.replace('{userId}', currentUser.userId.toString()) + '/search',
      JSON.stringify(requestSearch)).pipe(
        map((response: ThaiQRInquiryResponse) => {
        return response
      }));
  }
}
