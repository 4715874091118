
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

import { environment } from '../shared/app-constant';
import { TransactionDetailRequestModel } from './models/transaction-detail-request.model';
import { TransactionDetailModel } from './models/transaction-detail.model';

@Injectable()
export class TransactionDetailService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  getTransactionDetail(request: TransactionDetailRequestModel, chargeId: string): Observable<TransactionDetailModel> {
    const currentUser = this.auth.getCurrentUserInfo();
    return this.http.post(
      environment.transactionDetailApiUrl
      .replace('{userId}', currentUser.userId.toString())
      .replace('{chargeId}', chargeId),
      JSON.stringify(request)).pipe(
      map((response: TransactionDetailModel) => {
        return response;
      }));
  }
}
