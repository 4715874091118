import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';

import { MerchantSettingUserResultModel } from '../../services/models/merchant-setting-user-result.model';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { MESSAGE_EVENT_LISTENER } from '../../shared/app-constant';

@Component({
  selector: 'app-merchant-setting-payment',
  templateUrl: './merchant-setting-payment.component.html',
  styleUrls: ['./merchant-setting-payment.component.css']
})

export class MerchantSettingPaymentComponent implements OnInit {

  @ViewChild('inneriframe') inneriframe: ElementRef;

  companyId = "";
  editEnabled = false;
  passswordLinkEditEnabled = false;
  paypalStatus = null;
  allowPaypal = "";
  editPermit = false;
  companyInfos: any = {};
  userInfo: any = {};
  companyIdName: String;
  public allowKSocialPay: boolean;
  public isHasLSosialPay: boolean;
  public iframeUrl: string;
  public isBlockBtn: boolean;
  private tokenOneTime: string;

  merchantSettingUser: MerchantSettingUserResultModel;

  constructor(private commonService: CommonService,
    private auth: AuthenticationService,
    private merchantSettingService: MerchantSettingService,
    private sanitizer: DomSanitizer
  ) {
    this.userInfo = this.auth.getCurrentUserInfo();
    this.companyInfos = this.auth.getCurrentUserCompanyInfos();
    this.companyIdName = this.companyInfos[0].companyIdName;
    this.companyId = this.companyInfos[0].companyId;
    this.merchantSettingGetCompanyInfo();

  }

  enablePaypal(){
    this.commonService.isLoading(true);
    this.merchantSettingService.paypalReferals().subscribe(
      result => {
        var data = result.links[1];
        //console.info(data.href+"&displayMode=minibrowser");
        //document.location.href= data.href+"&displayMode=minibrowser";
        //window.open(data.href,"_self");
        /*var newwindow=window.open(data.href+"&displayMode=minibrowser","Test Popup",'height=600,width=650');
        if (window.focus) {
          newwindow.focus()
        }*/
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  refresh(){
    this.commonService.isLoading(true);
    this.merchantSettingService.checkActivateEmail().subscribe(
      result => {
        this.paypalStatus = result.paypalReady;
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
    });
  }

  ngOnInit() {
    $(document).ready(function(){
      (function(d, s, id){
        var js, ref = d.getElementsByTagName(s)[0];
        if (!d.getElementById(id)){
          js = d.createElement(s);
          js.id = id;
          js.async = true;
          js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
          ref.parentNode.insertBefore(js, ref);
        }
      }(document, "script", "paypal-js"));
  });

  }

  public openLink(){
    this.commonService.isLoading(true);
    this.merchantSettingService.paypalReferals().subscribe(
      result => {
        var data = result.links[1];
        //$('#enablePaypal').attr('href',data.href+"&displayMode=minibrowser");
        window.open(data.href+"&displayMode=minibrowser","_self");
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
    });
  }

  public linkWithKSocialPay() {
    console.log('Link with KSocial Pay...');
    this.isBlockBtn = true

    this.merchantSettingService.getOneTimeToken().subscribe((result) => {

      if (this.commonService.isSuccess(result)) {
        this.tokenOneTime = result.token;
        console.log("Token: " + this.tokenOneTime);
        this.iframeUrl = result.ksocialpay_iframe_url;

        var kSocialPayIframe = document.getElementById('kSocialPayIframe');
        if(kSocialPayIframe != null){
          console.log('Found target object, removing the target.');
          this.inneriframe.nativeElement.removeChild(kSocialPayIframe);
        }
        var iframe = document.createElement('iframe');
        iframe.setAttribute('id', 'kSocialPayIframe');
        iframe.setAttribute('src', this.iframeUrl);
        this.inneriframe.nativeElement.appendChild(iframe);
        let assignToken = false;

        window.addEventListener('message', (event) => {
          console.log(event);

          if (event.data == MESSAGE_EVENT_LISTENER.SOCIAL_PLUGIN_LOADED) {
            if (!assignToken) {
              assignToken = true;
              iframe.contentWindow.postMessage(MESSAGE_EVENT_LISTENER.ASSIGN_ONE_TIME_TOKEN + ":" + this.tokenOneTime, '*');
            } else {
              console.log('Already received message!!!');
            }
          } else if (event.data == MESSAGE_EVENT_LISTENER.ONE_TIME_TOKEN_RECEIVED) {
            console.log('ONE_TIME_TOKEN_RECEIVED');
            this.isBlockBtn = false;
            iframe.remove();
            window.open(result.ksocialpay_redirect_url, "_self");
          }
        });

      }
    }, err => {
      this.isBlockBtn = false;
      console.log('get token fail!');
    });
  }

  private merchantSettingGetCompanyInfo() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getCompanyInfo(this.companyId).subscribe(
      result => {
        console.log(result);
        this.paypalStatus = result.paypalReady;
        this.allowPaypal = result.allowPaypal;
        this.isHasLSosialPay = this.auth.getCurrentUserMenuInfo().find((menu) => menu.screenName == 'PAYMENT SETTING').componentsInfo.some((componentInfo) => componentInfo.roleActionCode == 'ROLE_B_3502');
        this.allowKSocialPay = result.allowKSocialPay;
        if(this.paypalStatus == null){
          this.commonService.isLoading(false);
          //console.info('on load links 222');
          //this.commonService.isLoading(true);
          /*this.merchantSettingService.paypalReferals().subscribe(
           result => {
             console.log(result);
             var data = result.links[1];
             //console.info(data);
             //console.info(data.href+"&displayMode=minibrowser");
             //$('#enablePaypal').attr('href',data.href+"&displayMode=minibrowser")
             //$('#enablePaypal').attr('href',data.href);
           },
           error => {
             this.commonService.isLoading(false);
           },
           () => {
             this.commonService.isLoading(false);
         });*/
        }
        else if(!this.paypalStatus){
          this.refresh();
        }
        else{
          this.commonService.isLoading(false);
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
      });
  }

  /*@HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if(this.paypalStatus == null){
      this.merchantSettingService.paypalReferals().subscribe(
        result => {
          var data = result.links[1];
          $('#enablePaypal').attr('href',data.href+"&displayMode=minibrowser")
        },
        error => {
          this.commonService.isLoading(false);
        },
        () => {
          this.commonService.isLoading(false);
      });
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    //console.info('HostListener  = onBlur');
  }*/
}
