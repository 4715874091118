import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QrSimulatorOrderTransaction } from '../../services/models/qr-simulator-order-transaction';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { QrSimulatorService } from '../../services/qr-simulator.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-qr-simulator',
  templateUrl: './qr-simulator.component.html',
  styleUrls: ['./qr-simulator.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QrSimulatorComponent implements OnInit {

  searchForm: FormGroup;

  model: QrSimulatorOrderTransaction = null;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toasterService: ToasterService,
    private _qrSimulatorService: QrSimulatorService) { }

  ngOnInit() {
    this.createForm();
    this._commonService.visibleBtnAdvanceSearch(false); // disable advance search
  }

  createForm() {
    this.searchForm = this._formBuilder.group({
      qrId: ['', [Validators.required]]
    });
  }

  submit() {
    if (this.searchForm.valid) {
      const test = this.searchForm.get('qrId').value;
      console.log(test);
      this._commonService.isLoading(true);
      this._qrSimulatorService.getOrderTransactionDetail(test).subscribe(
        result => {

          if (this._commonService.isSuccess(result)) {
            this.model = result;
          } else {
            this.model = null;
            this.searchForm.reset();
            this._toasterService.error(result.status_message);
          }
        },
        error => {
          this.model = null;
          this._commonService.isLoading(false);
          this.searchForm.reset();
          this._toasterService.error('Please contact administrator.', 'Server API Error!');
        },
        () => {
          this._commonService.isLoading(false);
        });
    }
  }

  pay() {
    this._commonService.isLoading(true);
    this._qrSimulatorService.pay(this.model.qrId).subscribe(
      result => {
        if (result.status_code === 'success') {
          this._toasterService.success('Pay ' + this.model.qrId + ' success.');
          this.reload(this.model.qrId);
        } else {
          this._toasterService.error(result.status_message);
        }
      },
      error => {
        this._commonService.isLoading(false);
        this._toasterService.error('Please contact administrator.', 'Server API Error!');
      },
      () => {
        this._commonService.isLoading(false);
      }
    );
  }

  reload(qrId) {
    this._qrSimulatorService.getOrderTransactionDetail(qrId).subscribe(
      result => {
        if (this._commonService.isSuccess(result)) {
          this.model = result;
        }
      }
    );
  }

  addClassHasError(field: string) {
    return (this.searchForm.get(field).invalid && (this.searchForm.get(field).touched));
  }

  isEnabledPay() {
    return this.model !== null && this.model.status.toUpperCase() === 'REQUESTED';
  }
}
