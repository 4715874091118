import { ResultModel } from "./result.model";

export class MultipleRefundConfirmation {
    refundData: RefundData[];
}

export class RefundData {
    trxId: string;
    amount: string;
}

export class RefundConfirmationRequest {
    trxIds: string[];
    pageIndex: number;
}

export class RefundConfirmationResponse extends ResultModel {
    totalElements: number;
    pageSize: number;
    trxInfoSummaries: TransactionInfoSummaries[];
    multipleRefundConfirmTrxInfos: MultipleRefundConfirmTransactionInfo[];
}

export class TransactionInfoSummaries {
    groupDate: string;
}

export class MultipleRefundConfirmTransactionInfo {
    trxId: string;
    isFullRefund: boolean;
    createDate: string;
    refNumber: string;
    productDescription: string;
    trxState: string;
    paymentTypeDesc: string;
    currencyCode: string;
    settledAmount: string;
    lastRefundDatetime: string;
    remainingAmount: string;
    refundAmount? : string;
}