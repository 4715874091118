import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../shared/app-constant';
import { MessageResultModel } from './models/message-result.model';


@Injectable()
export class MessageService {
    constructor(private http: HttpClient) { }

    getMessages(): Observable<MessageResultModel> {
        return this.http.get<MessageResultModel>(
            environment.getMessageAPIUrl);
    }
}
