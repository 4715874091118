import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  isLoading: boolean;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.isLoading$.subscribe(data => {
      this.isLoading = data;
    });
  }

}
