import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable()
export class FingerprintService {
  constructor() { }

  public getFingerprint(): string {
    return crypto.SHA256(this.generateFingerprint()).toString();
  }

  private generateFingerprint(): string {
    // Generate a string based on "stable" information taken from the browser
    // We call here "stable information", information that normally don't change during the user
    // browse the application just after authentication
    const fingerprint = [];

    // Take plugins
    for (let i = 0; i < navigator.plugins.length; i++) {
      fingerprint.push(navigator.plugins[i].name);
      fingerprint.push(navigator.plugins[i].filename);
      fingerprint.push(navigator.plugins[i].description);
      fingerprint.push(navigator.plugins[i].version);
    }

    // Take User Agent
    fingerprint.push(navigator.userAgent);

    // Take Screen resolution
    fingerprint.push(screen.availHeight);
    fingerprint.push(screen.availWidth);
    fingerprint.push(screen.colorDepth);
    fingerprint.push(screen.height);
    fingerprint.push(screen.pixelDepth);
    fingerprint.push(screen.width);

    // Take Graphical card info
    try {
      const canvas = document.createElement('canvas');
      let gl;
      gl = canvas.getContext('experimental-webgl');
      gl.viewportWidth = canvas.width;
      gl.viewportHeight = canvas.height;
      fingerprint.push(gl.getParameter(gl.VERSION));
      fingerprint.push(gl.getParameter(gl.SHADING_LANGUAGE_VERSION));
      fingerprint.push(gl.getParameter(gl.VENDOR));
      fingerprint.push(gl.getParameter(gl.RENDERER));
      fingerprint.push(gl.getSupportedExtensions().join());
    } catch (e) {
      // Get also error because it's will be stable too..
      fingerprint.push(e);
    }

    // Last and, in order to made this browser unique, generate a random ID that we will store
    // in local storage (in order to be persistent after browser close/reopen)
    // Add this ID because, in Enterprise, most of the time browser have the same configuration
    let browserUniqueID = localStorage.getItem('browserUniqueID');
    if (browserUniqueID === null) {
      localStorage.setItem('browserUniqueID', crypto.lib.WordArray.random(80));
      browserUniqueID = localStorage.getItem('browserUniqueID');
    }
    fingerprint.push(browserUniqueID);

    return fingerprint.join();
  }
}
