
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';

import { environment } from '../shared/app-constant';
import { TransactionRequestModel } from './models/transaction.request.model';
import { TransactionModel } from './models/transaction.model';
import { TransactionInfoModel } from './models/transaction-info.model';
import { TransactionReportLogFileModel } from './models/transaction-report-log-file.module';
import { TransactionReportLogModel } from './models/transaction-report-log.model';
import { ResultModel } from './models/result.model';
import { CommonService } from './common.service';
import { ToasterService } from './toaster.service';
import { CountReportRequest } from './models/count-report-request.model';
import { ExportReportRequest } from './models/export-report-request.model';
import { ChannelConfigResponse } from './models/channel-config.model';

const INSTALLMENT_CROSS_BANK = "Installment Cross Bank";

@Injectable()
export class TransactionService {

    constructor(
        private http: HttpClient,
        private auth: AuthenticationService,
        private commonService: CommonService,
        private toasterService: ToasterService
        ) { }

    getTransaction(request: TransactionRequestModel): Observable<TransactionModel> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.transactionApiUrl.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request)).pipe(
            map((response: TransactionModel) => {
                return response;
            }));
    }

    getTransactionReport(request: TransactionRequestModel) {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.post(
            environment.transactionReportUrl.replace('{userId}', currentUser.userId.toString()),
            JSON.stringify(request), {
                responseType: 'text'
            });

    }


    voidTransaction(transactionsList: TransactionInfoModel[]) {
        const request = {};
        request['data'] = [];

        transactionsList.map(x => {
            request['data'].push({ charge_id: x.chargeId, });
        });

        console.log(JSON.stringify(request));

        return this.http.post(
            environment.transactionVoidApiUrl,
            JSON.stringify(request)).pipe(
            map((response) => {
                return response;
            }));
    }

    refundTransaction(transactionsList: TransactionInfoModel[]) {
        const request = {};
        request['data'] = [];

        transactionsList.map(x => {
            request['data'].push({
                charge_id: x.chargeId,
                amount: x.Amount
            });
        });

        console.log(JSON.stringify(request));

        return this.http.post(
            environment.transactionRefundApiUrl,
            JSON.stringify(request)).pipe(
            map((response) => {
                return response;
            }));
    }

    settleTransaction(transactionsList: TransactionInfoModel[], settleType: string) {
        const request = {};
        request['data'] = [];

        transactionsList.map(x => {
            request['data'].push({
                charge_id: x.chargeId,
                amount: x.Amount,
                type: settleType
            });
        });

        console.log(JSON.stringify(request));

        return this.http.post(
            environment.transactionSettleApiUrl,
            JSON.stringify(request)).pipe(
            map((response) => {
                return response;
            }));
    }

    /**
	 *
	 * @param fromDate
	 * @param toDate
	 * @param reportConfigId
	 */
	getTotalRecords(request: CountReportRequest): Observable<ResultModel> {
		return this.http.post(
			environment.countTransactionReport,
			JSON.stringify(request))
			.pipe(map((response: any) => {
				return response;
			}));
    }

    /**
	 * export
	 * @param fromDate
	 * @param toDate
	 * @param reportConfigId
	 */
	exportTransactionReportFile(createReportRequest: ExportReportRequest): Observable<any> {
		return this.http.post(
			environment.exportTransactionReportFile,
			JSON.stringify(createReportRequest))
			.pipe(map((response: any) => {
				return response;
			}));
    }

    /**
	 * get data for log file
	 * @param request
	 */
	getFileReport(request: TransactionReportLogFileModel): Observable<TransactionReportLogModel> {
		return this.http.post(
			environment.getFileReport,
			JSON.stringify(request)).pipe(
				map((response: TransactionReportLogModel) => {
					return response;
				}));
    }

    downloadFile(request): Observable<any> {
		return this.http.post(environment.downloadFile, JSON.stringify(request)).pipe(
			map((response: any) => {
				return response;
			})
		)
    }

    regenerate(request): Observable<any> {
		return this.http.post(environment.regenerateReport, JSON.stringify(request)).pipe(
			map((response: any) => {
				return response;
			})
		)
    }

    /**
	 *
	 * @param request
	 */
	deleteRecordLog(request): Observable<any> {
		return this.http.post(environment.deleteRecordLog, JSON.stringify(request)).pipe(
			map((response: any) => {
				return response;
			})
		)
    }
    exportTransaction(request) {
        return this.http.post(environment.exportTransaction.replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()), request).pipe(
            map((response: ResultModel) => {
                return response;
            })
        )
    }

    getChannelConfig(): Observable<ChannelConfigResponse> {
        const currentUser = this.auth.getCurrentUserInfo();
        return this.http.get<ChannelConfigResponse>(
            environment.getChannelConfig.replace('{userId}', currentUser.userId.toString()));
    }

    isInstallmentCrossBank(reportName: string): boolean {
        return reportName.trim().toLocaleLowerCase().includes(INSTALLMENT_CROSS_BANK.toLocaleLowerCase())
    }
}
