import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-advancesearch-select-company',
  templateUrl: './advancesearch-select-company.component.html',
  styleUrls: ['./advancesearch-select-company.component.css']
})
export class AdvancesearchSelectCompanyComponent implements OnInit {

  @Output() onApplyFilter = new EventEmitter<object>();

  constructor(private commonService: CommonService,
    private auth: AuthenticationService) { }

  isShow: boolean;
  companiesList: any[];

  ngOnInit() {
    this.commonService.isVisibleAdvanceSearch$.subscribe(data => {
      this.isShow = data;
    });

    this.companiesList = this.getCompany();
  }

  hideAdvanceSearchFilter() {
    this.isShow = false;
  }

  getCompany() {
    return this.auth.getCurrentUserCompanyInfos().map(x => {
      return {
        id: x.companyId,
        name: x.companyIdName,
        value: false
      };
    });
  }

  applyFilter() {
    this.onApplyFilter.emit(this.companiesList.filter(x => x.value === true).map(x => {
      return x.id;
    }));
    this.hideAdvanceSearchFilter();
  }
}
