import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SelectionTime } from '../../services/models/selection-time.model';
import { TimeSelector } from '../../services/models/time-selector';

export const MINUTE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

export const HOUR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const NUMBER = /^[0-9]*$/;

@Component({
  selector: 'time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.css']
})
export class TimeSelectionComponent implements OnInit {

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  @Input() isOpen: boolean;
  @Input() readonly: boolean;
  @Input() defaultTime: SelectionTime = new SelectionTime();
  @Output() changeTime = new EventEmitter<any>();
  @Input() hourStep: number;
  @Input() minuteStep: number;

  public timeSelector: TimeSelector;
  public minuteArray = MINUTE;
  public hourArray = HOUR;

  constructor() { }

  ngOnInit() {
    this.timeSelector = new TimeSelector(+this.defaultTime.hour, +this.defaultTime.minute);
  }

  changeHour() {
    if (this.defaultTime.hour > 23 || !this.defaultTime.hour.toString().match(NUMBER)) {
      this.defaultTime.hour = 0;
    }
    this.defaultTime.hour = this.defaultTime.hour < 10 ? '0' + +this.defaultTime.hour : this.defaultTime.hour;
    this.changeTime.emit(this.defaultTime);
  }

  changeMinute() {
    if (this.defaultTime.minute > 59 || !this.defaultTime.minute.toString().match(NUMBER)) {
      this.defaultTime.minute = 0;
    }
    this.defaultTime.minute = this.defaultTime.minute < 10 ? '0' + +this.defaultTime.minute : this.defaultTime.minute;
    this.changeTime.emit(this.defaultTime);
  }

  increaseHour() {
    const indexOfValue = this.hourArray.indexOf(+this.defaultTime.hour);
    const index = indexOfValue < 0 ? 0 : indexOfValue;
    const indexAfterIncrease = index + this.hourStep >= this.hourArray.length ? (index + this.hourStep) - this.hourArray.length : index + this.hourStep;
    this.defaultTime.hour = this.hourArray[indexAfterIncrease];
    this.changeHour();
  }

  decreaseHour() {
    const indexOfValue = this.hourArray.indexOf(+this.defaultTime.hour);
    const index = indexOfValue <= 0 ? this.hourArray.length : indexOfValue;
    const indexAfterDecrease = index - this.hourStep < 0 ? this.hourArray.length + (index - this.hourStep) : index - this.hourStep;
    this.defaultTime.hour = this.hourArray[indexAfterDecrease];
    this.changeHour();
  }

  increaseMinute() {
    const indexOfValue = this.minuteArray.indexOf(+this.defaultTime.minute);
    const index = indexOfValue < 0 ? 0 : indexOfValue;
    const indexAfterIncrease = index + this.minuteStep >= this.minuteArray.length ? (index + this.minuteStep) - this.minuteArray.length : index + this.minuteStep;
    this.defaultTime.minute = this.minuteArray[indexAfterIncrease];
    this.changeMinute();
  }

  decreaseMinute() {
    const indexOfValue = this.minuteArray.indexOf(+this.defaultTime.minute);
    const index = indexOfValue < 0 ? this.minuteArray.length : indexOfValue ;
    const indexAfterDecrease = index - this.minuteStep < 0 ? this.minuteArray.length + (index - this.minuteStep) : index - this.minuteStep;
    this.defaultTime.minute = this.minuteArray[indexAfterDecrease];
    this.changeMinute();
  }
}
