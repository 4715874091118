import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiKeyInfoService } from '../../services/api-key-info.service';
import { CommonService } from '../../services/common.service';
import { CompanyInfoModel } from '../../services/models/companyinfo.model';
import { ApiKeyInfoModel } from '../../services/models/api-key-info.model';
import { ApiKeyInfoRequestModel } from '../../services/models/api-key-info-request.model';
import { RollApiKeyRequestModel } from '../../services/models/roll-api-key-request.model';
import { forEach } from '@angular/router/src/utils/collection';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-merchant-setting-key',
  styleUrls: ['./merchant-setting-key.component.css'],
  templateUrl: './merchant-setting-key.component.html'
})
export class MerchantSettingKeyComponent implements OnInit, OnDestroy {

  request: ApiKeyInfoRequestModel;
  apikeyInfoList: ApiKeyInfoModel[];
  isShowRequestGoliveBtn: boolean;
  isShowBtnRollKey: boolean;

  constructor(
    private auth: AuthenticationService,
    private commonService: CommonService,
    private apiKeyInfoService: ApiKeyInfoService
  ) { }

  ngOnInit() {
    console.log('MerchantSettingKeyComponent ngOnInit');
    this.commonService.isLoading(true);
    this.isShowBtnRollKey = false;
    const menuInfos = this.auth.getCurrentUserMenuInfo();
    if (menuInfos) {
      menuInfos.forEach(menuInfo => {
        if (menuInfo.screenCode === 'M008') {
          const componentsInfos = menuInfo.componentsInfo;
          if (componentsInfos) {
            componentsInfos.forEach(componentsInfo => {
              if (componentsInfo.roleActionCode === 'ROLE_B_2801') {
                this.isShowBtnRollKey = true;
              }
            });
          }
        }
      });
    }
    this.request = this.setRequestApiKeyInfo();
    this.getApiKeyInfo(this.request);
    this.isShowRequestGoliveBtn = this.commonService.isEnvironmentSandBox();
  }

  ngOnDestroy() {
    console.log('MerchantSettingKeyComponent ngOnDestroy');
  }

  private setRequestApiKeyInfo(): ApiKeyInfoRequestModel {
    const request = new ApiKeyInfoRequestModel();
    request.companyId = this.auth.getCurrentUserCompanyInfos().map((element, index) => {
      return element.companyId;
    });
    return request;
  }

  private getApiKeyInfo(request: ApiKeyInfoRequestModel) {
    this.apiKeyInfoService.getApiKeyInfo(request).subscribe(result => {
      if (this.commonService.isSuccess(result)) {
        this.apikeyInfoList = result.apiKeyInfo;
      } else {
        this.commonService.alertError('Fail', result.status_message, null);
      }
    },
    error => {
      this.commonService.isLoading(false);
    },
    () => {
      this.commonService.isLoading(false);
    });
  }

  clickRollKey(companyId: string, keyType: string) {
    const callback = new Subject<boolean>();
      callback.asObservable().subscribe(result => {
        if (result === true) {
          this.doProcessRollKey(companyId, keyType);
        }
      });
      this.commonService.alertConfirm('Confirm Roll Key', 'Please Confirm Roll Key', callback);
  }

  private doProcessRollKey(companyId: string, keyType: string) {
    this.commonService.isLoading(true);
    const request = new RollApiKeyRequestModel();
    request.companyId = parseInt(companyId, 10);
    request.keyType = keyType;
    this.apiKeyInfoService.rollApiKey(request).subscribe(result => {
      if (this.commonService.isSuccess(result)) {
        this.apikeyInfoList.forEach(apikeyInfo => {
          if (apikeyInfo.companyId === result.companyId) {

            if (apikeyInfo.publicKeyType === result.keyType) {
              apikeyInfo.publicKey = result.keyValue;

            } else if (apikeyInfo.secretKeyType === keyType) {
              apikeyInfo.secretKey = result.keyValue;
            }

          }
        });
      } else {
        this.commonService.alertError('Fail', result.status_message, null);
      }
    },
    error => {
      this.commonService.isLoading(false);
    },
    () => {
      this.commonService.isLoading(false);
    });
  }

}
