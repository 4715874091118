export class BulkPaymentUploadFileRequest {
    file?: File;
    bulkPaymentId?: string;
    fileType?: string;

    constructor(file?: File, bulkPaymentId?: string, fileType?: string) {
        this.file = file;
        this.bulkPaymentId = bulkPaymentId;
        this.fileType = fileType;
    }
}