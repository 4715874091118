import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { DCCData } from '../../services/models/merchant-setting-dcc.model';

@Component({
  selector: 'app-merchant-configuration-dcc',
  templateUrl: './merchant-configuration-dcc.component.html',
  styleUrls: ['./merchant-configuration-dcc.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationDccComponent implements OnInit {
  
  @Input() companyId: string;

  isOpenDCCSof: boolean = false;
  dccItem: DCCData[];
  totalElement: number;
  pageSize: number;
  pageIndex: number;
  loadFlag: boolean = false;

  constructor(
    private merchantSetting: MerchantSettingService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.totalElement = 0;
    this.pageIndex = 1;
  }

  onClickMoreDetail() {
    this.isOpenDCCSof = !this.isOpenDCCSof;
    if(this.loadFlag){
      return;
    }
    this.getMerchantMcc(this.pageIndex);
    this.loadFlag = true;
  }

  getMerchantMcc(pageIndex: number){
    this.commonService.isLoading(true);
    this.merchantSetting.getMerchantSettingDCC(this.companyId, pageIndex.toString()).subscribe(
      result => {
        if (this.commonService.isSuccess(result)) {
          this.dccItem = result.dccData;
          this.totalElement = result.totalElement;
          this.pageIndex = pageIndex;
          this.pageSize = result.pageSize;
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  loadPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.getMerchantMcc(pageIndex);
  }
}
