import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CacheObservableService } from '../../services/cache-observable.service';
import { CommonService } from '../../services/common.service';
import { MultipleRefundConfirmation, RefundConfirmationRequest, RefundConfirmationResponse, RefundData } from '../../services/models/multiple-refund-confirmation.model';
import { MultipleRefundConfirmationService } from '../../services/multiple-refund-confirmation.service';
import { ToasterService } from '../../services/toaster.service';
import { ERROR_CODE, message } from '../../shared/app-constant';
import { TransactionRequestModel } from '../../services/models/transaction.request.model';
import { CACHE_OBSERVABLE_SERVICE } from '../../shared/app-constant';
import { GroupList, MultipleTrxInfos } from '../../services/models/multiple-refund-response.model';
import { cloneObject } from '../../shared/common.util';

@Component({
  selector: 'app-multiple-refund-confirmation',
  templateUrl: './multiple-refund-confirmation.component.html',
  styleUrls: ['./multiple-refund-confirmation.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MultipleRefundConfirmationComponent implements OnInit, OnDestroy {

  groupList: GroupList[] = [];
  // list to save data is edited refund amount
  refundConfirmationEdited: MultipleTrxInfos[] = [];
  request: RefundConfirmationRequest;
  refundRequest: MultipleRefundConfirmation = new MultipleRefundConfirmation();
  refundConfirmationResponse : RefundConfirmationResponse;

  //limit display od records
  limit: number;
  totalDays: number;

  public isExceedLimit: boolean;

  // Pagination
  collectionSize: number;

  // Transaction Status
  currentStatus: string;
  subStatusRefunded: string;

  private criteriaSearch: TransactionRequestModel;
  private isBackToSelectRefund: boolean = false;

  constructor(
    private commonService: CommonService,
    private multipleRefundConfirmationService: MultipleRefundConfirmationService,
    private cache : CacheObservableService,
    private router : Router,
    private toasterService : ToasterService
  ) { }

  ngOnInit() {
    this.request = new RefundConfirmationRequest();
    if(this.cache.has(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS)) {
      this.request.trxIds = this.cache.getAnyObject(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS);
    }
    if(this.cache.has(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA)) {
      this.criteriaSearch = this.cache.getAnyObject(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA);
    }

    this.request.pageIndex = 1;
    this.getTransaction();
  }

  getTransaction(){
    this.commonService.isLoading(true);
    this.multipleRefundConfirmationService.getRefundConfirm(this.request).subscribe(
      result => {
        this.clearTransactionList();
        if (this.commonService.isSuccess(result)) {
          this.refundConfirmationResponse = result;
          this.groupingTransaction();
          this.groupList.map(group => {
            group.trxInfos.map(transaction =>{
              const editedTransaction = this.refundConfirmationEdited.find(editedTransaction => editedTransaction.trxId == transaction.trxId);
              if(!editedTransaction){
                this.refundConfirmationEdited.push(cloneObject(transaction));
              } else {
                editedTransaction.refundAmount = editedTransaction.refundAmount;
              }
            })
          })
        } else {
          if (result.status_code == ERROR_CODE.TRX_EXCEED_LIMIT) {
            this.isExceedLimit = true;
            this.commonService.alertError(message.title.error, result.status_message, null);
          }
        }
      },
      error => {
        this.commonService.isLoading(false);
      },
      () => {
        this.commonService.isLoading(false);
      });
  }

  groupingTransaction() {
    this.collectionSize = +this.refundConfirmationResponse.totalElements;

    for (const group of this.refundConfirmationResponse.trxInfoSummaries) {
      console.log('trx group: ' + group);
      const groupName = group.groupDate.split(' ').shift();
      if (!this.groupList.some(x => x.groupName === groupName)) {
        const transactionList = this.refundConfirmationResponse.multipleRefundConfirmTrxInfos.filter(m =>
          m.createDate.split(' ').shift() === groupName);
        transactionList.map(t => {
          const foundEditedRefund = this.refundConfirmationEdited.find(element => element.trxId == t.trxId);
          if (foundEditedRefund) {
            foundEditedRefund.remainingAmount = t.remainingAmount;
            t.refundAmount = parseFloat(foundEditedRefund.refundAmount) ? parseFloat(foundEditedRefund.refundAmount).toFixed(2) : null;
          } else {
            t.refundAmount = parseFloat(t.remainingAmount).toFixed(2);
          }
        });
        const modelGroup = {
          groupDate: group.groupDate,
          groupName: groupName,
          trxInfos: transactionList
        };
        this.groupList.push(modelGroup);
      }
    }
  }

  pasteInput(event : MultipleTrxInfos) {
    setTimeout(() => {
      event.refundAmount = event.refundAmount.replace(/\D/g, '');
    }, 100);
  }

  keyupDecimalPoint(event : MultipleTrxInfos) {
    if (Number.parseFloat(event.refundAmount) != 0 && Number.parseFloat(event.refundAmount) < 0.01) {
      event.refundAmount = '0';
      return;
    }
    const amount = '' + event.refundAmount;
    const index = amount.indexOf('.');
    if (index >= 0) {
      if (this.isNoDecimalPointCurrency(event)) {
        event.refundAmount = amount.substring(0, index);
      } else {
        event.refundAmount = amount.substring(0, index + 3);
      }
    }
  }

  updateCorrectNumber(event : MultipleTrxInfos) {
    if (!event.refundAmount) {
      // update refund amount in refundConfirmationEdited list
      const refundRecord = this.refundConfirmationEdited.find(refundRecord => refundRecord.trxId === event.trxId);
      refundRecord.refundAmount = event.refundAmount;
      return
    }
    const amount = '' + event.refundAmount;
    const index = amount.indexOf('.');
    if (index >= 0) {
      const numberArray = event.refundAmount.split('.');
      event.refundAmount = numberArray.length == 2 && numberArray[1].length && numberArray[1].length == 1
      ? event.refundAmount + '0' : numberArray[1].length == 2 ? event.refundAmount : event.refundAmount + '00';
      if (!numberArray[0] || numberArray[0].length == 0) {
        event.refundAmount = '0' + event.refundAmount;
      }
    } else {
      event.refundAmount = parseFloat(event.refundAmount).toFixed(2);
    }
    // update refund amount in refundConfirmationEdited list
    const refundRecord = this.refundConfirmationEdited.find(refundRecord => refundRecord.trxId === event.trxId);
    if (refundRecord && event.refundAmount) {
      refundRecord.refundAmount = event.refundAmount;
    }
  }

  isNoDecimalPointCurrency(trxInfo : MultipleTrxInfos): boolean {
    return trxInfo && (trxInfo.currencyCode === 'JPY' || trxInfo.currencyCode === 'KRW');
  }

  parseFloat(amount: string): number{
    return parseFloat(amount);
  }

  loadPage(event:number){
    this.request.pageIndex = event;
    this.getTransaction()
  }

  clearTransactionList() {
    this.collectionSize = 0;
    this.groupList = [];
  }

  validateConfirmButton(): boolean {
    return this.refundConfirmationEdited.some(refundTxn => !refundTxn.refundAmount || !parseFloat(refundTxn.refundAmount)
      || parseFloat(refundTxn.refundAmount) <= 0 || parseFloat(refundTxn.refundAmount) > parseFloat(refundTxn.remainingAmount));
  }

  confirmRefund(){
    const callback = new Subject<boolean>();
    callback.asObservable().subscribe(result => {
      if (result) {
        this.submitRefund();
      }
    });
    this.commonService.alertConfirm(message.title.confirmation, message.content.confirm_multiple_refund, callback);
  }

  submitRefund(){
    this.commonService.isLoading(true);
    this.refundRequest.refundData = [];
    this.request.trxIds.forEach(txnId => {
      const refundTxn = this.refundConfirmationEdited.find(refundTxn => refundTxn.trxId == txnId);
      var refundData: RefundData = new RefundData();
      refundData.trxId = refundTxn ? refundTxn.trxId : txnId;
      refundData.amount = refundTxn ? refundTxn.refundAmount : null;
      this.refundRequest.refundData.push(refundData);
    })
    this.multipleRefundConfirmationService.submitMultipleRefund(this.refundRequest).subscribe(
      result => {
        if(this.commonService.isSuccess(result)){
          this.router.navigate(['/multiple-selection']);
        } else {
          this.toasterService.error(result.status_message ? result.status_message : message.content.multiple_refund_fail, message.title.error);
        }
        this.commonService.isLoading(false);
      }, error => {
        console.log("Multiple Refund error");
        console.log(error);
        this.commonService.isLoading(false);
        this.toasterService.error(message.content.multiple_refund_fail, message.title.error);
      });
  }

  goBack(){
    this.isBackToSelectRefund = true;
    this.cache.set(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_SELECTED_RECORDS, this.request.trxIds);
    if (this.criteriaSearch) {
      this.cache.set(CACHE_OBSERVABLE_SERVICE.MULTIPLE_REFUND_CRITERIA, this.criteriaSearch);
    }
    this.router.navigate(['/multiple-selection/multiple-refund']);
  }

  ngOnDestroy(): void {
    if (!this.isBackToSelectRefund) {
      this.cache.clear();
    }
  }
}
