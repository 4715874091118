import { TransactionRequestDateModel } from './transaction.request.model.date';
import { TransactionRequestStatusStateModel } from './transaction.request.status-state.model';

export class TransactionRequestModel {
    companyId: number[];
    cardNo: string;
    spec: string;
    cardId: string;
    productDescription: string;
    sourceType: string;
    refTrxId: string;
    invoiceNo: string;
    secureTransaction: boolean[];
    paymentType: number[];
    refNumber: string;
    objectId: string;
    trxState: string[];
    trxStatusState: TransactionRequestStatusStateModel[];
    cardType: string[];
    currencyCode: string[];
    bankName: string;
    status: string[];
    paymentLinkRef: string;

    // amountType: string; // CHARGE_AMOUNT, REFUND_BAHT_AMOUNT, SETTLEMENT_BAHT_AMOUNT
    amountOpr: string;
    amountRef1: number;
    amountRef2: number;
    // searchTypeAmount: string;
    mobileNo: string;
    ref1: string;
    ref2: string;
    ref3: string;
    merchantId: string;

    searchDate: TransactionRequestDateModel[];

    // Customer
    customerEmail: string;
    customerName: string;
    customerObjectId: string;
    sourceOfFund: number[];

    // Sub filter
    refundStatus: string;
    refundId: string;
    supplierNo: string;

    channel: string;

    fileName: string;

    pageIndex: number;
    pageSize: number;

    selectedSearchList = [];
    selectedListValue = {};

    //limit records
    isSearching: boolean;
    bulkRefundId: string;
    paymentTypeThaiQR: string;
    transactionThaiQR: boolean;
}
