
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { environment } from '../shared/app-constant';
import { Observable } from 'rxjs';
import { ThaiQRInquiryDetailResponse } from './models/thai-qr-inquiry-detail-response.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ThaiQrInquiryDetailService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getDetail(orderId: string, companyId: number[], orderTransactionId): Observable<ThaiQRInquiryDetailResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    const companyIdList = {
      companyId: companyId
    }
    return this.http.post(
      environment.searchThaiQRInquiry.replace('{userId}', currentUser.userId.toString()) + '/' + orderId + '/' + orderTransactionId,
      JSON.stringify(companyIdList)).pipe(
        map((response: ThaiQRInquiryDetailResponse) => {
          return response
        }));
  }

  inquire(orderId: string, companyId: number[]): Observable<ThaiQRInquiryDetailResponse> {
    const currentUser = this.auth.getCurrentUserInfo();
    const companyIdList = {
      companyId: companyId
    }
    return this.http.post(
      environment.searchThaiQRInquiry.replace('{userId}', currentUser.userId.toString()) + '/' + orderId + '/inquire',
      JSON.stringify(companyIdList)).pipe(
        map((response: ThaiQRInquiryDetailResponse) => {
          return response
        }));
  }
}
