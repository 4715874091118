import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FILE_TYPES, message } from '../../shared/app-constant';
import { Location } from '@angular/common';
import { ToasterService } from '../../services/toaster.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { BulkRefundService } from '../../services/bulk-refund.service';
import { BulkRefundUploadFileRequest } from '../../services/models/bulk-refund-upload-file-request.model';

@Component({
  selector: 'app-bulk-refund-upload-file',
  templateUrl: './bulk-refund-upload-file.component.html',
  styleUrls: ['./bulk-refund-upload-file.component.css']
})
export class BulkRefundUploadFileComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef;
  public fileTypes: any;
  public invalidFile: boolean;
  public bulkRefundUploadModel: BulkRefundUploadFileRequest;
  public fileName: string;
  public errorMsg: string;
  public limitUploadRecord: number;

  constructor(
    private location: Location,
    private toasterService: ToasterService,
    private bulkRefundService: BulkRefundService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fileTypes = FILE_TYPES;
    this.bulkRefundUploadModel = new BulkRefundUploadFileRequest();
    this.fileName = '';
    this.generateBatchId();
  }

  public chooseFile(element) {
    this.inputFile.nativeElement.click();
    element.blur();
  }

  public changeFile(event) {
    this.invalidFile = false;
    const fileInfo = event.target.files[0]
    this.fileName = fileInfo.name;
    this.bulkRefundUploadModel.file = fileInfo;
    this.bulkRefundUploadModel.fileType = fileInfo.type;
  }

  public uploadFile() {
    this.commonService.isLoading(true);
    this.bulkRefundService.uploadFile(this.bulkRefundUploadModel).subscribe((response) => {
      if(this.commonService.isSuccess(response)) {
        this.toasterService.success(message.content.upload_success);
        this.router.navigate(['/bulk-refund']);
        this.invalidFile = false;
      } else {
        this.errorMsg = response.status_message;
        this.invalidFile = true;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }

  public goBack() {
    this.location.back();
  }

  private generateBatchId() {
    this.commonService.isLoading(true);
    this.bulkRefundService.generateBulkRefundId().subscribe((response) => {
      if (this.commonService.isSuccess(response)) {
        this.bulkRefundUploadModel.bulkRefundId = response.batchId;
        this.limitUploadRecord = response.limitUploadRecord;
      }
      this.commonService.isLoading(false);
    }, err => {
      console.log(err);
      this.commonService.isLoading(false);
    });
  }
}
