import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MerchantSettingService } from '../../services/merchant-setting.service';
import { Ewalet, EwaletResponse } from '../../services/models/ewalet-response.model';

@Component({
  selector: 'app-merchant-configuration-wechat',
  templateUrl: './merchant-configuration-wechat.component.html',
  styleUrls: ['./merchant-configuration-wechat.component.css', '../merchant-setting-configuration/merchant-setting-configuration.component.css']
})
export class MerchantConfigurationWechatComponent implements OnInit {

  @Input() companyId: string;

  public viewDetail: boolean = false;
  public totalElements: number;
  public pageIndex: number;
  public pageSize: number;
  public wechats: Ewalet[];
  public isFirstTime: boolean = false;

  constructor(
    private commonService: CommonService,
    private merchantSettingService: MerchantSettingService
  ) { }

  ngOnInit() {
    this.pageIndex = 1;
    this.isFirstTime = true;
  }

  onClickViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (this.viewDetail && this.isFirstTime) {
      this.getWechatPay();
    }
    this.isFirstTime = false;
  }

  loadPage(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.getWechatPay();
  }

  getWechatPay() {
    this.commonService.isLoading(true);
    this.merchantSettingService.getWechatPay(this.companyId, this.pageIndex)
      .subscribe((response: EwaletResponse) => {
        if (this.commonService.isSuccess(response)) {
          this.totalElements = response.totalElement;
          this.pageSize = response.pageSize;
          this.wechats = response.wechatData;
        }
        this.commonService.isLoading(false);
      }, err => {
        this.commonService.isLoading(false);
        console.log("Get WechatPay data error!")
      });
  }

}
