import { TransactionDetailLogModel } from './transaction-detail-log.model';
import { ResultModel } from './result.model';
import { TransactionDetailMulticlearingModel } from './transaction-detail-multiclearing.model';
import { TransactinDetailNotifyHistory } from './transaction-detail-notify-history.model';

export class TransactionDetailModel extends ResultModel {
    trxId: string;
    refTrxId: string;
    invoiceNo: string;
    productDescription: string;
    amount: string;
    capturedAmount: string;
    settledAmount: string;
    bahtSettledAmount: string;
    refundSentAmount: string;
    refundedAmount: string;
    bahtRefundedAmount: string;
    rate: string; // dcc
    bahtAmount: string; // dcc
    currencyCode: string;
    createDate: string;
    authorizeTime: string;
    updateDate: string;
    trxState: string;
    responseCode: string;
    cardId: string;
    tokenId: string;
    cardNumberMask: string;
    cardName: string;
    cardExpired: string;
    cardBrand: string;
    bankName: string;
    voidAfterSettlement: string;
    syncInsertApi: string;
    settlementType: string;
    chargeId: string;
    secureTransaction: string;
    paymentTypeCode: string;
    paymentTypeDesc: string;
    paymentTerm: string;
    paymentRate: string;
    smartpayId: string;
    statusId: string;
    statusStateId: string;
    sourceType: string;
    qrId: string;
    qrType: string;
    allowQrVoid: string;
    linkId: string;
    partnerId: string;
    redirectType: string;
    customerId: string;
    customerEmail: string;
    logs: TransactionDetailLogModel[];
    multiClearingItems: TransactionDetailMulticlearingModel[];
    multiClearing: string;
    convFee: boolean;
    convFeeAmt: string;
    convFeeVatAmt: string;
    convFeeVat: boolean;
    netAmount: number;
    refNumber: string;
    paymentLinkRef: string;
    payBy: string;
    sourceOfFund: string;
    paymentNumber: string;
    ref1: string;
    ref2: string;
    ref3: string;
    info1: string;
    info2: string;
    info3: string;
    billExpired: string;
    billerId: string;
    customerFee: number;
    corporateFee: number;
    billpaymentSof: string;
    merchantId: string;
    terminalId: string;
    campaignCode: string;
    campaignDescription: string;
    allowAlipayRefund: boolean;
    allowWechatRefund: boolean;
    kbankMpi: boolean;
    eci: string;
    xid: string;
    cavv: string;
    notifyHistories: TransactinDetailNotifyHistory[];
    smartpayDescription: string;
    showSmartpayDescription: boolean;
    smartPayRefundable: boolean;
    refundExpired: boolean;
    approvalCode: string;
    smartpayIpanMode: string;
    supplierNo: string;
    channel: string;
    orderId: string;
    cutOffTime: string;
    installmentCrossBank: boolean;
    digipayIssuerBankName: string;
    payerName: string;
    payerBankAccount: string;
    payerBankCode: string;
    qrPaymentType: string;
    originalTransactionId: string;

    constructor() {
        super();
        this.notifyHistories = [];
    }

    // status_code: string;
    // status_message: string;
}
