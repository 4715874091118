import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment, HTTP_ERROR_MESSAGE, message } from "../shared/app-constant";
import { CommonService } from "./common.service";
import { TransactionExportResult, TransactionExports } from "./models/transaction-export-result.model";
import { map, catchError } from "rxjs/operators";
import { ToasterService } from "./toaster.service";
import { ResultModel } from "./models/result.model";
import { AuthenticationService } from "./auth/authentication.service";

@Injectable()
export class TransactionExportsService {

    constructor(
        private http: HttpClient,
        private commonService: CommonService,
        private toasterService: ToasterService,
        private auth: AuthenticationService
    ) { }

    getTransactions(): Observable<TransactionExports[]> {
        return this.http.post(environment.getTransactions.replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()), {pageIndex: 0, pageSize: 10}).pipe(
            map((result: TransactionExportResult) => {
                if (result && this.commonService.isSuccess(result)) {
                    return result.transaction_exports;
                } else {
                    this.toasterService.error(result.status_message);
                    return [];
                }
            }),
            catchError((err) => {
                if (err.status != '401') {
                    this.toasterService.error(HTTP_ERROR_MESSAGE[err.status] || message.content.common_error);
                }
                throw err;
            })

        )
    }

    deleteTransaction(transactionExportId: string): Observable<boolean> {
        return this.http.post(environment.deleteTransaction.replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()), {transactionExportId: transactionExportId}).pipe(
            map((result: ResultModel) => {
                if (this.commonService.isSuccess(result)) {
                    return true;
                } else {
                    return false;
                }
            })
        )
    }
    
    downloadTransaction(transactionExportId: string): Observable<boolean> {
        return this.http.post(environment.downloadTransaction.replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()), {transactionExportId: transactionExportId}).pipe(
            map((result: ResultModel) => {
                if (this.commonService.isSuccess(result)) {
                    const anchor = document.createElement('a');
                    anchor.href = result.properties.preSignUrl;
                    anchor.setAttribute('download', '');
                    anchor.click();
                    return true;
                } else {
                    return false;
                }
            })
        )
    }

    regenerateTransaction(transactionExportId: string): Observable<boolean> {
        return this.http.post(environment.regenerateTransaction.replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()), {transactionExportId: transactionExportId}).pipe(
            map((result: ResultModel) => {
                if (this.commonService.isSuccess(result)) {
                    return true;
                } else {
                    return false;
                }
            })
        )
    }
}