import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { environment } from '../shared/app-constant';
import { map } from 'rxjs/operators';
import { MultipleSelectionResponse } from './models/multiple-selection.model';

@Injectable({
  providedIn: 'root'
})
export class MultipleSelectionService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
  ) { }

  getMultipleSelection(page: number) {
    return this.http.get(
      environment
      .getMultipleSelection
      .replace('{userId}', this.auth.getCurrentUserInfo().userId.toString()) + "/" + page).pipe(
        map((response : MultipleSelectionResponse) => {
          return response;
        }));
  } 
}
